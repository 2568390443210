import React from "react";
import { Box } from "@chakra-ui/core";

import { ReactComponent as BackgroundNotFound } from "./Background pattern decorative.svg";
import { ReactComponent as BackgroundPattern02 } from "./Background pattern decorative-02.svg";
import { ReactComponent as NotFound } from "./Illustration.svg";
import { ReactComponent as User01 } from "./user-01.svg";

export const allIcons = {
	"background-notfound": (props) => <BackgroundNotFound {...props} />,
	"notfound-illustration": (props) => <NotFound {...props} />,
	"user-01": (props) => <User01 {...props} />,
	"background-pattern-02": (props) => <BackgroundPattern02 {...props} />,
};

export const Image = ({
	img,
	sx,
	size,
	width,
	height,
	className,
	color,
	...props
}) => {
	const pixelMe = (val) =>
		val === "auto" || val.includes("%") || val.includes("px")
			? val
			: `${val}px`;
	const svgWidth =
		width !== undefined ? pixelMe(`${width}`) : pixelMe(`${size}`);
	const svgHeight =
		height !== undefined ? pixelMe(`${height}`) : pixelMe(`${size}`);

	if (color) {
		sx = {
			color: color,
			...sx,
		};
	}

	const renderIcon = (icon) => {
		if (allIcons.hasOwnProperty(icon)) {
			return allIcons[icon](props);
		}
		return "";
	};

	return (
		<Box
			sx={{
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				"> svg": {
					width: `${svgWidth}`,
					height: `${svgHeight}`,
					maxWidth: "100%",
					maxHeight: "100%",
				},
				...sx,
			}}
			className={className}
			{...props}
		>
			{renderIcon(img)}
		</Box>
	);
};

Image.defaultProps = {
	size: "auto",
};
