import apiV2 from "api-v2";
import { toFormData } from "axios";
import { GUESTRFQ, GUESTRFQTOTAL } from "constants/guest";
import { showToast } from "utils/helpers";
import { getGuestRFQ, saveGuestRFQ } from "utils/storageHelper";

export const downloadAllDocumentationGuest = async (rfqId, email) => {
	try {
		let response = await apiV2.post(
			`guest/${rfqId}/download-documents`,
			{ email },
			{ responseType: "blob" }
		);
		if (response.status === 200) {
			const url = window.URL.createObjectURL(response.data);
			const a = document.createElement("a");
			a.href = url;
			a.download = "download.zip";
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			showToast("Documents downloaded", "Success", true);
			return true;
		} else {
			showToast("Failed to download documents", "Error");
			return false;
		}
	} catch (e) {
		showToast("Failed to download documents", "Error");
		return false;
	}
};

const savingStorage = (key, payload) => {
	let guest_rfq = {
		total: "",
		data: [],
	};
	let guestRFQStorage = getGuestRFQ();
	if (guestRFQStorage) {
		guest_rfq = { ...guestRFQStorage };
		guest_rfq[key] = payload;
	} else {
		guest_rfq[key] = payload;
	}
	saveGuestRFQ(guest_rfq);
};

export const createRFQTOTAL = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GUESTRFQTOTAL, payload: payload });
			savingStorage("total", payload);
		} catch (err) {
			showToast(err?.message, "Error");
		}
	};
};

export const createRFQ = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GUESTRFQ, payload: payload });
			savingStorage("data", payload);
		} catch (err) {
			showToast(err?.message, "Error");
		}
	};
};

export const getSubmissionGuest = async (rfqId, email) => {
	try {
		let response = await apiV2.post(`guest/show-submission/${rfqId}`, {
			email,
		});
		if (response.status === 200) {
			let quoteData = response?.data?.data?.submission?.quote;
			let returnableData = response?.data?.data?.submission?.returnableSchedule;
			let quoteSavedFile = response?.data?.data?.submission?.quote_file;
			let returnableScheduledSavedFile =
				response?.data?.data?.submission?.returnable_schedule_file;

			return {
				submissionStatus: response?.data?.data?.status,
				quoteData: quoteData,
				returnableData: returnableData,
				rfqDetail: response?.data?.data?.rfq,
				quoteSavedFile,
				returnableScheduledSavedFile,
			};
		} else {
			return null;
		}
	} catch (err) {
		showToast(err?.message, "Error");
		return null;
	}
};

export const acceptDecliceSubmissionGuest = async (rfqId, isAccept, email) => {
	const data = toFormData({
		isAccept,
		email,
	});
	try {
		let response = await apiV2.post(`guest/accept-or-decline/${rfqId}`, data);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		showToast(e?.response?.data?.message, "Error");
		return false;
	}
};

export const saveSubmissionGuestRFQ = async (email, rfqId, value) => {
	const formData = toFormData({ email, ...value });
	try {
		let response = await apiV2.post(`guest/save-submission/${rfqId}`, formData);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast("Failed to save submission", "Error");
			return false;
		}
	} catch (e) {
		showToast(e?.response?.data?.message, "Error");
		return false;
	}
};

export const submitSubmissionGuestRFQ = async (rfqId, email, isRead) => {
	const formData = toFormData({ email, isRead });
	try {
		let response = await apiV2.post(
			`guest/submit-submission/${rfqId}`,
			formData
		);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		showToast(
			e?.response?.data?.message || "Failed to submit submission",
			"Error"
		);
		return false;
	}
};
