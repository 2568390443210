import moment from "moment";

const getDateAndTime = (date, time = "") => {
	if (time) {
		return moment(
			moment(date).format("YYYY-MM-DD") + " " + time + ":00"
		).format("MMMM D YYYY, h:mm A");
	}

	return moment(date).format("MMMM D YYYY, h:mm A");
};

const previewProjectDetails = (rfqDetail, rfqTypes, rfxLead) => [
	{
		label: "Request Title",
		value: rfqDetail?.name || " - ",
	},
	{
		label: "RFx ID number",
		value: rfqDetail?.rfq_id_number || rfqDetail.rfqIdNumber || " - ",
	},
	{
		label: "Type",
		value: rfqDetail?.type
			? rfqTypes?.find((type) => type.value === rfqDetail?.type)?.label ||
				rfqDetail.type
			: " - ",
	},
	{
		label: "Address",
		value: rfqDetail?.address?.value || rfqDetail.location || "-",
	},
	{
		label: "Lead Contact Name",
		value: rfxLead?.full_name || rfqDetail?.rfxLead?.fullName || "-",
	},
	{
		label: "Phone Number",
		value: rfxLead?.phone || rfqDetail?.rfxLead?.phoneNumber || "-",
	},
	{
		label: "Email",
		value: rfxLead?.email || rfqDetail?.rfxLead?.email || "-",
	},
	{
		label: "Description",
		value: rfqDetail?.description || rfqDetail?.documentationDescription || "-",
	},
	{
		label: "Client",
		value: rfqDetail?.client || "-",
	},
	{
		label: "Deadline Date and Time",
		value:
			`${rfqDetail?.deadline_date ? getDateAndTime(rfqDetail?.deadline_date, rfqDetail?.deadline_time) : getDateAndTime(rfqDetail.submissionDeadline)} ${
				rfqDetail?.submission_deadline_timezone || rfqDetail.submissionTimezone
			}` || "-",
	},
	{
		label: "Scope of Service",
		value: rfqDetail?.escope_of_service || rfqDetail.escopeOfService || "-",
	},
];

export const mapFromRFQFormToRFQData = (formValue, rfqTypes, companyUsers) => {
	const rfxLead = companyUsers?.find(
		(user) => user?.id === formValue?.rfx_lead_id
	);

	return {
		projectDetails: previewProjectDetails(formValue, rfqTypes, rfxLead),
		documents: formValue.documents,
		description: formValue.description,
		documentDescription: formValue.documentation_description,
		questionnaire: formValue.sections,
		quote: formValue.quote,
		quoteFile: { name: formValue.quote_file, path: formValue.quote_file_path },
		quoteType: formValue?.quote_type,
		returnableScheduleType: formValue?.returnable_schedule_type,
		returnableScheduleFile: {
			file_name: formValue?.returnable_schedule_file,
			file_path: formValue?.returnable_schedule_file_path,
		},
	};
};

export const mapFromRFQDetailToRFQData = (rfqDetail, rfqTypes) => {
	const regexQuoteFilePathExt = /\.([a-zA-Z0-9]+)\?/;
	const quoteFileName = rfqDetail?.quoteFilePath
		? rfqDetail?.quoteFilePath
				.match(regexQuoteFilePathExt)?.[0]
				.replace("?", "") || ""
		: "";
	return {
		projectDetails: previewProjectDetails(rfqDetail, rfqTypes),
		documents: rfqDetail.documentationDocument,
		documentDescription: rfqDetail.documentationDescription,
		description: rfqDetail.description,
		questionnaire: rfqDetail.questionnnaire,
		quote: rfqDetail.quotes,
		quoteFile: {
			name: `Quote template${quoteFileName}`,
			path: rfqDetail.quoteFilePath,
		},
		quoteType: rfqDetail.quoteType,
		returnableScheduleType: rfqDetail.returnableScheduleType,
		returnableScheduleFile: rfqDetail.returnableScheduleFilePath,
	};
};
