import apiV2 from "api-v2";
import { toFormData } from "axios";
import { showToast } from "utils/helpers";

export const getAddendum = async (id, status) => {
	try {
		const { data: addendum } = await apiV2.get(`addendum/${id}/${status}`);
		if (addendum.status) {
			return addendum;
		} else {
			return null;
		}
	} catch (err) {
		throw new Error(err?.response?.data?.message);
	}
};

export const postAddendumQuestion = async ({
	rfq_id,
	addendum,
	attachments,
}) => {
	const formData = toFormData({ rfq_id, addendum, attachments });
	try {
		const { data: addendum } = await apiV2.post(
			`addendum/store-question`,
			formData
		);
		if (addendum.status) {
			showToast(addendum?.message, addendum?.title, true);
			return addendum.data;
		} else {
			showToast(addendum?.message, addendum?.title);
			return null;
		}
	} catch (err) {
		showToast(err?.response?.data?.message, "Error");
		throw new Error(err?.response?.data?.message);
	}
};

export const postAddendumAnswer = async (data) => {
	const formData = toFormData(data);
	try {
		const { data: addendum } = await apiV2.post(
			`addendum/store-answer`,
			formData
		);
		if (addendum.status) {
			showToast(
				"Your answer to the query has been sent.",
				addendum?.title,
				true
			);
			return addendum.data;
		} else {
			showToast(addendum?.message, addendum?.title);
			return null;
		}
	} catch (err) {
		showToast(err?.response?.data?.message, "Error");
		throw new Error(err?.response?.data?.message);
	}
};

export const updateAddendumAnswer = async (id, data) => {
	const formData = toFormData(data);
	try {
		const { data: addendum } = await apiV2.patch(
			`addendum/${id}/update-answer`,
			formData
		);
		if (addendum.status) {
			showToast(
				data.published ? "Addendum published" : "Addendum unpublished",
				addendum?.title,
				true
			);
			return addendum.data;
		} else {
			showToast(addendum?.message, addendum?.title);
			return null;
		}
	} catch (err) {
		showToast(err?.response?.data?.message, "Error");
		throw new Error(err?.response?.data?.message);
	}
};

export const deleteAddendumClient = async (id, isAnswer) => {
	try {
		const { data: addendum } = await apiV2.delete(
			`addendum/${id}/${isAnswer ? "delete-answer" : "delete-question"}`
		);
		if (addendum.status) {
			showToast(addendum?.message || "Addendum deleted", addendum?.title, true);
			return addendum.data;
		} else {
			showToast(addendum?.message || "Error delete addendum", addendum?.title);
			return null;
		}
	} catch (e) {
		showToast("Error delete addendum", "Error");
		console.log(e);
	}
};
