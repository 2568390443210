import React from "react";
import { useFormikContext } from "formik";
import { Select } from "../Select";

export const ProfileType = ({
	name,
	defaultValue,
	hideClient,
	required,
	...props
}) => {
	const { setFieldValue } = useFormikContext();

	const optionsArray = [
		{
			value: 1,
			label: "Client",
		},
		{
			value: 4,
			label: "Consultant",
		},
		{
			value: 2,
			label: "Head Contractor",
		},
		{
			value: 3,
			label: "Subcontractor",
		},
	];

	hideClient && optionsArray.shift();

	let value = optionsArray.filter((item) => {
		return item.value === defaultValue;
	});

	return (
		<Select
			label={props.label || "Profile Type"}
			options={optionsArray}
			name={name}
			setFieldValue={setFieldValue}
			defaultValue={value}
			required={required}
			{...props}
		/>
	);
};
