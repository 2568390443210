import { Icon } from "assets/icons/Icon";
import { Avatar } from "components/RFQ/Avatar";
import { AddendumAnswer } from "features/addendum/addendum.types";
import moment from "moment";
import { useCurrentUser } from "reducers/store-helpers/auth";
import { cn } from "utils/helpers";
import { AddendumAttachment } from "./AddendumAttachment";

type AddendumUserNameProps = {
	children: React.ReactNode;
	isCurrentUser: boolean;
};

export const AddendumUserName = ({
	children,
	isCurrentUser,
}: AddendumUserNameProps) => {
	return (
		<div className="my-3 flex items-center font-roboto text-[14px] font-medium text-secondary-700">
			<span>{children}</span>
			{isCurrentUser && (
				<div className="ml-3 max-h-[22px] w-12 rounded-full border border-solid border-[#EAECF0] bg-gray-utility-100 text-center font-roboto text-[12px] leading-5 text-primary-900">
					YOU
				</div>
			)}
		</div>
	);
};

// const ChatBubbleBoxStyled = styled.div(() => [
// 	css`
// 		ol {
// 			list-style: decimal;
// 			margin-left: 24px;
// 		}
// 		ul {
// 			list-style: disc;
// 			margin-left: 24px;
// 		}
// 		em {
// 			font-style: italic;
// 			color: inherit;
// 		}
// 		strong {
// 			color: inherit;
// 		}
// 		p {
// 			word-wrap: break-word;
// 		}
// 	`,
// ]);

type ChatBubbleBoxProps = {
	className?: string;
	isPublished?: boolean;
} & React.HTMLAttributes<HTMLParagraphElement>;

export const ChatBubbleBox = ({
	className,
	isPublished,
	...props
}: ChatBubbleBoxProps) => {
	return (
		<div
			className={cn(
				"rounded-md px-3.5 py-2.5 font-roboto text-base",
				isPublished
					? "bg-royal-blue2 text-white"
					: "bg-gray-utility-100 text-primary-900",
				className
			)}
		>
			{props?.children}
		</div>
	);
};

type StatusCompProps = {
	isPublished: boolean;
	createdAt: string;
};

export const StatusComp = ({ isPublished, createdAt }: StatusCompProps) => {
	return (
		<div className="mb-3 mt-1.5 flex items-center justify-between">
			<div className="font-roboto text-xs text-tertiary-600">
				{moment(createdAt).format("dddd h:mmA")}
			</div>
			<div>
				<div className="flex items-center justify-end">
					<div className="text-xs">{isPublished ? "Public" : "Private"}</div>
					<div className="ml-2">
						{" "}
						{/* @ts-ignore icon type is valid but not properly typed */}
						<Icon
							icon={isPublished ? "eye-on" : "eye-off"}
							color="#475467"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

type AnswerCompProps = {
	answer: AddendumAnswer;
	isCommunication: boolean;
};

export const AnswerComp = ({ answer, isCommunication }: AnswerCompProps) => {
	const currentUser = useCurrentUser();
	return (
		<div className={cn("ml-16 flex items-start justify-start gap-3 pb-1")}>
			{/* @ts-ignore */}
			<Avatar
				size="md"
				avatar={answer?.user?.logo_path}
			/>
			<div className="ml-5 w-full">
				<AddendumUserName
					isCurrentUser={answer?.user?.name === currentUser?.full_name}
				>
					{answer?.user?.name}
				</AddendumUserName>
				<ChatBubbleBox isPublished={answer?.published === 1}>
					<div
						dangerouslySetInnerHTML={{
							__html: answer?.addendum?.replace("<span", "<p"),
						}}
					/>

					{answer?.attachments?.map((attachment) => (
						<AddendumAttachment
							attachment={attachment}
							isCommunication={isCommunication}
						/>
					))}
				</ChatBubbleBox>
				<StatusComp
					isPublished={answer?.published === 1}
					createdAt={answer?.createdAt}
				></StatusComp>
			</div>
		</div>
	);
};
