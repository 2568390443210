import { Flex } from "@chakra-ui/react";
import { Link } from "@reach/router";
import { Icon } from "assets/icons/Icon";
import { EditDropdown } from "components/Pages/Projects/EditDropdown";
import { Avatar } from "components/RFQ/Avatar";
import { Button } from "components/RFQ/Button";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import {
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import React from "react";
import tw, { styled, css } from "twin.macro";

const ListViewStyled = styled.div(() => [
	tw`border rounded-lg mb-12`,
	css`
		.title {
			font-weight: 500;
			font-family: Roboto;
			font-size: 14px;
		}
		table {
			border-collapse: collapse;
			border: solid 1px #eaecf0;
		}
		table tr {
			border: solid 1px #eaecf0;
		}
		table td {
			border-bottom: solid 1px #eaecf0;
		}
		.text {
			font-weight: 400;
			font-family: Roboto;
			font-size: 14px;
		}
		.th {
			font-size: 12px;
			line-height: 18px;
			font-weight: 500;
		}
		td:nth-child(1) {
			position: relative;
		}
	`,
]);

const SkeletonCustom = styled.div(() => [
	tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
	<TableRow>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
	</TableRow>
);

const ThComp = ({ title }) => {
	return (
		<div
			className="th font-roboto text-xs text-tertiary-600"
			style={{ gap: "8px", alignItems: "center" }}
		>
			{title}
		</div>
	);
};

const ListViewProjects = ({ data, isLoading, currPage, currentTab }) => {
	return (
		<ListViewStyled>
			<table className="w-full overflow-hidden rounded-lg border bg-white shadow-lg">
				<TableHeader className="rounded-lg">
					<TableRow>
						<TableHead firstCol={true}>
							<div
								className="th flex font-roboto text-tertiary-600"
								style={{ gap: "8px", alignItems: "center" }}
							>
								Project/Asset
							</div>
						</TableHead>
						<TableHead className={tw`min-w-[90px] max-w-[90px]`}>
							<ThComp title="Project Status"></ThComp>
						</TableHead>
						<TableHead className={tw`min-w-[286px] max-w-[286px]`}>
							<ThComp title="Project Lead"></ThComp>
						</TableHead>
						<TableHead className={tw`min-w-[180px] max-w-[180px]`}>
							<ThComp title="No. of Suppliers list"></ThComp>
						</TableHead>
						<TableHead className={tw`min-w-[180px] max-w-[180px]`}>
							<ThComp title="No. of RFx"></ThComp>
						</TableHead>
						<TableHead className={tw`max-w-[68px]`}>
							<div className="hidden">Icon</div>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{isLoading && currPage === 1 && !data ? (
						[...Array(6)].map(() => <SkeleteonRow />)
					) : data?.length > 0 ? (
						<>
							{data.map((project, index) => {
								const slug = project.name?.toLowerCase().replace(/[ /]+/g, "-");
								return (
									<TableRow
										key={index}
										className="group border-b border-gray-200"
									>
										<TableCell
											firstCol={true}
											className={tw`min-w-[380px] max-w-[400px] group-hover:bg-utility-blue-light-50/30`}
										>
											<Link
												to={`${project.id}/${slug}`}
												className="font-roboto font-semibold text-primary-900"
											>
												<div
													className="flex gap-3"
													style={{
														alignItems: "center",
													}}
												>
													<Avatar
														alt={project?.name}
														avatar={
															project.logoPath
																? project.logoPath
																: "/img/placeholder-sm.jpg"
														}
														className="shadow-md shadow-gray-300"
													/>
													<p className="title line-clamp-1 max-w-[360px] text-ellipsis text-nowrap font-roboto capitalize text-primary-900">
														{project.name ? project.name : " - "}
													</p>
												</div>
											</Link>
										</TableCell>

										{/* <TableCell className={tw`max-w-[360px]`}>
                                                    <p className='text overflow-hidden line-clamp-1 text-ellipsis'>
                                                        {project?.description ? htmlToText(project.description) : " - "}
                                                    </p>
                                                </TableCell> */}
										<TableCell
											className={tw`max-w-[300px] group-hover:bg-utility-blue-light-50/30`}
										>
											<StatusBadge status={project.status} />
										</TableCell>
										<TableCell
											className={tw`max-w-[300%] group-hover:bg-utility-blue-light-50/30`}
										>
											<p>{`${project.projectLead?.firstName} ${project.projectLead?.lastName}`}</p>
										</TableCell>
										<TableCell
											className={tw`max-w-[180px] group-hover:bg-utility-blue-light-50/30`}
										>
											<p className="text">
												{project.numberSupplierList
													? project.numberSupplierList
													: "0"}
											</p>
										</TableCell>
										<TableCell
											className={tw`max-w-[180px] group-hover:bg-utility-blue-light-50/30`}
										>
											<p className="text">
												{project.numberRfq ? project.numberRfq : "0"}
											</p>
										</TableCell>

										<TableCell
											className={tw`max-w-[68px] group-hover:bg-utility-blue-light-50/30`}
										>
											<EditDropdown
												id={project.id}
												currentTab={currentTab}
											/>
										</TableCell>
									</TableRow>
								);
							})}
							{isLoading && data && [...Array(4)].map(() => <SkeleteonRow />)}
						</>
					) : (
						<TableRow className="col-span-5 h-[500px]">
							<div className="absolute left-2/4 -translate-x-2/4">
								<NotFoundDatas
									text={`No Projects/Assets Found`}
									description={"To get started, create a Project/Asset."}
								>
									<Link to={`new-project`}>
										<Button style={{ maxHeight: "48px" }}>
											<Flex className="items-center gap-[6px]">
												<div className="flex h-5 w-5 items-center justify-center">
													<Icon
														icon="plus"
														style={{
															width: "12px",
															height: "12px",
															color: "#fff",
															fontWeight: "bold",
														}}
													/>
												</div>
												<div className="font-roboto text-base font-semibold capitalize">
													Create Project/Asset
												</div>
											</Flex>
										</Button>
									</Link>
								</NotFoundDatas>
							</div>
						</TableRow>
					)}
				</TableBody>
			</table>
		</ListViewStyled>
	);
};

export default ListViewProjects;
