import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getApiWithToken } from "../../utils/helpers";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import { cn } from "utils/helpers";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/react";
import tw, { styled } from "twin.macro";
import { toSentenceCase } from "utils/formatText";
import moment from "moment";

export const LogsComponent = ({ company, ...props }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [logs, setLogs] = useState({});

	const fetchLogs = async (pageNumber = 1) => {
		const abortController = new AbortController();
		const fetchData = async () => {
			try {
				const result = await getApiWithToken()
					.get(`logs?company_id=${company.id}&page=${pageNumber}`)
					.json();
				setIsLoading(false);
				setLogs(result);
			} catch (error) {}
		};
		fetchData();
		return () => {
			abortController.abort();
		};
	};

	useEffect(() => {
		if (company) fetchLogs();
	}, [company]); // eslint-disable-line

	const TableHeadComponent = ({ title, className }) => {
		return (
			<div
				className={cn("th font-roboto text-tertiary-600", className)}
				style={{ gap: "8px", alignItems: "center" }}
			>
				{title}
			</div>
		);
	};

	const SkeletonCustom = styled.div(() => [
		tw`animate-pulse bg-gray-300 w-full h-6`,
	]);

	const SkeleteonRow = () => (
		<TableRow>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
		</TableRow>
	);

	const enableNext = () => {
		if (parseInt(logs?.last_page) > parseInt(logs?.current_page)) {
			return true;
		} else {
			return false;
		}
	};

	const enablePrevious = () => {
		if (parseInt(logs?.current_page) === 1) {
			return false;
		} else {
			return true;
		}
	};
	return (
		<>
			<div className="mb-5 mt-5 w-full rounded-xl border border-utility-gray-200 shadow-card">
				<Table className="mb-1 mt-1 rounded-xl">
					<TableHeader>
						<TableRow>
							<TableHead>
								<TableHeadComponent title="Activity Date"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Activity Type"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Activity Action"></TableHeadComponent>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{!isLoading &&
							logs &&
							logs?.data?.map((log, index) => {
								return (
									<TableRow
										key={log.id}
										className="pl-5 pr-5"
									>
										<TableCell className="f font-roboto text-sm font-normal text-tertiary-600">
											{moment(log.created_at).format("DD/MM/YYYY @ hh:mm A")}
										</TableCell>
										<TableCell className="log-type f font-roboto text-sm font-normal text-tertiary-600">
											{toSentenceCase(log.type)}
										</TableCell>
										<TableCell className="font-roboto text-sm font-normal text-tertiary-600">
											{log.action}
										</TableCell>
									</TableRow>
								);
							})}
						{isLoading && (
							<>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
							</>
						)}
					</TableBody>
				</Table>
				{!isLoading && logs && (
					<div className="flex h-[64px] items-center justify-between border-t p-5 transition-colors">
						<Button
							disabled={!enablePrevious()}
							btntype="base"
							className="border border-fg-disabled_subtle shadow-input"
							onClick={() => {
								if (enablePrevious()) {
									fetchLogs(parseInt(logs?.current_page) - 1);
								}
							}}
						>
							<Flex align={"center"}>
								<p className="font-roboto font-semibold text-secondary-700">
									Previous
								</p>
							</Flex>
						</Button>
						<div className="font-roboto text-sm font-medium text-tertiary-600">
							Page {logs?.current_page} of {logs?.last_page}
						</div>
						<Button
							disabled={!enableNext()}
							btntype="base"
							className="border border-fg-disabled_subtle shadow-input"
							onClick={() => {
								if (enableNext()) {
									fetchLogs(parseInt(logs?.current_page) + 1);
								}
							}}
						>
							<Flex align={"center"}>
								<p className="font-roboto font-semibold text-secondary-700">
									Next
								</p>
							</Flex>
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const current_user = state.auth.user;

	return {
		isRequesting: state.companyProfile.isRequesting,
		company: company,
		current_user: current_user,
	};
};

export const Logs = connect(mapStateToProps, {})(LogsComponent);
