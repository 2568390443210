import React from "react";

import { DataTable } from "components/DataTable";
import { NoResults } from "components/NoResults";
import { ResourceLink } from "components/ResourceLink";

export const CompanyResourcesListing = ({
	resources,
	tableHeaders,
	...props
}) => {
	return (
		<>
			{resources.length ? (
				<DataTable
					data={resources}
					component={ResourceLink}
					headers={tableHeaders}
					componentProps={{ className: "col sm-12", edit: false }}
				/>
			) : (
				<NoResults />
			)}
		</>
	);
};
