import { navigate } from "@reach/router";
import { Icon } from "assets/icons/Icon";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
	MenuDivider,
} from "components/RFQ/MenuDropdown";

import tw from "twin.macro";

export const EditRFQDetailThreeDotsMenu = ({
	setTypeModal,
	onOpen,
	projectId,
	rfqId,
}) => {
	const listMenuEdit = [
		{
			name: "Edit Project",
			icon: "edit-icon",
			value: "edit-project",
			divider: false,
		},
		{
			name: "Archive Project",
			value: "archive",
			icon: "archive",
			divider: true,
		},
		{
			name: "Pause Project",
			icon: "pause-square",
			value: "pause",
			divider: false,
		},
		{
			name: "Cancel Project",
			value: "cancel",
			icon: "trash-03",
			divider: false,
		},
	];

	const redirectChecker = `../../../projects/edit-project/${projectId}`;

	const handleButtonClick = (value) => {
		if (value === "edit-rfq") {
			navigate(`../edit-rfx/${rfqId}`);
		}
		if (value !== "edit-project") {
			setTypeModal({
				type: value,
				projectId: projectId,
			});
			onOpen();
		}
		actionFunc(value);
	};

	const actionFunc = (menu) => {
		switch (menu) {
			case "edit-project":
				navigate(redirectChecker);
				break;
			default:
				break;
		}
	};

	return (
		<div>
			<DropdownMenu>
				<DropdownMenuTrigger
					icon
					noBg
				></DropdownMenuTrigger>
				<DropdownMenuDrawer className={tw`-right-40`}>
					{listMenuEdit.map((menu, index) => {
						return (
							<div key={index}>
								<DropdownItem
									className="w-full"
									onClick={() => handleButtonClick(menu.value)}
								>
									<Icon
										icon={menu.icon}
										color="#667085"
										width={16}
										height={16}
									/>
									<p
										className="font-roboto text-[14px] font-medium text-secondary-700 hover:text-[14px]"
										style={{ lineHeight: "20px" }}
									>
										{menu.name}
									</p>
								</DropdownItem>
								{menu.divider && <MenuDivider />}
							</div>
						);
					})}
				</DropdownMenuDrawer>
			</DropdownMenu>
		</div>
	);
};
