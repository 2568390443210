import styled from "styled-components";
import { width, margin, typography, color } from "styled-system";

import { theme } from "../../theme";

export const ButtonPillStyled = styled.button`
	display: inline-block;
	border: none;
	text-transform: uppercase;
	text-align: center;
	font-size: 0.875rem;
	font-weight: 700;
	padding: 4px 14px 2px;
	flex-shrink: 0;
	border-radius: 30px;
	cursor: ${(props) => (props.disablePointer ? "no-drop" : "pointer")};
	pointer-events: ${(props) => (props.disablePointer ? "none" : "auto")};
	line-height: 1.5;
	background: ${theme.colors.mintDark};
	color: white;
	border: none;
	margin-top: 0px;
	transition: ${(props) => props.theme.transitions.default};
	${width}
	${margin}
    ${typography}
    ${color}

    &::hover {
		background: #003acd;
		color: white;
	}

	&:disabled {
		background: grey !important;
	}
`;
