import React from "react";
import RFQStatusComp from "components/RFQ/RFQStatusComp/RFQStatusComp";
import RfQResponseComp from "components/RFQ/RfQResponseComp/RfQResponseComp";
import { TableCell, TableRow } from "components/RFQ/Table";
import tw, { styled } from "twin.macro";
import moment from "moment";
import { Avatar } from "components/RFQ/Avatar";
import { timezoneChecker } from "utils/helpers";
const RfqList = ({ data, navigatePreview, isLoading, isLoadingPagination }) => {
	const SkeletonCustom = styled.div(() => [
		tw`animate-pulse bg-gray-300 w-full h-6`,
	]);

	const SkeleteonRow = () => (
		<TableRow>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
		</TableRow>
	);
	const tableHeader = [
		{
			name: "Project Name",
			width: "20%",
		},
		{
			name: "Response",
			width: "20%",
		},
		{
			name: "Client",
			width: "20%",
		},
		{
			name: "Submission Deadline",
			width: "20%",
		},
		{
			name: "RFx Status",
			width: "10%",
		},
		{
			action: "",
			width: "10%",
		},
	];

	return (
		<div className="overflow-x-auto rounded-md border border-[#EAECF0] bg-white shadow-lg">
			<table className="mb-3 mt-3 w-full table-auto rounded-md border-b border-solid border-[#EAECF0] bg-white">
				<thead style={{ height: "30px" }}>
					<tr className="border-b border-[#EAECF0]">
						{tableHeader.map((th, index) => {
							return (
								<th
									className="font-roboto text-[12px] font-medium text-tertiary-600"
									style={{ width: th.width, textAlign: "left" }}
								>
									<div
										style={{
											marginTop: "-10px",
											marginLeft: index === 0 ? "20px" : "0px",
										}}
									>
										{th?.name}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{!isLoading && (
						<>
							{data.map((singelData, index) => {
								return (
									<tr
										key={index}
										className="border-b border-[#EAECF0] hover:bg-utility-blue-light-50/30"
										onClick={() =>
											navigatePreview(`${singelData?.rfq?.id}/documentation`)
										}
										style={{ cursor: "pointer" }}
									>
										<td className="px-4 py-2">
											<div className="flex items-center">
												<Avatar
													avatar={singelData?.rfq?.project?.logoPath}
													size="lg"
												/>
												<div className="ml-2">
													<h3 className="mt-3 line-clamp-1 overflow-hidden font-roboto text-[14px] font-semibold capitalize text-primary-900">
														{singelData?.rfq?.name}
													</h3>
													<div className="mb-4 font-roboto text-[14px] font-normal uppercase text-primary-500">
														{singelData?.rfq?.supplierList?.name}
													</div>
												</div>
											</div>
										</td>
										<td>
											<RfQResponseComp
												response={singelData?.status}
											></RfQResponseComp>
										</td>
										<td>
											<div className="font-roboto text-[14px] font-medium text-primary-500">
												{singelData?.rfq.client}
											</div>
										</td>
										<td>
											<div className="font-roboto text-[14px] font-medium text-primary-500">
												{moment(singelData?.rfq?.submissionDeadline)
													.tz(
														timezoneChecker(
															singelData?.rfq?.submissionTimezone || "AWST"
														)
													)
													.format("dddd, D MMMM YYYY")}
											</div>
											<div className="font-roboto text-[14px] font-normal text-gray-400">
												{moment(singelData?.rfq?.submissionDeadline)
													.tz(
														timezoneChecker(
															singelData?.rfq?.submissionTimezone || "AWST"
														)
													)
													.format("HH:mm A")}
											</div>
										</td>
										<td>
											<RFQStatusComp
												status={singelData?.rfq?.status}
											></RFQStatusComp>
										</td>
										<td></td>
									</tr>
								);
							})}
						</>
					)}
					{isLoadingPagination && (
						<>
							<SkeleteonRow />
							<SkeleteonRow />
							<SkeleteonRow />
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default RfqList;
