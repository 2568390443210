import React from "react";
import PropTypes from "prop-types";

import { ButtonStyled, ButtonWithIconStyled } from "./styled";

export const Button = ({
	children,
	btntype,
	alt,
	margin,
	padding,
	className,
	disabled,
	active,
	disabledStyle,
	...rest
}) => {
	return (
		<ButtonStyled
			margin={margin}
			btntype={btntype}
			padding={padding}
			disabled={disabled}
			className={className}
			active={active}
			alt={alt ? 1 : undefined}
			{...rest}
		>
			{children}
		</ButtonStyled>
	);
};

Button.defaultProps = {
	btntype: "primary",
	alt: false,
	size: "md",
};

Button.propTypes = {
	btntype: PropTypes.string,
	alt: PropTypes.bool,
	size: PropTypes.string,
	disabledStyle: PropTypes.bool,
};

export const ButtonWithIcon = ({
	text,
	icon,
	style,
	className,
	btntype,
	...props
}) => {
	return (
		<ButtonWithIconStyled
			style={{ ...style }}
			className={className}
			btntype={btntype}
			{...props}
		>
			{icon}
			{text}
		</ButtonWithIconStyled>
	);
};
