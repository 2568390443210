import { FormatedInput } from "components/FormInputs/FormatedInput";
import { useField } from "formik";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { cn } from "utils/helpers";

type TextAreaQuestionnaireProps = {
	className?: string;
	description?: string;
	question: string;
	typeQuestion?: "short" | "long" | "4500";
	required: boolean;
	fieldValue: string;
} & Partial<ComponentPropsWithoutRef<typeof FormatedInput>>;

export const TextAreaQuestionnaire = ({
	className,
	description,
	question,
	typeQuestion = "short",
	required,
	fieldValue,
	...props
}: TextAreaQuestionnaireProps) => {
	const [, { error }] = useField(props.name);

	const characterLimit = useMemo(() => {
		if (typeQuestion === "short") return 500;
		if (typeQuestion === "long") return 1500;
		if (typeQuestion === "4500") return 4500;
		return 1500;
	}, [typeQuestion]);

	const subTitle = useMemo(() => {
		if (typeQuestion === "short") return "Maximum of 500 characters.";
		if (typeQuestion === "long") return "Maximum of 1500 characters.";
		if (typeQuestion === "4500") return "Maximum of 4500 characters.";
		return "Maximum of 1500 characters.";
	}, [typeQuestion]);

	return (
		<div className={cn(className)}>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{description && (
				<p className="text-sm text-tertiary-600">{description}</p>
			)}
			<div>
				{/* @ts-ignore */}
				<FormatedInput
					characterLimit={characterLimit}
					rfq
					className="my-4 w-full"
					required={required}
					placeholder="Enter a description..."
					subTitle={subTitle}
					// hideError
					{...props}
				/>
			</div>
		</div>
	);
};
