import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React from "react";
import tw, { styled } from "twin.macro";

const TabButtonStyled = styled.button(({ isActive, isFinished }) => [
	tw`border-t-4 pt-3 bg-white text-left w-full items-center`,
	!isActive && tw`border-t-quarternary-400`,
	isActive && tw`border-t-royal-blue text-royal-blue`,
	isFinished && tw`border-t-utility-brand-300 text-black`,
]);

function TabButton({
	label,
	description,
	isFinished,
	onClick,
	index,
	selectedTab,
	value,
	isActive,
	isError,
	...props
}) {
	return (
		<TabButtonStyled
			isActive={isActive}
			onClick={onClick}
			isFinished={isFinished}
			{...props}
		>
			<Flex
				gap={3}
				align={"center"}
			>
				<div id="indicator-tab">
					{isActive ? (
						<div className="h-6 w-6 content-center rounded-full bg-royal-blue ring-4 ring-utility-blue-light-50 [text-align:-webkit-center]">
							<div className="h-2 w-2 rounded-full bg-white" />
						</div>
					) : isError ? (
						<div className="h-6 w-6 content-center rounded-full bg-red-500 ring-4 ring-utility-blue-light-50 [text-align:-webkit-center]">
							<div className="h-2 w-2 rounded-full bg-white" />
						</div>
					) : isFinished ? (
						<Icon
							icon="check-box"
							size="sm"
							className="text-utility-brand-300"
						/>
					) : (
						<div className="h-6 w-6 content-center rounded-full border bg-gray-utility-100 [text-align:-webkit-center]">
							<div className="h-2 w-2 rounded-full bg-fg-disabled_subtle" />
						</div>
					)}
				</div>
				<div id="label-button">
					<p className="text-sm font-semibold">{label}</p>
					<p className="text-sm font-normal">{description}</p>
				</div>
			</Flex>
		</TabButtonStyled>
	);
}

export default TabButton;
