import React from "react";
import ReactModal from "react-modal";

import {
	ModalStyled,
	ModalInner,
	ModalTransitionStyles,
	CloseButton,
} from "./styled";
import { ReactComponent as CloseIcon } from "./close.svg";

ReactModal.setAppElement("#root");

export const Modal = ({
	children,
	isOpen,
	content,
	width,
	height,
	scroll,
	hideClose,
	blurBackground,
	onRequestClose,
	backgroundImage,
	innerClassName,
	...rest
}) => {
	const modalStyles = {
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "rgba(36, 36, 36, 0.75)",
			background: backgroundImage
				? `url(${backgroundImage}) rgba(36, 36, 36, 0.75) no-repeat fixed`
				: "rgba(36, 36, 36, 0.75)",
			backgroundBlendMode: backgroundImage && "overlay",
			backgroundSize: backgroundImage && "cover",
			height: "100%",
			overflow: "hidden",
			backdropFilter: blurBackground ? "blur(2.5px)" : "none",
			zIndex: 999,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		content: {
			maxHeight: height ? height : "calc(100% - 60px)",
			position: "relative",
			margin: "0 auto",
			width: "100%",
			maxWidth: `${width ? width : 500}px`,
		},
	};

	return (
		<>
			<ModalTransitionStyles />
			<ModalStyled
				isOpen={isOpen}
				closeTimeoutMS={500}
				style={modalStyles}
				onRequestClose={onRequestClose}
				{...rest}
			>
				{!hideClose && (
					<CloseButton
						isOpen={isOpen}
						onClick={onRequestClose}
						top={rest.closeIconTop}
					>
						<CloseIcon
							height={rest.closeIconHeight || "24px"}
							width={rest.closeIconWidth || "24px"}
						/>
					</CloseButton>
				)}

				<ModalInner
					isOpen={isOpen}
					scroll={scroll}
					className={innerClassName}
				>
					{children}
				</ModalInner>
			</ModalStyled>
		</>
	);
};
