import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { cn } from "utils/helpers";

const Tooltip = ({
	label,
	placement,
	className,
	children,
	shouldWrapChildren = true,
	...props
}) => {
	return (
		<ChakraTooltip
			label={label}
			placement={placement}
			hasArrow
			shouldWrapChildren={shouldWrapChildren}
			className={cn(
				"!border !border-gray-200 !bg-gray-900 !bg-opacity-80 !shadow-lg",
				className
			)}
			{...props}
		>
			{children}
		</ChakraTooltip>
	);
};

export default Tooltip;
