import React, { useReducer, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { ProjectDetailCard } from "components/ProjectCard";
import { ProjectCardStyled } from "../ProjectCard/styled";
import { DataTable } from "../DataTable";
import { ProjectLink } from "../ProjectLink";
import { NoResults } from "../NoResults";
import { SearchBox } from "../SearchBox";
import { SectorsStyled } from "../SearchBox/styled";
import api from "../../api";
import { getCookie } from "utils/cookiesHelper";
import { Modal } from "components/Modal";

export const ProjectsRow = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.space.lg};

	${ProjectCardStyled} {
		width: 50%;
		flex-basis: 50%;
		padding: 0 15px;
	}
`;

const ProjectsFilter = styled(SearchBox)`
	margin-bottom: ${(props) => props.theme.space.sm};
	z-index: 9;
	position: relative;
	padding-inline: 15px;

	${SectorsStyled} {
		width: 100%;
		max-width: 400px;
	}
`;

export const CompanyProjectsListingContainer = ({
	pastProjects,
	companyId,
	loadingPastProjects,
	profileTypeId,
	...props
}) => {
	const initialState = { projects: pastProjects, isSearching: false };

	const urlParams = new URLSearchParams(window.location.search);
	const projectId = urlParams.get("project");
	const [isModalOpen, setIsModalOpen] = useState(projectId);

	const filterReducer = (state, action) => {
		switch (action.type) {
			case "FILTER_REQUESTED":
				return {
					...state,
					isSearching: true,
					hasSearched: true,
				};
			case "FILTER_SUCCESS":
				return {
					...state,
					isSearching: false,
					projects: action.payload,
				};
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(filterReducer, initialState);
	const { projects } = state;

	function getApiWithToken() {
		const token = getCookie("access_token");
		return api.extend({
			hooks: {
				beforeRequest: [
					(request) => {
						request.headers.set("Authorization", `Bearer ${token}`);
					},
				],
			},
		});
	}

	const onFilterSubmit = async (values) => {
		let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
		const { search, sector } = values;
		dispatch({ type: "FILTER_REQUESTED" });

		try {
			const filteredProjects = await getApiWithToken()
				.get(
					`past-projects?filter[company_id]=${companyId}&filter[name]=${search}&filter[sector_id]=${
						sector || ""
					}&published=true&email=${guest_email?.email}`
				)
				.json();
			await dispatch({
				type: "FILTER_SUCCESS",
				payload: filteredProjects.data,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const tableHeaders = [
		"",
		{ label: "Name", key: "name" },
		{ label: "Primary Sector", key: "sector.name" },
		{ label: "Construction Value (AUD)", key: "value" },
		{ label: "Year Complete", key: "year_completed" },
		{ label: "Procurement Model", key: "procurement_methods[0].name" },
	];

	const tableHeadersSubcontractor = [
		"",
		{ label: "Name", key: "name" },
		{ label: "Primary Sector", key: "sector.name" },
		{ label: "Trade Packages Value (AUD)", key: "trade_value" },
		{ label: "Year Complete", key: "year_completed" },
		{ label: "Trade Package/s", key: "trades[0].name" },
	];

	return (
		<>
			{pastProjects.length > 0 && (
				<ProjectsFilter
					placeholder="Search resources"
					handleFormSubmit={onFilterSubmit}
					sectorFilter
					showPorojectsCount
					resultsCount={projects.length}
					companyId={companyId}
				/>
			)}

			{!state.isSearching ? (
				<div>
					<ProjectsRow className="row">
						{projects && projects.length ? (
							projects.slice(0, 2).map((project, index) => (
								<ProjectDetailCard
									data={project}
									key={index}
									profileTypeId={profileTypeId}
									noPaddings
								/>
							))
						) : (
							<NoResults text="No projects found" />
						)}
					</ProjectsRow>

					{projects.slice(2).length > 0 && (
						<>
							<h3>More projects by {props.company.name}</h3>
							<div className="overflow-x-auto px-4 pb-8">
								<DataTable
									data={projects.slice(2)}
									headers={
										profileTypeId === 3
											? tableHeadersSubcontractor
											: tableHeaders
									}
									component={ProjectLink}
									componentProps={{ className: "col px-8" }}
								/>
							</div>
						</>
					)}
				</div>
			) : (
				<Skeleton
					count={30}
					height={12}
					duration={1}
				/>
			)}

			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				width="50%"
				className="w-1/2 max-w-[1000px]"
				scroll
				shouldCloseOnOverlayClick
				innerClassName={"!max-h-[90vh]"}
			>
				<ProjectDetailCard
					data={projects?.find(
						(project) => project?.id === parseInt(projectId)
					)}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	const { activeCompany } = state.search;

	return {
		company: activeCompany,
		profileTypeId: activeCompany.profile_type.id,
	};
};

export const CompanyProjectsListing = connect(
	mapStateToProps,
	{}
)(CompanyProjectsListingContainer);
