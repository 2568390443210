// validation for single input components where formik is not used

export const isNull = (value) => {
	return value === "" || value === null;
};

export const checkIsNotEmpty = (val) => {
	if (val !== undefined && val !== null && val !== "") {
		return true;
	} else {
		return false;
	}
};

export const checkIsValidNumber = (value) => {
	if (checkIsNotEmpty(value)) {
		if (parseInt(value) > 0) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const isArray = (type) => type === "checkbox";
export const isSmartField = (type) =>
	type === "project_experience" ||
	type === "key_personnel" ||
	type === "corporate_documents";
export const isFile = (type) => type === "file";
