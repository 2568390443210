import React from "react";
import { TagContainerStyled, AllTagContainer, Tag } from "./styled";

export const TagContainer = ({ label, datas, ...props }) => {
	return (
		<TagContainerStyled>
			{label && <h3 className="mb-2 capitalize">{label}</h3>}
			{datas.length > 0 && (
				<AllTagContainer className="alltag">
					{datas.map((data) => (
						<Tag>{data.name}</Tag>
					))}
				</AllTagContainer>
			)}
		</TagContainerStyled>
	);
};

export default TagContainer;
