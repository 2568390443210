import React, { useEffect, useRef } from "react";
import { Portal } from "../Portal";
import { useState } from "react";
import { usePopper } from "react-popper";
import { useValueRef } from "hooks/useValueRef";
import { useClickAway } from "react-use";

export const PopoverTrigger = React.forwardRef(
	({ children, ...props }, ref) => {
		return (
			<div
				ref={ref}
				{...props}
			>
				{children}
			</div>
		);
	}
);

export const PopoverContent = React.forwardRef(
	({ children, clickAwayHandler, ...props }, ref) => {
		return (
			<div
				ref={ref}
				{...props}
			>
				{children}
			</div>
		);
	}
);

export const Popover = ({
	isOpen,
	closeHandler,
	openHandler,
	placement,
	offset,
	children,
	keyFor,
}) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const clickoutsideRef = useRef(null);
	const [canClose, setCanClose] = useState(false);
	const { styles, attributes, update } = usePopper(
		referenceElement,
		popperElement,
		{
			strategy: "fixed",
			placement: placement || "bottom-start",
			boundariesElement: "viewport",
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						padding: 12,
						altAxis: true,
						boundariesElement: "viewport",
					},
				},
				{ name: "offset", options: { offset: offset || [0, 20] } },
			],
		}
	);

	const openHandleTrigger = (e) => {
		setCanClose(true);
		openHandler && openHandler();
		e.stopPropagation();
	};

	const trigger = React.cloneElement(children[0], {
		ref: setReferenceElement,
		onClick: openHandleTrigger,
		id: keyFor,
	});
	const content = React.cloneElement(children[1], {
		ref: setPopperElement,
		style: { zIndex: 9999, ...styles.popper },
		clickAwayHandler: closeHandler,
		...attributes.popper,
	});

	useEffect(() => {
		if (isOpen && update) {
			update();
		}
	}, [update, isOpen]);

	const popperRef = useValueRef(popperElement);
	useClickAway(popperRef, (e) => {
		if (isOpen && canClose) {
			closeHandler();
		}
	});

	return (
		<div
			ref={clickoutsideRef}
			id={keyFor}
			key={keyFor}
		>
			{trigger}
			{isOpen && <Portal>{content}</Portal>}
		</div>
	);
};
