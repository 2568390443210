import { useSelector } from "react-redux";
import { User } from "types/auth.types";
import { Company } from "types/company.types";

export const useCurrentCompany = () => {
	const currentCompany = useSelector(
		(state: any) => state?.auth?.current_company
	);
	return currentCompany as Company;
};

export const useCurrentUser = () => {
	const currentUser = useSelector((state: any) => state?.auth?.user);
	return currentUser as User;
};
