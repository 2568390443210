import apiV2 from "api-v2";

const createQuestionServices = async (payload) => {
	return await apiV2.post(`addendum/store-question`, payload);
};
const getAddendumServices = async (rfqId, status) => {
	return await apiV2.get(`addendum/${rfqId}/${status}`);
};
const deleteAddendumQuestionServices = async (rfqId, status) => {
	return await apiV2.delete(`addendum/${rfqId}/delete-question`);
};

export {
	createQuestionServices,
	getAddendumServices,
	deleteAddendumQuestionServices,
};
