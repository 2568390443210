import {
	Card,
	Flex,
	CircularProgress,
	CircularProgressLabel,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useEffect } from "react";
import { formatBytes } from "utils/formatBytes";
import { cn, filenameSpliter } from "utils/helpers";
import { Input } from "../Input/Input";
import useChunkFileUpload from "hooks/useChunkFileUpload";

export const FileCardSelect = ({
	file,
	index,
	setFiles,
	deleteHandler,
	uploadProgress,
	selectOptions,
	fileSize,
	selectValue,
	draggableProps,
	fileExtraData,
	handleFileUploadComplete,
	handleFileUploadStart,
	className,
}) => {
	const filename =
		typeof file === "string"
			? file
			: file?.path ||
				file?.name ||
				file?.fileName ||
				file?.file_name ||
				file?.title ||
				file?.document ||
				"-";
	const filesize = fileSize
		? formatBytes(fileSize)
		: formatBytes(file?.size) || null;
	const ext = filenameSpliter(filename).ext;
	// Calculate the background color based on the upload progress
	let bgColor;
	if (uploadProgress < 50) {
		bgColor = "red.50";
	} else if (uploadProgress < 75) {
		bgColor = "yellow.50";
	} else {
		bgColor = "green.50";
	}

	const { uploadFile, progress, error, isUploading } = useChunkFileUpload({
		handleFileUploadComplete: handleFileUploadComplete,
	});

	const changeHandler = (e) => {
		setFiles((prev) => {
			let newArr = [...prev];
			newArr[index].category_id = e.value;
			return newArr;
		});
	};

	useEffect(() => {
		if (typeof file !== "string") {
			uploadFile(file, fileExtraData);
			handleFileUploadStart();
		}
		// eslint-disable-next-line
	}, [file]);

	return (
		<Card
			className={cn(
				`border-gray-outline hover:text-purple group my-4 border !bg-white px-4 py-3 hover:border-blue-50 hover:bg-blue-50 ${bgColor} ${className}`
			)}
			radius="sm"
		>
			<Flex className="relative items-center justify-between gap-4">
				<Flex className="relative items-center justify-between gap-4">
					<div {...draggableProps}>
						<Icon
							icon={"sixDots"}
							size="md"
						/>
					</div>
					<Icon
						icon={ext}
						size="md"
					/>
					<div>
						<p className="text-sm font-semibold group-hover:underline">
							{filename}
						</p>
						{filesize && <p className="text-sm font-thin">{filesize}</p>}
					</div>
				</Flex>
				<div className="flex items-center gap-4">
					<Input
						type="select"
						className="w-64"
						options={selectOptions}
						onChange={changeHandler}
						value={selectValue}
						fullIndicator
						size="sm"
						checkIcon
					/>
					<div className="flex items-center gap-8">
						{isUploading ? (
							<CircularProgress
								value={`${error ? 100 : progress}`}
								color={`${error ? "red" : "green.400"}`}
							>
								<CircularProgressLabel>
									{error ? (
										<button
											onClick={() => {
												uploadFile(file);
											}}
										>
											Retry
										</button>
									) : (
										`${progress}%`
									)}
								</CircularProgressLabel>
							</CircularProgress>
						) : (
							<>
								<div className="flex group-hover:hidden">
									<Icon icon="check" />
								</div>
								<button
									className="hidden cursor-pointer rounded-full bg-gray-300 p-2 hover:bg-red-200 group-hover:block"
									onClick={deleteHandler}
								>
									<Icon
										icon="trash"
										style={{ width: "16px", height: "16px" }}
									/>
								</button>
							</>
						)}
					</div>
				</div>
			</Flex>
		</Card>
	);
};
