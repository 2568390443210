//ui
export const IS_LOADING = "IS_LOADING";
export const IS_LOADING_NEXT = "IS_LOADING_NEXT";
export const IS_REQUESTING = "REQUESTING";
export const CREATE_PROFILE_TYPE = "CREATE_PROFILE_TYPE";
//companies/account
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_PER_PAGE = "GET_COMPANIES_PER_PAGE";
export const GET_COMPANIES_SEARCH = "GET_COMPANIES_SEARCH";
export const GET_COMPANY = "GET_COMPANY";
export const SUBMIT_COMPANY_PROFILE_FORM = "SUBMIT_COMPANY_PROFILE_FORM";
export const SEARCH_COMPANIES = "SEARCH_COMPANIES";
export const SEARCH_COMPANIES_DIRECT = "SEARCH_COMPANIES_DIRECT";
export const RESUME_SEARCH_COMPANIES = "RESUME_SEARCH_COMPANIES";
export const IS_SEARCHING = "IS_SEARCHING";
export const GET_COMPANY_SIZE_OPTIONS = "GET_COMPANY_SIZE_OPTIONS";
export const GET_POSTCODE_REGIONS = "GET_POSTCODE_REGIONS";
export const GET_LOCAL_GOVT_ASSOCIATION = "GET_LOCAL_GOVT_ASSOCIATION";
export const SEARCH = "SEARCH";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_LAST4 = "UPDATE_LAST4";
export const UPDATE_SUBSCRIPTION_ACCOUNT = "UPDATE_SUBSCRIPTION_ACCOUNT";
export const UPDATE_SUBSCRIPTION_PROFILE = "UPDATE_SUBSCRIPTION_PROFILE";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";
export const UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO";

//search
export const HAS_SEARCHED = "HAS_SEARCHED";
export const REMOVE_COMPANY_FROM_RESULTS = "REMOVE_COMPANY_FROM_RESULTS";
export const ADD_COMPANY_TO_COMPARE = "ADD_COMPANY_TO_COMPARE";
export const ADD_COMPANY_TO_COMPARE_DIRECT = "ADD_COMPANY_TO_COMPARE_DIRECT";
export const ADD_ALL_COMPANY_TO_COMPARE_DIRECT =
	"ADD_ALL_COMPANY_TO_COMPARE_DIRECT";
export const REMOVE_COMPANY_FROM_COMPARE = "REMOVE_COMPANY_FROM_COMPARE";
export const ADD_COMPANY_TO_SHORTLIST = "ADD_COMPANY_TO_SHORTLIST";
export const ADD_COMPANY_TO_SHORTLIST_DIRECT =
	"ADD_COMPANY_TO_SHORTLIST_DIRECT";
export const ADD_ALL_COMPANY_TO_SHORTLIST_DIRECT =
	"ADD_ALL_COMPANY_TO_SHORTLIST_DIRECT";
export const REMOVE_COMPANY_TO_SHORTLIST_DIRECT =
	"REMOVE_COMPANY_TO_SHORTLIST_DIRECT";
export const REMOVE_ALL_COMPANY_TO_SHORTLIST_DIRECT =
	"REMOVE_ALL_COMPANY_TO_SHORTLIST_DIRECT";
export const REMOVE_COMPANY_FROM_SHORTLIST = "REMOVE_COMPANY_FROM_SHORTLIST";
export const ADD_SEARCH_RESULT_LOCKED_ITEM = "ADD_SEARCH_RESULT_LOCKED_ITEM";
export const ADD_COMPARE_LOCKED_ITEM = "ADD_COMPARE_LOCKED_ITEM";
export const REMOVE_SEARCH_RESULT_LOCKED_ITEM =
	"REMOVE_SEARCH_RESULT_LOCKED_ITEM";
export const REMOVE_COMPARE_LOCKED_ITEM = "REMOVE_COMPARE_LOCKED_ITEM";
export const REORDER_SEARCH_RESULTS = "REORDER_SEARCH_RESULTS";
export const REORDER_COMPARE_RESULTS = "REORDER_COMPARE_RESULTS";
export const GET_REGION_OPTIONS = "GET_REGION_OPTIONS";
export const GET_GROUP = "GET_GROUP";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const ADD_TO_GROUP = "ADD_TO_GROUP";
export const ADD_PROJECT_TO_COMPARE = "ADD_PROJECT_TO_COMPARE";
export const GET_DISCIPLINES_BY_PROFILE_TYPE =
	"GET_DISCIPLINES_BY_PROFILE_TYPE";
export const CLEAR_LISTS = "CLEAR_LISTS";
export const GET_ALL_DISCIPLINES = "GET_ALL_DISCIPLINES";
export const GET_ALL_TYPES = "GET_ALL_TYPES";
export const SET_ADD_PROJECT_INDEX = "SET_ADD_PROJECT_INDEX";
export const REMOVE_COMPANY_CARD_PROJECT = "REMOVE_COMPANY_CARD_PROJECT";
export const ADD_COMPANY_CARD_PROJECT = "ADD_COMPANY_CARD_PROJECT";
export const GET_COMPARE_LIST = "GET_COMPARE_LIST";
export const GET_SHORTLIST = "GET_SHORTLIST";
export const ADDING_CARD_TO_COMPARE = "ADDING_CARD_TO_COMPARE";
export const ADDING_CARD_TO_SHORTLIST = "ADDING_CARD_TO_SHORTLIST";
export const ADDING_PROJECT_TO_COMPARE = "ADDING_PROJECT_TO_COMPARE";
export const IS_ADDING_PROJECT = "IS_ADDING_PROJECT";
export const GET_PRORATE_AMOUNT = "GET_PRORATE_AMOUNT";
export const GET_CALCULATION = "GET_CALCULATION";
export const SET_ACCOUNT_STATUS = "SET_ACCOUNT_STATUS";
export const UPDATE_PLAN_DATA = "UPDATE_PLAN_DATA";
export const CREATE_SUPPLIER_LIST = "CREATE_SUPPLIER_LIST";
export const ADD_SUPPLIER_LIST = "ADD_SUPPLIER_LIST";
export const REMOVE_SUPPLIER_LIST = "REMOVE_SUPPLIER_LIST";

//users/resources
export const GET_RESOURCES = "GET_RESOURCES";
export const ADD_RESOURCE = "ADD_RESOURCE";
export const EDIT_RESOURCE = "EDIT_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const REQUESTING_RESOURCES = "REQUESTING_RESOURCES";

//past projects
export const GET_PAST_PROJECTS = "GET_PAST_PROJECTS";
export const ADD_PAST_PROJECT = "ADD_PAST_PROJECT";
export const EDIT_PAST_PROJECT = "EDIT_PAST_PROJECT";
export const DELETE_PAST_PROJECT = "DELETE_PAST_PROJECT";
export const SAVING_DRAFT_PAST_PROJECT = "SAVE_DRAFT_PAST_PROJECT";
export const FETCHING_PAST_PROJECTS = "FETCHING_PAST_PROJECTS";
export const IS_SEARCHING_PROJECTS = "IS_SEARCHING_PROJECTS";

//all projects
export const FETCHING_ALL_PROJECTS = "FETCHING_ALL_PROJECTS";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const ADD_ALL_PROJECTS = "ADD_ALL_PROJECTS";

//documents
export const GET_DOCUMENT_CATEGORIES = "GET_DOCUMENT_CATEGORIES";
export const GET_DOCUMENT_SUBCATEGORIES = "GET_DOCUMENT_SUBCATEGORIES";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DOCUMENT_IS_DELETING = "DOCUMENT_IS_DELETING";
export const DOCUMENT_ACCESS_REQUEST = "DOCUMENT_ACCESS_REQUEST";
export const UPDATE_DOCUMENT_ACCESS_REQUEST = "UPDATE_DOCUMENT_ACCESS_REQUEST";
export const SEND_DOCUMENT_ACCESS_REQUEST = "SEND_DOCUMENT_ACCESS_REQUEST";

//form options
export const GET_SECTORS = "GET_SECTORS";
export const GET_PROCUREMENT_METHODS = "GET_PROCUREMENT_METHODS";
export const GET_EXPERTISE_DISCIPLINES = "GET_EXPERTISE_DISCIPLINES";
export const GET_TRADES = "GET_TRADES";
export const GET_TRADES_VALUE = "GET_TRADES_VALUE";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_GLOBAL_PRESENCE = "GET_GLOBAL_PRESENCE";
//account types

export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
//auth
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER = "GET_USER";
export const GET_SIGNUP_DETAILS = "GET_SIGNUP_DETAILS";
export const GET_CURRENT_COMPANY = "GET_CURRENT_COMPANY";
export const AUTHENTICATE_TWO_FACTOR = "AUTHENTICATE_TWO_FACTOR";
export const ENABLED_TWO_FACTOR = "ENABLED_TWO_FACTOR";
export const DEACTIVATE_TWO_FACTOR = "DEACTIVATE_TWO_FACTOR";

//account-management

export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";
export const USER_DRAFT = "USER_DRAFT";

//guest-account

export const GUEST_SIGNUP = "GUEST_SIGNUP";
export const GUEST_SIGNIN = "GUEST_SIGNIN";
export const LOGOUT_GUEST_USER = "LOGOUT_GUEST_USER";
export const AUTHENTICATE_GUEST_USER = "AUTHENTICATE_GUEST_USER";
export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const INVITED_USER = "INVITED_USER";
export const UPDATE_INVITED_USER = "UPDATE_INVITED_USER";
