import { Flex, Spinner } from "@chakra-ui/react";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Heading } from "components/Heading";
import { FileUploadSelect } from "components/RFQ/FileUpload/FileUploadSelect";
import { FieldForm } from "components/RFQ/Input/Input";
import React from "react";
import tw, { styled } from "twin.macro";
import { AcceptedFileTypesForRFxWithImage } from "utils/rfxLargeFileUploadHelper";

const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const Documentation = ({ titlePage, subTitlePage, documentCategories }) => {
	const isLoading = false;

	return (
		<ProjectDetailContainer>
			<div
				id="subheader-page"
				className="py-8"
			>
				<div
					id="subheader-page-title"
					className="text-xl font-semibold text-primary-900"
				>
					<Heading
						fontSize={"36px"}
						color="#003CE9"
					>
						{" "}
						{titlePage}
					</Heading>
				</div>
				<div
					id="subheader-page-subtitle"
					className="mt-1 font-roboto text-sm text-tertiary-600"
				>
					{subTitlePage}
				</div>
			</div>
			<div
				id="project-detail-content"
				className="relative h-full min-h-[60vh]"
			>
				{!isLoading ? (
					<>
						<Flex>
							<FormatedInput
								label="Documentation Description (Optional)"
								name="documentation_description"
								characterLimit={1500}
								rfq
								className="w-full"
								placeholder={
									"E.g. Tender Documents - Information for Tenderers"
								}
								subTitle={
									"Summarise what documents are uploaded as part of this request."
								}
							/>
						</Flex>
						<Flex
							gap={8}
							marginBottom={20}
						>
							<FileUploadSelect
								label={"Document upload"}
								multiple={true}
								name="documents"
								maxFileSize={100}
								types={AcceptedFileTypesForRFxWithImage}
								defaultFileName="Document"
								selectName="category_id"
								selectOptions={documentCategories}
								required={true}
								customMessage="Please select category before uploading the document"
								subtitle="Select a category and upload the relevant  document/s.  Once uploaded, you can re-order the documents.  "
							>
								<FieldForm
									name="category_id"
									type="select"
									placeholder="Select category"
									className="my-3 w-60"
									options={documentCategories}
									onClick={(e) => e.stopPropagation()}
									size="sm"
									checkIcon
								/>
							</FileUploadSelect>
						</Flex>
					</>
				) : (
					<Spinner
						className="absolute left-1/2 top-[30vh] -translate-x-1/2"
						size="xl"
					/>
				)}
			</div>
		</ProjectDetailContainer>
	);
};

export default Documentation;
