import React, { useContext } from "react";
import { Box } from "@chakra-ui/react";
import {
	renderQuestion,
	SectionComponent,
} from "components/RFQ/QuestionComponent";
import { fieldTypeQuestion } from "helpers/Enum/FieldTypeList";
import { useSelector } from "react-redux";
import ReturnableSchedulesFileUploadComp from "./ReturnableSchedulesFileUploadComp";
import { filterFieldValues } from "utils/helpers";
import { RFQContext } from "context/RFQContext";

const ReturnableSchedulesComp = ({
	questionnaire,
	touchedForm,
	isGuest,
	isReturnableSchedulesTypeFile,
	companyName,
	templateReturnableScheduleFile,
	disabled,
}) => {
	const { documents, resources, pastProjects } = useSelector(
		(state) => state?.companyProfile
	);
	let hasDocuments = documents?.length > 0;
	let hasResources = resources?.length > 0;
	let hasPasProjects = pastProjects?.length > 0;
	const { rfqId, guestUserId, currentCompanyId } = useContext(RFQContext);

	const isSmartField = (type) =>
		type === "project_experience" ||
		type === "key_personnel" ||
		type === "corporate_documents";

	return (
		<div className="space-y-10">
			{isReturnableSchedulesTypeFile === "file" ? (
				<ReturnableSchedulesFileUploadComp
					companyName={companyName}
					templateReturnableScheduleFile={templateReturnableScheduleFile}
					currentCompanyId={currentCompanyId}
					rfqId={rfqId}
					guestUserId={guestUserId}
					disabled={disabled}
				/>
			) : (
				questionnaire?.map((section, sectionID) => {
					return (
						<Box
							className="block min-h-80 w-full space-y-8 rounded-xl border p-10 pt-4 shadow-xl"
							key={sectionID}
						>
							<SectionComponent
								sectionTitle={section?.name || " - "}
								description={section?.description}
								rfqSubmission={true}
							/>
							{section.question?.map((question, index) => {
								let nameField = `question_answer.question-${question.id}`;
								if (
									isSmartField(question?.fields?.type) &&
									((!hasDocuments &&
										question?.fields?.type === "corporate_documents") ||
										(!hasResources &&
											question?.fields?.type === "key_personnel") ||
										(!hasPasProjects &&
											question?.fields?.type === "project_experience")) &&
									!isGuest
								) {
									nameField = nameField + ".additional";
								}
								const questionProps = {
									type: fieldTypeQuestion[question.type]?.type || "table",
									name: nameField,
									question: question.name,
									typeQuestion:
										fieldTypeQuestion[question.type]?.type || "table",
									fieldValue: filterFieldValues(question) || [],
									description: fieldTypeQuestion[question.type]?.description,
									rfqSubmission: true,
									required: question.required,
									touchedField: touchedForm,
									isGuest: isGuest,
									disabled: disabled,
								};
								return renderQuestion(
									questionProps,
									index,
									hasDocuments,
									hasResources,
									hasPasProjects
								);
							})}
						</Box>
					);
				})
			)}
		</div>
	);
};

export default ReturnableSchedulesComp;
