import { Flex } from "@chakra-ui/core";
import { Button } from "components/Button";
import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState } from "react";
import { ProjectStyled } from "./styled";
import FilterButton from "components/FilterButton/FilterButton";
import ButtonViewGroup from "components/ButtonViewGroup/ButtonViewGroup";
import GridViewProjects from "./GridViewProjects";
import ListViewProjects from "./ListViewProjects";
import { FilterProject } from "components/RFQ/FilterProject";
import { useDisclosure } from "@chakra-ui/core";
import { getProjects, getFavoriteProjects } from "actions/RFQ/projectsAction";
import { connect } from "react-redux";
import SearchBar from "components/RFQ/SearchBar/SearchBar";
import BadgeComp from "components/RFQ/BadgeComp/BadgeComp";
import SortComp from "components/RFQ/SortComp/SortComp";
import { useDispatch } from "react-redux";
import { SET_PROJECT_BADGES_COUNT } from "actions/RFQ/actionTypes";
import { useSelector } from "react-redux";
import { Icon } from "assets/icons/Icon";
import { navigate } from "@reach/router";
import { useQueryState } from "nuqs";

const sortedData = [
	{
		name: "Sort Alphabetically (A to Z)",
		value: "asc",
		sort: "Sort by: A to Z",
	},
	{
		name: "Sort Alphabetically (Z to A)",
		value: "desc",
		sort: "Sort by: Z to A",
	},
	{
		name: "Sort by Last Activity",
		value: "last_activity",
		sort: "Sort by: Activity",
	},
	{
		name: "Sort by Date Created",
		value: "created",
		sort: "Sort by: Date",
	},
];

const tabMenu = [
	{
		id: "myProject",
		name: "My Projects/Assets",
		showBadge: true,
	},
	{
		id: "allProject",
		name: "All Projects/Assets",
		showBadge: true,
	},
	{
		id: "archived",
		name: "Archived",
		showBadge: true,
	},
	{
		id: "favourite",
		name: "Favourites",
		showBadge: true,
	},
];

function Projects({
	getProjects,
	getFavoriteProjects,
	projects,
	isLoading,
	isLoadingFavorites,
}) {
	const [currentTab, setCurrentTab] = useQueryState("tab", {
		defaultValue: "myProject",
	});
	const currentPage = projects?.[currentTab]?.meta?.current_page;
	const [view, setView] = useState("grid");
	const [sorted, setSorted] = useState(sortedData[2]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [openPopUp, setOpenPopUp] = useState(false);
	const [filters, setFilters] = useState(null);
	const [loadingPagination, setLoadingPagination] = useState(false);
	const [search, setSearch] = useState("");
	const projectBadgeCounts = useSelector(
		(state) => state.rfq?.projects?.projectCount
	);
	const { sidebarcollapse } = useSelector((state) => state.sidebar);

	const dispatch = useDispatch();

	const pageChecker = (pages = "current_page") => {
		let page = 1;
		if (JSON.stringify(projects) !== "{}") {
			page = projects?.[currentTab]?.meta?.[pages];
		}
		return page;
	};

	const [currPage, setCurrPage] = useState(pageChecker("current_page"));
	const [lastPage, setLastPage] = useState(pageChecker("last_page"));

	const onScroll = async () => {
		const { clientHeight, scrollHeight, scrollTop } =
			document.getElementById("element-scroll");
		if (
			!isLoading &&
			!loadingPagination &&
			scrollTop + clientHeight >= scrollHeight - 300
		) {
			if (currPage < lastPage) {
				setLoadingPagination(true);
				getProjects({
					page: currPage + 1,
					search,
					sort: sorted.value,
					filters,
				});
				setLoadingPagination(false);
			}
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			onScroll();
		};

		const scrollableElement = document.getElementById("element-scroll");

		if (scrollableElement) {
			scrollableElement.addEventListener("scroll", handleScroll);

			return () => {
				scrollableElement.removeEventListener("scroll", handleScroll);
			};
		}
		// eslint-disable-next-line
	}, [currPage, lastPage, projects, isLoading, loadingPagination]);

	useEffect(() => {
		setLoadingPagination(false);
		// eslint-disable-next-line
	}, [currPage]);

	useEffect(() => {
		setCurrPage(1);
		getProjects({ page: 1, search, sort: sorted.value, filters, reset: true });

		if (!isLoadingFavorites) {
			getFavoriteProjects();
		}
		//eslint-disable-next-line
	}, [search, sorted, filters, currentTab]);

	useEffect(() => {
		const currentTotal = projects?.[currentTab]?.meta?.total;
		if (currentTotal !== undefined && currentTotal !== null) {
			const updatedBadgeCounts = {
				...projectBadgeCounts,
				[currentTab]: currentTotal,
			};
			dispatch({ type: SET_PROJECT_BADGES_COUNT, payload: updatedBadgeCounts });
		}
		//eslint-disable-next-line
	}, [search, sorted, filters, currentTab]);

	useEffect(() => {
		setCurrPage(pageChecker("current_page"));
		setLastPage(pageChecker("last_page"));
		//eslint-disable-next-line
	}, [projects, currentPage, currentTab]);

	const getSortingProject = () => {
		setOpenPopUp(false);
	};

	return (
		<ProjectStyled>
			<TabHeader
				heading="Projects and Assets"
				description="Create and manage all your projects and assets here"
			>
				<Button
					to={`new-project`}
					className="create-project-btn max-h-12"
					onClick={() => navigate("./projects/new-project")}
				>
					<Icon
						icon="plus"
						className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
					/>
					Create Project/Asset
				</Button>
			</TabHeader>
			<div
				id="layout-dashboard"
				className="space-y-9"
			>
				<div>
					<div className="grid w-full gap-8 md:grid-cols-1 lg:grid-cols-2 lg:gap-0">
						<Flex className="scrollbar order-2 gap-2 overflow-x-auto overflow-y-hidden pb-3 lg:order-1 lg:pb-0">
							{tabMenu.map((tbMenu, index) => (
								<div
									key={index}
									className="group"
								>
									<Button
										btntype="tab"
										active={currentTab === tbMenu.id ? true : false}
										onClick={() => setCurrentTab(tbMenu.id)}
									>
										<div
											style={{ fontFamily: "roboto", fontWeight: "500" }}
											className={`text-${currentTab === tbMenu.id ? "to-utility-brand-700" : "text-primary-500"}`}
										>
											{tbMenu.name}
										</div>
										{tbMenu.showBadge && (
											<BadgeComp
												title={tbMenu.id}
												active={currentTab}
												total={
													projects?.[tbMenu.id]?.meta?.total ||
													projectBadgeCounts[tbMenu.id] ||
													0
												}
											></BadgeComp>
										)}
									</Button>
								</div>
							))}
						</Flex>
						<Flex className="order-1 gap-4 justify-self-end lg:order-2">
							<SearchBar setState={setSearch} />
							<FilterButton
								onClick={onOpen}
								filters={filters}
							/>
							<SortComp
								data={sortedData}
								sorted={sorted}
								onClickCB={(item) => {
									getSortingProject(item);
									setSorted(item);
								}}
								openPopUpCB={openPopUp}
								setOpenPopUpCB={setOpenPopUp}
							></SortComp>
							<ButtonViewGroup
								setView={setView}
								view={view}
							/>
						</Flex>
					</div>
				</div>
				<div>
					{view === "grid" ? (
						<GridViewProjects
							data={projects?.[currentTab]?.data}
							currentTab={currentTab}
							currPage={currPage}
							isLoading={isLoading}
							isSidebarCollapsed={sidebarcollapse}
						/>
					) : (
						<ListViewProjects
							data={projects?.[currentTab]?.data}
							currentTab={currentTab}
							currPage={currPage}
							isLoading={isLoading}
						/>
					)}
				</div>
			</div>
			<FilterProject
				setFilters={setFilters}
				filters={filters}
				isOpen={isOpen}
				onClose={onClose}
				currentTab={currentTab}
			/>
		</ProjectStyled>
	);
}

const mapStateToProps = (state) => {
	return {
		projects: state.rfq.projects.projects,
		isLoading: state.rfq.projects.loading,
		isLoadingFavorites: state.rfq.projects.favorites.loading,
	};
};

export default connect(mapStateToProps, { getProjects, getFavoriteProjects })(
	Projects
);
