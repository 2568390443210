import { SkeletonCircle } from "@chakra-ui/react";
import { SkeletonBase } from "components/Skeleton";

export const AddendumLoading = () => {
	return (
		<div className="border-solid border-[#EAECF0] bg-white py-4 pb-6 shadow-md">
			{[...Array(3)].map(() => (
				<>
					<div className="mb-2 mt-4">
						<div className="mt-2 flex items-start justify-start pb-1 pl-10 pr-10">
							<SkeletonCircle
								width={12}
								height={12}
								startColor="lightgray"
							/>
							<div
								style={{ width: "100%" }}
								className="ml-5"
							>
								{/*@ts-ignore  */}
								<SkeletonBase
									width="300px"
									height="26px"
								/>
								<div className={`mb-2 mt-2`}>
									{/*@ts-ignore  */}
									<SkeletonBase
										width="100%"
										height="46px"
									/>
								</div>
								<div className="mb-5">
									{/*@ts-ignore  */}
									<SkeletonBase
										width="200px"
										height="20px"
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			))}
		</div>
	);
};
