import styled from "styled-components";

export const CompaniesListingStyled = styled.div`
	.message {
		text-align: center;
		margin-top: 200px;
		font-size: 28px;
		font-weight: 700;
	}
`;

export const ListStyled = styled.div`
	padding: ${(props) =>
		props.padded ? `${props.theme.space.lg} ${props.theme.space.md}` : 0};

	${(props) =>
		props.searchShortlist &&
		`
        margin: 0;
    `}

	.shortlisted-text {
		font-size: ${(props) => props.theme.fontSizes.xxl};
		line-height: 32px;
		margin-bottom: 0px;
	}
`;
