import * as constants from "../actions/actionTypes";

const INITIAL_AUTH_STATE = {
	access_token: "",
	user: {},
	guestUser: { login: false },
	signup_details: {},
	current_company: {},
	two_factor_enabled: false,
	two_factor_verified: false,
};

export const authReducer = (state = INITIAL_AUTH_STATE, action) => {
	switch (action.type) {
		case constants.AUTHENTICATE_USER:
			return {
				...state,
				two_factor_enabled: action.payload.two_factor_enabled,
				access_token: action.payload.access_token,
			};
		case constants.AUTHENTICATE_TWO_FACTOR:
			return {
				...state,
				access_token: action.payload.access_token,
				two_factor_enabled: true,
				two_factor_verified: true,
			};
		case constants.ENABLED_TWO_FACTOR:
			return {
				...state,
				two_factor_enabled: true,
			};
		case constants.DEACTIVATE_TWO_FACTOR:
			return {
				...state,
				two_factor_enabled: false,
				two_factor_verified: false,
			};
		case constants.LOGOUT_USER:
			return {
				...state,
				access_token: "",
				user: {},
			};
		case constants.LOGOUT_GUEST_USER:
			return {
				...state,
				guestUser: { login: false },
			};
		case constants.GET_USER:
			return {
				...state,
				user: action.payload,
			};
		case constants.GET_CURRENT_COMPANY:
			return {
				...state,
				current_company: action.payload,
			};
		case constants.GET_SIGNUP_DETAILS:
			localStorage.setItem(
				"signup_data",
				JSON.stringify({
					...JSON.parse(localStorage.getItem("signup_data")),
					...action.payload,
				})
			);
			return {
				...state,
				signup_details: { ...state.signup_details, ...action.payload },
			};
		case constants.GUEST_SIGNUP:
			return {
				...state,
				guestUser: { login: true, ...action.payload },
			};
		case constants.AUTHENTICATE_GUEST_USER:
			return {
				...state,
				guestUser: { login: true, ...action.payload },
			};
		case "FIRSTLOGGEDIN":
			let guestUser = state.guestUser;
			guestUser.first_logged = action.payload;
			return {
				...state,
				guestUser,
			};
		default:
			return state;
	}
};
