import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
	MenuDivider,
} from "components/RFQ/MenuDropdown";
import React from "react";
import Tooltip from "components/Tooltip";
const InviteSupplierButton = ({ setTypeModal, onOpen }) => {
	const listMenuEdit = [
		{
			name: "Invite Supplier",
			value: "add_supplier",
			icon: "plus",
			divider: true,
		},
		{
			name: "Invite Guest",
			value: "invite_guest",
			icon: "request-for-quote",
			divider: false,
		},
	];

	const handleMenuClick = (value) => {
		setTypeModal(value);
		onOpen();
	};

	return (
		<div>
			<DropdownMenu className="dropdown-menu">
				<DropdownMenuTrigger>
					<Tooltip
						label="After adding suppliers to your list, invite them to this RFx"
						placement="bottom-end"
						hasArrow
					>
						<Button
							btntype={"primary"}
							className="h-12 !max-h-12"
						>
							<Flex
								gap={2}
								align={"center"}
							>
								<div className="text-base">Invite Supplier</div>
								<Icon icon="chevron-down" />
							</Flex>
						</Button>
					</Tooltip>
				</DropdownMenuTrigger>
				<DropdownMenuDrawer className={"!-right-40"}>
					{listMenuEdit.map((menu, index) => {
						return (
							<div key={index}>
								<DropdownItem
									className="w-full"
									onClick={() => handleMenuClick(menu.value)}
								>
									<Icon
										icon={menu.icon}
										color="#667085"
										width={20}
									/>
									<p
										className="font-roboto text-[14px] font-medium text-secondary-700 hover:text-[14px]"
										style={{ lineHeight: "20px" }}
									>
										{menu.name}
									</p>
								</DropdownItem>
								{menu.divider && <MenuDivider />}
							</div>
						);
					})}
				</DropdownMenuDrawer>
			</DropdownMenu>
		</div>
	);
};

export default InviteSupplierButton;
