import apiV2 from "api-v2";
import { toFormData } from "axios";
import { showToast } from "utils/helpers";

export const getSubmission = async (rfqId, companyId) => {
	try {
		let response = await apiV2.get(
			`rfq/${rfqId}/submission?companyId=${companyId}`
		);
		if (response.status === 200) {
			let quoteData = response?.data?.data?.submission?.quote;
			let returnableData = response?.data?.data?.submission?.returnableSchedule;
			let quoteSavedFile = response?.data?.data?.submission?.quote_file;
			let returnableScheduledSavedFile =
				response?.data?.data?.submission?.returnable_schedule_file;

			return {
				submissionStatus: response?.data?.data?.status,
				quoteData: quoteData,
				returnableData: returnableData,
				rfqDetail: response?.data?.data?.rfq,
				quoteSavedFile,
				returnableScheduledSavedFile,
			};
		} else {
			return null;
		}
	} catch {
		return null;
	}
};

export const acceptDecliceSubmission = async (rfqId, isAccept, reason) => {
	const data = toFormData({
		isAccept: isAccept,
		reason: reason,
	});
	try {
		let response = await apiV2.post(`/rfq/${rfqId}/accept-or-decline`, data);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast(e?.response?.data?.message, "Error");
		return false;
	}
};

export const getListCompanyData = async (type, params) => {
	try {
		let response = await apiV2.get(`${type}`, { params });
		if (response.status === 200) {
			return response.data.data;
		}
	} catch (e) {
		console.log(e);
		showToast(e?.response?.data?.message, "Error");
	}
};

export const saveSubmissionRFQ = async (rfqId, value) => {
	const formData = toFormData(value);
	try {
		let response = await apiV2.post(`rfq/${rfqId}/store-submission`, formData);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast("Failed to save submission", "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast("Failed to save submission", "Error");
	}
};

export const submitSubmissionRFQ = async (rfqId, companyId, isRead) => {
	const formData = toFormData({ companyId, isRead });
	try {
		let response = await apiV2.post(`rfq/${rfqId}/submit-submission`, formData);
		if (response.status === 200) {
			showToast(response.data.message, "Success", true);
			return response.data.data;
		} else {
			showToast(response.data.message, "Error");
			return false;
		}
	} catch (e) {
		console.log(e);
		showToast(
			e?.response?.data?.message || "Failed to submit submission",
			"Error"
		);
	}
};

export const downloadAllDocumentation = async (rfqId) => {
	try {
		let response = await apiV2.get(`rfq/${rfqId}/download-documents`, {
			responseType: "blob",
		});
		if (response.status === 200) {
			const url = window.URL.createObjectURL(response.data);
			const a = document.createElement("a");
			a.href = url;
			a.download = "download.zip";
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			showToast("Documents downloaded", "Success", true);
			return true;
		} else {
			showToast("Failed to download documents", "Error");
			return false;
		}
	} catch (e) {
		showToast("Failed to download documents", "Error");
		return false;
	}
};
