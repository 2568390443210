import { PastProjectType } from "./ProjectForm.types";
import _ from "lodash";

/* 
    savedFormState contains data returned from the API, so all the values in this state will be strings.
    currentFormState contains the data in the current editable form, which can include instances of files.
 */
export const isFormStateAndSavedFormStateSame = (
	savedFormState: PastProjectType,
	currentFormState: PastProjectType
) => {
	if (savedFormState?.images?.length === currentFormState?.images?.length) {
		const savedImages = savedFormState?.images?.map((image) =>
			filterImagePathFromFullSignaturePath(image as string)
		);

		const currentFormImages = currentFormState?.images?.map((image, index) =>
			typeof image === "string"
				? filterImagePathFromFullSignaturePath(image)
				: savedImages[index]
		);

		return _.isEqual(
			{
				...savedFormState,
				images: savedImages,
				testimonials: filterImagePathFromFullSignaturePath(
					savedFormState?.testimonials as string
				),
			},
			{
				...currentFormState,
				images: currentFormImages,
				testimonials: getTestimonial(
					savedFormState?.testimonials as string,
					currentFormState?.testimonials
				),
			}
		);
	}

	return false;
};

export const filterImagePathFromFullSignaturePath = (
	imagePath: string
): string => {
	return imagePath?.split("?")[0];
};

const getTestimonial = (
	savedTestimonials: string,
	currentFormTestimonials: File | string
) => {
	if (
		typeof currentFormTestimonials !== "string" &&
		Array.isArray(currentFormTestimonials)
	) {
		if (
			currentFormTestimonials[0]?.path ===
			getImageNameFormPath(savedTestimonials as string)
		) {
			return filterImagePathFromFullSignaturePath(savedTestimonials as string);
		}
		return [...currentFormTestimonials].length > 0
			? currentFormTestimonials
			: "";
	}

	return filterImagePathFromFullSignaturePath(
		currentFormTestimonials as string
	);
};

const getImageNameFormPath = (imagePath: string): string => {
	return filterImagePathFromFullSignaturePath(imagePath)
		.split("/")
		.pop()
		.replace(/\s+/g, "_");
};
