import React from "react";
import styled from "styled-components";

const NotAccessibleStyled = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`;

export const NotAccessible = () => {
	return (
		<NotAccessibleStyled>
			<h1>
				Error: You are already logged in using another account. Please logout
				and try again.
			</h1>
		</NotAccessibleStyled>
	);
};
