import { Spinner } from "@chakra-ui/react";
import React, { Suspense, useRef, useState, useEffect } from "react";

export const RenderOnViewportEntry = ({
	children,
	setPageNumber,
	pageNumber,
	threshold = 0,
	root = null,
	loadingComponent,
	rootMargin = "0px 0px 0px 0px",

	...wrapperDivProps
}) => {
	const screenRef = useRef(null);
	const [entered, setEntered] = useState(false);

	useEffect(() => {
		const currentScreenRef = screenRef.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setEntered(true);
					observer.disconnect();
				}
			},
			{ threshold, root, rootMargin }
		);

		if (currentScreenRef) {
			observer.observe(currentScreenRef);
		}

		return () => {
			if (currentScreenRef) {
				observer.unobserve(currentScreenRef);
			}
		};
	}, [threshold, root, rootMargin, setPageNumber, pageNumber]);

	return (
		<div
			{...wrapperDivProps}
			ref={screenRef}
		>
			{entered ? (
				<Suspense fallback={<Spinner />}>{children}</Suspense>
			) : (
				<>{loadingComponent}</>
			)}
		</div>
	);
};
