import styled from "styled-components";

export const Divider = styled.hr`
	margin: 30px 0px;
`;

export const IndigenousCheckBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	margin-bottom: 25px;
	svg {
		margin-left: 4px;
	}
`;

export const InputWrapper = styled.div`
	margin-bottom: 25px;
`;
