import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import {
	LoginWrapper,
	LeftSection,
	RightSection,
	LoginSection,
	BottomTag,
	BottomLinks,
} from "./styled";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { authenticateUser } from "actions/authActions";
import rightImg from "./../../icons/login-right-img.jpg";
import { Checkbox2 } from "../FormInputs/Checkbox2";
import { Link, navigate } from "@reach/router";
import { FlashError } from "../FormInputs/FlashError";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";
import { showToast } from "utils/helpers";
export const LoginComponent = ({ ...props }) => {
	const queryParams = new URLSearchParams(window.location.search);
	const invitetoken = queryParams.get("sc");

	const handleSubmit = async (values, setErrors, setSubmitting, setStatus) => {
		setSubmitting(true);
		setStatus(null);
		try {
			await props.authenticateUser(values, setSubmitting, setStatus);
		} catch (e) {
			const error = await e.response.json();
			setStatus(e.response?.status);
			showToast(error.error ?? error?.message, "Error");
		}

		setSubmitting(false);
	};

	return (
		<LoginWrapper>
			<LeftSection>
				<LogoWrapper />

				<LoginSection>
					<Heading marginBottom="40px">
						<span className="welcome">Welcome.</span>
						<span>Sign in by entering the information below.</span>
					</Heading>

					<Formik
						enableReinitialize
						initialValues={{
							email: "",
							password: "",
							invitetoken: invitetoken,
						}}
						validationSchema={Yup.object({
							email: Yup.string().email().required(),
							password: Yup.string().required(),
						})}
						onSubmit={(values, { setErrors, setSubmitting, setStatus }) => {
							handleSubmit(values, setErrors, setSubmitting, setStatus);
						}}
					>
						{(props) => {
							const { setFieldValue, isSubmitting, status, setStatus } = props;

							const onSubmitValidationError = () => {
								setStatus("required");
							};

							return (
								<Form>
									<div className="row">
										<TextInput
											label="Enter your email"
											placeholder="Email"
											name="email"
											type="email"
											className="col xl-12"
											setFieldValue={setFieldValue}
										/>

										<TextInput
											label="Enter your password"
											placeholder="Password"
											name="password"
											type="password"
											className="col xl-12"
											setFieldValue={setFieldValue}
											icon
										/>
										<BottomLinks className="col xl-12">
											<Checkbox2
												label="Remember me"
												name="remember me"
											/>
											<Link to="/reset-password">Forgot my password</Link>
										</BottomLinks>
										<div className="col xl-12">
											{status && (
												<FlashError
													heading={errorMessages[status]?.heading || status}
													text={
														errorMessages[status]?.description ||
														errorMessages.invalid.description
													}
													margin="20px 0px 0px 0px"
												/>
											)}
										</div>
										<div className="col xl-12">
											<Submit
												type="submit"
												widthExpand
												marginTop="24px"
												isSubmitting={isSubmitting}
												text="sign in"
												submittingText="Logging in..."
												style={{ textTransform: "uppercase", textLine: "1.25" }}
											/>
										</div>
									</div>
									<OnSubmitValidationError callback={onSubmitValidationError} />
								</Form>
							);
						}}
					</Formik>
					<BottomTag>
						Not registered yet?{" "}
						<a href="https://www.procuracon.com.au/about-us.php?contact=us&#contact">
							Connect with us
						</a>
					</BottomTag>
					<div className="guest-link">
						<span onClick={() => navigate("guest/login")}>
							Click here for Guest Login
						</span>
					</div>
				</LoginSection>
			</LeftSection>
			<RightSection
				style={{ backgroundImage: `url(${rightImg})` }}
			></RightSection>
		</LoginWrapper>
	);
};

const errorMessages = {
	required: {
		heading: "* Required Field",
		description: "There are incomplete required fields, Please complete them.",
	},
	403: {
		heading: "Invalid Credentials",
		description: "Email or Password does not match with existing records.",
	},
	429: {
		heading: "Too Many Login Attempts.",
		description:
			"Your account has been suspended, please try again with the right credentials in 3 mins",
	},
	connection: {
		heading: "Connection not established",
		description: "Unable to process your request",
	},
};

const mapStateToProps = (state) => {
	return {
		user: "",
	};
};

export const Login = connect(mapStateToProps, { authenticateUser })(
	LoginComponent
);
