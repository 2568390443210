import { Link } from "@reach/router";
import { useSelector } from "react-redux";

export default function SharedCompany() {
	const sharedProfiles = useSelector(
		(state) => state?.auth?.guestUser?.shared_profiles
	);

	return (
		<div className="grid grid-cols-4">
			{sharedProfiles?.map((sharedProfile, index) => {
				return (
					<Link
						key={index}
						to={`/company/${sharedProfile?.company?.id}`}
					>
						<div className="relative shadow">
							<div className="h-60 w-full">
								<img
									className="h-2/3 w-full object-cover"
									src={
										sharedProfile?.company?.banner_path ||
										` ${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`
									}
									alt=""
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`;
									}}
								/>
							</div>
							<div>
								<img
									className="absolute left-[120px] top-[100px] h-[120px] w-[120px] rounded-full border-4 border-white object-cover"
									src={
										sharedProfile?.company?.logo_path ||
										` ${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`
									}
									alt=""
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`;
									}}
								/>
							</div>
							<div>
								<h4> {sharedProfile?.company?.name}</h4>
							</div>
						</div>
					</Link>
					// <CompanyCard
					// 	key={index}
					// 	id={sharedProfile?.company?.id}
					// 	name={sharedProfile?.company?.name}
					// 	size={sharedProfile?.company?.size}
					// 	logo={sharedProfile?.company?.logo_path}
					// 	banner={sharedProfile?.company?.banner_path}
					// 	admin={false}
					// 	location={sharedProfile?.company?.location}
					// 	profileType={sharedProfile?.company?.profile_type}
					// 	company_profile_status={
					// 		sharedProfile?.company?.company_profile_status
					// 	}
					// 	linkNewTab
					// 	isAdmin={false}
					// />
				);
			})}
		</div>
	);
}
