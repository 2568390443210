import { setLocale } from "yup";

setLocale({
	mixed: {
		// default: 'Não é válido',
		//eslint-disable-next-line
		required: "This is a required field",
	},
	string: {
		email: "Must be a valid email",
	},
	number: {},
});
