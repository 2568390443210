const specialCases = {
	rfx: "RFx",
	id: "ID",
};

export const toTitleCase = (text = "") => {
	if (typeof text !== "string") return "";

	// list of words to keep lowercase (unless they're the first word)
	const smallWords =
		/^(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|vs?\.?|via)$/i;

	return text
		.replace(/(_)/g, " ") // Replace underscores with spaces
		.toLowerCase()
		.split(" ")
		.map((word, index) => {
			if (specialCases[word.toLowerCase()]) {
				return specialCases[word.toLowerCase()];
			}

			// always capitalize the first word
			if (index === 0) return word.charAt(0).toUpperCase() + word.slice(1);

			// capitalize if it's not a small word
			if (!smallWords.test(word)) {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
			return word;
		})
		.join(" ");
};

export const toSentenceCase = (text = "") => {
	if (typeof text !== "string") return "";

	// trim leading and trailing whitespace, then split into sentences
	const sentences = text.trim().split(/(?<=[.!?])\s+/);

	// capitalize the first letter of each sentence and lowercase the rest
	return sentences
		.map((sentence) => {
			const words = sentence.toLowerCase().split(" ");
			return words
				.map((word, index) => {
					if (specialCases[word]) {
						return specialCases[word];
					}
					if (index === 0) {
						return word.charAt(0).toUpperCase() + word.slice(1);
					}
					return word;
				})
				.join(" ");
		})
		.join(" ");
};
