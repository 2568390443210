import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { SliderButton } from "../SliderButton";
import {
	ImageWrap,
	ImageSliderStyled,
	ImageSliderCaption,
	ImageSliderSubCaption,
	TextOverlay,
} from "./styled";
import { theme } from "../../theme";

export const Image = styled.img`
	object-fit: ${(props) => (props.isPlaceholder ? "contain" : "cover")};
	background-color: ${(props) =>
		props.isPlaceholder ? "#e9ecf1" : "transparent"};
	width: calc(100% + 20px);
	position: relative;
	height: 300px;

	${theme.mediaQueries.xl} {
		height: 420px;
	}
`;

export const ImageSlider = ({ images, ...props }) => {
	const swiperRef = useRef(null);
	return (
		<ImageWrap hasImage={images[0]}>
			{images.length > 1 ? (
				<ImageSliderStyled>
					<Swiper
						ref={swiperRef}
						navigation={false}
						slidesPerView={1}
						className="max-w-[1000px]"
					>
						<SliderButton
							direction="prev"
							onClick={() => swiperRef.current.swiper.slidePrev()}
						/>
						{images.length &&
							images.map((image, i) => (
								<SwiperSlide key={i}>
									<Image
										key={i}
										src={image}
										alt=""
									/>
								</SwiperSlide>
							))}
						<SliderButton
							direction="next"
							onClick={() => swiperRef.current.swiper.slideNext()}
						/>
					</Swiper>
				</ImageSliderStyled>
			) : (
				<Image
					src={images[0] || process.env.PUBLIC_URL + "/img/placeholder-lg.jpg"}
					alt=""
					isPlaceholder={!images[0]}
				/>
			)}

			<TextOverlay dark={!images[0]}>
				{props.heading && (
					<ImageSliderCaption>{props.heading}</ImageSliderCaption>
				)}
				{props.subheading && (
					<ImageSliderSubCaption>
						<a
							href={`https://www.google.com/maps/search/?api=1&query=${props.subheading}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{props.subheading}
						</a>
					</ImageSliderSubCaption>
				)}
			</TextOverlay>
		</ImageWrap>
	);
};
