import styled from "styled-components";

export const ResetPasswordWrapper = styled.div`
	width: 100%;
	display: flex;
	.reset-arrow {
		display: flex;
		align-items: center;
		margin-bottom: 38px;
	}

	.instructions {
		font-size: 15px;
		margin-bottom: 40px;
	}

	.float-bottom {
		position: absolute;
		display: flex;
		bottom: 40px;
		left: 40px;
		p {
			margin-top: 16px;
			margin-right: 24px;
			font-size: ${(props) => props.theme.fontSizes.xs};
			a {
				color: ${(props) => props.theme.colors.succession};
			}
		}
	}
`;
