import styled from "styled-components";
import { width, margin, typography, height, padding } from "styled-system";

import { theme } from "theme";

export const ButtonStyled = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	text-transform: capitalize;
	font-size: ${(props) => (props.textfont ? props.textfont : "1rem")}!important;
	font-weight: 600;
	font-family: roboto;
	padding: 15px 20px;
	border-radius: ${(props) => (props.radius ? props.radius : "4px")};
	width: ${(props) => (props.widthExpand ? "100%" : "auto")};
	letter-spacing: normal;

	:disabled {
		color: ${theme.colors.grayLight};
		background: ${theme.colors.snowDark};
		border-color: #d2d5da;
		cursor: not-allowed;
		pointer-events: all !important;
		&:hover {
			background-color: ${theme.colors.snowDark};
		}
		svg path {
			stroke: ${theme.colors.grayLight};
		}
	}

	cursor: ${(props) => (props.disablePointer ? "no-drop" : "pointer")};
	pointer-events: ${(props) => (props.disablePointer ? "none" : "auto")};
	line-height: 1.5;
	background: ${theme.colors.royalBlue};
	color: white;
	border: 1px solid ${theme.colors.royalBlue};
	box-shadow: ${(props) =>
		!props.boxShadow ? "none" : "4px 4px 8px 2px #edeef0"};
	transition: ${(props) => props.theme.transitions.default};
	${width}
	${height}
  ${margin}
  ${padding}
  ${typography}
  &:hover {
		background: #003acd;
	}
	span {
		margin-left: 6px;
	}
	&:focus {
		outline: none;
	}

	${"" /* Primary Alt */}
	${(props) =>
		props.btntype === "primary" &&
		props.alt &&
		`
    background-color: ${theme.colors.mintDark};
    border-color: ${theme.colors.mintDark};
  `}
  ${(props) =>
		props.btntype === "plain" &&
		`
    color: #475467;
    background-color: transparent;
    border-color: transparent;
    &:hover, &:focus-within {
      background-color: ${theme.colors.snowDark};
    }
  `}

  ${(props) =>
		props.btntype === "plain-border" &&
		`
    color: #0031DD;
    background-color: transparent;
    border-color: solid 1px #DEEBFF;
    &:hover, &:focus-within {
      background-color: #DEEBFF;
    };
    &:disabled {
    background-color: transparent;
    }
  `}
  
  ${(props) =>
		props.btntype === "plain-color" &&
		`
    color: #0031DD;
    background-color: transparent;
    border-color: transparent;
    &:hover, &:focus-within {
      background-color: #DEEBFF;
    };
    &:disabled {
    background-color: transparent;
    }
  `}
  
  ${(props) =>
		props.btntype === "plain-icon" &&
		`
    color: #475467;
    background-color: transparent;
    border-color: transparent;
    border-radius: 999px;
    width: 20px;
    height: 20px;
    item-align: center;
    padding: 17px !important;
    &:hover, &:focus-within {
      background-color: ${theme.colors.snowDark};
    }

  `}
  ${(props) =>
		props.btntype === "plain-error" &&
		`
    color: #B42318;
    background-color: transparent;
    border-color: transparent;
    &:hover, &:focus-within {
      background-color: #fee2e2;
    };
    &:disabled {
    background-color: transparent;
    }

  `}
  ${(props) =>
		props.btntype === "base" &&
		`
    color: #475467;
    background-color: transparent;
    border-color: #D0D5DD;
    &:hover, &:focus-within {
      background-color: #F9FAFB;
    }

  `}
${(props) =>
		props.btntype === "tab" &&
		`
    font-family: Roboto !important;
    letter-spacing: 0;
    height: 44px;
    padding: 17px 8px !important;
    text-transform: none;
    color: ${props.active ? "#0031DD" : "#667085"};
    background-color: ${props.active ? "#DEEBFF" : "transparent"};
    border-color: transparent;
    &:hover, &:focus-within {
        background-color: #DEEBFF;
        color: #0031DD;
    }
  `}
 



    ${"" /* Secondary */}
    ${(props) =>
		props.btntype === "secondary" &&
		`
    color: ${theme.colors.royalBlue};
    border-color:#708FFB;
    background-color: white;

    &:hover, &:focus-within {
        background-color: rgba(0, 69, 245, 0.2);
    }
  `}
    ${"" /* Secondary */}
    ${(props) =>
		props.btntype === "icon" &&
		`
    color: ${theme.colors.royalBlue};
    border-radius: 999px;
    background-color:#BDD6FF;
    border-color:#BDD6FF;
    padding: 13px !important;
    width: 56px;
    height: 56px;  
    &:hover, &:focus-within {
        background-color: rgba(0, 69, 245, 0.2);
    }
  `}

    ${"" /* Secondary Danger*/}
    ${(props) =>
		props.btntype === "secondary-danger" &&
		`
        color: ${theme.colors.danger};
        border-color: rgba(225, 25, 0, 0.1);
        background-color: rgba(225, 25, 0, 0.1);

        &:hover, &:focus-within {
            background-color: rgba(225, 25, 0, 0.2);
        }
    `}

${"" /* Secondary Danger*/}
    ${(props) =>
		props.btntype === "third-danger" &&
		`
        color:#fff;
        border-color: #D92D20;
        background-color: #D92D20;

        &:hover, &:focus-within {
            background-color: rgba(225, 25, 0, 0.2);
        }
    `}


    ${"" /* Secondary Alt */}
    ${(props) =>
		props.btntype === "secondary" &&
		props.alt &&
		`
    color: ${theme.colors.mintDark};
    background: rgba(11, 208, 182, 0.1);
    border-color: rgba(11, 208, 182, 0.1);
    `}


    ${"" /* Danger */}
    ${(props) =>
		props.danger &&
		`
        color: white;
        background: #d92d20;
        border-color: #d92d20;

        &:hover, &:focus-within {
            background: #ce3f34;
            border-color: #ce3f34;
        }
    `}

    ${"" /* Secondary */}
    ${(props) =>
		props.btntype === "blueBorder" &&
		`
    color: ${theme.colors.royalBlue};
    border-color: ${theme.colors.royalBlue};
    background: transparent;

    &:hover, &:focus-within {
        background-color: rgba(0, 69, 245, 0.2);
    }
  `}

  ${"" /***** disabledStyle *****/}
    ${(props) =>
		props.disabledStyle &&
		props.btntype === "primary" &&
		`
    color: ${theme.colors.grayLight};
    background: ${theme.colors.snowDark};
    border-color: ${theme.colors.snowDark};
    opacity: 0.5;
  `}

    ${(props) =>
		props.disabledStyle &&
		props.btntype === "secondary" &&
		`
    color: ${theme.colors.grayLight};
    background: ${theme.colors.snowDark};
    border-color: #D2D5DA;
  `}

  ${(props) =>
		props.disabledStyle &&
		props.btntype === "secondary-danger" &&
		`
        color: ${theme.colors.grayLight};
        background: ${theme.colors.snowDark};
        border-color: #D2D5DA;
        cursor: not-allowed;
        pointer-events: all !important;
        &:hover, &:focus-within {
            background-color: ${theme.colors.snowDark};
        }

    `}

  ${"" /***** Sizes *****/}
  ${(props) =>
		props.size === "xs" &&
		`
    font-size: 0.75rem;
    padding: 7px 16px 6px;
  `}

  ${(props) =>
		props.size === "sm" &&
		`
    font-size: 0.75rem;
    padding: 13px 21px 12px;
  `}

  ${(props) =>
		props.size === "md" &&
		`
    padding: 10px 20px;
  `}


  ${(props) =>
		props.size === "lg" &&
		`
    padding: 25px 46px;
  `}

  ${(props) =>
		props.size === "full" &&
		`
      width: 100%;
  `}

  ${"" /* Download */}
      ${(props) =>
		props.btntype === "iconInline" &&
		`
      color: ${theme.colors.royalBlue};
      border-color: transparent;
      background: transparent;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      box-shadow: none;
      padding-left: 0;

      span.iconInline{
          display: inline-flex;
          background-color: #ffffff;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          margin-right: 17px;
          align-items: center;
          justify-content: center;
          svg{
            margin-right: 0;
          }
      }

      &:hover, &:focus-within {
          background-color: transparent;

          span.iconInline{
              background-color: ${theme.colors.royalBlue};

              path{
                  stroke: #ffffff;
              }
          }
      }
  `}
`;

export const ButtonWithIconStyled = styled.button`
  color: ${theme.colors.royalBlue};
  border-color: rgba(0, 69, 245, 0.1);
  background-color: rgba(0, 69, 245, 0.1);
  font-size: 1rem;
  display: flex;
  border-radius: 1.5rem;
  align-content: flex-end;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "7rem")};
  height: ${(props) => (props.height ? props.height : "2.5rem")}
}

  &:hover, &:focus-within {
    background-color: rgba(0, 69, 245, 0.2);
  }
`;
