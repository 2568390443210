import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { Select } from "../FormInputs/Select";
import { findUserRole, userRoles } from "../../utils/helpers";
import { createCompanyUser } from "../../actions/authActions";
import { TabHeader } from "components/TabHeader";
import { navigate } from "@reach/router";
import { showToast } from "../../utils/helpers";
import { VStack, Grid } from "@chakra-ui/react";

const phoneRegExp =
	/^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/;

const validationSchema = Yup.object({
	first_name: Yup.string()
		.trim()
		.required("Enter the user's first name")
		.min(2, "First name must be at least 2 characters")
		.max(50, "First name cannot exceed 50 characters"),
	last_name: Yup.string()
		.trim()
		.required("Enter the user's last name")
		.min(2, "Last name must be at least 2 characters")
		.max(50, "Last name cannot exceed 50 characters"),
	email: Yup.string()
		.email("Please enter a valid email address")
		.required("Enter the user's email address"),
	password: Yup.string()
		.required("Password is required")
		.min(12, "The password must be at least 12 characters")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
			"The password must contain at least one uppercase letter, one lowercase letter, and one number"
		),
	position: Yup.string().required("Select a User type"),
	phone: Yup.string()
		.required("Enter the user's phone number")
		.matches(phoneRegExp, "Please enter a valid Australian phone number"),
});

export const CreateUserFormComponent = ({ company, tab, ...props }) => {
	const initialValues = {
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		position: "",
		company_id: company?.id,
	};

	const handleSubmit = async (values, { setErrors, setSubmitting }) => {
		try {
			const result = await props.createCompanyUser(values, setSubmitting);
			if (result.success) {
				showToast("User created successfully", "Success", true);
				navigate("./../account-settings");
			}
		} catch (error) {
			showToast(error || "Failed to create user", "Error");
			if (error.errors) {
				setErrors(error.errors);
			}
		}
	};

	if (!company) {
		return (
			<Skeleton
				count={5}
				duration={0.5}
			/>
		);
	}

	return (
		<>
			<TabHeader
				heading="Create User"
				description="Create a new user for your company and set their permissions."
			/>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting, values }) => (
					<Form autoComplete="off">
						<VStack
							id="wrapper__supplier-search-form"
							spacing={2}
							align="stretch"
						>
							<Grid
								templateColumns="repeat(2, 1fr)"
								gap={2}
								columnGap={6}
							>
								<TextInput
									label="First Name"
									required
									name="first_name"
									placeholder="Enter first name"
								/>
								<TextInput
									label="Last Name"
									required
									name="last_name"
									placeholder="Enter last name"
								/>
								<TextInput
									label="Phone Number"
									required
									name="phone"
									placeholder="Enter Australian phone number"
								/>
								<TextInput
									label="Email Address"
									required
									name="email"
									placeholder="Enter email address"
									type="email"
								/>
								<Select
									required
									options={userRoles}
									name="position"
									label="User Type"
									value={findUserRole(values.position)}
									placeholder="Select user type"
									inputProps={{
										autoComplete: "off",
									}}
									isSearchable={false}
								/>
								<TextInput
									label="Password"
									required
									name="password"
									icon
									type="password"
									placeholder="Enter password"
								/>
							</Grid>
							<Submit
								type="submit"
								isSubmitting={isSubmitting}
								text="Create User"
								submittingText="Creating User..."
							/>
						</VStack>
					</Form>
				)}
			</Formik>
		</>
	);
};

const mapStateToProps = (state) => ({
	company: state.auth.current_company,
	current_user: state.auth.user,
});

export const CreateUserForm = connect(mapStateToProps, { createCompanyUser })(
	CreateUserFormComponent
);
