import React from "react";
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
} from "@chakra-ui/react";
import { CloseButton } from "components/RFQ/CloseButton";
import { useFormikContext } from "formik";
import PreviewRFQComponent from "components/PreviewRFQComponent/PreviewRFQComponent";
import { mapFromRFQFormToRFQData } from "utils/mapper";
import { useSelector } from "react-redux";

const PreviewRFQ = ({
	isOpen,
	rfqData,
	onClose,
	unitMeasures,
	documentCategories,
	...props
}) => {
	const { values: formValues } = useFormikContext();
	const companyUsers = useSelector(
		(state) => state?.accountManagement?.companyUsers
	);

	return (
		<div>
			<Drawer
				placement={"right"}
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				{...props}
				className="bg-white p-11"
			>
				<DrawerOverlay onClick={onClose} />
				<DrawerContent maxWidth={"1280px"}>
					<DrawerHeader>
						<Flex
							justifyContent="space-between"
							align={"center"}
						>
							<div>
								<p className="text-lg font-semibold text-primary-900">
									RFx Preview
								</p>
							</div>
							<CloseButton onClose={onClose} />
						</Flex>
					</DrawerHeader>
					<DrawerBody>
						<PreviewRFQComponent
							rfqData={rfqData}
							rfqDetail={mapFromRFQFormToRFQData(
								formValues,
								props?.rfqTypes,
								companyUsers
							)}
							documentCategories={documentCategories}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</div>
	);
};

export default PreviewRFQ;
