import React from "react";
import { TagContainer } from "./TagContainer";
import { ImageBanner, CompanySectorStyled } from "./styled";

export const CompanySectors = ({ company, ...props }) => {
	const sectors = company.sector;
	const type = company.profile_type.id;

	const labelType = () => {
		if (type === 2) return "Specialty";
		if (type === 3) return "Trade";
		if (type === 4) return "Discipline/s";
	};
	const typeDatas = () => {
		if (type === 2) return company.speciality;
		if (type === 3) return company.trade;
		if (type === 4) return company.disciplines;
		return null;
	};

	const getDisciplines = (disciplines) => {
		return company?.disciplines?.filter((_discipline) =>
			disciplines?.find(
				(selectedDiscipline) => selectedDiscipline === _discipline?.id
			)
		);
	};

	return (
		<CompanySectorStyled>
			{company.banner_path && (
				<ImageBanner
					className="mb-6"
					src={company.banner_path}
				/>
			)}
			<TagContainer
				label="Relevant Sectors & Experience"
				datas={sectors}
			/>
			{type === 4 &&
				company.disciplinesFormatted.length > 0 &&
				company.disciplinesFormatted?.map((discipline) => (
					<>
						<h2>Expertise:{discipline?.expertise}</h2>
						<TagContainer
							label="Discipline/s"
							datas={getDisciplines(discipline?.disciplines)}
						/>
					</>
				))}
			{type === 3 && (
				<TagContainer
					label="Specialty"
					datas={company.speciality}
				/>
			)}
			{typeDatas() && type !== 4 && (
				<TagContainer
					label={labelType()}
					datas={typeDatas()}
				/>
			)}
		</CompanySectorStyled>
	);
};
