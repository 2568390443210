import styled from "styled-components";
import { space, color } from "styled-system";

import { DocumentControlsStyled } from "../DocumentControls/styled";

export const DocumentItemStyled = styled.div`
	display: flex;
	position: relative;
	flex-wrap: nowrap;
	align-items: center;
	justify-items: between;
	min-height: 48px;
	color: ${(props) => props.theme.colors.grayDark};
	${color}
	${space}

    &::hover {
		${"" /* color: ${(props) => props.theme.colors.royalBlue}; */}
	}

	& > svg {
		width: ${(props) => (props.small ? "20px" : "30px")};
		height: ${(props) => (props.small ? "20px" : "30px")};
		margin-right: ${(props) => props.theme.space.xs};
		color: green;
		flex-shrink: 0;

		path {
			${(props) =>
				props.showIndicator &&
				`
                stroke: ${props.cross ? props.theme.colors.grayLight : props.iconIndicator ? props.theme.colors.mintDark : props.theme.colors.red};
            `}

			${(props) =>
				props.private &&
				`
                stroke: ${props.theme.colors.red};
            `}
		}
	}

	.document {
		&__title {
			font-size: ${(props) => props.theme.fontSizes.md};
			color: inherit;
			font-weight: 500;
			margin: 0;
			overflow: hidden;
			max-width: 100%;
			text-overflow: ellipsis;
			display: -webkit-box;
		}
	}

	a.document__title {
		max-width: 75%;
		overflow: hidden;
		min-height: 48px;
		max-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		&::hover {
			color: ${(props) => props.theme.colors.royalBlue};
		}
	}
	p.document__title {
		min-height: 22px;
		max-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
	}

	&::hover {
		${DocumentControlsStyled} {
			opacity: 1;
			pointer-events: auto;
		}
	}
	.alert-icon {
		width: 24px;
		height: 24px;
		background: red;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -12px;
		left: -8px;
		.guide {
			border: 0.5px solid #8492a6;
			border-radius: 4px;
			letter-spacing: 0.1px;
			font-family: ${(props) => props.theme.fonts.open_sauce_two};
			font-size: 13px;
			text-transform: none;
			padding: 6px 8px;
			font-weight: normal;
			max-width: 400px;
		}
		svg {
			width: 14px;
			height: 14px;
			g {
				fill: white;
			}
		}
	}
`;
