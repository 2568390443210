import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	DrawerFooter,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Form, Formik } from "formik";
import AccordionSectionSupplier from "./AccordionSectionSupplier";
import { Button } from "../Button";
import moment from "moment";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
	start_date: Yup.date()
		.required()
		.transform((val, origin) => new Date(moment(origin).format("YYYY-MM-DD")))
		.nullable(),
	end_date: Yup.date()
		.required("This field is required")
		.transform((val, origin) => new Date(moment(origin).format("YYYY-MM-DD")))
		.min(Yup.ref("start_date"), "End can't be before start date"),
});

const FormikStyled = styled(Formik)(() => [tw`overflow-y-auto`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
	tw`text-black py-6 border-b`,
	css`
		.close-text {
			font-weight: 400;
			font-size: 14px;
			cursor: pointer;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			align-items: center;
		}

		.clear-container {
			display: flex;
			justify-items: center;
			place-items: center;
		}
	`,
]);

const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-0`]);

const FilterSupplier = ({ onClose, isOpen, setFilters, filters, ...props }) => {
	const [isSubmiting, setIsSubmiting] = useState(false);

	const initialValues = {
		start_date: filters?.start_date || "",
		end_date: filters?.end_date || "",
	};

	const handleSubmit = async (filters, actions) => {
		if (isSubmiting) {
			const formatedDate = {
				start_date: filters?.start_date
					? moment(filters.start_date).format("YYYY-MM-DD")
					: null,
				end_date: filters?.end_date
					? moment(filters.end_date).format("YYYY-MM-DD")
					: null,
			};
			setIsSubmiting(false);
			setFilters(formatedDate);
			onClose(true);
		} else {
			actions.resetForm();
		}
	};

	return (
		<div>
			<Drawer
				placement={"right"}
				isOpen={isOpen}
				onClose={onClose}
				size="md"
				{...props}
			>
				<DrawerOverlay onClick={onClose} />
				<FormikStyled
					initialValues={initialValues}
					enableReinitialize={true}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
					validateOnBlur={true}
					validateOnChange={true}
				>
					{({ values, setValues, isValid, resetForm }) => (
						<Form>
							<DrawerContent>
								<DrawerHeaderStyled borderBottomWidth="1px">
									<div className="header-container">
										<p className="font-roboto text-base font-bold">
											Filter Suppliers
										</p>
										<div className="clear-container">
											<Button
												btntype="plain"
												type={"button"}
												className="cursor-pointer font-roboto text-sm !font-medium text-tertiary-600"
												onClick={() => {
													setValues({ start_date: "", end_date: "" });
													setFilters(null);
													onClose(true);
												}}
											>
												Clear all filters
											</Button>
											<Button
												btntype="plain-icon"
												type={"button"}
												onClick={onClose}
											>
												<Icon icon={"close"} />
											</Button>
										</div>
									</div>
								</DrawerHeaderStyled>
								<DrawerBodyStyled>
									<AccordionSectionSupplier />
								</DrawerBodyStyled>
								<DrawerFooter>
									<Button
										size="full"
										disabled={!isValid}
										type="submit"
										onClick={() => setIsSubmiting(true)}
									>
										Apply Filters
									</Button>
								</DrawerFooter>
							</DrawerContent>
						</Form>
					)}
				</FormikStyled>
			</Drawer>
		</div>
	);
};

export default FilterSupplier;
