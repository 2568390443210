import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { AccountManagementStyled, CancelLabel, RestartLabel } from "./styled";
import tw, { styled } from "twin.macro";
import {
	allTransaction,
	restart_subs,
} from "../../actions/AccountManagementActions";
import { setAccountStatus } from "../../actions/AccountManagementActions";
import { getAccountTypes } from "../../actions/accountTypeActions";
import { getCompanyUsers } from "../../actions/AccountManagementActions";
import { updateSubscription } from "actions/companyActions";
import { cn } from "utils/helpers";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { RestartSubscriptionModal } from "components/RestartModal";
import { dateParser } from "utils/dateFormat";
import ChangeSubscriptionModal from "./ChangeSubscriptionModal";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/react";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { Icon } from "assets/icons/Icon";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import moment from "moment";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { UpdateCreditCardComponent } from "components/CreditCardDetail/CreditCardDetail";
import { navigate } from "@reach/router";

const PlanDetailItem = ({ icon, children }) => (
	<div className="mb-3">
		<div className="flex w-full items-center">
			<div className="mr-4 w-4">
				<Icon
					icon={icon}
					style={{ color: "#667085" }}
				/>
			</div>
			<div className="mr-4 font-opensans text-sm font-normal text-secondary-700">
				{children}
			</div>
		</div>
	</div>
);

export const AccountManagementComponent = ({
	company,
	viewOnly,
	allTransaction,
	getAccountTypes,
	getCompanyUsers,
	current_user,
	setAccountStatus,
	restart_subs,
	updateSubscription,
	account_types,
	...props
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);
	const [isOpenSubs, setIsOpenSubs] = useState(false);
	const [listTransaction, setListTransaction] = useState([]);

	useEffect(() => {
		allTransaction(company.id);
		getCompanyUsers(company.id);
		getAccountTypes();
	}, [allTransaction, getAccountTypes, getCompanyUsers, current_user, company]);

	useEffect(() => {
		if (props?.invoices) {
			setIsLoading(false);
			setListTransaction(props.invoices);
		}
	}, [props?.invoices]);

	const profileTypeId = company?.profile_type?.id || null;

	const expiredCheck = (date) => {
		const present = new Date();
		const billingDate = dateParser(date);
		if (billingDate < present) return true;
		else return false;
	};

	const getPlanLabel = (packageName) => {
		const index = account_types?.findIndex(
			(type) => type.package_name === packageName
		);
		return account_types[index];
	};

	const TableHeadComponent = ({ title, className }) => {
		return (
			<div
				className={cn("th font-roboto text-tertiary-600", className)}
				style={{ gap: "8px", alignItems: "center" }}
			>
				{title}
			</div>
		);
	};

	const SkeletonCustom = styled.div(() => [
		tw`animate-pulse bg-gray-300 w-full h-6`,
	]);

	const SkeleteonRow = () => (
		<TableRow>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
		</TableRow>
	);

	return company ? (
		<AccountManagementStyled>
			<div className="mb-5 mt-5 w-full rounded-md border border-gray-200 p-5 shadow-card">
				<div className="flex items-center justify-start pb-2 pt-2">
					<div className="font-roboto text-[18px] font-semibold text-primary-900">
						Plan Details
					</div>
					<div className="ml-auto">
						<div className="flex">
							{company?.payment_method === "credit_card" && (
								<NoBorderButton
									onClick={() => setIsCreditCardModalOpen(true)}
									text="Update Payment Details"
									className="bg-white font-roboto text-[14px] font-medium text-tertiary-600"
								/>
							)}
							<Button
								btntype="base"
								className="border border-utility-blue-light-300"
								onClick={() => setIsOpenSubs(true)}
							>
								<Flex align={"center"}>
									<p className="font-roboto font-semibold text-utility-blue-dark-100">
										Change Plan
									</p>
								</Flex>
							</Button>
						</div>
					</div>
				</div>
				<div className="mb-3 mt-3 border-b border-gray-200"></div>
				<div className="flex w-full flex-col">
					<PlanDetailItem icon="check-light">
						{company?.plan ? (
							<div className="inline-flex gap-2">
								{getPlanLabel(company?.plan)?.label}{" "}
								<div className="mr-4 rounded-md bg-green-50 pl-2 pr-2 font-inter text-[10px] font-medium text-green-800">
									Active Plan
								</div>{" "}
							</div>
						) : (
							"No Plan Selected"
						)}
					</PlanDetailItem>
					{profileTypeId !== 1 && (
						<PlanDetailItem icon="user3">
							{company?.plan ? (
								<div className="inline-flex gap-2">
									{company?.profile_type?.name}
									<div className="mr-4 rounded-md bg-green-50 pl-2 pr-2 font-inter text-[10px] font-medium text-green-800">
										{getPlanLabel(company?.plan)?.package_name}
									</div>{" "}
								</div>
							) : (
								"No Plan Selected"
							)}
						</PlanDetailItem>
					)}
					<PlanDetailItem icon="bill-cc">
						<div className="flex items-center">
							<span className="mr-4">
								{company?.plan
									? getPlanLabel(company?.plan)?.total_amount?.toLocaleString(
											"en-AU",
											{
												style: "currency",
												currency: "AUD",
												minimumFractionDigits: 2,
												maximumFractionDigits: 4,
											}
										)
									: "No Plan Selected"}
							</span>
							<div className="rounded-md bg-could-blue p-1 pl-2 pr-2 font-inter text-[10px] font-medium text-light-blue">
								Billed annually
							</div>
						</div>
					</PlanDetailItem>
					<PlanDetailItem icon="bill-calendar">
						Next renewal date{" "}
						{company.next_billing_date ? (
							<div className="inline-flex items-center gap-4">
								{expiredCheck(company.next_billing_date) ||
								company.account_status === "cancelled"
									? "--/--/--"
									: company.next_billing_date}
								<div>
									{expiredCheck(company.next_billing_date) &&
										(company.account_status !== "cancelled" ? (
											<RestartLabel onClick={() => setIsOpen2(true)}>
												Restart Subscription
											</RestartLabel>
										) : (
											<CancelLabel onClick={() => setIsOpen(true)}>
												Cancel Subscription
											</CancelLabel>
										))}
								</div>
							</div>
						) : (
							<>{"--/--/-- (next billing date is empty)"}</>
						)}
					</PlanDetailItem>
				</div>
			</div>
			<div className="mb-5 mt-5 w-full rounded-md border border-gray-200 p-5 shadow-card">
				<div className="mb-5">
					<div className="font-roboto text-[18px] font-semibold text-primary-900">
						Invoice History
					</div>
					<p className="mt-2 font-roboto text-sm text-tertiary-600">
						Download and view your past invoices.
					</p>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>
								<TableHeadComponent title="Invoice Number"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Invoice Date"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Plan"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Amount"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Status"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title=""></TableHeadComponent>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{!isLoading &&
							listTransaction.map((transaction, index) => {
								return (
									<TableRow key={transaction?.id}>
										<TableCell>
											<div className="font-roboto text-sm font-medium text-primary-900">
												{transaction?.transaction_id}
											</div>
										</TableCell>
										<TableCell>
											<p className="font-roboto text-sm font-normal text-tertiary-600">
												{moment(
													transaction?.transaction_date,
													"DD/MM/YYYY"
												).format("DD/MM/YYYY")}
											</p>
										</TableCell>
										<TableCell>
											<p className="font-roboto text-sm font-normal text-tertiary-600">
												{transaction?.plan_name}
											</p>
										</TableCell>
										<TableCell>
											<div className="font-roboto text-sm font-normal text-tertiary-600">
												{transaction?.charged_amount.toLocaleString("en-US", {
													style: "currency",
													currency: "AUD",
												})}
											</div>
										</TableCell>
										<TableCell>
											<div className="font-roboto text-sm font-normal text-tertiary-600">
												<Button
													size={"xs"}
													btntype={"secondary"}
													width={"103px"}
													height={"35px"}
													textfont={"14px"}
													boxshadow={"false"}
													radius={"20px"}
													onClick={() => {
														navigate("account-settings/invoice", {
															state: {
																invoice: transaction,
																prevItem: transaction,
																id: transaction.id,
																invoiceLabel: getPlanLabel(
																	transaction?.plan_name
																),
																prevLabel: transaction
																	? getPlanLabel(transaction.plan_name)
																	: null,
															},
														});
													}}
												>
													View
												</Button>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						{isLoading && (
							<>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
							</>
						)}
					</TableBody>
				</Table>
			</div>

			<CancelSubscriptionModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				setAccountStatus={setAccountStatus}
			/>
			<RestartSubscriptionModal
				isOpen={isOpen2}
				setIsOpen={setIsOpen2}
				restart_subs={restart_subs}
				currentInvoice={props.invoices[0]}
			/>
			<ChangeSubscriptionModal
				isOpen={isOpenSubs}
				setIsOpenSubs={setIsOpenSubs}
			/>
			<CustomModal
				isOpen={isCreditCardModalOpen}
				onClose={() => setIsCreditCardModalOpen(false)}
				header={{
					title: `Update Card Details`,
					description: `Please enter your card details below`,
					icon: `bill-cc`,
					bgColor: "bg-brand-secondary",
					iconColor: "text-utility-brand-700",
				}}
			>
				<UpdateCreditCardComponent
					company={company}
					current_user={current_user}
					disableHeading
				/>
			</CustomModal>
		</AccountManagementStyled>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};

const mapStateToProps = (state) => {
	const company = state.auth.current_company;
	const current_user = state.auth.user;
	const invoices = state.accountManagement.transactionData;
	const account_types = state.accountTypes.accountTypeData;
	const company_users = state.accountManagement.companyUsers;
	return {
		isRequesting: state.companyProfile.isRequesting,
		company: company,
		current_user: current_user,
		invoices: invoices,
		account_types: account_types,
		company_users: company_users,
	};
};

export const AccountManagement = connect(mapStateToProps, {
	allTransaction,
	getAccountTypes,
	getCompanyUsers,
	setAccountStatus,
	restart_subs,
	updateSubscription,
})(AccountManagementComponent);
