import styled from "styled-components";

import { ButtonStyled } from "../Button/styled";

export const ButtonToggleStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	${ButtonStyled} {
		border-radius: 0;

		&:focus {
			outline: none;
		}

		& + ${ButtonStyled} {
			border-left: 2px solid #d2d5da;
		}

		&:first-of-type {
			border-top-left-radius: ${(props) => props.theme.radii.default};
			border-bottom-left-radius: ${(props) => props.theme.radii.default};
			border-right: none;
		}

		&:last-of-type {
			border-top-right-radius: ${(props) => props.theme.radii.default};
			border-bottom-right-radius: ${(props) => props.theme.radii.default};
		}
	}
`;
