import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { LocalGovtAssociation } from "../FormInputs/LocalGovtAssociation";
import { LocationFormStyled } from "./styled";
import { Location } from "../FormInputs/Location";
import {
	submitCompanyLocationForm,
	deleteCompanyAddress,
} from "actions/companyActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { FlashError } from "../FormInputs/FlashError";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { Submit } from "components/FormInputs/Submit";
import { showToast as showToastFunction } from "../../utils/helpers";
import FormHeader from "components/FormHeading/FormHeader";
import { VStack, Divider, Grid } from "@chakra-ui/react";

const AutoSave = ({ debounceMs, setShowToast }) => {
	const formik = useFormikContext();
	setShowToast(false);
	// eslint-disable-next-line
	const debouncedSubmit = React.useCallback(
		debounce(() => formik.submitForm(), debounceMs),
		[formik.submitForm]
	);

	useEffect(() => {
		debouncedSubmit();
	}, [debouncedSubmit, formik.values]);

	return true;
};
export const CompanyLocationFormComponent = ({
	company,
	viewOnly,
	btnOfficeLocation,
	...props
}) => {
	const initialValues = props.initialValues;
	const [showToast, setShowToast] = useState(false);
	const noOfAddresses = parseInt(company.locations.length);
	const [forms, setForms] = useState(noOfAddresses > 0 ? noOfAddresses - 1 : 0);
	const [sameState, setSameState] = useState(true);
	const [disableButton, setDisableButton] = useState(false);
	// Fill rest of the arrays with empty data. Temporary fix for following known issue with the library.
	// Ref: https://github.com/jquense/yup/issues/676
	const autoFillInitialValues = (initialValues) => {
		for (let i = 0; i < forms; i++) {
			for (let key in initialValues) {
				for (let j = initialValues[key].length + 1; j <= forms; j++) {
					initialValues[key].push(undefined);
				}
			}
		}

		return initialValues;
	};

	const handleAddressDelete = async (companyId, addressId) => {
		if (!addressId) {
			setSameState(true);
			setForms(forms - 1);
		} else {
			try {
				const res = await props.deleteCompanyAddress(companyId, addressId);
				if (res) {
					setForms(forms - 1);
					showToastFunction("Address has been deleted", "Success", true);
				} else showToastFunction("Unable to delete Address", "Error");
			} catch (error) {
				showToastFunction("Unable to delete Address: " + error, "Error");
			}
		}
	};

	const getErrors = (n, msg) => {
		const errObj = { 0: msg };
		for (let i = 1; i < n; i++) {
			errObj[i] = msg;
		}
		return errObj;
	};

	const checkForEmptyAddresses = (values) => {
		let isRequired = false;
		for (let a = 0; a <= forms; a++) {
			if (
				!values?.address[a]?.value ||
				values?.address[a]?.value?.trim() === ""
			) {
				isRequired = true;
				break;
			}
			if (!values?.city[a] || values?.city[a]?.trim() === "") {
				isRequired = true;
				break;
			}
			if (!values?.local_govt_association_id[a]) {
				isRequired = true;
				break;
			}
			if (!values?.postcode[a]) {
				isRequired = true;
				break;
			}
			if (!values?.state[a] || values?.state[a]?.trim() === "") {
				isRequired = true;
				break;
			}
		}
		setDisableButton(isRequired);
	};

	const validationSchema = Yup.object({
		state: Yup.array().test(
			"same state",
			getErrors(forms + 1, "Address must be from same state"),
			(value) => {
				return value.every((v) => v === value[0] && v != null && v !== "");
			}
		),
		address: Yup.array().of(
			Yup.object().shape({
				value: Yup.string().typeError("Address is required").required(),
			})
		),
		city: Yup.array().of(Yup.string().typeError("City is required").required()),
		postcode: Yup.array().of(
			Yup.number().typeError("Postcode must be numbers").required()
		),
		local_govt_association_id: Yup.array().of(
			Yup.number().typeError("Local Government Area is required").required()
		),
	});

	const handleSubmit = async (values, { setErrors, setSubmitting }) => {
		if (
			values["address"].length === forms + 1 &&
			values["postcode"].length === forms + 1 &&
			values["city"].length === forms + 1 &&
			values["state"].length === forms + 1 &&
			values["local_govt_association_id"].length === forms + 1
		) {
			try {
				if (!values.local_govt_association_id)
					delete values.local_govt_association_id;
				await props.submitCompanyLocationForm(values.company_id, values);
				showToast && showToastFunction("Data Updated", "Success", true);
				setSameState(true);
			} catch (e) {
				const error = await e.response.json();
				showToastFunction(error.message, "Error");
				error.errors !== undefined && setErrors(error.errors);
			}
		} else {
			setSameState(true);
		}
		setSubmitting(false);
	};

	return company !== undefined ? (
		<>
			<LocationFormStyled>
				<Formik
					innerRef={props?.formikRef}
					enableReinitialize
					initialValues={autoFillInitialValues(initialValues)}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					validate={(values) => {
						checkForEmptyAddresses(values);
					}}
					validateOnChange={true}
				>
					{(props) => {
						const {
							setFieldValue,
							isSubmitting,
							values,
							setErrors,
							isValid,
							dirty,
						} = props;
						const onSubmitValidationError = (e) => {
							if ("state" in e.errors) {
								setSameState(false);
							} else {
								setSameState(true);
							}
						};
						return (
							<Form>
								{forms + 1 === company.locations.length &&
									!isSubmitting &&
									JSON.stringify(initialValues) !== JSON.stringify(values) && (
										<AutoSave
											setShowToast={setShowToast}
											debounceMs={850}
										/>
									)}

								<VStack
									id="wrapper__primary-address"
									spacing={2}
									align="stretch"
								>
									<FormHeader
										className="mb-6"
										header="Your Primary Office Address"
										subHeader="Please provide the details of your main office location."
									/>
									<Location
										label="Address"
										required={!viewOnly}
										name="address[0]"
										setFieldValue={setFieldValue}
										initialValue={initialValues.address[0]}
										className="!mb-6"
										disabled={viewOnly}
										errorBottom={"-3px"}
										rfq={true}
									/>
									<Grid
										templateColumns="repeat(2, 1fr)"
										gap={2}
										columnGap={6}
									>
										<TextInput
											label="City / Suburb"
											required={!viewOnly}
											name="city[0]"
											disabled={viewOnly}
										/>
										<TextInput
											label="State"
											required={!viewOnly}
											name="state[0]"
											disabled={viewOnly}
										/>
										<TextInput
											label="Post Code"
											required={!viewOnly}
											name="postcode[0]"
											disabled={viewOnly}
										/>
										<LocalGovtAssociation
											name="local_govt_association_id[0]"
											profileType={values.profile_type}
											value={values.local_govt_association_id[0]}
											required={!viewOnly}
											disabled={viewOnly || !values.state[0]}
											state={values.state[0]}
											errorBottom={"-3px"}
											noOptionsMessage="Please select State first"
										/>
									</Grid>
								</VStack>

								{[...Array(forms)].map((formNo, index) => {
									return (
										<div key={index}>
											<Divider className="my-6" />
											<VStack
												id={`wrapper__additional-address-` + index}
												spacing={2}
												align="stretch"
											>
												<FormHeader
													className="mb-6"
													header={`${index === 0 ? "Your Secondary Office Address" : "Your Additional Office Address"}`}
													subHeader="If applicable, please provide the details of any additional office locations within the state."
												/>
												<Location
													label="Address"
													required={!viewOnly}
													name={`address[${index + 1}]`}
													setFieldValue={setFieldValue}
													initialValue={initialValues.address[index + 1]}
													className="!mb-6"
													disabled={viewOnly}
												/>
												<Grid
													templateColumns="repeat(2, 1fr)"
													gap={2}
													columnGap={6}
												>
													<TextInput
														label="City / Suburb"
														required={!viewOnly}
														name={`city[${index + 1}]`}
														disabled={viewOnly}
													/>
													<TextInput
														label="State"
														required={!viewOnly}
														name={`state[${index + 1}]`}
														disabled={viewOnly}
													/>
													<TextInput
														label="Post Code"
														required={!viewOnly}
														maxLength="4"
														name={`postcode[${index + 1}]`}
														disabled={viewOnly}
													/>
													<LocalGovtAssociation
														name={`local_govt_association_id[${index + 1}]`}
														profileType={values.profile_type}
														value={values.local_govt_association_id[index + 1]}
														required={!viewOnly}
														disabled={viewOnly || !values.state[index + 1]}
														state={values.state[index + 1]}
													/>
												</Grid>
											</VStack>

											{!viewOnly && (
												<div className="col xl-12 delete-address py-3 duration-300">
													<button
														title="Delete Address"
														className="delete-btn font-roboto text-sm font-semibold text-utility-error-700"
														type="button"
														style={{ transition: "all 0.4s ease-in-out" }}
														disabled={props.isRequesting}
														onClick={() => {
															// remove values as well
															values.address.splice(index + 1, 1);
															values.postcode.splice(index + 1, 1);
															values.city.splice(index + 1, 1);
															values.state.splice(index + 1, 1);
															values.local_govt_association_id.splice(
																index + 1,
																1
															);
															setErrors({});
															handleAddressDelete(
																initialValues.company_id,
																initialValues.id[index + 1]
															);
														}}
													>
														Delete Address
													</button>
												</div>
											)}
										</div>
									);
								})}
								<TextInput
									name="company_id"
									value={initialValues.company_id}
									type="hidden"
								/>
								{!viewOnly && (
									<div className="add-more-container flex w-full">
										{!sameState && (
											<div className="mb-2 mt-2">
												<FlashError
													heading={"Location Error"}
													text={
														"You are trying to add a address from a different state. Each address must be from same state."
													}
												/>
											</div>
										)}
									</div>
								)}
								{!viewOnly && (
									<div className="flex items-center justify-between">
										<div className="flex-1">
											<NoBorderButton
												iconPosition="left"
												icon="plus-circle"
												iconStyle={{
													width: "20px",
													height: "20px",
													color: "#475467",
												}}
												iconClassName="text-tertiary-600 text-lg"
												onClick={() => setForms(forms + 1)}
												text="Add New Address"
												className="bg-white text-[14px] text-tertiary-600"
											/>
										</div>
										<Submit
											type="submit"
											onClick={() => {
												setShowToast(true);
											}}
											isSubmitting={isSubmitting}
											spinner
											text="Save Changes"
											submittingText="Saving..."
											disabled={!dirty || !isValid || disableButton}
											className="!h-[50px]"
										/>
									</div>
								)}
								<OnSubmitValidationError callback={onSubmitValidationError} />
							</Form>
						);
					}}
				</Formik>
			</LocationFormStyled>
		</>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const locations = state.search.activeCompany.locations;
	return {
		isRequesting: state.companyProfile.isRequesting,
		company: company,
		initialValues: {
			address:
				locations.map((loc) => {
					return {
						value: loc.address,
						longitude: loc.longitude,
						latitude: loc.latitude,
					};
				}) || [],
			local_govt_association_id:
				locations.map((loc) => loc.local_govt_association_id) || [],
			company_id: company.id || "",
			postcode: locations.map((loc) => loc.postcode) || [],
			city: locations.map((loc) => loc.city) || [],
			state: locations.map((loc) => loc.state) || [],
			id: locations.map((loc) => loc.id) || [],
			profile_type: company.profile_type.id,
		},
	};
};

export const CompanyLocationForm = connect(mapStateToProps, {
	submitCompanyLocationForm,
	deleteCompanyAddress,
})(CompanyLocationFormComponent);
