import styled from "styled-components";

export const SearchTagStyled = styled.div`
	background: rgba(0, 69, 245, 0.1);
	color: ${(props) => props.theme.colors.royalBlue};
	padding: ${(props) => props.theme.space.xxs}
		${(props) => props.theme.space.xs};
	border-radius: 4px;
	// margin-right: ${(props) => props.theme.space.xs};
	// margin-bottom: ${(props) => props.theme.space.xxs};
	display: flex;
	align-items: center;

	&:last-of-type {
		margin-right: 0;
	}

	p {
		margin-bottom: 0;
		margin-top: 0;
	}

	.title {
		text-transform: uppercase;
		font-size: ${(props) => props.theme.fontSizes.xs};
		font-weight: bold;
		margin-right: 8px;
		line-height: 1.5rem;
		margin-top: 1px;
	}

	.title--normal {
		font-weight: normal;
	}
`;

export const SearchTagRowStyled = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 2.5rem;
`;
