import { GUESTRFQ, GUESTRFQTOTAL } from "constants/guest";

const INITIAL_STATE = {
	total: "",
	data: "",
};

export const guestRFQReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GUESTRFQ:
			return {
				...state,
				data: action.payload,
			};
		case GUESTRFQTOTAL:
			let newState = {
				...state,
				total: action.payload,
			};
			return newState;
		default:
			return state;
	}
};
