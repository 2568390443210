import { Flex } from "@chakra-ui/react";
import { assignSupplierListToProject } from "actions/RFQ/projectsAction";
import EditDropdown from "components/Pages/ProjectDetail/EditDropdown/EditDropdown";
import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import { navigate } from "@reach/router";
import { showToast } from "utils/helpers";
import tw, { styled, css } from "twin.macro";
import { RFQButtonActions } from "./RFQButtonActions";
import { STATUS_EVALUATION, STATUS_ACTIVE } from "utils/constants";
import { Icon } from "assets/icons/Icon";

const ListViewStyled = styled.div(() => [
	tw`pt-0`,
	css`
		.title {
			font-weight: 500;
			font-family: Roboto;
			font-size: 14px;
		}

		.text {
			font-weight: 400;
			font-family: Roboto;
			font-size: 14px;
		}
	`,
]);

const ThComp = ({ title }) => {
	return (
		<div
			className="th font-roboto text-xs text-tertiary-600"
			style={{ gap: "8px", alignItems: "center" }}
		>
			{title}
		</div>
	);
};

const SupplierChipComponent = ({ children }) => {
	return (
		<div className="flex w-fit items-center gap-2 rounded-lg bg-[#F9FAFB] px-2 py-2">
			{children}
		</div>
	);
};

const SupplierList = ({ data, project, setSupplierLists }) => {
	const isEvaluation = (rfqStatus) =>
		rfqStatus && rfqStatus?.toUpperCase() === STATUS_EVALUATION;
	const isActive = (rfqStatus) =>
		rfqStatus && rfqStatus?.toUpperCase() === STATUS_ACTIVE;
	const avatars = (data) =>
		data.map((supplier) => {
			return {
				avatar: supplier?.logo_path,
			};
		});

	const removeHandler = (supplier) => {
		const checkedRow = data.filter((item) => item.id !== supplier.id);
		assignSupplierListToProject(project?.id, {
			supplier_list_id:
				checkedRow?.length > 0 ? checkedRow.map((item) => item.id) : null,
		})
			.then((res) => {
				setSupplierLists(checkedRow);
				showToast(res, "Success", true);
			})
			.catch((err) => {
				showToast("Error while detaching the supplier list", "Error");
			});
	};

	return (
		<ListViewStyled>
			<Table containerClass={tw`w-full`}>
				<TableHeader>
					<TableRow>
						<TableHead
							firstCol={true}
							className="!max-w-[180px]"
						>
							<div
								className="flex"
								style={{ gap: "8px", alignItems: "center" }}
							>
								<ThComp title="Supplier List"></ThComp>
							</div>
						</TableHead>
						<TableHead className="!w-[300px]">
							<ThComp title="List Type"></ThComp>
						</TableHead>
						<TableHead className="!w-[800px]">
							<ThComp title="RFx"></ThComp>
						</TableHead>
						<TableHead className="!w-[500px]">
							<ThComp title="Suppliers"></ThComp>
						</TableHead>
						<TableHead className="!w-[200px]">
							<div className="hidden">CTA</div>
						</TableHead>
						<TableHead className="!w-[80px]">
							<div className="hidden">Icon Dropdown</div>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className="text-sm">
					{data?.map((rfq, index) => {
						const slug = rfq.supplierListName?.toLowerCase().replace(/ /g, "-");
						const rfqSlug = rfq.title?.toLowerCase().replace(/ /g, "-");
						return (
							<TableRow
								key={index}
								className="group"
							>
								<TableCell
									firstCol={true}
									className="after-box-shadow !max-w-[268px] group-hover:bg-utility-blue-light-50/30"
								>
									<div
										style={{
											alignItems: "center",
											gap: "8px",
										}}
									>
										<a
											href={`../../supplier-list/${rfq.supplierListId}/${slug}?project-id=${project?.id}`}
											className="title line-clamp-1 text-ellipsis text-tertiary-600"
										>
											{rfq?.supplierListName}
										</a>
									</div>
								</TableCell>
								<TableCell className="!w-[300px] group-hover:bg-utility-blue-light-50/30">
									<SupplierChipComponent>
										<svg
											width="8"
											height="8"
											viewBox="0 0 8 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="4"
												cy="4"
												r="3"
												fill="#667085"
											/>
										</svg>
										<p style={{ fontWeight: "500" }}>
											{rfq?.is_company_approved_list ? "Panel" : "My Suppliers"}
										</p>
									</SupplierChipComponent>
								</TableCell>
								<TableCell className="!w-[500px] group-hover:bg-utility-blue-light-50/30">
									<button
										type="button"
										className="text-left"
										onClick={() => {
											navigate(
												`../../request-for-quote/${rfq.id}/all/${rfqSlug}`
											);
										}}
									>
										<SupplierChipComponent>
											<p className="line-clamp-2 font-roboto font-normal text-tertiary-600">
												{rfq?.title ?? "-"}
											</p>
											<Icon
												icon="arrow-right-ios"
												style={{ height: 16, width: 16 }}
											/>
										</SupplierChipComponent>
									</button>
								</TableCell>
								<TableCell
									className={"group-hover:bg-utility-blue-light-50/30"}
								>
									<Flex className="items-center gap-3">
										{rfq?.suppliers?.length > 1 ? (
											<>
												<p>{rfq?.suppliers.length} suppliers</p>
												<GlobalAvatarGroup
													avatarSize="sm"
													maxAvatars={5}
													avatars={avatars(rfq?.suppliers)}
												/>
											</>
										) : (
											<>
												{rfq?.suppliers?.length === 1 && (
													<>
														<GlobalAvatarGroup
															avatarSize="md"
															maxAvatars={1}
															avatars={avatars(rfq?.suppliers)}
														/>
														<p className="line-clamp-1 text-ellipsis text-sm font-medium">
															{rfq?.suppliers[0]?.name}
														</p>
													</>
												)}
											</>
										)}
									</Flex>
								</TableCell>
								<TableCell className="!w-[400px] group-hover:bg-utility-blue-light-50/30">
									<RFQButtonActions
										project={project}
										rfq={rfq}
									/>
								</TableCell>
								<TableCell className="!w-[20px] group-hover:bg-utility-blue-light-50/30">
									{isEvaluation(rfq?.status) || isActive(rfq?.status) ? (
										<></>
									) : (
										<EditDropdown
											className="ml-auto w-max"
											removeHandler={() => removeHandler(rfq?.supplier)}
										/>
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</ListViewStyled>
	);
};

export default SupplierList;
