import tw from "twin.macro";
import { theme } from "../../../theme";

export const createSelectStyles = ({
	isError,
	disabled,
	height,
	width,
	maxWidth,
	isSearchBox,
	touched,
	error,
}) => ({
	option: (provided, state) => ({
		...provided,
		cursor: "pointer",
		color: state.isSelected ? "#ffffff" : theme.colors.gray,
	}),

	control: (provided) => {
		if (isSearchBox) {
			return {
				...provided,
				minHeight: "48px",
				padding: "0 8px",
				borderRadius: "6px",
				maxWidth: "550px",
				...(touched && error
					? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary`
					: tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary border border-[#D0D5DD] shadow-sm`),
			};
		}

		return {
			...provided,
			padding: "0.338rem",
			minHeight: height || "58px",
			width,
			maxWidth: maxWidth || "600px",
			borderRadius: "6px",
			borderColor: isError ? theme.colors.danger : "#e7eaee",
			marginBottom: "24px",
			backgroundColor: disabled ? "#d7d7d7" : "white",
			...(isError
				? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary`
				: tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary`),
		};
	},

	dropdownIndicator: (base) => ({
		...base,
		color: "#336AF7",
	}),

	multiValueLabel: (provided, state) => ({
		...provided,
		textTransform: state.selectProps.capitalize ? "capitalize" : "none",
	}),

	multiValue: (provided) => ({
		...provided,
		...tw`!bg-transparent !border !rounded-lg !border-gray-300`,
	}),

	multiValueRemove: (provided) => ({
		...provided,
		...tw`!bg-transparent hover:!bg-transparent hover:!text-gray-700 transition-colors`,
	}),

	menu: (provided) => ({
		...provided,
		zIndex: "100",
	}),

	placeholder: (provided) => ({
		...provided,
		marginLeft: "0px",
	}),
});
