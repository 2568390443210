import styled from "styled-components";

export const FileUploadStyled = styled.div`
	margin: 0 0 ${(props) => props.theme.space.sm_2};
	position: relative;
`;

export const RemoveButton = styled.button.attrs(() => ({ type: "button" }))`
	border: none;
	background: transparent;

	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: ${(props) => props.theme.transitions.default};
	transform: translateX(1px);
	cursor: pointer;
	pointer-events: none;

	svg {
		width: 20px;
		height: 20px;
		color: ${(props) => props.theme.colors.red};
		transition: ${(props) => props.theme.transitions.default};
	}

	&::hover {
		svg {
			color: ${(props) => props.theme.colors.redDark};
		}
	}
`;

export const FileDetailsStyled = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${(props) => props.theme.space.xs};

	&::hover {
		${RemoveButton} {
			transform: translateX(0);
			opacity: 1;
			pointer-events: auto;
		}

		a {
			color: ${(props) => props.theme.colors.royalBlueLight};
		}
	}

	svg {
		margin-right: ${(props) => props.theme.space.xs};
	}

	p {
		margin: 0;
	}
`;
