import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Image } from "assets/img";
import React from "react";
import tw, { styled } from "twin.macro";
import { cn } from "utils/helpers";

const ContainerLogo = styled.div(() => [
	tw`relative w-[480px] h-[48px] text-center items-center flex`,
]);

export const CustomModal = ({
	isOpen,
	onClose,
	header,
	footerComponent,
	children,
	size = "xl",
	...props
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={size}
			closeOnEsc
			isCentered
			{...props}
		>
			<ModalOverlay />
			<ModalContent containerProps={{ sx: { height: "100vh" } }}>
				<ModalHeader>
					<div className="space-y-3 overflow-hidden">
						<ContainerLogo>
							<Image
								img="background-pattern-02"
								className="absolute -left-5 -top-10 z-0"
								style={{ width: "180px" }}
							/>
							<div
								className={cn(
									"h-10 w-10 content-center items-center rounded-full",
									header?.bgColor
								)}
								style={{ textAlign: "-webkit-center" }}
							>
								<Icon
									icon={header?.icon}
									className={header?.iconColor}
								/>
							</div>
						</ContainerLogo>
						<div className="space-y-1">
							<h2 className="relative z-20 text-lg font-semibold text-primary-900">
								{header?.title}
							</h2>
							<p className="text-sm font-normal text-tertiary-600">
								{header?.description}
							</p>
						</div>
					</div>
				</ModalHeader>
				<ModalCloseButton onClose={onClose} />
				<ModalBody>{children}</ModalBody>
				<ModalFooter>{footerComponent}</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default Modal;
