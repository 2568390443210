import React from "react";

import { TabsStyled, TabStyled } from "./styled";

export const Tabs = ({ data, ...rest }) => {
	//reduce tabs array depending if they have visibility property set to false
	let visibleTabs = data.reduce((ids, tab) => {
		if (!tab.hidden) {
			ids.push(tab);
		}
		return ids;
	}, []);

	return (
		<TabsStyled {...rest}>
			{visibleTabs.map((tab, i) => {
				return (
					<TabStyled
						to={tab.link}
						key={i}
					>
						{tab.title}
					</TabStyled>
				);
			})}
		</TabsStyled>
	);
};
