import React from "react";
import {
	EmailShareButton,
	EmailIcon,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";

const share_url = process.env.REACT_APP_APP_URL + `/signup`;
export const SocialInvitation = () => {
	return (
		<>
			<EmailShareButton
				url={share_url}
				subject="Procuracon Invitation"
				body={
					"Hey there, Let's be the part of Procuracon. It's simple and fast application."
				}
			>
				<EmailIcon
					size={40}
					round
				/>
			</EmailShareButton>
			<FacebookShareButton
				url={share_url}
				quote={
					"Hey there, Let's be the part of Procuracon. It's simple and fast application." +
					<a href={share_url}>Link</a>
				}
			>
				<FacebookIcon
					size={40}
					round
				/>
			</FacebookShareButton>
			<LinkedinShareButton
				url={share_url}
				quote={
					"Hey there, Let's be the part of Procuracon. It's simple and fast application." +
					<a href={share_url}>Link</a>
				}
			>
				<LinkedinIcon
					size={40}
					round
				/>
			</LinkedinShareButton>
			<TwitterShareButton
				url={share_url}
				quote={
					"Hey there, Let's be the part of Procuracon. It's simple and fast application." +
					<a href={share_url}>Link</a>
				}
			>
				<TwitterIcon
					size={40}
					round
				/>
			</TwitterShareButton>
		</>
	);
};
