import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";
import { PageWrap } from "../components/Layout";
//Routes
import { Shortlist } from "./search/Shortlist";
import { NotFound } from "../routes/NotFound";

import styled from "styled-components";
import { useSelector } from "react-redux";
import { isAdmin, hasCompaniesListingAccess } from "utils/helpers";
import { Spinner } from "@chakra-ui/react";

export const CompaniesWrapper = styled.div`
	width: 100%;
	h1 {
		font-weight: 400;
		margin: 0px 0px 2.5rem;
		line-height: 1;
		font-size: 5.25rem;
	}
	.content-container {
		padding-top: 20px;
		padding-left: 48px;
		padding-right: 48px;
	}
	.content-container > div > div {
		padding: 0;
	}
	.content-container > div > div > div {
		display: flex;
		flex: 0 1 auto;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export const CompanyListing = ({ company, ...props }) => {
	const currentCompany = useSelector((state) => state?.auth?.current_company);
	const isLoading = useSelector((state) => state?.auth?.loading);
	const user = useSelector((state) => state?.auth?.user);

	useEffect(() => {
		// only check access after both user and company data are loaded
		if (
			!isLoading &&
			currentCompany &&
			Object.keys(currentCompany).length > 0 &&
			user
		) {
			// Allow access if user is admin OR has companies listing access
			if (!isAdmin() && !hasCompaniesListingAccess(currentCompany)) {
				navigate("/404");
			}
		}
	}, [currentCompany, isLoading, user]);

	if (
		isLoading ||
		!currentCompany ||
		Object.keys(currentCompany).length === 0
	) {
		return <Spinner size="xl" />;
	}

	return (
		<PageWrap>
			<CompaniesWrapper>
				<Router primary={false}>
					<Shortlist
						path="/"
						admin={props.admin}
						linkNewTab={false}
						className={"shortlist-container"}
					/>
					<NotFound default />
				</Router>
			</CompaniesWrapper>
		</PageWrap>
	);
};
