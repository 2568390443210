import React from "react";

import { SidebarStyled } from "./styled";
import { CompanySidebar } from "../CompanySidebar";
import { SkeletonBase } from "components/Skeleton";

export const Sidebar = ({ guestUser, isLoading }) => {
	return isLoading ? (
		<div className="fixed flex h-[100vh] w-[350px] flex-col gap-4 py-16 ps-8">
			<SkeletonBase
				height="180"
				width="100"
			/>
			<SkeletonBase
				height="70"
				width="100"
			/>
			<SkeletonBase
				height="20"
				width="25"
			/>
			<SkeletonBase
				height="30"
				width="100"
			/>
			<SkeletonBase
				height="30"
				width="100"
			/>
		</div>
	) : (
		<SidebarStyled>
			<CompanySidebar />
		</SidebarStyled>
	);
};
