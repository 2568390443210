import { getAddendum } from "actions/RFQ/addendumAction";
import { AddendumClient } from "components/Pages/RFQDetail/AddendumClient";
import { Addendum } from "pages/RFQSubmission/addendum/Addendum.types";
import { useEffect, useState } from "react";

type ClientAddendumContainerProps = {
	isCommunication: boolean;
	rfqDetail: any;
	totalAddendumAndComms: { totalAddendum: number; totalComms: number };
	setTotalAddendumAndComms: (totalAddendumAndComms) => void;
};

export const ClientAddendumContainer = ({
	isCommunication = false,
	rfqDetail,
	setTotalAddendumAndComms,
	totalAddendumAndComms,
}: ClientAddendumContainerProps) => {
	const [addendums, setAddendums] = useState<Addendum[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		setIsLoading(true);
		if (rfqDetail) {
			getAddendum(
				rfqDetail?.id,
				isCommunication ? "unpublished" : "published"
			).then((res) => {
				setAddendums(res?.data);
				setTotalAddendumAndComms(
					isCommunication
						? {
								...totalAddendumAndComms,
								totalComms: res?.additional?.addendumCounter,
							}
						: {
								...totalAddendumAndComms,
								totalAddendum: res?.additional?.addendumCounter,
							}
				);

				setIsLoading(false);
			});
		}
		// eslint-disable-next-line
	}, [rfqDetail, isCommunication]);

	return (
		// @ts-ignore
		<AddendumClient
			rfqDetail={rfqDetail}
			addendumData={addendums}
			setAddendumData={setAddendums}
			isCommunication={isCommunication}
			isDataLoading={isLoading}
		/>
	);
};
