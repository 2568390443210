import * as Yup from "yup";
import moment from "moment";
import { QUESTION_TYPES } from "components/Pages/CreateRFQ/ReturnableSchedulesCard/QuestionTypeComponent";
import { filterFieldValues } from "utils/helpers";

export const tabLists = [
	{
		value: 1,
		label: "RFx Details",
		description: "Provide project and RFx details",
		title: "RFx DETAILS",
		subTitle: "Please specify the details of the RFx and the required scope.",
	},
	{
		value: 2,
		label: "Documentation",
		description: "Upload relevant documents",
		title: "Documentation",
		subTitle:
			"Identify and include relevant project documents such as Project Briefs, Plans and Drawings, Approvals, Terms and Conditions, etc.",
	},
	{
		value: 3,
		label: "Returnable Schedules",
		description: "Create your questionnaire",
		title: "Returnable Schedules",
		subTitle:
			"Here you can create requests or questions and customise how answers can be provided by using the 'CUSTOMISE' dropdown. If you have returnable schedules for the respondents you can upload these as well.",
	},
	{
		value: 4,
		label: "Quote",
		description: "Create/Upload a quoting template",
		title: "QUOTE",
		subTitle:
			"Create or upload a quoting template for the Supplier to complete as part of their response. \nSimply describe the scope and nominate a unit of measurement from the dropdown.",
	},
	{
		value: 5,
		label: "RFx Participants",
		description: "Confirm RFx participants and send",
		title: "RFx Participants",
		subTitle:
			"Select suppliers that will receive the RFQ. You can also invite guest users.",
	},
];

const defaultSections = [
	{
		id: null,
		name: "",
		description: "",
		sort_order: 1,
		isRemove: 0,
		questionnaire: [
			{
				id: null,
				question: "",
				rfq_question_type_id: 2,
				required: 1,
				fields: [""],
				name: "",
				sort_order: 1,
			},
		],
	},
];

export const formValuesChecker = (rfqData) => {
	const documents = rfqData?.documentationDocument?.map((doc) => ({
		id: doc.id || null,
		document: doc.fileName || null,
		category_id: doc.categoryId || null,
		sort_order: doc.sortOrder || null,
		file_size: doc?.fileSize || null,
		path: doc.documentPath || null,
		isRemove: 0,
	}));

	const sections = rfqData?.questionnnaire?.map((section, i) => ({
		id: section.id || null,
		name: section.name || "",
		description: section.description || "",
		sort_order: i + 1,
		isRemove: 0,
		questionnaire: section.question?.map((question, j) => ({
			id: question.id || null,
			name: question.name || "",
			fields: filterFieldValues(question) || [],
			sort_order: j + 1,
			required: question.required ? 1 : 0,
			rfq_question_type_id: question.type || null,
			isRemove: 0,
		})),
	}));

	const quotes = rfqData?.quotes?.map((quote) => ({
		id: quote.id || null,
		name: quote.name || "",
		description: quote.description || "",
		sort_order: quote.sortOrder || null,
		required: 1,
		isRemove: 0,
		items: quote?.items.map((item, k) => ({
			id: item.id || null,
			name: item.name || "",
			unit_id: item.unit?.id ? item.unit?.id : item?.unit_id,
			sort_order: k + 1,
			placeholder: item.placeholder || "",
			isRemove: item?.isRemove || 0,
		})),
	}));

	const suppliers = rfqData?.supplierInvited?.all
		?.filter((supplier) => supplier.company)
		.map((supplier) => supplier?.company.id);
	const guest = rfqData?.supplierInvited?.all
		?.filter((supplier) => !supplier.company)
		.map((supplier) => ({ name: supplier.name, email: supplier.email }));
	const regexQuoteFilePathExt = /\.([a-zA-Z0-9]+)\?/;
	const quoteFileName = rfqData?.quoteFilePath
		? rfqData?.quoteFilePath
				.match(regexQuoteFilePathExt)?.[0]
				.replace("?", "") || ""
		: "";

	return {
		name: rfqData?.name || "",
		rfq_id_number: rfqData?.rfqIdNumber || "",
		type: rfqData?.type || "",
		address: {
			latitude: rfqData?.locationLatitude || "",
			longitude: rfqData?.locationLongitude || "",
			value: rfqData?.location || "",
		},
		rfx_lead_id: rfqData?.rfxLead?.id || "",
		description: rfqData?.description || "",
		client: rfqData?.client || "",
		deadline_date: rfqData?.submissionDeadline
			? moment(rfqData?.submissionDeadline)
			: "",
		deadline_time: rfqData?.submissionDeadline
			? moment(rfqData?.submissionDeadline).format("HH:mm")
			: "",
		submission_deadline_timezone: rfqData?.submissionTimezone || "AWST",
		escope_of_service: rfqData?.escopeOfService || "",
		documentation_description: rfqData?.documentationDescription || "",
		documents: documents || [],
		returnable_schedule_type: rfqData?.returnableScheduleType || "form",
		sections: sections.length > 0 ? sections : defaultSections,
		returnable_schedule_file: rfqData?.returnableScheduleFilePath?.file_type
			? `Returnable Schedules Template.${rfqData?.returnableScheduleFilePath?.file_type}`
			: "",
		returnable_schedule_file_path:
			rfqData?.returnableScheduleFilePath?.file_path || "",
		quote: quotes || [],
		quote_type: rfqData?.quoteType || "form",
		quote_file: quoteFileName ? `Quote Template${quoteFileName}` : "",
		quote_file_path: rfqData?.quoteFilePath || "",
		suppliers: suppliers || [],
		guest: guest || [],
	};
};

const generateQuestionSchema = () => {
	const typesChecker = (type) => {
		switch (type) {
			case QUESTION_TYPES.SINGLE_OPTION:
				return "Option";
			case QUESTION_TYPES.MULTIPLE_CHOICE:
				return "Checkbox";
			case QUESTION_TYPES.DROP_DOWN:
				return "Selection";
			default:
				return "Option";
		}
	};
	return Yup.object().shape({
		id: Yup.string().nullable(),
		name: Yup.string().when("isRemove", {
			is: (val) => val === 0 || val === null || val === undefined,
			then: Yup.string().required("Question name is required"),
			otherwise: Yup.string().nullable(),
		}),
		fields: Yup.array().when(
			"rfq_question_type_id",
			(rfq_question_type_id, schema) => {
				if (parseInt(rfq_question_type_id) === QUESTION_TYPES.TABLE) {
					let index = 0;
					return schema.of(
						Yup.object().shape({
							header: Yup.string().required("Header value is required"),
							values: Yup.array()
								.when("$", (_, schema, data) => {
									if (index === 0) {
										index++;
										return schema.of(
											Yup.mixed().required("Question is required")
										);
									}
									return schema.of(Yup.string().nullable());
								})
								.required("Values array is required"),
						})
					);
				}
				return [
					QUESTION_TYPES.SINGLE_OPTION,
					QUESTION_TYPES.MULTIPLE_CHOICE,
					QUESTION_TYPES.DROP_DOWN,
				].includes(parseInt(rfq_question_type_id))
					? schema
							.of(
								Yup.string().required(
									`${typesChecker(parseInt(rfq_question_type_id))} name is required`
								)
							)
							.nullable()
					: schema.nullable("rfq_question_type_id");
			}
		),
		sort_order: Yup.number().nullable(),
		required: Yup.number().oneOf([0, 1]).required(),
		rfq_question_type_id: Yup.string().required(
			"Please select the question type"
		),
	});
};

const generateSectionSchema = () => {
	return Yup.object().shape({
		id: Yup.string().nullable(),
		name: Yup.string().when("isRemove", {
			is: (val) => val === 0 || val === null || val === undefined,
			then: Yup.string().required("Section name is required"),
			otherwise: Yup.string().nullable(),
		}),
		description: Yup.string().when("isRemove", {
			is: (val) => val === 0 || val === null || val === undefined,
			then: Yup.string().required("Description is required"),
			otherwise: Yup.string().nullable(),
		}),
		sort_order: Yup.number().nullable(),
		questionnaire: Yup.array().when("isRemove", {
			is: (val) => val === 0 || val === null || val === undefined,
			then: Yup.array()
				.of(generateQuestionSchema())
				.min(1, "Please add at least 1 question for this section"),
			otherwise: Yup.array().nullable(),
		}),
	});
};

const generateGuestSchema = () => {
	return Yup.object().shape({
		name: Yup.string().required("Name is required"),
		email: Yup.string().email("Invalid email").required("Email is required"),
	});
};

const generateQuoteItemSchema = () => {
	return Yup.object().shape({
		id: Yup.string().nullable(),
		name: Yup.string().required("Item name is required"),
		unit_id: Yup.string().required("Unit of Measurement is not selected"),
		sort_order: Yup.number().nullable(),
	});
};

const generateQuoteSchema = () => {
	return Yup.object().shape({
		id: Yup.string().nullable(),
		name: Yup.string().required("Section name is required"),
		description: Yup.string().required("Section description is required"),
		sort_order: Yup.number().nullable(),
		required: Yup.number().oneOf([1]).required(),
		isRemove: Yup.number().oneOf([0, 1]).required(),
		items: Yup.array()
			.test("rowTotal", "At least one row is required", function (value) {
				return value.filter((item) => !item.isRemove).length > 0;
			})
			.of(generateQuoteItemSchema()),
	});
};

export const validationSchema = () => {
	// Define validation rules for each step
	const stepValidations = {
		// Step 1: RFx Details
		details: {
			name: Yup.string().required("Request Title is required"),
			type: Yup.string().required("Type is required"),
			client: Yup.string().required("Client is required"),
			rfx_lead_id: Yup.number().required("RFx lead is required"),
			deadline_date: Yup.date().required("Deadline date is required"),
			deadline_time: Yup.string().required("Time is required"),
			address: Yup.object().shape({
				value: Yup.string().required("Location is required"),
			}),
		},

		// Step 2: Documentation
		documentation: {
			documents: Yup.array().test(
				"documentTotal",
				"At least one document is required",
				(value) => value.filter((doc) => !doc.isRemove).length > 0
			),
			documentation_description: Yup.string().test(
				"len",
				"Input has exceeded the max character limit",
				(val) => (val ? val?.replace(/<\/?[^>]+>/gi, "").length <= 1500 : true)
			),
		},

		// Step 3: Returnable Schedules
		schedules: {
			sections: Yup.array().when("returnable_schedule_type", (type, schema) =>
				type !== "file"
					? schema.of(generateSectionSchema()).min(1, "Section is required")
					: schema.nullable()
			),
			returnable_schedule_file: Yup.mixed().when("returnable_schedule_type", {
				is: "file",
				then: Yup.mixed().required(),
			}),
		},

		// Step 4: Quote
		quote: {
			quote: Yup.array().when("quote_type", (type, schema) =>
				type !== "file"
					? schema.of(generateQuoteSchema()).min(1, "Quote is required")
					: schema.nullable()
			),
			quote_file: Yup.mixed().when("quote_type", {
				is: "file",
				then: Yup.mixed().required("Template file is required"),
			}),
		},

		// Step 5: RFx Participants
		participants: {
			guest: Yup.array().of(generateGuestSchema()),
		},
	};

	// Combine all validation rules into one schema
	return Yup.object().shape({
		...stepValidations.details,
		...stepValidations.documentation,
		...stepValidations.schedules,
		...stepValidations.quote,
		...stepValidations.participants,
	});
};

export const validStepChecker = (step, errors) => {
	if (errors) {
		if (step === 1) {
			return (
				!errors.type && !errors.client && !errors.deadline_date && !errors.name
			);
		}
		if (step === 2) {
			return !errors.documents;
		}
		if (step === 3) {
			const sectionErrors = Object.keys(errors).some(
				(key) =>
					key.includes("sections") || key.includes("returnable_schedule_file")
			);
			return !sectionErrors;
		}
		if (step === 4) {
			const quoteErrors = Object.keys(errors).some((key) =>
				key.includes("quote")
			);
			return !quoteErrors;
		}
		if (step === 5) {
			return !errors.guest;
		}
	}
	return true;
};

export const validateReturnableSchedulesStep = (currentFormValues) => {
	if (currentFormValues?.returnable_schedule_type === "form") {
		if (currentFormValues?.sections?.length > 0) {
			if (
				currentFormValues?.sections?.length === 1 &&
				currentFormValues?.sections[0]?.name === "deleted"
			) {
				return {
					message: "At least one section with questionnaire is required",
					title: "Section Required",
				};
			} else {
				const nullQuestionnaire = isReturnableQuestionnaireNull(
					currentFormValues?.sections
				);
				const nullSection = currentFormValues?.sections?.find(
					(currentSection) => currentSection.name === ""
				);

				if (nullSection)
					return {
						message: "Please enter the value of section",
						title: "Section value is required",
						data: nullSection,
					};

				if (nullQuestionnaire)
					return {
						message: "Please enter the value of questionnaire",
						title: "Question value is required",
						data: nullQuestionnaire,
					};
			}
		}
	} else {
		if (
			!currentFormValues?.returnable_schedule_type ||
			currentFormValues?.returnable_schedule_type?.length < 1
		) {
			return {
				message: "Please upload returnables schedules templates",
				title: "Returnable schedules template is required",
				data: "returnable_schedule_type",
			};
		}
	}
};

const isReturnableQuestionnaireNull = (currentFormSection) => {
	return currentFormSection?.find((currentSection) => {
		return currentSection?.questionnaire?.find(
			(question) => question?.name === ""
		);
	});
};
