import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import "./index.css";
import { UserbackProvider } from "@userback/react";

const showUserback = true;

// identify your logged-in users (optional)
const userbackToken =
	process.env.REACT_APP_RFQ_USERBACK_TOKEN ||
	"10882|22399|rtG9AvxiLE9HeYKBKruB5db8d";
const user_data = {
	id: "123456",
	info: {
		name: "someone",
		email: "someone@example.com",
	},
};

const store = configureStore();

// Create root instance
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	showUserback ? (
		<UserbackProvider
			token={userbackToken}
			options={{ user_data: user_data }}
		>
			<Provider store={store}>
				<App />
			</Provider>
		</UserbackProvider>
	) : (
		<Provider store={store}>
			<App />
		</Provider>
	)
);

if (module.hot) {
	module.hot.accept("./App", () => {
		root.render(
			showUserback ? (
				<UserbackProvider
					token={userbackToken}
					options={{ user_data: user_data }}
				>
					<Provider store={store}>
						<App />
					</Provider>
				</UserbackProvider>
			) : (
				<Provider store={store}>
					<App />
				</Provider>
			)
		);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.info("[LogRocket]", 'Initialised "qlpwa6/procuracon"');
LogRocket.init("qlpwa6/procuracon");

// // userback code
// const Userback = window.Userback || {};
// Userback.access_token = "10882|22399|rtG9AvxiLE9HeYKBKruB5db8d";
// (function (d) {
//   var s = d.createElement("script");
//   s.async = true;
//   s.src = "https://static.userback.io/widget/v1.js";
//   (d.head || d.body).appendChild(s);
// })(document);
