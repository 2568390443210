import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash.isempty";
import { SearchTemplate } from "../../layout/SearchTemplate";
import { SearchResults } from "./SearchResults";
import { FullDraw } from "../../components/FullDraw";
import { SearchShortlist } from "./SearchShortlist";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import {
	getGroup,
	searchCompanies,
	removeFromList,
	addProjectToCompareCard,
	removeProjectFromCompareCard,
	getCompareList,
	getShortlist,
	setAddProjectIndex,
	addALLToListCustom,
} from "../../actions/searchActions";
import { getPastProjects } from "../../actions/companyActions";
import apiV2 from "api-v2";
import { SkeletonBase } from "components/Skeleton";
import { sidebarCollapse as sidebarCollapseAction } from "actions/sidebarActions";
import { showToast } from "utils/helpers";
import { getSupplierListById } from "actions/RFQ/supplierListsActions";
import { SET_GROUP_ID } from "actions/actionTypes";

const SearchContainerConnected = ({
	results,
	compareList,
	compareListCompanies,
	shortlist,
	groupId,
	getGroup,
	searchCriteria,
	searchCompanies,
	isSearching,
	disciplines,
	group,
	sectors,
	types,
	hasSearched,
	lockedSearchItems,
	lockedCompareItems,
	removeFromList,
	getPastProjects,
	pastProjects,
	searchMeta,
	isLoadingNext,
	getCompareList,
	getShortlist,
	trades,
	specialities,
	supplierlist,
	...props
}) => {
	const location = useLocation();
	const isDirectCompare = location?.pathname?.includes("/direct/compare");
	const urlParams = new URLSearchParams(window.location.search);
	const supplierListId = urlParams.get("supplier");
	const [currentGroupId, setCurrentGroupId] = useState(groupId);

	const [loading, setLoading] = useState(true);
	let pagesSplit = location.pathname.split("/");
	const lastPage = pagesSplit[pagesSplit.length - 1];
	const [resultFiltered, setResultFiltered] = useState([]);
	const [shortlistFiltered, setShortListFiltered] = useState(shortlist);
	const [isOpen, setIsOpen] = useState(false);
	const [companyProjectData, setCompanyProjectData] = useState({});
	const [showCompanyProjects, setCompanyProjectsView] = useState(false);
	const [companyPersonnel, setCompanyPersonnel] = useState({});
	const [showCompanyPersonnel, setCompanyPersonnelView] = useState(false);
	const { sidebarcollapse } = useSelector((state) => state.sidebar);
	const [compareProjectHeight, setCompareProjectHeight] = useState(50);
	const [sticked, setSticked] = useState(false);
	const [sleft, setSleft] = useState(0);
	const addProjectIndexes = useSelector(
		(state) => state.search.addProjectIndex
	);

	const dispatch = useDispatch();
	const companyId = useSelector((state) => state.auth.user.company_id);

	useEffect(() => {
		if (isDirectCompare) {
			getSupplierListById(supplierListId).then((res) => {
				dispatch({ type: SET_GROUP_ID, payload: res?.data?.groupId });
				setCurrentGroupId(res?.data?.groupId);
			});
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!sidebarcollapse) {
			setIsOpen(false);
		}
	}, [sidebarcollapse]);

	useEffect(() => {
		if (!supplierlist?.directcompare) {
			setShortListFiltered(shortlist);
		}
		//eslint-disable-next-line
	}, [shortlist]);

	const handleAddProjectClick = async (project, index) => {
		if (addProjectIndexes.length === 0) {
			showToast("No more room for projects", "Error");
			return false;
		}

		const res = await dispatch(
			addProjectToCompareCard(
				currentGroupId,
				project.company_id
					? project.company_id
					: project.company && project.company.id,
				project,
				addProjectIndexes[0],
				isDirectCompare
			)
		);

		if (res) {
			addProjectIndexes.shift();
			dispatch(setAddProjectIndex(addProjectIndexes));
			showToast("Project successfully added to compare view", "Success", true);
			setCompanyProjectsView(false);
			setIsOpen(false);
		} else showToast("Unable to add project", "Error");
	};

	const handleRemoveProjectClick = async (project, index) => {
		const res = await dispatch(
			removeProjectFromCompareCard(
				currentGroupId,
				project.company_id
					? project.company_id
					: project.company && project.company.id,
				project,
				index,
				isDirectCompare
			)
		);

		if (res) {
			setCompanyProjectsView(false);
			setIsOpen(false);
			showToast(
				"Project successfully removed from compare view",
				"Success",
				true
			);
		} else showToast("Unable to remove project", "Error");
	};

	useEffect(() => {
		if (groupId !== "direct") {
			if (groupId && !group.search_criteria) {
				getGroup(groupId);
				getCompareList(parseInt(groupId));
				getShortlist(parseInt(groupId));
				setLoading(false);
			}

			if (!_isEmpty(searchCriteria) && !hasSearched) {
				let search = searchCriteria;
				let newSearchCriteria = { ...search, group_id: parseInt(groupId) };

				(async () => {
					await searchCompanies(newSearchCriteria, true);
				})();
			}
		}

		// eslint-disable-next-line
	}, [getGroup, group, groupId]);

	useEffect(() => {
		const abortController = new AbortController();
		const fetchData = async () => {
			try {
				setLoading(true);
				if (supplierlist?.directcompare === true) {
					let payload = {
						companyId: companyId,
						supplierIds: supplierlist?.suppliers,
					};
					let resCompare = await apiV2.post(
						`/supplier/${supplierlist?.id}/compare`,
						payload,
						{ signal: abortController.signal }
					);
					resCompare = resCompare?.data?.data;
					let formatedCompare = [];
					for (let a = 0; a < resCompare.length; a++) {
						resCompare[a].company.projects = resCompare[a].projects;
						formatedCompare.push(resCompare[a].company);
					}
					dispatch(addALLToListCustom("compare", formatedCompare));
					setLoading(false);
				} else {
					setResultFiltered(results);
					setLoading(false);
				}
			} catch (error) {}
		};
		fetchData();
		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	}, [supplierlist]);

	const compareSlideoutClick = (items, type, companyId) => {
		if (!sidebarcollapse) {
			dispatch(sidebarCollapseAction(true));
		}
		if (type === "projects") {
			//fetch company projects
			getPastProjects(companyId);

			console.log("compare list", compareList);

			//grab all the project id's we've got in our compare card for the company, so we can test if the add button should be disabled
			const compareProjects =
				compareList && compareList.length
					? compareList.flatMap((company) => {
							if (companyId !== company?.id) {
								return [];
							}

							return isDirectCompare
								? company.projects
								: company?.compare_projects;
						})
					: [];

			setCompanyProjectData({ companyId, compareProjects });
			setCompanyProjectsView(true);
			setCompanyPersonnelView(false);
			setIsOpen(true);
		} else if (type === "personnel") {
			setCompanyPersonnel({ companyId, data: items });
			setCompanyProjectsView(false);
			setCompanyPersonnelView(true);
			setIsOpen(true);
		}
	};

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const hasReachedHorizontalEnd = (element) => {
		let scrollLeft = element.scrollLeft;
		let scrollWidth = element.scrollWidth;

		//trigger 150px before reaching end of scroll div
		if (
			Math.ceil(scrollWidth - scrollLeft - 150) <=
			Math.ceil(element.getBoundingClientRect().width)
		) {
			return true;
		}

		return false;
	};

	const handleResultsScroll = (element) => {
		if (hasReachedHorizontalEnd(element)) {
			if (searchMeta.current_page < searchMeta.last_page && !isLoadingNext) {
				searchCompanies(searchCriteria, true, searchMeta.current_page + 1);
			}
		}
	};

	const removeFromCompareListFunc = (companyId) => {
		let filteredResult = [...resultFiltered];
		filteredResult = filteredResult.filter((f) => {
			return f.id !== companyId;
		});
		setResultFiltered(filteredResult);
	};

	useEffect(() => {
		setResultFiltered(results);
	}, [results]);

	useEffect(() => {
		if (isSearching) {
			setLoading(true);
		} else {
			if (!supplierlist?.directcompare) {
				setLoading(false);
			}
		}
	}, [isSearching, supplierlist]);

	useEffect(() => {
		/**
		 * set height for No. of projects relevant to sector column
		 * if have data => 1590px
		 * if no data => 50px
		 */
		let currentHeight = 50;
		if (compareList.length > 0) {
			for (let a = 0; a < compareList.length; a++) {
				if (!supplierlist?.directcompare) {
					if (compareList[a]?.compare_projects.length > 0) {
						for (let b = 0; b < compareList[a]?.compare_projects?.length; b++) {
							if (compareList[a]?.compare_projects[b] !== null) {
								currentHeight = 1590;
							}
						}
					}
				} else {
					if (compareList[a]?.projects.length > 0) {
						for (let b = 0; b < compareList[a]?.projects?.length; b++) {
							currentHeight = 1560;
						}
					}
				}
			}
			setCompareProjectHeight(currentHeight);
		}
	}, [compareList, supplierlist]);

	const handleScroll = () => {
		const elem = document.getElementsByClassName("comp-name")[0];
		const pos = elem?.getBoundingClientRect().top;
		const posX = elem?.getBoundingClientRect().left + 1;

		setSleft(posX);
		if (pos <= 0) {
			setSticked(true);
			setSleft(posX);
		} else setSticked(false);

		handleContentHeaderOnScroll();
	};

	const handleContentHeaderOnScroll = () => {
		const sections = document.querySelector("#compare-data-headings");
		if (!sections) return;

		const headings = sections.querySelectorAll("[contentType]");

		const projectContent = document.querySelector(
			"[contentType='project-list-data']"
		);
		const documentContent = document.querySelectorAll(
			"[contenttype='documents']"
		)[1];

		headings.forEach((heading) => {
			const contentType = heading.getAttribute("contentType");

			const handleStickyHeader = (content, threshold) => {
				if (!content) return;

				const contentTop = content.getBoundingClientRect().top;
				const isSticky = contentTop <= 0 && contentTop > -threshold;

				heading.style.position = isSticky ? "fixed" : "relative";
				heading.style.top = isSticky ? "0" : "1px";
				heading.style.width = "260px";
			};

			if (contentType === "project-list") {
				handleStickyHeader(projectContent, 1474);
			} else if (contentType === "documents") {
				handleStickyHeader(documentContent, 1463.5);
			}
		});
	};

	return (
		<>
			<SearchTemplate
				resultsCount={
					supplierlist?.directcompare ? resultFiltered.length : searchMeta.total
				}
				handleScroll={handleScroll}
				compareCount={
					supplierlist?.directcompare
						? resultFiltered.length
						: compareList
							? compareList.length
							: 0
				}
				shortlistCount={shortlistFiltered ? shortlistFiltered.length : 0}
				shortlist={shortlistFiltered}
				noDataTable={["shortlist"].includes(lastPage)}
				searchCriteria={searchCriteria}
				disciplines={disciplines}
				sectors={sectors}
				types={types}
				groupId={currentGroupId}
				trades={trades}
				specialities={specialities}
				supplierlist={supplierlist}
				loading={loading}
			>
				{loading ? (
					<div className="mt-5 pt-5">
						<div className="ml-5 flex pl-5">
							<div className="ml-2 mr-2">
								<SkeletonBase
									width="300px"
									height="236px"
								/>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
							</div>
							<div className="ml-2 mr-2">
								<SkeletonBase
									width="300px"
									height="236px"
								/>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
							</div>
							<div className="ml-2 mr-2">
								<SkeletonBase
									width="300px"
									height="236px"
								/>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
								<div className="mb-2 mt-3">
									<SkeletonBase
										width="300px"
										height="36px"
									/>
								</div>
							</div>
						</div>
					</div>
				) : (
					<>
						<Router
							primary={false}
							style={{
								width: "100%",
								backgroundColor: "#F4F5F7",
								flexGrow: 1,
								paddingTop: "50px",
							}}
						>
							{!loading && (
								<>
									<SearchResults
										path="results/*"
										groupId={currentGroupId}
										results={resultFiltered}
										shortlistFiltered={shortlist}
										lockedItems={lockedSearchItems}
										compareSlideoutClick={compareSlideoutClick}
										isResults
										cardType="search"
										handleScrollEnd={handleResultsScroll}
										isLoadingNext={isLoadingNext}
										sticked={sticked}
										sleft={sleft}
										directCompare={supplierlist?.directcompare}
										removeFromCompareListFunc={removeFromCompareListFunc}
									/>
									<SearchResults
										path="compare/*"
										groupId={currentGroupId}
										results={compareList}
										shortlistFiltered={shortlist}
										compareList={compareList}
										compareSlideoutClick={compareSlideoutClick}
										lockedItems={lockedCompareItems}
										isCompare
										sticked={sticked}
										sleft={sleft}
										cardType="compare"
										isFullDrawOpen={isOpen}
										directCompare={supplierlist?.directcompare}
										removeFromCompareListFunc={removeFromCompareListFunc}
										compareProjectHeight={compareProjectHeight}
										loading={loading}
									/>
									<SearchShortlist
										path="shortlist/*"
										items={shortlist}
										removeFromList={removeFromList}
										groupId={currentGroupId}
										directcompare={supplierlist?.directcompare}
									/>
								</>
							)}
						</Router>
					</>
				)}

				<FullDraw
					companyProjectData={companyProjectData}
					showCompanyProjects={showCompanyProjects}
					companyPersonnel={companyPersonnel}
					showCompanyPersonnel={showCompanyPersonnel}
					handleAddProjectClick={handleAddProjectClick}
					handleRemoveProjectClick={handleRemoveProjectClick}
					isOpen={isOpen}
					groupId={currentGroupId}
					toggleOpen={toggleOpen}
					sectors={sectors}
					pastProjects={pastProjects}
				/>
			</SearchTemplate>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		isSearching: state.search.isSearching,
		results: state.search.searchResult,
		searchMeta: state.search.searchMeta,
		isLoadingNext: state.search.isLoadingNext,
		group: state.search.group,
		searchCriteria: state.search.searchCriteria,
		hasSearched: state.search.hasSearched,
		disciplines: state.search.disciplines,
		types: state.search.types,
		sectors: state.sectors,
		pastProjects: state.companyProfile.pastProjects,
		compareList: state.search.compareList,
		trades: state.companyProfile.trades,
		specialities: state.companyProfile.specialities,
	};
};

export const SearchContainer = connect(mapStateToProps, {
	getGroup,
	searchCompanies,
	removeFromList,
	getPastProjects,
	getCompareList,
	getShortlist,
})(SearchContainerConnected);
