import React from "react";
import { useField } from "formik";
import styled from "styled-components";
import tw from "twin.macro";
import { Error } from "../Error";

export const FormLabel = styled.label`
	font-size: ${(props) => props.theme.fontSizes.xs};
	color: ${(props) => props.theme.colors.textDark};
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	margin-bottom: ${(props) => props.theme.space.xxs};

	> span {
		color: #dc2538;
		margin-left: 3px;
	}
`;

const CheckboxWrap = styled.div`
	margin-bottom: ${(props) => props.theme.space.xs};
	position: relative;
`;

export const CheckboxError = styled(Error)`
	top: auto;
	bottom: -58px;
`;

export const CheckboxStyled = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	user-select: none;
	font-size: ${(props) =>
		props.fontSize ? props.fontSize : props.theme.fontSizes.sm};
	font-weight: 500;
	${tw`font-roboto`}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		&::checked .checkmark {
			background-color: ${(props) => props.theme.colors.royalBlue};
		}
	}

	.checkmark {
		position: absolute;
		top: ${(props) => (props.checkmarkTop ? props.checkmarkTop : 0)};
		left: 0;
		height: 19px;
		width: 19px;
		background-color: #eee;
		display: block;
		border: 1px solid ${(props) => props.theme.colors.grayLight};
		border-radius: 2px;
		&::after {
			content: "";
			position: absolute;
			opacity: 0;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 11px;
			border: solid white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			transition: ${(props) => props.theme.transitions.default};
		}
	}

	&::hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark {
		background-color: #0045f5;
		border: none;
	}

	input:checked ~ .checkmark::after {
		opacity: 1;
	}
`;

export const Checkbox = ({ children, name, isFormik, ...props }) => {
	const [field, meta] = useField(name);

	return (
		<CheckboxWrap
			className={props.className}
			style={props.style}
		>
			<CheckboxStyled className="container-checkbox">
				<span dangerouslySetInnerHTML={{ __html: props.label }} />
				<input
					type="checkbox"
					checked={!!field.value || !!props.initialValue}
					{...field}
					{...props}
				/>
				<span className="checkmark"></span>
			</CheckboxStyled>

			<CheckboxError
				meta={meta}
				leftAlign
			/>
		</CheckboxWrap>
	);
};

Checkbox.defaultProps = {
	isFormik: true,
};
