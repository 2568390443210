import { Flex } from "@chakra-ui/react";
import React from "react";
import * as constant from "../../../../utils/constants";
import { Button } from "components/Button";
import BadgeComp from "components/RFQ/BadgeComp/BadgeComp";
const RFQDetailButtonTab = ({
	rfqDetail,
	currentTab,
	setCurrentTab,
	totalAddendumAndComms,
}) => {
	const { accepted, all, submitted, declined } = rfqDetail?.supplierInvited || {
		accepted: [],
		all: [],
		declined: [],
		submitted: [],
	};

	const tabMenu = [
		{
			id: constant.ALL_INVITED_SUPPLIER,
			name: "All Invited Suppliers",
			showBadge: true,
			total: all?.length || 0,
		},
		{
			id: constant.ACCEPTED,
			name: "Accepted",
			showBadge: true,
			total: accepted?.length || 0,
		},
		{
			id: constant.SUBMITTED,
			name: "Submitted",
			showBadge: true,
			total: submitted?.length || 0,
		},
		{
			id: constant.DECLINED,
			name: "Declined",
			showBadge: true,
			total: declined?.length || 0,
		},
		{
			id: "addendum",
			name: "Addendum",
			showBadge: true,
			total: totalAddendumAndComms?.totalAddendum || 0,
		},
		{
			id: "comms",
			name: "Comms",
			showBadge: true,
			total: totalAddendumAndComms?.totalComms || 0,
		},
	];

	return (
		<div>
			<Flex
				style={{ gap: "8px" }}
				className="bg-white"
			>
				{tabMenu.map((tbMenu, index) => {
					return (
						<div
							key={index}
							className="group"
						>
							<Button
								btntype="tab"
								active={currentTab === tbMenu.id ? true : false}
								onClick={() => setCurrentTab(tbMenu.id)}
							>
								<div
									style={{ fontFamily: "roboto", fontWeight: "500" }}
									className={`text-${currentTab === tbMenu.id ? "to-utility-brand-700" : "text-gray-text-400"}`}
								>
									{tbMenu.name}
								</div>
								{tbMenu.showBadge && (
									<BadgeComp
										title={tbMenu.id}
										active={currentTab}
										total={tbMenu.total}
									></BadgeComp>
								)}
							</Button>
						</div>
					);
				})}
			</Flex>
		</div>
	);
};

export default RFQDetailButtonTab;
