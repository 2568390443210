import styled from "styled-components";

export const DocumentFormStyled = styled.div`
	background-color: white;
	padding: ${(props) => props.theme.space.sm_2};
`;

export const RequestAccessFormStyled = styled.div`
	background-color: white;
	padding: ${(props) => props.theme.space.sm_2};
`;

export const PurposeCheckBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.75rem;
	align-items: left;
	svg {
		margin-left: 4px;
	}
	padding-bottom: 0.5rem;
`;
