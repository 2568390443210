import React from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { CompanyProjectsListing } from "../../components/CompanyProjectsListing";

const CompanyProjects = ({
	pastProjects,
	companyId,
	loadingPastProjects,
	...props
}) => {
	if (loadingPastProjects) {
		return (
			<Skeleton
				count={30}
				height={12}
				duration={1}
			/>
		);
	}

	return (
		<div className="overflow-x-auto">
			<CompanyProjectsListing
				companyId={companyId}
				pastProjects={pastProjects}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		pastProjects: state.companyProfile.pastProjects,
		loadingPastProjects: state.companyProfile.loadingPastProjects,
	};
};

export const CompanyProjectsContainer = connect(
	mapStateToProps,
	{}
)(CompanyProjects);
