import { Box } from "@chakra-ui/react";
import { FileCard } from "components/RFQ/FileCard";
import React from "react";

const Documentation = ({
	documentAttachments,
	documentDescription,
	...props
}) => {
	return (
		<>
			<Box
				className="mb-8 grid min-h-40 w-full rounded-md border p-10 shadow-lg"
				{...props}
			>
				<h1 className="text-xl font-bold">Documentation Description</h1>
				<p
					className="mt-3 [&>ol]:ml-5 [&>ol]:!list-decimal [&>ul]:ml-5 [&>ul]:!list-disc"
					dangerouslySetInnerHTML={{ __html: documentDescription }}
				/>
			</Box>
			<Box
				className="grid min-h-80 w-full rounded-md border p-10 shadow-xl"
				{...props}
			>
				<div>
					{documentAttachments
						? documentAttachments.map((category, index) => {
								return (
									<div key={index}>
										<h1 className="text-xl font-bold">{category.category}</h1>
										<div>
											{category?.documents.map((doc, index) => (
												<FileCard
													key={index}
													file={doc}
													isPreview={true}
												/>
											))}
										</div>
									</div>
								);
							})
						: []}
				</div>
			</Box>
		</>
	);
};

export default Documentation;
