import React from "react";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "../../icons/plus-solid.svg";
import { ReactComponent as CrossIcon } from "../../icons/cross-circle-solid.svg";

export const RepeaterButtonStyled = styled.button.attrs({
	type: "button",
})`
	font-size: ${(props) =>
		props.small ? props.theme.fontSizes.md : props.theme.fontSizes.xxl};
	display: flex;
	align-items: center;
	flex-direction: ${(props) => (props.vertical ? "column" : "row")};
	font-weight: 500;
	margin: 0;
	color: ${(props) => props.theme.colors.navy};
	border: none;
	background: transparent;
	margin-bottom: ${(props) => props.theme.space.sm_2};
	cursor: ${(props) => (!props.disabled ? "pointer" : "default")};
	transition: ${(props) => props.theme.transitions.default};
	padding: 0;

	&::hover {
		color: ${(props) =>
			!props.disabled ? props.theme.colors.royalBlue : props.theme.colors.navy};

		svg {
			color: ${(props) => props.theme.colors.royalBlue};
		}
	}

	&:focus {
		outline: none;
	}

	svg {
		width: 2rem;
		height: 2rem;
		color: ${(props) =>
			props.remove ? props.theme.colors.red : props.theme.colors.mint};
		margin-left: ${(props) => (props.vertical ? 0 : "0.75rem")};
		display: inline-block;
		transition: ${(props) => props.theme.transitions.default};
		margin-bottom: ${(props) => (props.vertical ? "12px" : "0")};
	}
`;

export const RepeaterButton = (props) => {
	return (
		<RepeaterButtonStyled
			className={props.className}
			style={props.style}
			remove={props.remove}
			onClick={!props.disabled ? props.handleAddClick : null}
			small={props.small}
			vertical={props.vertical}
			showIcon={props.showIcon}
			disabled={props.disabled}
			as={props.as}
		>
			{!props.vertical && props.text}{" "}
			{props.showIcon === undefined || props.showIcon ? (
				props.remove ? (
					<CrossIcon />
				) : (
					<PlusIcon />
				)
			) : (
				""
			)}{" "}
			{props.vertical && props.text}
		</RepeaterButtonStyled>
	);
};
