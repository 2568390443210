import React, { useEffect, useRef } from "react";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import { TabHeader } from "components/TabHeader";
import { CompaniesListing } from "components/CompaniesListing";
import { Documents } from "./Documents";
import {
	isAdmin,
	isBidder,
	isProcurer,
	isProcurerNBidder,
	isSuperAdmin,
} from "utils/helpers";
import { useSelector } from "react-redux";
import { Resources } from "./Resources";
import { ProjectListing } from "./ProjectListing";
import { CompanyLocationForm } from "components/CompanyLocationForm";
import { CompanyInformation } from "./CompanyInformation";
import { navigate, useParams } from "@reach/router";
import {
	getCompanyView,
	getDocuments,
	getPastProjects,
	getResources,
} from "actions/companyActions";
import { useDispatch } from "react-redux";
import { SkeletonBase } from "components/Skeleton";

const CompanyProfileV2 = ({ company, activeTab }) => {
	const { user, current_company } = useSelector((state) => state?.auth);

	const params = useParams();
	const companyId = user?.is_admin ? params?.companyId : current_company?.id;
	const dispatch = useDispatch();

	const allowedOfficeLocations =
		isProcurerNBidder(company) ||
		isBidder(company) ||
		isProcurer(company) ||
		isSuperAdmin();
	const allowedDocument =
		isAdmin(user) || isSuperAdmin() || isProcurer(company);

	const COMPANYINFORMATION = "Company Information";
	const OFFICELOCATIONS = "Office Locations";
	const PROJECTEXPERIENCES = "Projects and Experience";
	const KEYPERSONNELEXPERTISE = "Key Personnel and Expertise";
	const CORPORATEDOCUMENTS = "Corporate Documents";
	const SHAREDPROFILES = "Shared Profiles";
	const formikRef = useRef(null);
	const btnOfficeLocation = useRef(null);

	const tabMenu = [
		{
			id: "company-information",
			name: COMPANYINFORMATION,
			showBadge: false,
			total: 0,
			disabled: false,
		},
		{
			id: "company-locations",
			name: OFFICELOCATIONS,
			showBadge: false,
			total: 0,
			disabled: !allowedOfficeLocations,
		},
		{
			id: "projects",
			name: PROJECTEXPERIENCES,
			showBadge: false,
			total: 0,
			disabled: isProcurer(company),
		},
		{
			id: "resources",
			name: KEYPERSONNELEXPERTISE,
			showBadge: false,
			total: 0,
			disabled: isProcurer(company),
		},
		{
			id: "documents",
			name: CORPORATEDOCUMENTS,
			showBadge: false,
			total: 0,
			disabled: isProcurer(company),
		},
		{
			id: "shared-companies",
			name: SHAREDPROFILES,
			showBadge: false,
			total: 0,
			disabled: false,
		},
	];
	const currentTab = tabMenu.find((menu) => menu.id === activeTab)?.name;

	const changeActiveTab = (name, id) => {
		navigate(`./${id}`);
	};

	const { documents, resources, requestingResources, pastProjects } =
		useSelector((state) => state?.companyProfile);

	const isLoading = useSelector((state) => state?.search?.isLoading);

	useEffect(() => {
		if (companyId) {
			dispatch(getCompanyView(companyId));
			dispatch(getDocuments(companyId));
			dispatch(getPastProjects(companyId, true));
			dispatch(getResources(companyId));
		}
		// eslint-disable-next-line
	}, [companyId]);

	return (
		<div>
			<TabHeader
				heading="COMPANY PROFILE"
				description=""
			/>
			<div className="mb-5 mt-5">
				<div className={"flex gap-3"}>
					<TabsComp
						menus={tabMenu}
						activeTab={currentTab}
						changeActiveTab={changeActiveTab}
						isLoading={false}
					></TabsComp>
				</div>
			</div>
			<div className="border-b border-gray-200"></div>
			{!isLoading ? (
				<div className="mt-12">
					{currentTab === COMPANYINFORMATION && (
						<CompanyInformation formikRef={formikRef} />
					)}
					{currentTab === OFFICELOCATIONS && (
						<>
							{allowedOfficeLocations && (
								<CompanyLocationForm
									company={company}
									btnOfficeLocation={btnOfficeLocation}
								/>
							)}
						</>
					)}
					{currentTab === PROJECTEXPERIENCES && (
						<ProjectListing
							path="projects"
							projects={pastProjects}
							companyId={companyId}
						/>
					)}
					{currentTab === KEYPERSONNELEXPERTISE && (
						<Resources
							path="resources/*"
							resources={resources}
							isRequesting={requestingResources}
							companyId={companyId}
						/>
					)}
					{currentTab === CORPORATEDOCUMENTS && (
						<>
							{allowedDocument && (
								<Documents
									path="documents"
									documents={documents}
									editable
								/>
							)}
						</>
					)}
					{currentTab === SHAREDPROFILES && (
						<CompaniesListing company={company}></CompaniesListing>
					)}
				</div>
			) : (
				<div className="px-4">
					{[...Array(2)].map((e, i) => (
						<div className="my-4 flex w-full items-center justify-between gap-8">
							<div className="w-2/4">
								<div className="my-2">
									<SkeletonBase height="18px" />
								</div>
								<SkeletonBase
									key={i}
									width="100%"
									height="56px"
								/>
							</div>
							<div className="w-2/4">
								<div className="my-2">
									<SkeletonBase height="18px" />
								</div>
								<SkeletonBase
									key={i}
									width="100%"
									height="56px"
								/>
							</div>
						</div>
					))}
					<div className="w-full">
						<div className="my-2">
							<SkeletonBase height="18px" />
						</div>
						<SkeletonBase
							width="100%"
							height="200px"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
export default CompanyProfileV2;
