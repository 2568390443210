import React from "react";
import { styled, css } from "twin.macro";

const TextBoxFormated = styled.div(() => [
	css`
		ul {
		}
		ol {
		}
		width: 100%;
	`,
]);

export const TextBoxHTML = ({ className, html }) => {
	return (
		html && (
			<TextBoxFormated
				dangerouslySetInnerHTML={{ __html: html }}
				className={className}
			/>
		)
	);
};

export default TextBoxHTML;
