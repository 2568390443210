import React from "react";

import { PageWrap, ContentContainer } from "../components/Layout";
import { Heading } from "../components/Heading";

export const Placeholder = (props) => {
	return (
		<PageWrap>
			<ContentContainer>
				<Heading size="xl">{props.title}</Heading>
			</ContentContainer>
		</PageWrap>
	);
};
