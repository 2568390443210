import styled from "styled-components";

export const AdditionalProfilesWrapper = styled.div`
	display: flex;
	.side-content {
		padding: 73px 43px;
		font-family: Abolition;
	}
	button {
		margin-top: 24px;
	}
`;
