import styled from "styled-components";

export const ProjectImage = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	&::before {
		content: "";
		background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 80%,
				rgba(0, 0, 0, 0.7) 100%
			),
			linear-gradient(180deg, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.7) 100%);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		z-index: 1;
		pointer-events: none;
		border-radius: 4px;
	}

	img {
		width: 100%;
		height: 180px;
		object-fit: cover;
		position: relative;
		border-radius: 4px;
		box-shadow: 3px 5px 9px 0px #acacac;
	}

	p {
		color: white;
		font-weight: 500;
		position: absolute;
		bottom: 0.85rem;
		left: 0.85rem;
		margin: 0;
		font-size: ${(props) => props.theme.fontSizes.lg};
		z-index: 2;
		padding-right: 60px;
	}
`;

export const ProjectCount = styled.div`
	background: #b0ceff;
	border-radius: 50%;
	width: 46px;
	height: 46px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	position: absolute;
	bottom: 0.85rem;
	right: 0.85rem;
	font-weight: 500;
`;
