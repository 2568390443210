const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export const reorderList = (lists, result, setter) => {
	if (!result.destination) {
		return;
	}
	const reorderedResults = reorder(
		lists,
		result.source.index,
		result.destination.index
	);
	setter(reorderedResults);
	return reorderedResults;
};
