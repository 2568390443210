import React from "react";
import { createGlobalStyle } from "styled-components";

import { FullDrawStyled } from "./styled";
import { ControlButton } from "../CompanyResultCard/ControlButton";
import { ProjectListing } from "../../routes/account/companyProfile/ProjectListing";
import { Resources } from "../../routes/account/companyProfile/Resources";

export const FullDraw = ({
	companyProjectData,
	showCompanyProjects,
	isOpen,
	companyPersonnel,
	sectors,
	showCompanyPersonnel,
	groupId,
	toggleOpen,
	handleAddProjectClick,
	handleRemoveProjectClick,
	pastProjects,
}) => {
	const title = showCompanyProjects
		? "Select additional projects to add to Company Name card"
		: companyPersonnel
			? "View all personnel"
			: "";

	// use this to lock body scroll when draw is open
	const GlobalStyle = createGlobalStyle`
        body {
            overflow: ${(props) => (props.open ? "hidden" : "auto")}
        }
    `;

	const disabledTest = (projectId) => {
		return companyProjectData.compareProjects.some((customProject) => {
			if (
				customProject &&
				customProject !== null &&
				customProject.hasOwnProperty("id")
			) {
				return customProject.id === projectId;
			} else {
				return false;
			}
		});
	};

	return (
		<FullDrawStyled open={isOpen}>
			<GlobalStyle open={isOpen} />

			<div className="fullDraw">
				<h4 className="fullDraw__title">{title}</h4>
				<div className="fullDraw__exit-wrapper">
					<ControlButton
						type="inverseRemove"
						onClick={() => toggleOpen(!isOpen)}
						xl
					/>
				</div>

				<div className="fullDraw__company-list">
					{companyProjectData && showCompanyProjects && isOpen && (
						<ProjectListing
							projects={pastProjects}
							companyId={companyProjectData.companyId}
							handleAddProjectClick={handleAddProjectClick}
							handleRemoveProjectClick={handleRemoveProjectClick}
							edit={false}
							hideSearch={true}
							disableAddButton={false}
							disabledTest={disabledTest}
							disableUseEffect
						/>
					)}

					{companyPersonnel && showCompanyPersonnel && isOpen && (
						<>
							<Resources
								path="resources/*"
								resources={companyPersonnel.data}
								companyId={companyPersonnel.companyId}
								adminControls={false}
							/>
						</>
					)}
				</div>
			</div>
		</FullDrawStyled>
	);
};

FullDraw.defaultProps = {
	companyProjectData: {},
};
