import React from "react";
import { AddressCardWrap } from "./styled";
import { Grid, GridItem } from "@chakra-ui/react";
import { cn } from "utils/helpers";

export const AddressCard = ({ location, primary }) => {
	const { address, city, postcode, state, local_govt_association } = location
		? location
		: {};

	const addressHeaderClasses = "text-sm font-bold text-grayLight px-3";
	const addressDataClasses = "text-sm border-r border-gray-200 px-3 py-3";
	return (
		<AddressCardWrap>
			{
				<Grid
					templateColumns="repeat(12, 1fr)"
					className="mt-4"
				>
					<GridItem
						className={cn(addressHeaderClasses, "pl-0")}
						colSpan={3}
					>
						<span>Address</span>
					</GridItem>
					<GridItem
						className={addressHeaderClasses}
						colSpan={2}
					>
						<span>Postcode</span>
					</GridItem>
					<GridItem
						className={addressHeaderClasses}
						colSpan={2}
					>
						<span>City/Suburb</span>
					</GridItem>
					<GridItem
						className={addressHeaderClasses}
						colSpan={2}
					>
						<span>State</span>
					</GridItem>
					<GridItem
						className={addressHeaderClasses}
						colSpan={3}
					>
						<span>Local Government</span>
					</GridItem>
				</Grid>
			}
			<Grid
				templateColumns="repeat(12, 1fr)"
				className="mt-2 py-3.5 shadow-sm"
			>
				<GridItem
					className={cn(addressDataClasses, "pl-0")}
					colSpan={3}
				>
					<span>{address ? address : <>&mdash;</>}</span>
				</GridItem>
				<GridItem
					className={addressDataClasses}
					colSpan={2}
				>
					<span>{postcode ? postcode : <>&mdash;</>}</span>
				</GridItem>
				<GridItem
					className={addressDataClasses}
					colSpan={2}
				>
					<span>{city ? city : <>&mdash;</>}</span>
				</GridItem>
				<GridItem
					className={addressDataClasses}
					colSpan={2}
				>
					<span>{state ? state : <>&mdash;</>}</span>
				</GridItem>
				<GridItem
					className={cn(addressDataClasses, "border-0")}
					colSpan={3}
				>
					<span>
						{local_govt_association ? (
							local_govt_association.name
						) : (
							<>&mdash;</>
						)}
					</span>
				</GridItem>
			</Grid>
		</AddressCardWrap>
	);
};
