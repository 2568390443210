import {
	BottomTag,
	LeftSection,
	LoginSection,
	LoginWrapper,
	Logo,
	RightSection,
	Tagline,
} from "components/Login/styled";
import React, { useEffect } from "react";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import rightImg from "./../../icons/login-right-img.jpg";
import { Heading } from "components/Heading";
import { OTPInput } from "input-otp";
import { Button } from "components/Button";
import styled from "styled-components";
import { authTwoFactorLogin } from "actions/authActions";
import { connect } from "react-redux";
import { ReactComponent as BackIcon } from "../../icons/backicon.svg";

const TwoFactorContainer = styled.div`
	padding-bottom: 24px;
	margin-bottom: 24px;
	.slot-container {
		display: flex;
		gap: 12px;
	}
	.slot-code {
		transition: all 0.1s;
		width: 76px;
		height: 110px;
		border: 1px solid #d0d5dd;
		box-shadow: 0px 1px 2px 0px #1018280d;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.code-character {
		font-size: 36px;
		font-weight: 500;
		color: #344054;
	}
	.slot-active {
		border: 2px solid #0045f5;
	}
	.backup-code-link {
		font-weight: 400;
		color: #336af7;
		cursor: pointer;
		text-align: center;
		margin: 24px 0 0 0;
	}
`;

const Slot = (props) => {
	const haveChar = props.char !== null;
	return (
		<div className={`slot-code ${props?.isActive && "slot-active"}`}>
			<p
				style={{ opacity: haveChar ? 1 : 0.3 }}
				className="code-character"
			>
				{haveChar ? props.char : 0}
			</p>
		</div>
	);
};

const TwoFactor = ({ authTwoFactorLogin }) => {
	const [code, setCode] = React.useState("");
	const [isBackupCode, setIsBackupCode] = React.useState(false);

	const verifyHandler = () => {
		authTwoFactorLogin(code, isBackupCode);
	};

	useEffect(() => {
		setCode("");
	}, [isBackupCode]);

	return (
		<LoginWrapper>
			<LeftSection>
				<div style={{ position: "relative" }}>
					<a href="/">
						<Logo
							src={logo}
							alt="Procuracon Logo"
						/>
					</a>
					<Tagline
						src={tagline}
						alt="Build Possible"
					/>
					{isBackupCode && (
						<div
							style={{
								position: "absolute",
								left: 0,
								bottom: "-70px",
								display: "flex",
								gap: "12px",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => setIsBackupCode(false)}
						>
							<BackIcon style={{ width: "48px", height: "48px" }} />
							<p>Back</p>
						</div>
					)}
				</div>
				<LoginSection>
					<Heading marginBottom="40px">
						<span>{isBackupCode ? "Use your back up code" : "TWO-FACTOR"}</span>
						<span>
							{isBackupCode ? "to access your account" : "Authentication (2FA)"}
						</span>
					</Heading>

					<h3
						style={{
							fontWeight: 400,
							margin: `${isBackupCode ? "77px" : "93px"} 0 26px 0`,
						}}
					>
						{isBackupCode
							? "Enter the 6-digit back up code"
							: "Enter the 6-digit code generated by your authenticator app."}
					</h3>

					<TwoFactorContainer>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								verifyHandler();
							}}
						>
							<OTPInput
								value={code}
								textAlign="center"
								maxLength={isBackupCode ? 8 : 6}
								onComplete={(code) => setCode(code)}
								onChange={(code) => setCode(code)}
								render={({ slots }) => (
									<div className="slot-container">
										{slots.map((slot, index) => (
											<Slot
												key={index}
												{...slot}
											/>
										))}
									</div>
								)}
							/>
						</form>
						{!isBackupCode && (
							<h3
								className="backup-code-link"
								onClick={() => setIsBackupCode(true)}
							>
								Having trouble? Try using a backup code
							</h3>
						)}
					</TwoFactorContainer>

					<Button
						type="submit"
						text="Verify"
						onClick={verifyHandler}
						style={{ width: "100%" }}
						disabled={code.length < (isBackupCode ? 8 : 6)}
					>
						Verify
					</Button>
					<BottomTag style={{ textAlign: "left" }}>
						{isBackupCode && (
							<p>
								{`Your backup codes were generated when you first set up two-factor authentication (2FA).
               Make sure to store these codes securely,
               as they provide access to your account in case you can’t use your primary 2FA method.`}
							</p>
						)}
						<br />
						{isBackupCode
							? "Still can’t access your account? "
							: "Can’t access your authenticator app? "}
						<a href="https://www.procuracon.com.au/about-us.php?contact=us&#contact">
							Contact us
						</a>
					</BottomTag>
				</LoginSection>
			</LeftSection>
			<RightSection style={{ backgroundImage: `url(${rightImg})` }} />
		</LoginWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		access_token: state.auth.access_token,
		user: state.auth.user,
		guestUser: state.auth.guestUser,
		company: state.auth.current_company,
	};
};

export default connect(mapStateToProps, { authTwoFactorLogin })(TwoFactor);
