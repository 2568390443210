export const removeProgressFromUploading = (
	currentUploading,
	isRFxUserProcurer
) => {
	if (isRFxUserProcurer) {
		return {
			...currentUploading,
			rfxProcurer: currentUploading?.rfxProcurer?.slice(1),
		};
	} else {
		return {
			...currentUploading,
			rfxBidder: currentUploading?.rfxProcurer?.slice(1),
		};
	}
};

export const addProgressToUploading = (currentUploading, isRFxUserProcurer) => {
	if (isRFxUserProcurer) {
		return {
			...currentUploading,
			rfxProcurer: [...currentUploading?.rfxProcurer, true],
		};
	} else {
		return {
			...currentUploading,
			rfxBidder: [...currentUploading?.rfxBidder, true],
		};
	}
};

export const AcceptedFileTypesForRFx = [
	"pdf",
	"docx",
	"doc",
	"xlsx",
	"xls",
	"zip",
];

export const AcceptedFileTypesForRFxWithImage = [
	...AcceptedFileTypesForRFx,
	"jpeg",
	"jpg",
	"png",
];
