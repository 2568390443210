import React, { useState } from "react";
import { TabNavStyled, Tab } from "./styled";
import { SkeletonBase } from "components/Skeleton";
import { SkeletonCircle } from "@chakra-ui/react";
export const TabNav = ({ data, tabs, loading, supplierlist, ...rest }) => {
	const [activeState, setActiveState] = useState();
	const isDisabled = (tab, count) => {
		if (tab === "compare" && count < 2) return true;
		if (tab === "shortlist" && count < 1) return true;
		if (tab === "results" && supplierlist?.directcompare) return true;
	};
	return (
		<TabNavStyled {...rest}>
			{tabs.map((tab, i) => {
				return (
					<>
						{loading ? (
							<div
								key={i}
								className="flex"
							>
								<div className="mb-5 mr-2">
									<SkeletonBase
										width="200px"
										height="36px"
									/>
								</div>
								<div className="ml-1 mr-4">
									<SkeletonCircle
										width={30}
										height={30}
										startColor="lightgray"
									/>
								</div>
							</div>
						) : (
							<Tab
								to={
									supplierlist?.id
										? `${tab.link}?supplier=${supplierlist?.id}&supplierlist=${supplierlist?.name}&directcompare=${supplierlist?.directcompare}&directshortlist=${supplierlist?.directshortlist}`
										: tab.link
								}
								isActive={activeState === i ? true : false}
								key={i}
								currentTab={i}
								setActiveState={setActiveState}
								title={tab.title === "Search Results" ? "results" : tab.title}
								disabled={isDisabled(tab.link, tab.count)}
							>
								<p>{tab.title} </p>
								<p className="counter">{tab.count}</p>
							</Tab>
						)}
					</>
				);
			})}
		</TabNavStyled>
	);
};
