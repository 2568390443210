import React from "react";
import { Button } from "components/Button";
import { HStack, Text, Box } from "@chakra-ui/react";
import { cn } from "utils/helpers";

export const Pagination = ({
	currentPage,
	totalItems,
	itemsPerPage,
	onPageChange,
	showTotal = true,
}) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	// Don't show pagination if there's only one page or no items
	if (totalItems <= itemsPerPage || totalPages <= 1) {
		return null;
	}

	const handlePageChange = (newPage) => {
		// Only allow valid page numbers
		if (newPage >= 1 && newPage <= totalPages) {
			onPageChange(newPage);
		}
	};

	return (
		<Box mt={8}>
			<HStack
				justify="center"
				spacing={2}
			>
				<Button
					size="sm"
					onClick={() => onPageChange(currentPage - 1)}
					className={currentPage <= 1 && "!hidden"}
				>
					Previous
				</Button>

				{[...Array(totalPages)].map((_, index) => (
					<Button
						key={index + 1}
						size="sm"
						onClick={() => handlePageChange(index + 1)}
						className={cn(currentPage === index + 1 && "!text-active-300")}
					>
						{index + 1}
					</Button>
				))}

				<Button
					size="sm"
					onClick={() => handlePageChange(currentPage + 1)}
					className={currentPage >= totalPages && "!hidden"}
				>
					Next
				</Button>
			</HStack>
			{showTotal && (
				<Text
					textAlign="center"
					mt={2}
					fontSize="sm"
					color="gray.600"
				>
					Page {currentPage} of {totalPages}
				</Text>
			)}
		</Box>
	);
};
