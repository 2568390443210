import React from "react";
import { InvoiceView } from "./InvoiceView";

export const PrintableInvoice = React.forwardRef((props, componentRef) => {
	return (
		<InvoiceView
			ref={componentRef}
			componentRef={componentRef}
			target_invoice={props.target_invoice}
			company={props.company}
			prevItem={props.prevItem}
			prevLabel={props.prevLabel}
			invoiceLabel={props.invoiceLabel}
		/>
	);
});
