import React from "react";
import PropTypes from "prop-types";
import { cn } from "utils/helpers";

const FormHeader = ({ header, subHeader, className }) => {
	return (
		<div className={cn("font-roboto text-primary-900", className)}>
			<h2 className="text-base font-semibold">{header}</h2>
			{subHeader && <p className="text-sm text-tertiary-600">{subHeader}</p>}
		</div>
	);
};

FormHeader.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
};

export default FormHeader;
