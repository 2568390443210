import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { CardsWrapper, CheckboxStyled } from "./styled";
export const PackageCard = ({
	children,
	name,
	value,
	label,
	textInfo,
	width,
	amount = 0,
	isFormik,
	handleOnClick,
	current,
	...props
}) => {
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();
	const handleOnChange = () => {
		setFieldValue(name, value);
	};

	useEffect(() => {
		if (parseInt(current) === value) {
			setFieldValue(name, value);
		}
	}, [setFieldValue, current, name, value]);

	return (
		<CardsWrapper
			marginBottom={props.marginBottom}
			width={width}
			checked={field.value === value}
			onClick={handleOnClick}
			small={props.small}
		>
			<CheckboxStyled>
				<div className="card">
					<div className="header">
						<p>{label}</p>
						{parseInt(current) === value && (
							<p className="current">Current Plan</p>
						)}
					</div>
					<div className="flex-row">
						<p>
							${amount.toFixed(2)}
							<span>ex GST Per Year</span>
						</p>
					</div>
					<div>
						<p>{textInfo}</p>
					</div>
				</div>
				<input
					type="radio"
					checked={field.value === value}
					{...field}
					{...props}
					onChange={handleOnChange}
				/>
				<span className="checkmark"></span>
			</CheckboxStyled>
		</CardsWrapper>
	);
};
PackageCard.defaultProps = {
	isFormik: true,
};
