import { Button } from "../Button";
import { ReactComponent as BackIcon } from "../../../assets/icons/flip-backward.svg";

export const BackButton = ({ handleBackButtonClick, ...props }) => {
	return (
		<Button
			btntype={"icon"}
			onClick={handleBackButtonClick}
			{...props}
		>
			<BackIcon style={{ width: "28px", height: "28px", marginRight: "0px" }} />
		</Button>
	);
};
