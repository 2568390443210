import React from "react";
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "components/RFQ/Button";

const ModalDeleteSection = ({ isOpen, onClose, OptionSections }) => {
	return (
		<div>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				height="100%"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<div>
							<h2 className="text-lg font-semibold text-primary-900">
								Delete section
							</h2>
						</div>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<p>Are you sure you want to delete the section?</p>
						<p>This action could delete all questions within this section.</p>
					</ModalBody>

					<ModalFooter>
						<Flex
							gap={3}
							className="!w-full"
						>
							<Button
								btntype={"base"}
								style={{ width: "100%" }}
								onClick={onClose}
							>
								Cancel
							</Button>
							<Button
								style={{ width: "100%" }}
								btntype={"secondary-danger"}
							>
								Delete
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default ModalDeleteSection;
