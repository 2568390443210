const { GUESTRFQ_STORAGE } = require("constants/guest");

export const saveGuestRFQ = (data) => {
	return localStorage.setItem(GUESTRFQ_STORAGE, JSON.stringify(data));
};
export const getGuestRFQ = () => {
	if (localStorage.getItem(GUESTRFQ_STORAGE)) {
		return JSON.parse(localStorage.getItem(GUESTRFQ_STORAGE));
	} else {
		return null;
	}
};
