import React from "react";
import { useField } from "formik";
import tw, { styled } from "twin.macro";
import { RadioGroup, Stack } from "@chakra-ui/react";
import { cn } from "utils/helpers";

const RadioInputContainer = styled.div(() => [tw`flex flex-col gap-2`]);

const RadioInput = ({
	className,
	label,
	required,
	name,
	options,
	handleRadioChange,
	border,
	disabled = false,
	...props
}) => {
	const [, { value }, { setValue }] = useField(name);

	return (
		<RadioInputContainer className={className}>
			{label && <label className="font-medium">{label}</label>}
			<RadioGroup name={name}>
				<Stack spacing={border ? 0 : 5}>
					{options?.map((item, index) => (
						<label
							className={cn(
								"flex cursor-pointer items-center gap-3 text-sm font-medium text-tertiary-600",
								border && "border-b border-b-quarternary-400 px-6 py-4"
							)}
						>
							<input
								name={name}
								type="radio"
								className="scale-150 accent-royal-blue opacity-50 checked:opacity-100"
								key={index}
								value={item.value}
								isRequired={required}
								disabled={disabled}
								onChange={(e) => setValue(e.target.value)}
								checked={value === item.value}
							/>
							{item.label}
						</label>
					))}
				</Stack>
			</RadioGroup>
		</RadioInputContainer>
	);
};

export default RadioInput;
