import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { Icon } from "assets/icons/Icon";
import { cn } from "utils/helpers";

export const CustomToastV2 = ({ success, closeToast, title, subtitle }) => (
	<div className="z-50 flex h-max justify-between gap-3 rounded-md bg-white p-4 pl-2">
		<div className="flex gap-3">
			<Icon
				icon={success === true ? "toast-success" : "toast-error"}
				className="-mt-2"
			/>
			<div>
				<div
					className={cn(
						"font-roboto text-sm font-semibold",
						success ? "text-royal-blue" : "text-red-600"
					)}
				>
					{title || (success ? "Success" : "Error")}
				</div>
				{subtitle && (
					<div className="font-roboto text-sm font-normal text-tertiary-600">
						{subtitle}
					</div>
				)}
			</div>
		</div>

		<div>
			<Icon
				icon="toast-close"
				className="cursor-pointer pt-2"
				onClick={closeToast}
			/>
		</div>
	</div>
);
