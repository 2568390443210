import { Flex } from "@chakra-ui/core";
import { Icon } from "assets/icons/Icon";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Avatar from "../Avatar/Avatar";

const avatarSizes = {
	sm: tw`h-8 w-8 p-1`,
	md: tw`h-10 w-10 p-1`,
	lg: tw`h-12 w-12 p-1.5`,
};

const AvatarGroupContainer = styled.div((className) => [
	tw`flex items-center space-x-[-0.5rem]`,
	tw`${className}`,
]);

const MoreAvatars = styled.div(({ className, size }) => [
	tw`h-10 w-10 rounded-full border-[1px] border-white bg-gray-utility-200 flex items-center justify-center text-sm font-semibold`,
	avatarSizes[size],
	tw`${className}`,
]);

const GlobalAvatarGroup = ({
	avatars,
	maxAvatars = 3,
	inviteAble,
	handleClickInvite,
	classNameContainer,
	classNameAvatar,
	classNameMoreAva,
	avatarSize,
	defaultImage = "company",
	...props
}) => {
	const visibleAvatars = avatars.slice(0, maxAvatars);
	const overflowAvatars = avatars.slice(maxAvatars);

	return (
		<Flex style={{ gap: "8px" }}>
			<AvatarGroupContainer
				{...props}
				className={classNameContainer}
			>
				{visibleAvatars.map((avatar, index) => (
					<div
						style={{
							border: "solid 3px #fff",
							borderRadius: "100%",
							marginRight: "-5px",
						}}
					>
						<div
							style={{
								border: "solid 1px #E5E7EA",
								borderRadius: "100%",
								background: "#fff",
							}}
						>
							<Avatar
								className={classNameAvatar}
								size={avatarSize}
								key={index}
								avatar={avatar.avatar}
								alt="Avatar"
								defaultImage={defaultImage}
							/>
						</div>
					</div>
				))}
				{overflowAvatars.length > 0 && (
					<div style={{ border: "solid 3px #fff", borderRadius: "100%" }}>
						<div
							style={{
								border: "solid 1px #E5E7EA",
								borderRadius: "100%",
								background: "#fff",
							}}
						>
							<MoreAvatars
								className={classNameMoreAva}
								size={avatarSize}
							>
								<div
									className="font-roboto font-semibold text-secondary-700"
									style={{ fontSize: "12px" }}
								>
									+{overflowAvatars.length}
								</div>
							</MoreAvatars>
						</div>
					</div>
				)}
			</AvatarGroupContainer>
			{inviteAble && (
				<Icon
					icon="plus"
					onClick={handleClickInvite}
					width={24}
					height={24}
					sx={{
						borderRadius: "999px",
						border: "dashed 1px #CECFD2",
						padding: "4px",
						position: "relative",
						minWidth: "40px",
						minHeight: "40px",
					}}
					color={"#CECFD2"}
				/>
			)}
		</Flex>
	);
};

export default GlobalAvatarGroup;
