import { Box, Flex } from "@chakra-ui/react";
import { ProgressCircular } from "components/RFQ/ProgressIndicator";
// import { AvatarGroup } from "components/AvatarGroup";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { SkeletonBase } from "components/Skeleton";
import { TabHeader } from "components/TabHeader";
import React from "react";
import moment from "moment-timezone";
import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";

const HeaderCard = ({ rfqDetail, isLoading }) => {
	const companySupplierLists = rfqDetail?.supplierInvited?.all || [];
	const { accepted, all, submitted } = rfqDetail?.supplierInvited || {
		accepted: [],
		all: [],
		declined: [],
		submitted: [],
	};
	const allTotal = all?.length || 0;
	const submittedTotal = submitted?.length || 0;
	const acceptedTotal = accepted?.length + submittedTotal || 0;
	const projectName = rfqDetail?.project?.name || null;

	return (
		<div>
			<Box className="min-h-80 w-full content-between rounded-md border py-10 shadow-xl">
				<Flex
					id="header-wrapper"
					className="justify-between"
					gap={10}
				>
					<div
						id="header-content"
						className="w-full"
					>
						<div className="px-6">
							{projectName && (
								<p className="-mb-6 text-base text-secondary-500">
									{projectName}
								</p>
							)}
							<TabHeader
								noDivider={true}
								heading={`${rfqDetail?.name || " - "}`}
								description={rfqDetail?.description || " - "}
								isLoading={isLoading}
								color="#003CE9"
							/>
						</div>
						<div
							id="project-info"
							className="mt-12 px-6 pt-6"
						>
							{!isLoading ? (
								<Flex className="flex-col gap-4 text-base font-semibold">
									<Flex gap={4}>
										<p className="min-w-48 font-roboto text-base font-normal text-secondary-500">
											RFx Status
										</p>
										<StatusBadge status={rfqDetail?.status} />
									</Flex>
									<Flex gap={4}>
										<p
											className="min-w-48 font-roboto font-normal text-secondary-500"
											style={{ lineHeight: "24px", fontSize: "16px" }}
										>
											Client
										</p>
										<p
											className="font-roboto font-semibold"
											style={{ fontSize: "16px", lineHeight: "24px" }}
										>
											{rfqDetail?.client || " - "}
										</p>
									</Flex>
									<Flex gap={4}>
										<p
											className="min-w-48 font-roboto font-normal text-secondary-500"
											style={{ lineHeight: "24px", fontSize: "16px" }}
										>
											Project Location
										</p>
										<p
											className="font-roboto font-semibold"
											style={{ fontSize: "16px", lineHeight: "24px" }}
										>
											{rfqDetail?.location || " - "}
										</p>
									</Flex>
									<Flex gap={4}>
										<p
											className="min-w-48 font-roboto font-normal text-secondary-500"
											style={{ lineHeight: "24px", fontSize: "16px" }}
										>
											RFx Published Date
										</p>
										<p
											className="font-roboto font-semibold"
											style={{ fontSize: "16px", lineHeight: "24px" }}
										>
											<span className="font-semibold">
												{rfqDetail?.rfxPublished
													? moment(rfqDetail?.rfxPublished).format(
															"dddd DD MMMM YYYY"
														)
													: " - "}
											</span>
										</p>
									</Flex>
									<Flex gap={4}>
										<p
											className="min-w-48 font-roboto font-normal text-secondary-500"
											style={{ lineHeight: "24px", fontSize: "16px" }}
										>
											Submission Deadline
										</p>
										<p
											className="font-roboto font-semibold"
											style={{ fontSize: "16px", lineHeight: "24px" }}
										>
											<span>
												{rfqDetail?.submissionDeadline
													? moment(rfqDetail?.submissionDeadline).format(
															"dddd DD MMMM YYYY"
														)
													: " - "}
											</span>{" "}
											<span>
												{moment(rfqDetail?.submissionDeadline).format(
													"hh:mm A"
												)}{" "}
												{rfqDetail?.submissionTimezone}
											</span>
										</p>
									</Flex>
									<Flex gap={4}>
										<div className="flex items-center justify-start">
											<p
												className="min-w-48 font-roboto font-normal text-secondary-500"
												style={{ lineHeight: "24px", fontSize: "16px" }}
											>
												Invited
											</p>
											<div className="ml-3">
												<GlobalAvatarGroup
													avatarSize="sm"
													avatars={companySupplierLists.map((supplier) => ({
														avatar: supplier?.company?.logo_path,
													}))}
												/>
											</div>
										</div>
									</Flex>
								</Flex>
							) : (
								<Flex className="flex-col gap-4 text-base font-semibold">
									<Flex gap={4}>
										<SkeletonBase />
										<SkeletonBase width="280px" />
									</Flex>
									<Flex gap={4}>
										<SkeletonBase />
										<SkeletonBase width="280px" />
									</Flex>
									<Flex gap={4}>
										<SkeletonBase />
										<SkeletonBase width="280px" />
									</Flex>
									<Flex gap={4}>
										<SkeletonBase />
										<SkeletonBase width="280px" />
									</Flex>
									<Flex gap={4}>
										<SkeletonBase />
										<SkeletonBase width="280px" />
									</Flex>
								</Flex>
							)}
						</div>
					</div>
					{!isLoading && (
						<Flex
							gap={4}
							id="header-chart"
							className="w-[40%] px-6"
							justifyContent={"space-evenly"}
						>
							<ProgressCircular
								detail={
									<div className="text-base">
										<p className="text-xs font-medium">Accepted</p>
										<h1 className="text-2xl font-semibold text-primary-900">
											{allTotal > 0 ? `${acceptedTotal} / ${allTotal}` : "-"}
										</h1>
									</div>
								}
								progress={(acceptedTotal / allTotal) * 100 || 0}
								size="sm"
							/>
							<ProgressCircular
								detail={
									<div className="text-base">
										<p className="text-xs font-medium">Submitted</p>
										<h1 className="text-2xl font-semibold text-primary-900">
											{acceptedTotal > 0
												? `${submittedTotal} / ${acceptedTotal}`
												: "-"}
										</h1>
									</div>
								}
								progress={(submittedTotal / acceptedTotal) * 100 || 0}
								size="sm"
							/>
						</Flex>
					)}
				</Flex>
			</Box>
		</div>
	);
};

export default HeaderCard;
