import styled from "styled-components";

export const ImageWrap = styled.div`
	position: relative;

	${(props) =>
		props.hasImage &&
		`
        &::before {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.7) 100%),
                linear-gradient(180deg, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.7) 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            z-index: 5;
            pointer-events: none;
        }
    `}
`;

export const ImageSliderStyled = styled.div`
	display: flex;
	flex-wrap: nowrap;
	position: relative;

	.swiper-container {
		border-top-left-radius: ${(props) => props.theme.radii.default};
		border-top-right-radius: ${(props) => props.theme.radii.default};
	}

	.swiper-pagination {
		position: absolute;
		bottom: 0.5rem;
		right: 1.5rem;
		z-index: 5;
		text-align: right;
		width: 100%;

		&-bullet {
			width: 10px;
			height: 10px;
			background: white;
			display: inline-block;
			border-radius: 50%;
			opacity: 0.35;
			transition: ${(props) => props.theme.transitions.default};
			cursor: pointer;

			& + .swiper-pagination-bullet {
				margin-left: 0.5rem;
			}

			&::hover {
				opacity: 0.5;
			}

			&-active {
				opacity: 1;
			}
		}
	}
`;

export const ImageSliderCaption = styled.p`
	font-size: ${(props) => props.theme.fontSizes.xxl};
`;

export const ImageSliderSubCaption = styled.div``;

export const TextOverlay = styled.div`
	position: absolute;
	bottom: 1.5rem;
	left: 1.5rem;
	color: ${(props) => (props.dark ? props.theme.colors.gray : "white")};
	font-weight: 500;
	z-index: 5;

	p {
		margin: 0;
	}

	a {
		color: ${(props) => (props.dark ? props.theme.colors.gray : "white")};

		&::hover {
			opacity: 0.75;
		}
	}
`;
