import { Button } from "components/Button";
import { ButtonRow, ConfirmHeading } from "components/ConfirmDelete/styled";
import { Heading } from "components/Heading";
import { Modal } from "components/Modal";
import React from "react";

import styled from "styled-components";

export const ConfirmDeactivateModal = styled(Modal)`
	padding: 3.37rem;
	background-color: white;
	min-width: 570px;
	.delete-btn {
		float: right;
		padding: 8px 12px;
		color: white;
		background: #fe0202;
		border: 1px solid #fe0202;
		cursor: pointer;
		display: flex;
		padding: 8px 35px;
		align-items: center;
		justify-content: center;
	}
	.cancel-btn {
		float: right;
		padding: 8px 12px;
		color: #263859;
		background: transparent;
		border: 1px solid #263859;
		cursor: pointer;
		display: flex;
		padding: 8px 35px;
		justify-content: center;
		align-items: center;
	}
`;

const ModalEnabled2FA = ({ isOpen, onCLose }) => {
	return (
		<ConfirmDeactivateModal
			isOpen={isOpen}
			onRequestClose={onCLose}
			width="600"
		>
			<Heading
				marginBottom={"16px"}
				fontSize="2rem"
			>
				You’re all set up!
			</Heading>
			<ConfirmHeading>
				Two-Factor Authentication (2FA) has been successfully enabled. Your
				account now has an added layer of security using a unique code generated
				by your authenticator app each time you log in.
			</ConfirmHeading>
			<ButtonRow style={{ border: "none" }}>
				<div>
					<Button
						size={"lg"}
						width={"full"}
						height={"60px"}
						textfont={"14px"}
						boxshadow={false}
						onClick={onCLose}
						type="button"
					>
						<span>Okay</span>
					</Button>
				</div>
			</ButtonRow>
		</ConfirmDeactivateModal>
	);
};

export default ModalEnabled2FA;
