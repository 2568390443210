import React from "react";
import { useField } from "formik";
import styled from "styled-components";

import { Error } from "../Error";

const CheckboxWrap = styled.div`
	margin-bottom: ${(props) => props.theme.space.xs};
	position: relative;
`;

export const CheckboxError = styled(Error)`
	top: auto;
	bottom: -25px;
	width: 100%;
`;

export const CheckboxStyled = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	user-select: none;
	font-family: "Roboto";
	color: ${(props) => props.theme.colors.grayLight};
	font-size: ${(props) => props.theme.fontSizes.md};

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		&::checked .checkmark {
			background-color: ${(props) => props.theme.colors.royalBlue};
		}
	}

	.checkmark {
		position: absolute;
		top: ${(props) => (props.checkmarkTop ? props.checkmarkTop : "0px")};
		left: 0;
		height: 21px;
		width: 21px;
		background-color: white;
		display: block;
		border: 2px solid rgba(132, 146, 166, 0.5);
		border-radius: 50%;
		&::after {
			content: "";
			position: absolute;
			opacity: 0;
			left: 9px;
			top: -4px;
			width: 7px;
			height: 16px;
			border: 2px solid ${(props) => props.theme.colors.mint};
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			transition: ${(props) => props.theme.transitions.default};
		}
		&::before {
			content: "";
			position: absolute;
			opacity: 0;
			left: 11px;
			top: -6px;
			width: 7px;
			height: 22px;
			border: 2px solid white;
			border-width: 0 8px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			-webkit-transition: all 0.25s ease;
			-webkit-transition: all 0.25s ease;
			transition: all 0.25s ease;
		}
	}

	&::hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark::after {
		opacity: 1;
	}
	input:checked ~ .checkmark::before {
		opacity: 1;
	}
	input:checked ~ .checkmark {
		border: 2px solid ${(props) => props.theme.colors.mint};
	}
`;

export const Checkbox2 = ({
	children,
	name,
	isFormik,
	labelComponent,
	noWarning,
	...props
}) => {
	const [field, meta] = useField(name);
	return (
		<CheckboxWrap
			className={props.className}
			style={props.style}
		>
			<CheckboxStyled className="container-checkbox">
				{!labelComponent ? (
					<span dangerouslySetInnerHTML={{ __html: props.label }} />
				) : (
					labelComponent
				)}
				<input
					type="checkbox"
					checked={!!field.value || !!props.initialValue}
					{...field}
					{...props}
				/>
				<span className="checkmark"></span>
			</CheckboxStyled>

			{!noWarning && (
				<CheckboxError
					meta={meta}
					leftAlign
				/>
			)}
		</CheckboxWrap>
	);
};

Checkbox2.defaultProps = {
	isFormik: true,
};
