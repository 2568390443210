import styled from "styled-components";
export const BadgeStyled = styled.div`
        border-radius: 0px;
        background-color: ${(props) => props.backgroundColor};
        span {
            margin-left:5px;
            margin-right:5px;
            color:${(props) => props.textColor} !important;
            font-size :15px;
            font-weight : normal;
            padding: 0px;
           }
        }
`;
