import { Box, Flex, Spinner } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Heading } from "components/Heading";
import { QuoteCard } from "components/Pages/CreateRFQ/QuoteCard";
import QuoteTotal from "components/Pages/CreateRFQ/QuoteCard/QuoteTotal";
import { defaultOptions } from "components/Pages/CreateRFQ/QuoteCard/tableDefaultOptions";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { RFQContext } from "context/RFQContext";
import { useField } from "formik";
import React, { useContext, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import tw, { styled } from "twin.macro";
import { reorderList } from "utils/reorderList";
import { removeProgressFromUploading } from "utils/rfxLargeFileUploadHelper";

const QuoteContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ButtonAddContent = ({ onClickAddTable }) => {
	return (
		<Box className="card-shadow z-40 rounded-md bg-white p-2">
			<Flex
				onClick={onClickAddTable}
				className="[transition-duration: 0.18s] relative cursor-pointer items-center gap-2 p-3 transition-all hover:rounded-md hover:bg-utility-blue-light-50"
			>
				<Icon
					icon="plus-circle"
					style={{ color: "#475467" }}
				/>
				<p
					className="font-roboto font-medium text-secondary-700"
					style={{ fontSize: "14px", marginTop: "1px" }}
				>
					Add Table
				</p>
			</Flex>
		</Box>
	);
};
const QuestionerContainer = styled.div(({ isDisabled }) => [
	!isDisabled ? tw`opacity-100` : tw`opacity-50 pointer-events-none`,
]);

const Quote = ({ subTitlePage, titlePage, unitMeasures, setFieldValue }) => {
	const isLoading = false;

	const [, meta, helper] = useField("quote");
	const [, { value: typeValue }] = useField("quote_type");
	const { rfqId, setUploadInProgresses } = useContext(RFQContext);

	const quotesTable = meta.value;
	const setQuotesTable = helper.setValue;

	const tableTypeOptions = [
		{ label: "Create my own quote format", value: "form", icon: "text-input" },
		{ label: "Upload Template", value: "file", icon: "template" },
	];

	const tableVariable = [
		"#",
		"Description of Scope",
		"Unit of Measurement",
		"Quantity",
		"Rate",
		"Cost",
	];

	const addTableHandler = () => {
		helper.setValue([
			...quotesTable,
			{
				name: "",
				unit_id: "",
				items: defaultOptions(),
				sort_order: quotesTable.length + 1,
				required: 1,
				isRemove: 0,
			},
		]);
	};

	const dragHandler = (result) => {
		let value = quotesTable;
		let valueSetter = (newValue) => (value = newValue);
		reorderList(value, result, valueSetter);
		const reordered = value.map((item, index) => ({
			...item,
			sort_order: index + 1,
		}));
		setQuotesTable(reordered);
	};

	useEffect(() => {
		if (quotesTable.length === 0) {
			addTableHandler();
		}
		//eslint-disable-next-line
	}, []);

	const handleFileUploadComplete = (uploadedFile) => {
		setFieldValue("quote_file", "Quote.xlsx");
		setFieldValue("quote_file_path", uploadedFile?.quoteFilePath);
		setUploadInProgresses((prev) => removeProgressFromUploading(prev, true));
	};

	return (
		<QuoteContainer>
			<div
				id="subheader-page"
				className="py-8"
			>
				<div
					id="subheader-page-title"
					className="text-xl font-semibold text-primary-900"
				>
					<Heading
						fontSize={"36px"}
						color="#003CE9"
					>
						{" "}
						{titlePage}
					</Heading>
				</div>
				<div
					id="subheader-page-subtitle"
					className="mt-1 whitespace-pre-line font-roboto text-sm leading-[1.5rem] text-tertiary-600"
				>
					{subTitlePage}
				</div>
			</div>
			<div>
				<div className="space-y-5 rounded-md bg-[#EEF5FFB2] p-8">
					<p className="text-base text-tertiary-600">
						Select an option below to get started:
					</p>
					<FieldForm
						name="quote_type"
						type="select"
						isContainImg={true}
						options={tableTypeOptions}
						placeholder="Select"
						className="!max-w-[340px]"
					/>
				</div>
			</div>
			<div
				id="project-detail-content"
				className="relative h-full min-h-[60vh]"
			>
				{!isLoading ? (
					typeValue !== "file" ? (
						<>
							<QuestionerContainer>
								<DragDropContext
									onDragEnd={dragHandler}
									className="my-10"
								>
									<Droppable
										droppableId="questioner-container"
										direction="vertical"
									>
										{(provided) => (
											<div
												id="questioner-container"
												className="mb-8"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{quotesTable?.map((tableData, index) =>
													!tableData.isRemove ? (
														<Draggable
															key={`tableData-${index}`}
															draggableId={`tableData-${index}`}
															index={index}
														>
															{(provided) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																>
																	<QuoteCard
																		dragHandle={provided.dragHandleProps}
																		key={index}
																		initialDataItem={tableData}
																		tableVariable={tableVariable}
																		index={index}
																		quotesTable={quotesTable}
																		setQuotesTable={setQuotesTable}
																		unitMeasures={unitMeasures}
																	/>
																</div>
															)}
														</Draggable>
													) : null
												)}

												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
								<Flex
									gap={4}
									className="my-6"
								>
									<QuoteTotal />
								</Flex>
								<Flex className="my-6">
									<ButtonAddContent onClickAddTable={() => addTableHandler()} />
								</Flex>
							</QuestionerContainer>
						</>
					) : (
						<div className="mt-12">
							<FileUpload
								className="!min-h-[400px]"
								label="Upload template"
								name="quote_file"
								types={["xlsx", "csv"]}
								maxFileSize={100}
								singleFile
								required={typeValue === "file"}
								inputBoxClassName="!min-h-[300px]"
								fileExtraData={{
									model: "rfq_quote_file",
									rfq_id: rfqId,
								}}
								handleFileUploadComplete={handleFileUploadComplete}
								isProcurerRFx
							/>
						</div>
					)
				) : (
					<Spinner
						className="absolute left-1/2 top-[30vh] -translate-x-1/2"
						size="xl"
					/>
				)}
			</div>
		</QuoteContainer>
	);
};

export default Quote;
