import React, { useEffect, useState, useContext } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import styled, { keyframes } from "styled-components";
import { navigate } from "@reach/router";
import { CustomTooltip } from "./CustomTourStep";
import { updateUserTourFlags } from "../../actions/AccountManagementActions";
import { showToast } from "../../utils/helpers"; // Updated import
import { ProfileContext } from "../../context/ProfileContext";
import { tourSteps } from "./Steps";
// Pulse animation for the beacon
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  55% {
    background-color: rgba(255, 100, 100, 0.9);
    transform: scale(1.6);
  }
`;

// Styled beacon component
const Beacon = styled.div`
	position: relative;
	animation: ${pulse} 3s ease-in-out infinite;
	background-color: rgba(255, 27, 14, 0.6);
	border-radius: 50%;
	box-shadow: 0 0 10px 0 rgba(255, 27, 14, 0.6);
	display: flex;
	height: 1rem;
	width: 1rem;
	&::before {
		content: "I";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 0.5rem;
		color: #fff;
	}
`;

const BeaconComponent = React.forwardRef((props, ref) => (
	<Beacon
		ref={ref}
		{...props}
	/>
));

export const TourGuide = ({ run, uiTourSeen, rfqTourSeen }) => {
	let companyIdString = localStorage.getItem("company_id");
	let companyId = JSON.parse(
		companyIdString !== "undefined" ? companyIdString : null
	);

	const {
		isRfqFeatureEnabled,
		updateTourCookieFlags,
		forceRestartTour,
		setForceRestartTour,
		currentProfile,
	} = useContext(ProfileContext);

	const userRole = currentProfile;

	useEffect(() => {
		if (forceRestartTour) {
			setIsTourActive(true);
			setCurrentStepIndex(0);
			setDontShowAgain(false); // Reset checkbox state
			setForceRestartTour(false); // Reset the force restart flag
		}
	}, [forceRestartTour, setForceRestartTour]);

	const [isTourActive, setIsTourActive] = useState(uiTourSeen === 0);
	const [dontShowAgain, setDontShowAgain] = useState(false);
	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	// Default styling options for Joyride
	const defaultOptions = {
		arrowColor: "#fff",
		backgroundColor: "#fff",
		beaconSize: 36,
		overlayColor: "rgba(0, 0, 0, 0.5)",
		primaryColor: "#0045F5",
		spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
		textColor: "#333",
	};

	// Navigation paths for different tour steps
	const navigationPaths = {
		MyProfile: `/account/${companyId}/company-profile/edit-user-profile`,
		CompanyProfile: `/account/${companyId}/company-profile/company-information`,
		AccountSettings: `/account/${companyId}/account-settings`,
		SupplierList: `/account/${companyId}/supplier-list`,
		RFx: `/account/${companyId}/request-for-quote`,
		ProjectsAndAssets: `/account/${companyId}/projects`,
		//SearchSuppliers: `/search`,
	};

	const updateTourFlags = async (uiTour, rfqTour) => {
		try {
			await updateUserTourFlags(uiTour, rfqTour);
			updateTourCookieFlags(uiTour, rfqTour); // Update the cookies
			setIsTourActive(false);

			setIsTourActive(false);
		} catch (error) {
			const errorMessage = error.response
				? await error.response.json()
				: error.message;
			showToast(errorMessage, "Error");
		}
	};

	// Callback handler for Joyride events
	const handleJoyrideCallback = (data) => {
		const { action, index, status, type } = data;

		// Update current step index
		setCurrentStepIndex(index);

		// Handle skip action and close action call this function before handle navigation step top prevent redirect on close
		if (action === ACTIONS.SKIP || action === ACTIONS.CLOSE) {
			if (dontShowAgain) {
				console.log("skipped plus checkbox", type);
				updateTourFlags(true, isRfqFeatureEnabled);
			} else {
				console.log("skipped no checkbox", type);
				setIsTourActive(false); // Just close the tour without updating flags
			}
			return;
		}

		// Handle navigation between steps
		if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
			// Determine the direction of navigation
			const stepChange = action === ACTIONS.PREV ? -1 : 1;
			const nextStep = filteredSteps[index + stepChange];

			if (nextStep) {
				const targetClass = nextStep.target?.replace(".", "");
				const path = navigationPaths[targetClass];

				if (path) {
					navigate(path).then(() => {
						// Small delay to ensure DOM is updated
						setTimeout(() => {
							const targetElement = document.querySelector(nextStep.target);
							if (!targetElement) {
								// Retry finding the target element
								setTimeout(() => {
									const retryElement = document.querySelector(nextStep.target);
									if (!retryElement) {
										console.log(
											"Retry: Target element still not found:",
											nextStep.target
										);
									}
								}, 1000); // Adjust delay as needed
							}
						}, 500);
					});
				}
			}
		}

		// Handle tour completion
		if (status === STATUS.FINISHED) {
			setIsTourActive(false);
			updateTourFlags(true, isRfqFeatureEnabled);
		}
	};

	const shouldShowStep = (step) => {
		// Welcome step (index 0) always shows
		if (step.target === ".GuidedTourIcon") {
			return true;
		}

		if (step.target === ".SearchSuppliers") {
			if (userRole === "Bidder") {
				return false;
			}
		}

		// UI Tour Steps - skip if already seen
		if (step.uiTourStep) {
			return !uiTourSeen;
		}

		// RFQ Steps - handle role-specific RFQ steps
		if (step.rfqTourStep) {
			// First check if RFQ feature is enabled
			if (!isRfqFeatureEnabled) {
				return false;
			}

			// For other roles, show only non-role-specific RFQ steps
			return !step.procurerStep && !step.bidderStep;
		}

		// Non-RFQ, non-UI steps are shown to everyone
		return true;
	};

	// Get filtered steps
	const getFilteredSteps = () => {
		const allSteps = tourSteps(
			isRfqFeatureEnabled,
			uiTourSeen,
			rfqTourSeen,
			userRole
		);

		const filteredSteps = allSteps.filter(shouldShowStep);

		return filteredSteps;
	};

	const filteredSteps = getFilteredSteps();

	// Don't render if no steps or tour is inactive
	if (!filteredSteps.length || !isTourActive) {
		return null;
	}

	return (
		<Joyride
			beaconComponent={BeaconComponent}
			tooltipComponent={(props) => (
				<CustomTooltip
					{...props}
					dontShowAgain={dontShowAgain}
					setDontShowAgain={setDontShowAgain}
					currentStepIndex={currentStepIndex} // Pass the current index
					totalSteps={filteredSteps.length} // Pass total number of steps
				/>
			)}
			callback={handleJoyrideCallback}
			run={(run || forceRestartTour) && isTourActive}
			//stepIndex={stepIndex}
			steps={filteredSteps}
			continuous={true}
			showSkipButton={true}
			showProgress={true}
			disableOverlayClose={true}
			styles={{
				options: {
					...defaultOptions,
					zIndex: 9999999,
					overlayColor: "rgba(0, 0, 0, 0.25)",
				},
				spotlight:
					currentStepIndex === 0
						? {
								borderRadius: "50%", // Make the spotlight circular
							}
						: { height: "100vh !important" },

				floaterStyles:
					currentStepIndex === 0
						? {
								arrow: {},
								floater: {
									margin: "0.5rem", // Add a 1rem margin to the tooltip
								},
							}
						: {},
			}}
		/>
	);
};

export const GuidedTour = ({ children }) => {
	const [isTourGuide, setIsTourGuide] = useState(true);
	const [isTargetReady, setIsTargetReady] = useState(false);
	const { uiTourSeen, rfqTourSeen, forceRestartTour } =
		useContext(ProfileContext);

	const ignoreForPath = window.location.pathname.toString();
	const pathSegments = ignoreForPath.split("/").filter((segment) => segment); // Split and filter out empty segments

	useEffect(() => {
		const checkElement = () => {
			const element = document.querySelector(".MyProfile");

			if (element) {
				setIsTargetReady(true);
			} else {
				setTimeout(checkElement, 500);
			}
		};

		if (isTourGuide) {
			checkElement();
		}
		// Don't show tour if either tour has been seen
		if (uiTourSeen || rfqTourSeen) {
			return; // Exit early if either tour has been seen
		}

		// Show tour on initial load or forced restart
		if ((!uiTourSeen && !rfqTourSeen) || forceRestartTour) {
			setIsTourGuide(true);
			checkElement();
		}
	}, [uiTourSeen, rfqTourSeen, forceRestartTour, isTourGuide]);

	// Check if guided tour should be disabled based on env value (it is super annoying during development)
	if (process.env.REACT_APP_DISABLE_GUIDED_TOUR === "true") {
		return children;
	}

	return (
		<div className="GuidedTourWrapper">
			<TourGuide
				run={
					pathSegments[0] !== "company"
						? (isTourGuide && isTargetReady) || forceRestartTour
						: false
				}
				uiTourSeen={uiTourSeen}
				rfqTourSeen={rfqTourSeen}
			/>
			{children}
		</div>
	);
};

export default GuidedTour;
