export const dateParser = (dateString) => {
	const [day, month, year] = dateString.split("/").map(Number);
	if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
		const dateObject = new Date(year, month - 1, day);
		return dateObject;
	} else {
		console.log("Invalid date format");
	}
};

export const totalDaysFromDate = (prevDate, futureDate) => {
	const date1 = dateParser(prevDate);
	const date2 = dateParser(futureDate);

	// To calculate the time difference of two dates
	const DifferenceInTime = date2.getTime() - date1.getTime();

	// To calculate the no. of days between two dates
	const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

	//To display the final no. of days (result)

	return DifferenceInDays > 365 ? 365 : DifferenceInDays;
};
