import styled from "styled-components";

export const LocationFormStyled = styled.div`
	h3 {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		color: #253858;
	}
	.add-more-container {
		border-bottom: 1px solid ${(props) => props.theme.colors.snowDark};
		padding-bottom: 10px;
		margin-bottom: 24px;
		display: flex;
		.add-more-btn {
			background-color: transparent;
			display: flex;
			align-items: center;
			border: none;
			outline: none;
			margin-left: auto;
			font-size: 14px;
			color: ${(props) => props.theme.colors.grayLight};
			margin-bottom: 10px;
			cursor: pointer;
			span {
				margin-left: 10px;
			}
		}
	}

	.form-divider {
		border-bottom: 1px solid ${(props) => props.theme.colors.snowDark};
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.delete-address {
		margin-bottom: 40px;
		.delete-btn {
			float: right;
			padding: 8px 12px;
			color: red;
			background: transparent;
			border: 1px solid #fda29b;
			border-radius: 5px;
			cursor: pointer;
			&:hover {
				background: red;
				color: white;
			}
		}
	}
`;
