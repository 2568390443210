import { Skeleton } from "@chakra-ui/react";
import React from "react";

export const SkeletonBase = ({
	children,
	width = "128px",
	height = "24px",
	startColor = "lightgray",
	...props
}) => {
	return (
		<Skeleton
			{...props}
			width={width}
			height={height}
			startColor={startColor}
		>
			{children}
		</Skeleton>
	);
};
