import React, { useEffect } from "react";
import { connect } from "react-redux";

import { MultiSelect } from "../MultiSelect";
import { getTrades } from "../../../actions/companyActions";

const TradesConnected = ({
	className,
	name,
	label,
	options,
	getTrades,
	required,
	guideLine,
	maxWidth,
	...props
}) => {
	useEffect(() => {
		if (!options.length) {
			getTrades();
		}
	}, [options, getTrades]);

	return (
		<MultiSelect
			label={label}
			name={name}
			className={className}
			options={options}
			required={required}
			guideLineText={guideLine}
			sortingValue={true}
			maxWidth
		/>
	);
};

TradesConnected.defaultProps = {
	label: "Trade Package/s",
	options: [],
	name: "trades",
	required: false,
	className: "col sm-12",
};

const mapStateToProps = (state) => {
	return {
		options: state.companyProfile.trades,
	};
};

export const Trades = connect(mapStateToProps, { getTrades })(TradesConnected);
