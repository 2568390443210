import React from "react";
import { BadgeStyled } from "./styled";

export const Badge = ({ backgroundColor, text, textColor }) => {
	return (
		<BadgeStyled
			backgroundColor={backgroundColor ? backgroundColor : "aliceblue"}
			textColor={textColor}
		>
			<span>{text}</span>
		</BadgeStyled>
	);
};
