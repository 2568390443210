import React, { useContext, useEffect, useState } from "react";
import { ContextMenuLink } from "./ContextMenuLink";
import { IconBox } from "../IconBox";
import { ReactComponent as RequestIcon } from "../../icons/request-icon.svg";
import { ReactComponent as MyProfileClientIcon } from "../../icons/user-icon.svg";
import { ReactComponent as UserIcon } from "../../icons/users-02.svg";
import { ReactComponent as SettingIconPopup } from "./../../icons/settings-b.svg";
import { ReactComponent as EditIcon } from "./../../icons/edit-b.svg";
import { ReactComponent as UserAdminIcon } from "./../../icons/users-b.svg";
import { ReactComponent as LogoutIcon } from "./../../icons/logout-b.svg";
import { ReactComponent as CompanyProfileIcon } from "./../../icons/suitcase.svg";
import { ReactComponent as SignOutIcon } from "./../../icons/logout.svg";
import { ReactComponent as GearIcon } from "./../../icons/gear.svg";
import { ReactComponent as ViewIcon } from "./../../icons/eye.svg";
import { ReactComponent as ShareIcon } from "../../icons/share-icon.svg";
import { ReactComponent as HelpIcon } from "./../../icons/help-circle.svg";
import { logoutUser, logoutGuestUser } from "actions/authActions";
import { connect } from "react-redux";
import {
	isAdmin,
	isSuperAdmin,
	hasCompaniesListingAccess,
} from "../../utils/helpers";
import logo from "./logo.png";
import logoText from "./logo-procuracon.svg";
import { ShareProfileModal } from "components/ShareProfile/ShareProfileModal";
import { CompanyLogoImg, PopUp } from "components/GlobalNav/styled";
import { Link } from "@reach/router";
import { limitNotif } from "helpers/notificationHelper";
import { createRFQTOTAL } from "actions/supplierflow/supplierRfqActions";
import { getRFQServices } from "services/supplier-flow/rfqServices";
import { useDispatch } from "react-redux";
import SidebarHeader from "./SidebarHeader";
import { getCookie } from "utils/cookiesHelper";
import { ProfileContext } from "context/ProfileContext";
import { updateUserTourFlags } from "actions/AccountManagementActions";
function ContextMenuSupplierComponent({
	isCollapseNav,
	user,
	company,
	guestUser,
	logoutUser,
	logoutGuestUser,
	supplier_rfq_total,
	props,
}) {
	const dispatch = useDispatch();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [showPopUp, setShowPopUp] = useState(false);
	const company_id = localStorage.getItem("company_id");
	const [displaySideMenu, setDisplaySideMenu] = useState(false);
	const [showSupplierMenu, setShowSupplierMenu] = useState("init");
	const { setForceRestartTour } = useContext(ProfileContext);

	useEffect(() => {
		setShowPopUp(false);
	}, []);

	useEffect(() => {
		if (Object.keys(company).length !== 0) {
			if (
				isSuperAdmin() ||
				company.grouping === "procurer" ||
				company.grouping === "bidder_procurer"
			) {
				setShowSupplierMenu("yes");
			} else {
				setShowSupplierMenu("no");
			}
		}
	}, [company]);

	useEffect(() => {
		if (showSupplierMenu !== "init") {
			setDisplaySideMenu(true);
		}
	}, [showSupplierMenu]);

	const topMenu = [
		{
			name: "RFx",
			tourName: "RFx",
			link: `/account/${company_id}/request-for-quote`,
			icon: <RequestIcon />,
			total: supplier_rfq_total || 0,
			right: "-250px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
			icon_width: "24px",
			icon_height: "24px",
		},
	];

	const menus = [
		{
			name: "Account Settings",
			tourName: "AccountSettings",
			isLink: true,
			icon: <GearIcon />,
			link: `/account/${company_id}/account-settings`,
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
		},
		{
			name: "Company Profile",
			tourName: "CompanyProfile",
			isLink: true,
			icon: <CompanyProfileIcon />,
			link: `/account/${company_id}/company-profile/company-information`,
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
			exactLink: true,
		},
		{
			name: "Preview Profile",
			tourName: "PreviewProfile",
			isLink: true,
			type: "tab",
			icon: <ViewIcon />,
			link: `/company/${company_id}/projects`,
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
		},
		{
			name: "Share Profile",
			tourName: "ShareProfile",
			isLink: true,
			type: "tab",
			icon: <ShareIcon />,
			link: ``,
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
		},
		{
			name: "My Profile",
			tourName: "MyProfile",
			isLink: true,
			icon: <MyProfileClientIcon />,
			link: `/account/${company_id}/company-profile/edit-user-profile`,
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
			exactLink: true,
		},
	];

	useEffect(() => {
		const abortController = new AbortController();
		const fetchData = async () => {
			try {
				let currentPage = 1;
				let limit = 10;
				let search = "";
				const response = await getRFQServices(currentPage, search, limit, {
					signal: abortController.signal,
				});
				let responseData = response.data.data;
				let totalRFQs = responseData.all.meta.total;
				await dispatch(createRFQTOTAL(totalRFQs));
			} catch (error) {
				console.log("error", error);
			}
		};
		fetchData();
		// Clean-up function
		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	}, []);

	const logout = async () => {
		await logoutUser();
	};
	const logoutGuest = async () => {
		await logoutGuestUser();
	};

	const menuFunction = (menu) => {
		switch (menu) {
			case "logout":
				guestUser.login ? logoutGuest() : logout();
				break;
			default:
				break;
		}
	};

	const handleResetTourFlags = async () => {
		await updateUserTourFlags(false, false); // Reset the flags
		setForceRestartTour(true); // Trigger tour restart
	};

	return (
		<div>
			<SidebarHeader
				logo={props.logo}
				name={props.name}
				isCollapseNav={isCollapseNav}
			/>
			<div style={{ display: "grid", overflow: "hidden" }}>
				<div
					className={`${isCollapseNav ? "!overflow-x-hidden" : ""} scroll-container scroll flex flex-col justify-between`}
					style={{
						height: isCollapseNav ? "calc(100vh - 60%)" : "calc(100vh - 50%)",
					}}
				>
					{displaySideMenu &&
						topMenu.map((menu, index) => {
							if (
								menu.name === "Companies" &&
								!isSuperAdmin(user) &&
								!hasCompaniesListingAccess(company)
							) {
								return null;
							}

							if (menu.name === "RFx" && supplier_rfq_total === 0) {
								return null;
							}
							return (
								<div
									key={index}
									className={`${menu.tourName} ${isCollapseNav ? "menuleft mb-2 mr-3 rounded-md hover:bg-white-20" : "menuleft group overflow-hidden hover:bg-utility-blue-light-50 focus:bg-white"}`}
								>
									<ContextMenuLink
										to={menu.link}
										height={isCollapseNav ? "auto" : "56px"}
									>
										<div
											style={{
												paddingLeft: isCollapseNav ? "13px" : "32px",
												width: "24px",
												marginRight: "36px",
											}}
										>
											<IconBox
												icon={menu.icon}
												color={
													isCollapseNav
														? menu.color_secondary
														: menu.color_primary
												}
												width={menu.icon_width}
												height={menu.icon_width}
											/>
										</div>
										<div
											style={{
												display: isCollapseNav ? "none" : "flex",
												position: "relative",
												fontSize: "16px",
												lineHeight: "24px",
											}}
											className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
										>
											{menu.name}
											{menu.name !== "Companies" && (
												<div
													style={{
														fontSize: "12px",
														textAlign: "center",
														width: "30px",
														height: "22px",
														position: "absolute",
														right: menu.right,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
													className="rounded-full border border-quarternary-400 bg-gray-utility-100 font-medium"
												>
													<div className="font-roboto font-semibold text-secondary-700">
														{limitNotif(menu?.total)}
													</div>
												</div>
											)}
										</div>
									</ContextMenuLink>
								</div>
							);
						})}
				</div>
				<div style={{ position: "absolute", bottom: "32px", width: "100%" }}>
					{displaySideMenu &&
						menus.map((menu, index) => {
							return (
								<div
									key={index}
									className={`${menu.tourName} ${isCollapseNav ? "menuleft mb-2 mr-3 rounded-md hover:bg-white-20" : "menuleft group overflow-hidden hover:bg-utility-blue-light-50 focus:bg-white"}`}
								>
									{menu.isLink && menu.name === "Share Profile" ? (
										<button
											className="flex items-center py-2"
											onClick={() => {
												setIsOpenModal(true);
											}}
										>
											<div
												style={{
													paddingLeft: isCollapseNav ? "12px" : "32px",
													width: "24px",
													marginRight: "36px",
												}}
											>
												<IconBox
													icon={menu.icon}
													color={
														isCollapseNav
															? menu.color_secondary
															: menu.color_primary
													}
													width={menu.icon_width}
													height={menu.icon_width}
												/>
											</div>
											<div
												style={{
													display: isCollapseNav ? "none" : "flex",
													position: "relative",
													fontSize: "16px",
													lineHeight: "24px",
												}}
												className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
											>
												{menu.name}
											</div>
										</button>
									) : (
										<ContextMenuLink
											to={menu.link}
											height={isCollapseNav ? "auto" : "56px"}
											newTab={menu.name === "Preview Profile"}
										>
											<div
												style={{
													paddingLeft: isCollapseNav ? "12px" : "32px",
													width: "24px",
													marginRight: "36px",
												}}
											>
												<IconBox
													icon={menu.icon}
													color={
														isCollapseNav
															? menu.color_secondary
															: menu.color_primary
													}
													width={menu.icon_width}
													height={menu.icon_width}
												/>
											</div>
											<div
												style={{
													display: isCollapseNav ? "none" : "flex",
													position: "relative",
													fontSize: "16px",
													lineHeight: "24px",
												}}
												className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
											>
												{menu.name}
											</div>
										</ContextMenuLink>
									)}
								</div>
							);
						})}
					{displaySideMenu && (
						<div
							className={`${isCollapseNav ? "mr-3 rounded-md hover:bg-white-20" : "group hover:bg-utility-blue-light-50 focus:bg-white"}`}
						>
							<div
								style={{
									borderBottom: isCollapseNav
										? "solid 0px #EAECF0"
										: "solid 1px #EAECF0",
									marginBottom: "15px",
									marginLeft: "7px",
								}}
								className="sidemuchild"
							></div>
							<div
								onClick={() => menuFunction("logout")}
								className="ml-4 flex cursor-pointer items-center gap-2"
							>
								<div
									style={{
										marginLeft: isCollapseNav ? "-7px" : "16px",
										width: "24px",
										marginRight: "8px",
									}}
								>
									<IconBox
										icon={
											<SignOutIcon
												width="24px"
												height="24px"
											/>
										}
										color={isCollapseNav ? "#BDD6FF" : "#0045F5"}
										width="24px"
										height="24px"
									/>
								</div>
								<div
									style={{
										display: isCollapseNav ? "none" : "block",
										lineHeight: "24px",
									}}
									className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
								>
									<p className="text-lg">Logout</p>
								</div>
							</div>
							<div
								style={{
									borderBottom: isCollapseNav
										? "solid 0px #EAECF0"
										: "solid 1px #EAECF0",
									marginTop: "15px",
									marginLeft: "7px",
								}}
								className="sidemuchild"
							></div>
						</div>
					)}
					<div
						style={{
							paddingBottom: "20px",
							paddingLeft: "20px",
							paddingRight: "20px",
							paddingTop: isCollapseNav ? "0px" : "40px",
						}}
						className="flex justify-between"
					>
						<img
							src={logoText}
							alt="Procuracon Logo text"
							className="sidemuchild logolong"
							style={{ width: "154px", height: "24px" }}
						/>
						<button
							onClick={handleResetTourFlags}
							className="Frame35936 bg-Colors-Brand-25/70 inline-flex h-8 items-center justify-start gap-2.5 rounded-full p-2"
						>
							<div className="GuidedTourIcon relative h-5 w-5 rounded-full p-1 hover:cursor-pointer hover:text-royal-blue">
								<HelpIcon />
							</div>
						</button>
					</div>

					<div style={{ display: isCollapseNav ? "block" : "none" }}>
						<div className="">
							<IconBox
								icon={
									<img
										src={logo}
										alt="Procuracon Logo"
										width="40"
										height="40"
										style={{ marginLeft: "-9px" }}
									/>
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<ShareProfileModal
				isOpen={isOpenModal}
				setIsOpen={setIsOpenModal}
			/>
			{showPopUp && (
				<PopUp
					style={{
						marginLeft: !isCollapseNav ? "20px" : "",
						bottom: isCollapseNav ? "160px" : "90px",
					}}
				>
					<div
						className="header"
						onClick={() => setShowPopUp(false)}
					>
						<CompanyLogoImg
							src={
								company?.logo_path ||
								`https://ui-avatars.com/api/?name=${
									company?.name || guestUser.email
								}&color=fff&background=000`
							}
							onClick={() => setShowPopUp(false)}
						/>
						<p className="title">{company?.name}</p>
						<span className="tag">
							{company?.plan?.package_name || guestUser.login ? "guest" : ""}
						</span>
					</div>
					<div className="body">
						{!guestUser.login && (
							<li onClick={() => setShowPopUp(false)}>
								{" "}
								<Link
									to={`/account/${company_id}/company-profile/edit-user-profile`}
								>
									<UserIcon /> My Profile
								</Link>
							</li>
						)}
						{(isAdmin(user) || isSuperAdmin()) && (
							<>
								<li onClick={() => setShowPopUp(false)}>
									{" "}
									<Link
										to={`/account/${company_id}/company-profile/account-management`}
									>
										<SettingIconPopup /> Account Management
									</Link>
								</li>
								<li onClick={() => setShowPopUp(false)}>
									{" "}
									<Link
										to={`/account/${company_id}/company-profile/company-information`}
									>
										<EditIcon /> Edit Company Details
									</Link>
								</li>
							</>
						)}
						{getCookie("is_admin") === "true" && (
							<li onClick={() => setShowPopUp(false)}>
								{" "}
								<Link to="/companies">
									<UserAdminIcon /> Companies Admin
								</Link>
							</li>
						)}
						<li>
							{" "}
							<button onClick={guestUser.login ? logoutGuest : logout}>
								<LogoutIcon /> Sign Out
							</button>
						</li>
					</div>
				</PopUp>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		access_token: state.auth.access_token,
		guestUser: state.auth.guestUser,
		user: state.auth.user,
		company: state.auth.current_company,
		supplier_rfq_total: state?.supplier_rfq?.total,
	};
};

const ContextMenuSupplier = connect(mapStateToProps, {
	logoutUser,
	logoutGuestUser,
})(ContextMenuSupplierComponent);

export default ContextMenuSupplier;
