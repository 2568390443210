import React, { useEffect } from "react";
import { ReactComponent as Search } from "../../icons/search-icon.svg";
//import { ReactComponent as Share } from "../../icons/share-icon.svg";
import { ReactComponent as Projects } from "../../icons/projects-icon.svg";
import { ReactComponent as Request } from "../../icons/request-icon.svg";
import { ReactComponent as Supplier } from "../../icons/supplier-icon.svg";
import { ReactComponent as User } from "../../icons/user-icon.svg";
//import { ReactComponent as User } from "../../icons/users-02.svg";
import { ReactComponent as Companies } from "../../icons/users.svg";
import { ReactComponent as SettingPopup } from "./../../icons/settings-b.svg";
import { ReactComponent as Edit } from "./../../icons/edit-b.svg";
import { ReactComponent as UserAdmin } from "./../../icons/users-b.svg";
import { ReactComponent as Logout } from "./../../icons/logout-b.svg";
import { ReactComponent as CompanyProfile } from "./../../icons/suitcase.svg";
import { ReactComponent as Gear } from "./../../icons/gear.svg";
import { ReactComponent as SignOut } from "./../../icons/logout.svg";
import { ReactComponent as View } from "./../../icons/eye.svg";
import { ReactComponent as Help } from "./../../icons/help-circle.svg";
import { ReactComponent as XClose } from "./../../icons/close.svg";
import { ReactComponent as Announcement } from "./../../icons/announcement-02.svg";

const IconComponents = {
	Search,
	Projects,
	Request,
	Supplier,
	Gear,
	User,
	Companies,
	SettingPopup,
	Edit,
	UserAdmin,
	Logout,
	CompanyProfile,
	SignOut,
	View,
	Help,
	XClose,
	Announcement,
};

// Custom tooltip component that receives Joyride props
export const CustomTooltip = ({
	continuous,
	index,
	step,
	backProps,
	closeProps,
	primaryProps,
	skipProps,
	tooltipProps,
	isLastStep,
	dontShowAgain,
	setDontShowAgain,
	currentStepIndex,
	totalSteps,
}) => {
	// Add error boundary for props
	useEffect(() => {
		if (!primaryProps || !skipProps) {
			console.error("Missing required props in CustomTooltip");
		}
	}, [primaryProps, skipProps]);

	// Safe event handling
	const handlePrimaryClick = (e) => {
		e.preventDefault();
		if (primaryProps?.onClick) {
			primaryProps.onClick(e);
		}
	};

	// State logging for debugging
	useEffect(() => {}, [
		index,
		isLastStep,
		dontShowAgain,
		currentStepIndex,
		totalSteps,
	]);

	return (
		<div className="Modals inline-flex h-[381px] w-[419px] flex-col items-center justify-between rounded-xl bg-white shadow">
			<div className="ModalHeader flex h-64 flex-col items-center justify-start self-stretch pb-8">
				<div className="Content flex h-64 flex-col items-start justify-start gap-4 self-stretch px-6 pt-6">
					<div className="flex w-full items-center justify-between">
						<div className="FeaturedIconOutline relative flex h-7 w-7 items-center justify-center rounded-full">
							<div className="OutlineInner absolute left-[-4px] top-[-4px] h-9 w-9 rounded-3xl border-2 border-royal-blue opacity-30" />
							<div className="OutlineOuter absolute left-[-9px] top-[-9px] h-[46px] w-[46px] rounded-3xl border-2 border-royal-blue opacity-10" />
							{IconComponents[step.icon] ? (
								React.createElement(IconComponents[step.icon])
							) : (
								<Announcement />
							)}
						</div>
						<button
							{...closeProps}
							className="ButtonCloseX inline-flex h-11 w-11 cursor-pointer items-center justify-center rounded-lg p-3"
						>
							<XClose className="XClose relative h-6 w-6 opacity-50 hover:opacity-100" />
						</button>
					</div>
					<div className="TextAndSupportingText flex h-[152px] flex-col items-start justify-start gap-2 self-stretch">
						<div className="Text self-stretch font-['Roboto'] text-xl font-semibold leading-[30px] text-primary-900">
							{step.title}
						</div>
						<div
							className="SupportingText self-stretch text-sm text-tertiary-700"
							dangerouslySetInnerHTML={{ __html: step.content }}
						/>
						{step.subtext && (
							<div className="SubText self-stretch text-sm font-medium text-tertiary-700">
								{step.subtext}
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Footer with buttons */}
			<div className="ModalActions flex w-full flex-col items-start justify-center">
				<div className="Content inline-flex w-full items-center justify-start gap-3 border-t border-tertiary-200 border-opacity-25 px-6 py-6">
					{index === 0 ? (
						<label className="Checkbox flex items-center justify-start gap-2 hover:cursor-pointer">
							<input
								type="checkbox"
								checked={dontShowAgain}
								onChange={(e) => {
									setDontShowAgain(e.target.checked);
								}}
								className="mr-2 h-4 w-4 focus-visible:shadow-sm focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-royal-blue"
								aria-label="Don't show again"
							/>
							<div className="Text font-['Roboto'] text-sm font-medium leading-tight text-secondary-700 hover:underline">
								Don't show again
							</div>
						</label>
					) : (
						<div className="Text font-regular font-['Roboto'] text-sm leading-tight text-secondary-700 opacity-50">
							{`${currentStepIndex} of ${totalSteps - 1}`}
						</div>
					)}

					<div className="Actions flex h-11 shrink grow basis-0 items-center justify-end gap-3">
						{index === 0 ? (
							<button
								{...skipProps}
								disabled={!dontShowAgain}
								className={`ButtonsButton flex items-center justify-center gap-1.5 rounded border px-4 py-2.5 shadow ${
									!dontShowAgain
										? "cursor-not-allowed border-gray-200 bg-gray-100"
										: "bg-Component colors-Components-Buttons-Secondary-button-secondary-bg border-Component colors-Components-Buttons-Secondary-button-secondary-border"
								}`}
							>
								<div
									className={`Text font-['Roboto'] text-base font-semibold leading-normal ${!dontShowAgain ? "text-gray-400" : "text-secondary-700"}`}
								>
									Skip
								</div>
							</button>
						) : (
							<button
								{...backProps}
								className="ButtonsButton bg-Component colors-Components-Buttons-Secondary-button-secondary-bg border-Component colors-Components-Buttons-Secondary-button-secondary-border flex items-center justify-center gap-1.5 rounded border px-4 py-2.5 shadow"
							>
								<div className="Text font-['Roboto'] text-base font-semibold leading-normal text-secondary-700">
									Back
								</div>
							</button>
						)}

						<button
							{...primaryProps}
							onClick={handlePrimaryClick}
							className="ButtonsButton flex items-center justify-center gap-1.5 rounded border border-royal-blue bg-royal-blue px-4 py-2.5 shadow"
						>
							<div className="Text font-['Roboto'] text-base font-semibold leading-normal text-white">
								{isLastStep ? "Finish" : index === 0 ? "Get Started" : "Next"}
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
