import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { DateInput } from "components/DateInput";
import { Form, Formik } from "formik";
import TimePickerField from "components/TimePicker/TimePicker";
import { Button } from "components/RFQ/Button";

import * as Yup from "yup";
import { useRef, useState } from "react";
import { updateSubmissionDeadLine } from "actions/RFQ/rfqsAction";
import moment from "moment";
import { Spinner } from "@chakra-ui/react";
import { showToast } from "utils/helpers";

const ExtendSubmissionDeadlineModal = ({
	isModalOpen,
	rfqDetail,
	onSubmissionDateExtension,
	onClose,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const formikRef = useRef();

	const onSubmit = (formObject) => {
		const values = formikRef?.current?.values;
		const submissionDeadline = values?.extended_time
			? moment(
					moment(values?.extended_date).format("YYYY-MM-DD") +
						" " +
						values?.extended_time +
						":00"
				).format("YYYY-MM-DD HH:mm:ss")
			: "";
		const data = {
			submission_deadline: submissionDeadline,
			submission_deadline_timezone: values.submission_deadline_timezone,
		};

		setIsSubmitting(true);
		updateSubmissionDeadLine(rfqDetail.id, data)
			.then((res) => {
				onSubmissionDateExtension(res.data.data);
				setIsSubmitting(false);
				onClose();
				showToast("Submission Deadline Extended Successfully", "Success", true);
			})
			.catch((e) => {
				isSubmitting(false);
				onClose();
			});
	};

	return (
		<>
			<CustomModal
				header={{
					icon: "calendar",
					bgColor: "bg-brand-secondary",
					title: `Extend submission deadline`,
				}}
				isOpen={isModalOpen}
				onClose={onClose}
			>
				<div>
					<Formik
						innerRef={formikRef}
						validationSchema={Yup.object().shape({
							extended_date: Yup.string().required("This field is required"),
							extended_time: Yup.string().required("This field is required"),
						})}
						initialValues={{
							extended_date: rfqDetail?.submissionDeadline,
							extended_time: moment(rfqDetail?.submissionDeadline).format(
								"HH:mm"
							),
							submission_deadline_timezone: rfqDetail?.submissionTimezone,
						}}
						onSubmit={onSubmit}
					>
						{(props) => {
							const { setFieldValue, values } = props;

							return (
								<Form>
									<DateInput
										name="extended_date"
										label="Extended Deadline"
										setFieldValue={setFieldValue}
										values={values}
										minDate={moment(
											moment(rfqDetail?.submissionDeadline).format("YYYY/MM/D")
										).toDate()}
									/>
									<TimePickerField
										className="mt-9 w-full flex-1"
										name="extended_time"
										nameTimezone="submission_deadline_timezone"
										label="Extended Time"
									/>
									<Button
										btntype="submit"
										className="!w-full !py-3"
									>
										Extend Time
									</Button>
									{isSubmitting && (
										<div className="flex items-center justify-center gap-4 pt-4 text-royal-blue">
											<Spinner />
											<p className="text-center font-bold">Submitting ....</p>
										</div>
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
			</CustomModal>
		</>
	);
};

export default ExtendSubmissionDeadlineModal;
