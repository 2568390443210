import { Icon } from "assets/icons/Icon";

type PreviewOnlyCompProps = {
	icon?: string;
	submissionStatus: string;
	label?: string;
	isRFxClosed?: boolean;
};

export const PreviewOnlyComp = ({
	icon = "eye",
	submissionStatus,
	label = "Preview only",
	isRFxClosed,
}: PreviewOnlyCompProps) => {
	return (
		<div className="m-auto">
			<div
				className="flex items-center justify-center rounded-full border border-solid border-[#B0CEFF] bg-utility-blue-light-50 p-2 text-center font-medium text-utility-blue-dark-100"
				style={{ width: "fit-content", fontSize: "14px" }}
			>
				<div className="flex items-center justify-center rounded-full border border-solid border-[#B0CEFF] bg-white pb-1 pl-3 pr-3 pt-1">
					<div>
						{/* @ts-ignore */}
						<Icon icon={icon} />
					</div>
					<div className="ml-2">{label}</div>
				</div>
				{!isRFxClosed && submissionStatus?.toLowerCase() !== "submitted" && (
					<>
						<div
							className="ml-2 pr-2"
							style={{ marginTop: "-2px" }}
						>
							Tap "Accept" to agree to the terms and get started
						</div>
						<div className="pr-2">
							{/* @ts-ignore */}
							<Icon icon="arrow-right" />
						</div>
					</>
				)}
			</div>
		</div>
	);
};
