import { theme } from "../../../theme";

export const autoSuggestTheme = {
	container: {
		marginBottom: "1.25rem",
		position: "relative",
	},
	suggestionsContainer: {
		backgroundColor: "white",
		color: "white",
		position: "absolute",
		left: 0,
		width: "100%",
		padding: 0,
		zIndex: 99,
	},
	suggestionsList: {
		listStyle: "none",
		padding: 0,
		border: "1px solid #e7eaee",
		borderRadius: "2px",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		margin: 0,
	},
	suggestion: {
		color: "#5B6B88",
		fontWeight: 400,
		fontSize: theme.fontSizes.md,
		padding: 0,
		cursor: "pointer",
		transition: theme.transitions.default,
		lineHeight: 0,
	},
	suggestionHighlighted: {
		color: "white",
		backgroundColor: theme.colors.royalBlue,
	},
};
