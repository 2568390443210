import { useEffect, useState } from "react";
import { getAddendumServices } from "services/supplier-flow/adendumServices";
import AddendumsComp from "../RFQPreview/AddendumsComp";
import { Addendum } from "./Addendum.types";
import { useSelector } from "react-redux";

type AddendumContainerProps = {
	isCommunication: boolean;
	rfqId: number;
	isGuest: boolean;
	guestEmail: string;
	disabled: boolean;
	setCounterForTabs: (tab, count) => void;
};

export const AddendumContainer = ({
	isCommunication,
	rfqId,
	isGuest,
	guestEmail,
	setCounterForTabs,
	disabled,
}: AddendumContainerProps) => {
	const [addendums, setAddendums] = useState<Addendum[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const company = useSelector((state: any) => state?.auth?.current_company);
	useEffect(() => {
		setIsLoading(true);

		getAddendumServices(
			rfqId,
			isCommunication ? "unpublished" : "published"
		).then((res) => {
			setAddendums(res?.data?.data);
			setCounterForTabs(
				isCommunication ? "comms" : "addendums",
				res?.data?.additional?.addendumCounter
			);
			setIsLoading(false);
		});
		// eslint-disable-next-line
	}, [rfqId, isCommunication]);

	return (
		// @ts-ignore
		<AddendumsComp
			rfqId={rfqId}
			addendumData={addendums}
			setAddendumData={setAddendums}
			isGuest={isGuest}
			guestEmail={guestEmail}
			disabled={disabled}
			isCommunication={isCommunication}
			isDataLoading={isLoading}
			company={company}
		/>
	);
};
