import { styled } from "twin.macro";

export const DashboardStyled = styled.div`
	h2 {
		font-weight: 700;
		font-family: "Roboto";
		font-size: 20px;
		color: #101828;
	}
`;
