import React from "react";
import { connect } from "react-redux";
import { CompanyCard } from "../CompanyCard";
import { CompaniesListingStyled, ListStyled } from "./styled";

export const CompaniesListingComponent = ({ company, ...props }) => {
	return (
		<CompaniesListingStyled>
			{props.current_user?.shared_profiles?.length ? (
				<ListStyled className="flex flex-wrap justify-center gap-10">
					{[
						props.current_user.shared_profiles.map(({ company }) => (
							<CompanyCard
								key={company.id}
								id={company.id}
								name={company.name}
								size={company.size}
								logo={company.logo_path}
								banner={company.banner_path}
								className={`min-w-[300px] flex-1`}
								admin={props.admin}
								location={company.location}
								profileType={company.profile_type}
								linkNewTab={true}
							/>
						)),
					]}
				</ListStyled>
			) : (
				<div className="message">No Shared Profiles</div>
			)}
		</CompaniesListingStyled>
	);
};

const mapStateToProps = (state) => {
	const current_user = state.auth.user;

	return {
		current_user: current_user,
	};
};

export const CompaniesListing = connect(
	mapStateToProps,
	{}
)(CompaniesListingComponent);
