import React, { useCallback, useState } from "react";
import api from "api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _debounce from "lodash.debounce";
import { TextInput } from "../TextInput";
import { Location } from "../Location";
import { Sectors } from "../Sectors";
import { FormHeading } from "../../FormHeading";
import { Currency } from "../Currency";
import { Autosuggest } from "../Autosuggest";
import { Button } from "components/RFQ/Button";
import { getCookie } from "utils/cookiesHelper";

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const ResourceProjectForm = ({
	document,
	onSubmit,
	isSubmitting,
	initialValues,
	values,
	title,
	index,
	isEdit,
	onSave,
	...props
}) => {
	const [projectsSearchResults, setProjectsSearchResults] = useState([]);

	const companyId = window.location.pathname.split("/")[2];
	// eslint-disable-next-line
	const handleProjectNameChange = useCallback(
		_debounce(async (value) => {
			const response = await getApiWithToken()
				.get(`past-projects?company_id=${companyId}&search=${value}`)
				.json();
			setProjectsSearchResults(response.data);
		}, 250),
		[]
	);

	const getButtonText = () => {
		return isEdit
			? {
					buttonText: "Update Project Details",
					submittingText: "Updating Project Details...",
				}
			: {
					buttonText: "Add Project Details",
					submittingText: "Adding Project Details...",
				};
	};

	return (
		<div className="bg-white p-8">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					name: Yup.string().required(),
					role: Yup.string().required(),
					sector_id: Yup.string().required(),
					location: Yup.object().required(),
					value: Yup.string().required(),
				})}
				onSubmit={onSubmit}
			>
				{(props) => {
					const { values, isSubmitting, setFieldValue } = props;
					const setProjectFieldValues = (project) => {
						project?.sector?.id &&
							setFieldValue("sector_id", project.sector.id);
						project?.location && setFieldValue("location", project.location);
						project?.value.toString() &&
							setFieldValue("value", project.value.toString());
					};

					return (
						<Form>
							<FormHeading text={title || "Document Detail"} />

							<div className="flex flex-col gap-1">
								<Autosuggest
									label="Project Name"
									name="name"
									type="text"
									queryFunction={(value) => handleProjectNameChange(value)}
									searchResults={
										projectsSearchResults && projectsSearchResults.slice(0, 5)
									}
									handleClick={setProjectFieldValues}
								/>

								<Sectors
									label="Primary Sector"
									required="true"
									name="sector_id"
									values={values}
									singleValue
								/>

								<TextInput
									label="Your Role on the Project"
									required="true"
									name="role"
									type="text"
								/>

								<Location
									label="Project Location"
									className="mb-6"
									required="true"
									name="location"
									initialValue={initialValues.location}
								/>
								<Currency
									label="Project Construction Value (AUD)"
									required="true"
									name="value"
									type="number"
									lang="en_EN"
									setFieldValue={setFieldValue}
									values={values}
								/>
							</div>

							<Button
								type="submit"
								className="mt-3"
							>
								{isSubmitting
									? getButtonText().submittingText
									: getButtonText().buttonText}
							</Button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
