import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { TabHeader } from "components/TabHeader";
import { PreviewRFQButtonTab } from "components/Pages/CreateRFQ/PreviewRFQButtonTab";
import * as constant from "utils/constants";
import { Form, Formik } from "formik";
import moment from "moment";
import { TenderCost } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/TenderCost";
import { Questionnaire } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire";
import { Documentation } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Documentation";

const PreviewRFQComponent = ({
	rfqData,
	rfqDetail,
	unitMeasures,
	documentCategories,
	...props
}) => {
	const [currentTab, setCurrentTab] = React.useState(constant.PROJECT_DETAILS);

	const initialDataQuote = { quote: rfqDetail?.quote } || { quote: [] };

	const documentAttachments = rfqDetail?.documents
		.filter((doc) => !doc?.isRemove)
		.reduce((final, doc) => {
			const category = documentCategories.find(
				(docs) => docs.value === doc.category_id || doc.categoryId
			);
			const haveIndex = final.findIndex(
				(docs) => docs.category === category.label
			);
			if (haveIndex >= 0) {
				const docObj =
					doc.document instanceof File
						? doc.document
						: doc?.fileName
							? {
									name: doc?.fileName,
									file_size: doc?.fileSize,
									path: doc?.documentPath,
								}
							: doc;
				final[haveIndex].documents = [...final[haveIndex].documents, docObj];
				return final;
			} else {
				const docObj =
					doc.document instanceof File
						? doc.document
						: doc?.fileName
							? {
									name: doc?.fileName,
									file_size: doc?.fileSize,
									path: doc?.documentPath,
								}
							: doc;
				final.push({
					category: category.label,
					documents: [docObj],
				});
				return final;
			}
		}, []);

	return (
		<>
			<Box className="grid min-h-80 w-full content-between rounded-md border px-6 py-10 shadow-xl">
				<TabHeader
					noDivider={true}
					heading={`${rfqData?.name || " - "}`}
					description={rfqDetail?.description || " - "}
				/>
				<div id="content-title">
					<div
						id="content-wrapper"
						className="border-t pt-5"
					>
						<Flex justifyContent="space-between">
							<div className="w-1/3">
								<p className="text-base font-normal">Project Name</p>
								<p className="text-base font-semibold text-primary-900">
									{rfqData?.project?.name}
								</p>
							</div>
							<div className="w-1/3">
								<p className="text-base font-normal">Last Updated</p>
								<p className="text-base font-semibold text-primary-900">
									{moment(rfqData?.lastUpdated).format("DD MMM YYYY") || " - "}
								</p>
							</div>
							<div className="w-1/3">
								<p className="text-base font-normal">Location</p>
								<p className="text-base font-semibold text-primary-900">
									{rfqData?.location || rfqDetail?.address?.value || " - "}
								</p>
							</div>
						</Flex>
					</div>
				</div>
			</Box>
			<div className="my-6">
				<PreviewRFQButtonTab
					setCurrentTab={setCurrentTab}
					currentTab={currentTab}
					rfqDetail={rfqDetail}
				/>
			</div>
			<div>
				{currentTab === constant.PROJECT_DETAILS && (
					<Box className="grid min-h-80 w-full rounded-md border p-10 pt-6 shadow-xl">
						{rfqDetail.projectDetails.map((detail) => (
							<div className="my-3">
								<p className="text-lg font-semibold text-secondary-500">
									{detail.label}
								</p>
								<p
									className="text-base font-semibold text-primary-900 [&>ol]:ml-5 [&>ol]:!list-decimal [&>ul]:ml-5 [&>ul]:!list-disc"
									dangerouslySetInnerHTML={{ __html: detail?.value }}
								/>
							</div>
						))}
					</Box>
				)}
				{currentTab === constant.QUESTIONNAIRE && (
					<Formik initialValues={[]}>
						<Form>
							<Questionnaire
								questionnaire={rfqDetail.questionnaire}
								disable={true}
								returnableScheduleFile={
									rfqDetail?.returnableScheduleFile instanceof File
										? rfqDetail?.returnableScheduleFile
										: typeof rfqDetail?.returnableScheduleFile === "object"
											? {
													name: rfqDetail?.returnableScheduleFile?.file_name,
													path: rfqDetail?.returnableScheduleFile?.file_path,
												}
											: null
								}
								returnableScheduleFileType={rfqDetail?.returnableScheduleType}
							/>
						</Form>
					</Formik>
				)}
				{currentTab === constant.TENDER_COST && (
					<div>
						<Formik initialValues={initialDataQuote}>
							<Form>
								<TenderCost
									tables={rfqDetail?.quote}
									unitMeasures={unitMeasures}
									disable={true}
									quoteFile={
										rfqDetail?.quoteFile instanceof File
											? rfqDetail?.quoteFile
											: typeof rfqDetail?.quoteFile === "object"
												? {
														name: rfqDetail?.quoteFile?.name,
														path: rfqDetail?.quoteFile?.path,
													}
												: null
									}
									quoteType={rfqDetail?.quoteType}
								/>
							</Form>
						</Formik>
					</div>
				)}
				{currentTab === constant.DOCUMENTATION && (
					<Documentation
						documentAttachments={documentAttachments}
						documentDescription={rfqDetail.documentDescription}
					/>
				)}
			</div>
		</>
	);
};

export default PreviewRFQComponent;
