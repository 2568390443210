import { TabHeader } from "components/TabHeader";
import React, { useState, useEffect } from "react";
import { useNavigate } from "@reach/router";
import tw, { styled, css } from "twin.macro";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { getRFQServices } from "services/guest-flow/rfqServices";
import RfqCard from "components/RFQ/RFQCard/RFQCard";
import RfqCardAnimation from "./RfqCardAnimation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createRFQ, createRFQTOTAL } from "actions/guestflow/guestRfqActions";
import ModalOverviewComp from "./ModalOverviewComp";
import { guestFirseLoggedIn } from "actions/guestActions";

function RfqOverview() {
	const { email, first_logged } = useSelector((state) => state.auth.guestUser);
	const [showDialog, setShowDialog] = useState(first_logged);
	const guestEmail = email;
	const dispatch = useDispatch();
	const SupplierContainer = styled.div(() => [
		tw`relative overflow-hidden px-1`,
		css`
			.buttons-container {
				gap: 8px;
			}
			.container-content {
				padding-right: 24px;
				padding-left: 24px;
			}
		`,
	]);

	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const navigatePreview = (id) => {
		navigate("/account/request-for-quote/" + id);
	};

	useEffect(() => {
		const abortController = new AbortController();
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const response = await getRFQServices(guestEmail, {
					signal: abortController.signal,
				});
				let responseData = response.data.data;
				dispatch(createRFQTOTAL(responseData.length));
				dispatch(createRFQ(responseData));
				setData(responseData);
				setIsLoading(false);
			} catch (error) {
				console.log("error", error);
			}
		};
		fetchData();
		// Clean-up function
		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	}, []);

	const acceptTermsFunc = () => {
		dispatch(guestFirseLoggedIn(true));
		setShowDialog(false);
	};

	return (
		<>
			<SupplierContainer>
				<TabHeader
					heading="YOUR RFx"
					description=""
				/>
				<div id="layout-dashboard">
					<div className="mb-6 mt-6">
						{isLoading === true && <RfqCardAnimation />}
						{isLoading === false && (
							<>
								{data?.length < 1 && (
									<div className="flex items-center justify-center">
										<NotFoundDatas text="RFQ Not found" />
									</div>
								)}
								<div
									className="grid w-full min-w-[680px] grid-cols-2 gap-6 xlg:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6"
									style={{ marginRight: "20px" }}
									id="scrollable-drawer"
								>
									{data?.map((singleData) => {
										return (
											<RfqCard
												key={singleData?.id}
												data={singleData}
												navigatePreview={navigatePreview}
											/>
										);
									})}
								</div>
							</>
						)}
					</div>
				</div>
			</SupplierContainer>
			<div className="translate-y-1/2 overflow-hidden">
				<ModalOverviewComp
					showDialog={showDialog}
					acceptTerms={acceptTermsFunc}
					guestEmail={guestEmail}
				/>
			</div>
		</>
	);
}

export default RfqOverview;
