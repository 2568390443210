import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import * as AutosuggestComponent from "react-autosuggest";
// import _throttle from 'lodash.throttle';

import { AutosuggestStyled, Suggestion } from "./styled";
import { autoSuggestTheme } from "./autoSuggestTheme";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

// Autosuggest will call this function every time you need to clear suggestions.

export const Autosuggest = ({
	name,
	required,
	label,
	className,
	queryFunction,
	handleClick,
	customInputProps,
	searchResults,
	...props
}) => {
	// eslint-disable-next-line
	const [suggestions, setSuggestions] = useState([]);

	//eslint-disable-next-line
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();

	const onSuggestionsFetchRequested = (search) => {
		if (queryFunction && search.reason === "input-changed") {
			queryFunction(search.value);
		}
	};

	//on suggestion click
	const getSuggestionValue = (suggestion) => suggestion.name;

	//on suggestions clear
	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	//render suggestion
	const renderSuggestion = (suggestion) => (
		<Suggestion
			onClick={() => {
				if (handleClick) {
					handleClick(suggestion);
				} else {
					console.log("No click handler passed");
				}
			}}
		>
			{suggestion.name}
		</Suggestion>
	);

	const onChange = (event, { newValue }) => {
		setFieldValue(name, newValue);
	};

	const inputProps = {
		value: field.value,
		onChange: onChange,
	};

	return (
		<AutosuggestStyled className={className}>
			{label && (
				<LabelForm
					label={label}
					required={required}
				></LabelForm>
			)}
			<AutosuggestComponent
				suggestions={
					searchResults && Array.isArray(searchResults) ? searchResults : []
				}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={customInputProps ? customInputProps : inputProps}
				theme={autoSuggestTheme}
			/>
		</AutosuggestStyled>
	);
};
