import styled from "styled-components";

import { ButtonToggleStyled } from "../ButtonToggle/styled";
import { AccessRequestStyled } from "../AccessRequest";

export const DocumentGroupStyled = styled.div`
	margin-bottom: ${(props) => props.theme.space.sm_2};
	border-bottom: 1px solid ${(props) => props.theme.colors.grayBorder};
	padding: 0 0 ${(props) => props.theme.space.sm};

	.document-group {
		&__title {
			font-size: ${(props) => props.theme.fontSizes.sm};
			text-transform: uppercase;
			color: ${(props) => props.theme.colors.grayLight};
			font-weight: 700;
			margin: 0 0 ${(props) => props.theme.space.xs};
		}
	}
`;

export const DocumentContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.55rem;
	margin: 0 0 ${(props) => props.theme.space.xs};

	&:last-of-type {
		margin-bottom: 0;
	}

	${ButtonToggleStyled} {
		margin-left: auto;
	}

	${AccessRequestStyled} {
		margin-left: ${(props) => props.theme.space.xs};
	}
`;

export const DocumentStyled = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	color: ${(props) => props.theme.colors.grayDark};

	&::hover {
		color: ${(props) => props.theme.colors.royalBlue};
	}

	& > svg {
		width: 30px;
		height: 30px;
		margin-right: ${(props) => props.theme.space.xs};
	}

	.document {
		&__title {
			font-size: ${(props) => props.theme.fontSizes.md};
			color: inherit;
			font-weight: 500;
			margin: 0;
		}
	}
`;
