import apiV2 from "api-v2";

const getRFQServices = async (page, search, limit) => {
	if (search.trim() !== "") {
		return await apiV2(
			`/rfq/supplier-index?page=${page}&search=${search}&limit=${limit}`
		);
	} else {
		return await apiV2(`/rfq/supplier-index?page=${page}&limit=${limit}`);
	}
};

export { getRFQServices };
