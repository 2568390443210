import { useEffect, useRef } from "react";

export function useValueRef(newValue) {
	const ref = useRef(newValue);

	useEffect(() => {
		ref.current = newValue;
	}, [newValue]);

	return ref;
}
