import React from "react";
import { Divider } from "../Divider";
import { Heading } from "../Heading";
import { TabHeaderStyled } from "./styled";
import { Flex } from "@chakra-ui/core";
import { Skeleton } from "@chakra-ui/react";
import ReactTooltip from "react-tooltip";

export const TabHeader = ({
	heading,
	secondHeading,
	description,
	children,
	noDivider,
	icon,
	isLoading,
	color,
	tooltip,
}) => {
	return (
		<TabHeaderStyled>
			<Flex
				style={{ justifyContent: "space-between", gap: 8 }}
				align="center"
			>
				<div className="space-y-1">
					<div
						style={{
							display: "flex",
							gap: "12px",
							justifyItems: "center",
							alignItems: "center",
						}}
					>
						{icon}
						{!isLoading ? (
							<span>
								{color === undefined ? (
									<>
										<Heading fontSize={"3rem"}>{heading}</Heading>
										<Heading fontSize={"3rem"}>{secondHeading}</Heading>
									</>
								) : (
									<>
										<Heading fontSize={"3rem"}>
											<div style={{ color: color }}>{heading}</div>
										</Heading>
										<Heading fontSize={"3rem"}>
											<div style={{ color: color }}>{secondHeading}</div>
										</Heading>
									</>
								)}
							</span>
						) : (
							<Skeleton
								height="30px"
								width="240px"
								startColor="lightgray"
								className="mt-3"
							/>
						)}
					</div>
					{!isLoading
						? description && (
								<p
									data-tip
									data-for="tooltip"
									className={`text-base ${icon && "max-w-[100%] pl-16"} ${tooltip && "cursor-help"} [&>ol]:ml-5 [&>ol]:!list-decimal [&>ul]:ml-5 [&>ul]:!list-disc`}
									dangerouslySetInnerHTML={{ __html: description }}
								/>
							)
						: description && (
								<Skeleton
									height="20px"
									width="100%"
									startColor="lightgray"
									className={`${noDivider ? "mt-8" : "ml-[64px]"}`}
								/>
							)}
					{tooltip && (
						<ReactTooltip
							className="tooltip"
							id="tooltip"
							place="bottom"
							arrowColor="transparent"
						>
							<p
								className={`!text-white`}
								dangerouslySetInnerHTML={{ __html: tooltip }}
							/>
						</ReactTooltip>
					)}
				</div>
				{children}
			</Flex>

			{noDivider ? null : (
				<Divider
					top="21px"
					bottom="48px"
				/>
			)}
		</TabHeaderStyled>
	);
};
