import React from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { EditPlanStyled } from "./styled";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UpdateCardDetails } from "../UpdateCardDetail/UpdateCardDetails";
import {
	submitCompanyInformationForm,
	updateCardDetails,
} from "actions/companyActions";
import { TabHeader } from "../TabHeader";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export const UpdateCreditCardComponent = ({
	company,
	viewOnly,
	current_user,
	updateCardDetails,
	disableHeading,
	...props
}) => {
	const stripePromise = loadStripe(REACT_APP_STRIPE_API_KEY);

	return company ? (
		<EditPlanStyled>
			{!disableHeading && <TabHeader heading="Account Management" />}
			<Elements stripe={stripePromise}>
				<UpdateCardDetails
					user={current_user}
					handleEditDetails={updateCardDetails}
					disableHeading
				/>
			</Elements>
		</EditPlanStyled>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const current_user = state.auth.user;
	const account_types = state.accountTypes.accountTypeData;
	return {
		isRequesting: state.companyProfile.isRequesting,
		company: company,
		current_user: current_user,
		account_types: account_types,
	};
};

export const UpdateCreditCard = connect(mapStateToProps, {
	submitCompanyInformationForm,
	updateCardDetails,
})(UpdateCreditCardComponent);
