import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import { ContentContainer } from "../components/Layout";
import { ContextMenu } from "../components/ContextMenu";
import {
	getDocuments,
	getCompany,
	getPastProjects,
	getProcurementMethods,
	getResources,
} from "actions/companyActions";

//Routes
import { MyAccount } from "../routes/account/MyAccount";
import { Breadcrumbs } from "components/Breadcrumb";
import { Dashboard } from "pages/RFQ/Dashboard";
import Projects from "pages/RFQ/Projects/Projects";

import SupplierLists from "pages/RFQ/SupplierList/SupplierLists";
import { ProjectForm } from "pages/RFQ/Projects/ProjectForm";
import ProjectDetail from "pages/RFQ/Projects/ProjectDetail/ProjectDetail";
import SupplierForm from "pages/RFQ/SupplierList/SupplierForm";
import { SupplierDetail } from "pages/RFQ/SupplierList/SupplierDetail";
import { RFQ } from "pages/RFQ/RFQ";
import CreateRFQPage from "pages/RFQ/RFQ/CreateRFQ/CreateRFQPage/CreateRFQPage";
import { RFQDetail } from "pages/RFQ/RFQ/RFQDetail";
import { getTemplate } from "actions/RFQ/templateActions";
import { NotFound } from "./NotFound";
import { Search } from "routes/search/Search";
import AccountSettings from "./accountsettings/AccountSettings";
import CompanyProfileV2 from "./account/companyProfile/CompanyProfileV2";
import { AddResource } from "components/ResourceForm/AddResource";
import { EditResource } from "components/ResourceForm/EditResource";
import { AddProject } from "components/ProjectForm/AddProject";
import { EditProject } from "components/ProjectForm/EditProject";
import { DocumentRequest } from "components/DocumentRequest";
import { EditUserProfile } from "components/EditUserProfile";
import { UpdateCreditCard } from "components/CreditCardDetail/CreditCardDetail";
import { CreateUserForm } from "components/UserProfile";
import ViewRFQPage from "pages/RFQ/RFQ/ViewRFQ/ViewRFQPage";
import RFQSubmissionView from "components/Pages/RFQDetail/RFQSubmission/RFQSubmissionView";
import { Invoice } from "components/Invoice";
import { ProfileContext } from "context/ProfileContext";
import { CompanyListing } from "./CompanyListing";
import { EditUserAccount } from "components/EditUserAccount";

const AccountPageWrap = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	min-height: 100vh;
	${ContentContainer} {
		//future development
	}
`;

export const AccountComponent = ({
	getCompany,
	getPastProjects,
	getResources,
	getDocuments,
	companyId,
	getCompanySizeOptions,
	getTemplate,
	getProcurementMethods,
	...props
}) => {
	const profileContext = useContext(ProfileContext);
	const isLoading = useSelector((state) => state?.auth?.loading);
	const { current_company, user } = useSelector((state) => state.auth);

	useEffect(() => {
		//Set this company ID from the route variable - this allows anyone to access/edit all companies
		//When auth system is implemented, the getCompany action will be dispatched during login action,
		//and this data will be ready in the store

		const _companyId = user?.is_admin ? companyId : current_company?.id;

		if (_companyId) {
			getCompany(_companyId);
			getPastProjects(_companyId);
			getResources(_companyId);
			getTemplate();
			getProcurementMethods();
			getDocuments(_companyId);
		}
	}, [
		user,
		current_company,
		getCompany,
		getPastProjects,
		getDocuments,
		getResources,
		getTemplate,
		companyId,
		getProcurementMethods,
	]);

	if (isLoading) {
		return (
			<div className="flex-grow p-[50px]">
				<Skeleton
					duration={0.5}
					count={10}
					width="100%"
				/>
			</div>
		);
	}

	return (
		<AccountPageWrap>
			<ContextMenu />
			<ContentContainer
				id="element-scroll"
				noPadding={window?.location?.href.includes("search")}
			>
				<Breadcrumbs ignoreCompany={false} />

				<Router primary={false}>
					<NotFound default />
					<MyAccount path="my-account" />

					{/* START OF ACCOUNT SETTING MENU */}
					<AccountSettings
						path="account-settings"
						company={current_company}
					/>
					<EditUserAccount path="edit-user-account" />
					<Invoice
						path="account-settings/invoice"
						company={current_company}
					/>
					<CreateUserForm path="account-settings/create-user" />
					<UpdateCreditCard path="account-settings/update-card-details" />
					{/* END OF ACCOUNT SETTING MENU */}

					{/* START OF COMPANIES LISTING */}
					<CompanyListing path="companies" />
					<CompanyListing
						path="accounts/*"
						admin
					/>
					{/* END OF COMPANIES LISTING */}

					{/* START OF COMPANY PROFILE MENU */}
					<CompanyProfileV2
						path="company-profile/:activeTab"
						company={current_company}
					/>

					<EditUserProfile
						path="company-profile/edit-user-profile"
						company={"My Profile"}
					/>

					<AddResource path="company-profile/:activeTab/new-resource" />
					{current_company && (
						<EditResource path="company-profile/:activeTab/edit-resource/:resourceId" />
					)}
					<AddProject
						path="company-profile/:activeTab/create-project"
						profileTypeId={current_company?.profile_type?.id}
					/>
					<EditProject
						path="company-profile/:activeTab/edit-project/:projectId"
						profileTypeId={current_company?.company?.profile_type?.id}
					/>

					{/* END OF COMPANY PROFILE MENU */}

					{/* RFx (RFx and RFQ are same) */}
					{profileContext?.isRfqFeatureEnabled && (
						<>
							<Dashboard path="dashboard/*" />

							<Projects path="projects" />
							<ProjectForm
								path="projects/new-project"
								newProject
							/>
							<ProjectForm
								path="projects/new-project/suppliers-list/:supplierListId"
								newProjectFromSupplierList
							/>
							<ProjectForm
								path="projects/edit-project/:projectId"
								editProject
							/>
							<ProjectDetail path="projects/:id/:slug" />

							<SupplierDetail path="supplier-list/:id/:slug" />
							<SupplierLists path="supplier-list" />
							<SupplierForm
								path="supplier-list/new-supplier-list"
								isNew
							/>
							<SupplierForm
								path="/supplier-list/edit-supplier-list/:listId"
								isEdit
							/>

							<RFQ path="request-for-quote/*" />
							<CreateRFQPage path="request-for-quote/create-rfx/:rfqId" />
							<CreateRFQPage
								path="request-for-quote/edit-rfx/:rfqId"
								isEdit
							/>
							<RFQDetail path="request-for-quote/:rfqId/:currentTab/:slug" />
							<ViewRFQPage path="request-for-quote/view-rfx/:rfqId" />
							<RFQSubmissionView path="request-for-quote/:rfxUid/submission/:companyUid" />
							<RFQSubmissionView
								path="request-for-quote/:rfxUid/guest/submission/:companyUid"
								isGuest
							/>
						</>
					)}
					{/* END OF RFx */}

					<Search
						path="search/*"
						title="Search"
					/>
					<DocumentRequest path="company-profile/:activeTab/request-view" />
				</Router>
			</ContentContainer>
		</AccountPageWrap>
	);
};

export const Account = connect(null, {
	getCompany,
	getDocuments,
	getPastProjects,
	getResources,
	getTemplate,
	getProcurementMethods,
})(AccountComponent);
