import React from "react";
import styled, { css } from "styled-components";
import { Link, Match } from "@reach/router";

import { IconBox } from "../IconBox";
import { theme } from "../../theme";

export const GlobalNavStyled = styled.div`
	background-color: ${theme.colors.royalBlue};
	color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-grow: 0;
	position: fixed;
	left: 0;
	padding: 10px;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
	z-index: 100;
	hr {
		margin-left: -10px;
		margin-right: -10px;
		border-color: #e9ecf1;
	}
	${theme.mediaQueries.xxl} {
		padding: 12px;
		hr {
			margin-left: -12px;
			margin-right: -12px;
			border-color: #e9ecf1;
		}
	}

	> img {
		width: 40px;
		height: 40px;
		align-self: center;
		border-radius: ${(props) => props.theme.radii.default};
	}

	svg {
		path {
			stroke: white;
		}
	}
`;

export const GlobalNavLink = styled(
	({ to, icon, children, className, label, logo, dataFor }) => {
		return (
			<Match path={`${to}/*`}>
				{(props) => {
					return (
						<Link
							id={label}
							to={to}
							className={className}
							data-tip
							data-for={dataFor}
						>
							{children}
							<IconBox
								logo={logo}
								icon={icon}
								className={`${
									props.match && props.match.uri !== "/" && "is-active"
								}`}
							/>
							<label
								style={{
									color: "white",
									fontSize: "12px",
									margin: "8px 0px 0px 4px",
								}}
							>
								{label}
							</label>
						</Link>
					);
				}}
			</Match>
		);
	}
)``;

export const GlobalNavClient = styled.div`
	min-height: 100vh;
	background-color: #003ce9;
	width: 12px;
	position: fixed;
`;

const buttonStyles = css`
	margin-bottom: 0.7rem;
	opacity: 0.85;
	margin-bottom: 40px;
	&::hover {
		opacity: 1;
	}
`;

export const IconButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	> a {
		${buttonStyles}
	}
	${(props) =>
		props.admin &&
		`
        margin-top: auto;
        > a {
            margin-bottom: 0px;
        }
    `}
`;

export const ProfileButton = styled.button`
	background: transparent;
	border: none;
	${buttonStyles}
	margin-bottom: 0px;
	svg {
		width: 50px;
		height: 50px;
	}
`;

export const CompanyLogoImg = styled.img`
	height: auto;
	max-width: 220px;
`;

export const PopUp = styled.div`
	width: 270px;
	box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background: white;
	position: absolute;
	bottom: 80px;
	z-index: 1;
	.header {
		text-align: center;
		border-bottom: 1px solid #e9ecf1;
		padding: 24px;
		.title {
			font-size: 15px;
			color: black;
			font-weight: 700;
			max-width: 124px;
			margin: 16px auto;
		}
		.tag {
			background-color: ${(props) => props.theme.colors.mint};
			color: ${(props) => props.theme.colors.navy};
			padding: 8px 16px;
			border-radius: 20px;
			display: inline-block;
		}
	}
	.body {
		padding: 24px;
		li {
			color: #273444;
			font-size: 14px;
			list-style: none;
			margin-bottom: 24px;
			a,
			button {
				color: inherit;
				display: flex;
				align-items: center;
				background-color: transparent;
				cursor: pointer;
				border: none;
				padding: 0px;
				svg {
					margin-right: 12px;
					width: 32px;
					path {
						stroke: #273444;
					}
				}
			}

			&:last-of-type {
				margin-bottom: 0px;
			}
		}
	}
`;
