import { Spinner } from "@chakra-ui/react";
import {
	postAddendumAnswer,
	postAddendumQuestion,
} from "actions/RFQ/addendumAction";
import { Icon } from "assets/icons/Icon";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Avatar } from "components/RFQ/Avatar";
import { Button } from "components/RFQ/Button";
import { Checkbox } from "components/RFQ/Checkbox";
import { FileUpload } from "components/RFQ/FileUpload";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { RFQContext } from "context/RFQContext";
import { Form, Formik } from "formik";
import useRefreshWarning from "hooks/useRefreshWarning";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { cn } from "utils/helpers";
import { AcceptedFileTypesForRFx } from "utils/rfxLargeFileUploadHelper";

const AddendumMessage = ({
	nested = false,
	logo,
	rfqId,
	setAddendumData,
	questionId,
	indexList,
	...props
}) => {
	const [published, setPublished] = React.useState(nested ? false : true);
	const [isSending, setIsSending] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const company = useSelector((state) => state.auth?.current_company);
	const [hasAttachment, setHasAttachment] = useState(false);
	const setErrors = () => {};
	const onMaxCharacter = () => {};
	const { uploadInProgresses } = useContext(RFQContext);
	useRefreshWarning(uploadInProgresses?.rfxProcurer?.length > 0);

	const handleAddQuestion = async (values, resetForm) => {
		setIsSending(true);
		postAddendumQuestion({
			rfq_id: rfqId,
			addendum: values.input,
			attachments: values.attachments,
		}).then((res) => {
			if (res) {
				setAddendumData((prev) => {
					let newAddendum = [...prev];
					if (
						newAddendum.length < 1 ||
						moment(newAddendum[0]?.date).diff(moment({ hours: 0 }), "days") < 0
					) {
						newAddendum.unshift({ date: moment().format(), question: [res] });
					} else {
						newAddendum[0].question.unshift(res);
					}
					return newAddendum;
				});
				resetForm();
			}
		});
		setHasAttachment(false);
		setIsSending(false);
		setIsOpen(false);
	};

	const handleAddAnswer = async (values, resetForm) => {
		const data = {
			rfq_id: rfqId,
			addendum: values.input,
			attachments: values.attachments,
			published: published ? 1 : 0,
			rfq_addendum_question_id: questionId,
		};
		setIsSending(true);
		postAddendumAnswer(data).then((res) => {
			if (res) {
				setAddendumData((prev) => {
					let newAddendum = [...prev];
					if (published) {
						newAddendum.forEach((addendum, i) => {
							addendum.question = addendum.question.filter(
								(question) => question.id !== questionId
							);
						});
					} else {
						newAddendum[indexList.addendum].question[
							indexList.question
						].published = published;
						newAddendum[indexList.addendum]?.question[
							indexList.question
						]?.answer.unshift(res);
					}

					return newAddendum;
				});
				resetForm();

				props?.handleClose && props.handleClose();
			}
		});
		setHasAttachment(false);
		setIsSending(false);
		setIsOpen(false);
	};

	const handleSubmitForm = async (values, { resetForm }) => {
		if (nested) {
			handleAddAnswer(values, resetForm);
		} else {
			handleAddQuestion(values, resetForm);
		}
	};

	const dialogProperty = {
		icon: "blue-circle-check",
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: `Send ${nested ? "Answer" : "Addendum"}`,
		description: nested
			? "You’re about to respond to a Suppliers query. Please review your details carefully before sending.  "
			: `You're about to submit your addendum
    . Please review your details carefully to ensure all your information is accurate and complete. Once confirmed, we will begin processing your request immediately.`,
	};

	return (
		<div
			className={cn("items-start pb-1", nested && "pl-10 pr-10")}
			{...props}
		>
			<div className="flex items-start justify-start gap-3">
				<Avatar
					avatar={company?.logo_path}
					noHover
				/>
				<div style={{ width: "100%" }}>
					<Formik
						initialValues={{ input: "", attachments: [] }}
						onSubmit={handleSubmitForm}
						style={{ width: "100%" }}
					>
						{({ values, isValid, submitForm }) => {
							const FooterModal = () => {
								return (
									<div className="flex w-full justify-between gap-2 text-utility-error-50">
										<Button
											onClick={() => submitForm()}
											className="!w-full"
											variant="primary"
										>
											{isSending ? <Spinner /> : "Yes, Send"}
										</Button>
										<Button
											type="button"
											onClick={() => setIsOpen(false)}
											className="!w-full"
											btntype="base"
											disabled={isSending}
										>
											Cancel
										</Button>
									</div>
								);
							};
							return (
								<Form>
									<div className="relative">
										<div className="relative">
											<FormatedInput
												label=""
												name="input"
												type="text"
												placeholder={nested ? "Add a reply" : "Add an addendum"}
												characterLimit={1500}
												className="col xl-12"
												background="white"
												setErrors={setErrors}
												onMaxCharacter={onMaxCharacter}
												guideLine={
													"Tell us about this project and your company's role"
												}
												setFieldError={() => {}}
											/>

											<div className="absolute bottom-0 left-0 right-0 mx-2 mb-1 bg-white p-3 pt-0">
												<div className="flex items-center justify-between">
													<button
														type="button"
														className="pointer"
														onClick={() => setHasAttachment(!hasAttachment)}
													>
														<Icon
															icon="paperclip"
															style={{ height: 18, width: 18 }}
														/>
													</button>

													<div className="ml-auto flex items-center justify-end">
														{nested && (
															<div className="flex items-center gap-4">
																<Checkbox
																	label={
																		"Publish this addendum and notify every participant"
																	}
																	onChange={(e) =>
																		e.target.checked
																			? setPublished(true)
																			: setPublished(false)
																	}
																	checked={published}
																	className="text-sm"
																/>
																<div className="flex items-center gap-1">
																	<Icon
																		icon={published ? "eye-on" : "eye-off"}
																	/>
																	<div className="mr-8 font-roboto text-[12px] text-tertiary-600">
																		{!nested
																			? "This comment will be published and all suppliers will be notified"
																			: published
																				? "This question and reply will be published and all suppliers notified"
																				: "This Query and Answer remains private unless published."}
																	</div>
																</div>
															</div>
														)}
														<Button
															type="button"
															btntype="primary"
															className="!h-10 !rounded-lg"
															onClick={() => setIsOpen(true)}
															disabled={
																values?.input?.trim() === "" ||
																!isValid ||
																uploadInProgresses?.rfxProcurer?.length > 0
															}
														>
															<p className="font-roboto font-semibold">Send</p>
														</Button>
													</div>
												</div>
											</div>
										</div>
										{hasAttachment && (
											<div>
												<FileUpload
													name="attachments"
													fileExtraData={{
														model: "addendum",
													}}
													types={AcceptedFileTypesForRFx}
													isProcurerRFx
												/>
											</div>
										)}
									</div>
									<CustomModal
										header={dialogProperty}
										isOpen={isOpen}
										onClose={() => setIsOpen(false)}
										footerComponent={<FooterModal />}
									/>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default AddendumMessage;
