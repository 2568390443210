import React from "react";
import { Heading } from "../Heading";
import { navigate } from "@reach/router";
import { SignUpWelcomeSection } from "./styled";
import { Button } from "../Button";

export const SignUpWelcome = ({ ...props }) => {
	const handleOnClick = () => {
		localStorage.removeItem("signup_data");
		localStorage.removeItem("signup_step");
		navigate("/login", { replace: true });
	};
	return (
		<SignUpWelcomeSection>
			<Heading marginBottom="40px">
				<span>Registration Completed Successfully</span>
			</Heading>
			<p className="instructions mb-0">
				Thank you for signing up.
				<br /> We will review your account details within 24 hours. Once
				complete, we will send you an email so that you can verify your account.
			</p>
			<Button
				type="button"
				onClick={handleOnClick}
			>
				Return to Sign In
			</Button>
		</SignUpWelcomeSection>
	);
};
