import { Box, IconButton } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Avatar } from "components/RFQ/Avatar";
import { ProfileContext } from "context/ProfileContext";
import React, { useContext } from "react";
import { ReactComponent as ActiveProfileCheck } from "./../../icons/proflie-check.svg";
import { connect } from "react-redux";
export const SidebarHeader = ({ name, logo, isCollapseNav, companyLogo }) => {
	const { changeProfileType, currentProfile, isAccountSwitchable } =
		useContext(ProfileContext);

	const handleSwitchProfile = () => {
		const role = currentProfile === "Procurer" ? "supplier" : "client";
		const profileType = currentProfile === "Procurer" ? "supplier" : "client";
		changeProfileType(role, profileType);
	};

	const displayLogo = companyLogo || logo;

	return (
		<div
			style={{
				display: "flex",
				gap: 16,
				alignItems: "center",
				justifyContent: "start",
				margin: isCollapseNav ? "40px 0px 20px 0px" : "40px 8px 20px 12px",
			}}
		>
			<div>
				<Avatar
					avatar={displayLogo}
					alt={name}
					className="!p-0"
					noHover
				/>
			</div>
			<div
				id="profile"
				className="overflow-hidden text-ellipsis"
			>
				<h2 className="max-w-xs overflow-hidden truncate text-ellipsis font-roboto text-[30px] font-bold capitalize text-[#101828]">
					{name}
				</h2>
				<div className="flex items-center gap-2">
					<p className="uppercase text-[#0031DD]">{currentProfile}</p>
					<ActiveProfileCheck />
				</div>
			</div>
			{isAccountSwitchable && (
				<Box>
					<IconButton
						onClick={handleSwitchProfile}
						icon={<Icon icon="refresh" />}
					/>
				</Box>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		companyLogo: state.companyProfile.logo_path,
	};
};

export default connect(mapStateToProps)(SidebarHeader);
