import React from "react";
import { connect } from "react-redux";

import { MultiSelect } from "../MultiSelect";

const ProcurementMethodComponent = ({
	name,
	setFieldValue,
	values,
	defaultValue,
	methods,
	guideLine,
	...props
}) => {
	let optionsArray = [];

	if (methods.length) {
		optionsArray = methods.map((option) => ({
			value: option.id,
			label: option.name,
		}));
	}

	//use this var to create proper label/value obj
	let valueObj = [];

	//pick out the initial values from the options array based on method id's from form
	if (Array.isArray(values[name])) {
		valueObj = values[name].map((id) => {
			return optionsArray.find((option) => {
				return option.value === id;
			});
		});
	}

	return (
		<MultiSelect
			closeMenuOnSelect={false}
			label="Procurement Model"
			options={optionsArray}
			name={name}
			value={valueObj}
			guideLine={guideLine}
			isGuideLineLink
			className={props.className}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		methods: state.companyProfile.procurementMethods,
	};
};

export const ProcurementMethod = connect(
	mapStateToProps,
	{}
)(ProcurementMethodComponent);
