import React from "react";

import { LoginWrapper, LoginSection } from "./styled";
import { Heading } from "../Heading";
import { navigate } from "@reach/router";
import { Button } from "components/Button";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const GuestSignUpWelcome = ({ ...props }) => {
	const handleOnClick = () => {
		navigate("/guest/login", { replace: true });
	};
	return (
		<LoginWrapper>
			<LogoWrapper />

			<LoginSection>
				<Heading marginBottom="40px">
					<span>Thank You</span>
				</Heading>
				<h6 className="mb-5">
					You have successfully registered a Guest User account on Procuracon.
					You can now sign in as a Guest User. An email has also been sent to
					your registered email address.
				</h6>
				<Button
					type="button"
					className="text-center"
					onClick={handleOnClick}
				>
					Click to Sign In
				</Button>
			</LoginSection>
		</LoginWrapper>
	);
};
