import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getDocumentAccessRequests } from "../../actions/companyActions";
import { DocumentRequestStyled, InfoRow, RequestSection } from "./styled";
import { navigate } from "@reach/router";
import { Button } from "../Button";
import { ReactComponent as DocumentIcon } from "./../../icons/document.svg";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { Divider } from "../Divider";
import { BackButton } from "../BackButton/BackButton";
import { ViewRequestModal } from "./ViewRequestModal";
import { Badge } from "../Badge";

export const DocumentRequestComponent = ({
	company,
	viewOnly,
	current_user,
	getDocumentAccessRequests,
	...props
}) => {
	useEffect(() => {
		getDocumentAccessRequests(company.id);
	}, [getDocumentAccessRequests, company]);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [accessRequest, setAccessRequest] = useState("");

	const openViewRequestModal = (access_request) => {
		setAccessRequest(access_request);
		setModalIsOpen(true);
	};

	const closeViewRequestModal = () => {
		setModalIsOpen(false);
	};
	return company ? (
		<>
			<DocumentRequestStyled>
				<TabHeader heading="Document Requests" />
				<TabContent>
					<div className="reset-arrow">
						<BackButton
							label={"BACK TO CORPORATE DOCUMENTS"}
							fontSize={"14px"}
							fontWeight={"700"}
							marginBottom={"-3px"}
							handleOnClick={() => {
								navigate("../documents");
							}}
						/>
					</div>
					<RequestSection>
						<div className="grid grid-cols-12">
							<div className="col-span-4">
								<span>Document Name</span>
							</div>
							<div className="col-span-3">
								<span>Business Name</span>
							</div>
							<div className="col-span-3">
								<span>Date</span>
							</div>
							<div className="col-span-2">
								<span>Status</span>
							</div>
						</div>

						{props.access_requests.map((access_request, idx) => {
							return (
								<InfoRow
									key={idx}
									className="!grid !grid-cols-12"
								>
									<div className="col-span-4">
										<DocumentIcon />
										{access_request?.document?.name}
									</div>
									<div className="col-span-3">
										{access_request.client_company?.name}
									</div>
									<div className="col-span-3">
										{access_request?.request_date?.substr(0, 10)}
									</div>
									{access_request.is_viewed ? (
										<div className="col-span-2">
											<Badge
												text={
													parseInt(access_request.is_approved)
														? "Approved"
														: "Rejected "
												}
												textColor={
													parseInt(access_request.is_approved)
														? "green"
														: "red "
												}
											/>
										</div>
									) : (
										<div className="col md-2 data-cell last">
											<Button
												size={"xs"}
												btntype={"secondary"}
												width={"103px"}
												height={"35px"}
												textfont={"14px"}
												boxshadow={false}
												radius={"20px"}
												onClick={() => openViewRequestModal(access_request)}
											>
												<span>View</span>
											</Button>
										</div>
									)}
								</InfoRow>
							);
						})}
					</RequestSection>
					<Divider />
				</TabContent>
			</DocumentRequestStyled>
			<>
				{modalIsOpen && (
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeViewRequestModal}
						width="650"
					>
						<ViewRequestModal
							accessRequest={accessRequest}
							onSuccessfulSubmit={closeViewRequestModal}
						/>
					</Modal>
				)}
			</>
		</>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const current_user = state.auth.user;
	const access_requests = state.companyProfile.document_access_requests;
	return {
		company: company,
		current_user: current_user,
		access_requests: access_requests,
	};
};

export const DocumentRequest = connect(mapStateToProps, {
	getDocumentAccessRequests,
})(DocumentRequestComponent);
