import React from "react";
import { useField } from "formik";
import { styled, css } from "twin.macro";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/core";
import { Icon } from "assets/icons/Icon";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";
export const RFQSupplierInviteFormStyled = styled.div(() => [
	css`
		.img-card {
			object-fit: cover;
			width: 100%;
		}
		.rfq-supplier-form-wrapper {
			margin-bottom: 20px;
		}
		.rfq-form-sub-title {
			font-family: Roboto;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
		}
		.rfq-participant-guest-caption {
			font-family: Roboto;
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 16px;
		}
		.input-wrapper {
			display: flex;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	`,
]);

const InviteForm = () => {
	const fieldname = (index, name) =>
		index !== undefined ? `guest[${index}]${name}` : `guest`;
	const [, { value: guestLists }, { setValue: setGuestLists }] =
		useField(fieldname());

	const addInviteGuestFunc = () => {
		setGuestLists([...guestLists, { name: "", email: "" }]);
	};
	const deleteInviteGuestFunc = (index) => {
		setGuestLists(guestLists.filter((_, i) => i !== index));
	};

	return (
		<RFQSupplierInviteFormStyled>
			<div className="rfq-supplier-form-wrapper">
				<h3 className="rfq-form-sub-title">Invite guests</h3>
				<p className="rfq-participant-guest-caption">
					Invite others to respond to this request. Simply enter their email
					address below and they will receive a link to this RFx. Don't forget
					to check and verify that their email address is correct.
				</p>
				{guestLists.map((invite, index) => {
					return (
						<div className="input-wrapper group relative flex items-start pr-8">
							<div
								style={{ flex: 1 }}
								className="mr-3"
							>
								<FieldForm
									type="text"
									placeholder="Name"
									icon="user3"
									name={fieldname(index, "name")}
								/>
							</div>
							<div
								style={{ flex: 1 }}
								className="ml-3"
							>
								<FieldForm
									type="text"
									placeholder="you@example.com"
									icon="envelope"
									name={fieldname(index, "email")}
								/>
							</div>
							<div
								className={cn(
									"absolute -right-6 top-4 hidden h-max w-max cursor-pointer rounded-full bg-brand-secondary p-2 group-hover:flex"
								)}
								onClick={() => deleteInviteGuestFunc(index)}
							>
								<Icon
									icon="close"
									style={{ width: "14px", height: "14px", color: "#003CE9" }}
								/>
							</div>
						</div>
					);
				})}
				<Button
					btntype="secondary"
					type={"button"}
					className="!w-max"
					onClick={addInviteGuestFunc}
				>
					<Flex
						gap={2}
						className="items-center"
					>
						<Icon icon="plus-blue" />
						{guestLists?.length > 0 ? "Add another" : "Add guest"}
					</Flex>
				</Button>
			</div>
		</RFQSupplierInviteFormStyled>
	);
};

export default InviteForm;
