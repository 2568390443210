import {
	AnswerComp,
	AskComp,
	SplitComp,
} from "components/RFQ/AddendumComponent";
import React, { useMemo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const AddendumConvo = ({
	addendumData,
	setAddendumData,
	rfqDetail,
	isCommunication,
}) => {
	const displayNameCompany = (lead, companyName) =>
		`${lead || "-"}  ${companyName ? `| ${companyName}` : ""}`;
	const { user } = useSelector((state) => state.auth);
	const isUser = (name) => user?.full_name === name;

	const dateRenderer = (date) => {
		const messageTime = moment(date);
		if (messageTime.isSame(new Date(), "day")) return "Today";
		return messageTime.from(new Date().setHours(0, 0, 0, 0));
	};

	const publishQuestionHandler = (questionId, published) => {
		setAddendumData((prev) => {
			let newAddendum = [...prev];
			newAddendum.forEach((addendum, i) => {
				addendum.question = addendum.question.filter(
					(question) => question.id !== questionId
				);
			});
			return newAddendum;
		});
	};

	const deleteAnswerHandler = (questionId) => {
		setAddendumData((prev) => {
			let newAddendum = [...prev];
			newAddendum.forEach((addendum, i) => {
				addendum.question = addendum.question.filter(
					(question) => question.id !== questionId
				);
			});
			return newAddendum;
		});
	};

	const RenderAddendum = useMemo(() => {
		return addendumData.map((data, index) => {
			const date = dateRenderer(data.date);
			const question = data.question;
			return (
				<div
					key={index}
					className="space-y-6"
				>
					<SplitComp label={date} />
					{question?.map((message, qIndex) => {
						return (
							<div
								className="px-8"
								key={qIndex}
							>
								{/* Published Addendum */}
								{message?.isStatement ? (
									<AnswerComp
										key={qIndex}
										message={message.addendum}
										attachments={message?.attachments}
										displayName={displayNameCompany(
											message.user?.name,
											message.company?.name
										)}
										profileTypeId={1}
										isUser={isUser(message.user?.name)}
										companyLogo={message.user?.logo_path}
										status={message.published ? "public" : "private"}
										time={message.createdAt}
										rfqId={rfqDetail?.id}
										answerId={message.id}
										deleteAnswerHandler={deleteAnswerHandler}
										isCommunication={isCommunication}
									/>
								) : (
									// Question from suppliers
									<AskComp
										message={message.addendum}
										attachments={message.attachments}
										displayName={displayNameCompany(
											message.user?.name,
											message.company?.name
										)}
										isUser={isUser(message.user?.name)}
										companyLogo={message.user?.logo_path}
										status={message.published ? "public" : "private"}
										time={message.createdAt}
										rfqId={rfqDetail?.id}
										questionId={message.id}
										indexList={{ question: qIndex, addendum: index }}
										setAddendumData={setAddendumData}
										emptyAnswer={message.answer.length === 0}
										isCommunication={isCommunication}
									/>
								)}

								{message.answer?.map((answer, i) => {
									return (
										// Answer from client
										<AnswerComp
											key={i}
											message={answer.addendum}
											attachments={answer.attachments}
											displayName={displayNameCompany(
												answer.user?.name,
												answer.company?.name
											)}
											profileTypeId={1}
											isUser={isUser(answer.user?.name)}
											companyLogo={answer.user?.logo_path}
											status={answer.published ? "public" : "private"}
											nested
											time={answer.createdAt}
											rfqId={rfqDetail?.id}
											answerId={answer.id}
											questionId={message.id}
											publishQuestionHandler={publishQuestionHandler}
											deleteAnswerHandler={deleteAnswerHandler}
											isCommunication={isCommunication}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			);
		});
		//eslint-disable-next-line
	}, [addendumData]);

	return <div className="relative w-full">{RenderAddendum}</div>;
};

export default AddendumConvo;
