import * as constants from "../actions/actionTypes";

const INITIAL_GP_STATE = {
	data: [],
};

export const globalPresenceReducer = (state = INITIAL_GP_STATE, action) => {
	switch (action.type) {
		case constants.GET_GLOBAL_PRESENCE:
			return {
				...state,
				data: action.payload,
			};
		default:
			return state;
	}
};
