import React from "react";
import { ProjectImage, ProjectCount } from "./styled";

export const FeatureProject = ({ title, image, count, ...props }) => {
	return (
		<ProjectImage>
			{image && (
				<img
					src={image}
					alt={`${title} project`}
				/>
			)}
			{title && <p>{title}</p>}

			{count > 0 && <ProjectCount>+{count}</ProjectCount>}
		</ProjectImage>
	);
};

FeatureProject.defaultProps = {
	title: "",
	image: "",
	count: 0,
};
