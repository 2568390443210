import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";

import { theme } from "../../theme";

export const TabNavStyled = styled.div`
	display: flex;
	flex-wrap: nowrap;
`;

export const TabsStyled = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.space.sm};
	position: sticky;
	top: 0;
	background-color: ${(props) =>
		props.bg ? props.bg : props.theme.colors.snow};
	z-index: 10;
`;

export const Tab = styled((props) => {
	const isCurrent = ({ isPartiallyCurrent }) => {
		let url = new URL(window.location.href);
		if (
			isPartiallyCurrent ||
			url.pathname.includes(props?.title?.toLowerCase())
		) {
			props.setActiveState(props.currentTab);
			return {
				style: {
					borderBottom: "none",
					opacity: 1,
					color: theme.colors.white,
					background: theme.colors.royalBlue,
				},
			};
		}
	};

	return (
		<Link
			getProps={isCurrent}
			{...props}
			aria-disabled={true}
		>
			{props.children}
		</Link>
	);
})`
	padding: ${(props) => props.theme.space.xxs}
		${(props) => props.theme.space.xs};
	color: ${(props) => props.theme.colors.royalBlue};
	font-weight: 700;
	opacity: ${(props) => (props.disabled ? "0.5" : "1")};
	pointer-events: ${(props) => props.disabled && "none"};
	filter: ${(props) => props.disabled && "grayscale(100%)"};
	margin-right: ${(props) => props.theme.space.xs};
	background: #f7f8f9;
	width: max-content;
	height: 48px;
	border-bottom: 1px solid ${(props) => props.theme.colors.snowDark};
	border-radius: 5px 5px 0px 0px;
	color: ${(props) => props.theme.colors.royalBlue};
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;

	&:last-of-type {
		margin-right: 0;
	}

	.counter {
		background: ${(props) =>
			props.isActive ? props.theme.colors.white : props.theme.colors.royalBlue};
		color: ${(props) =>
			props.isActive ? props.theme.colors.royalBlue : props.theme.colors.white};
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
`;
