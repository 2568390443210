import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { connect } from "react-redux";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { BackButton } from "../BackButton/BackButton";
import { SignupSection } from "./styled";
import { FlashError } from "../FormInputs/FlashError";
import { getCompanies } from "../../actions/companyActions";
import { saveBusinessDetails } from "../../actions/authActions";
import { formatABN, isValidAbnOrAcn } from "../../utils/helpers";

export const SignUpBusinessDetailsComponent = ({ getCompanies, ...props }) => {
	useEffect(() => {
		getCompanies();
	}, [getCompanies]);
	const company_id = props.user.company_id ? props.user.company_id : null;
	const user_id = props.user.id ? props.user.id : null;
	const handleSubmit = async (values, setSubmitting, setStatus) => {
		setSubmitting(true);

		try {
			const result = await props.saveBusinessDetails(
				user_id,
				company_id,
				values.business_name,
				values.phone_no,
				values.abn,
				setSubmitting
			);
			if (result.success) {
				props.next(values);
			} else {
				if (result.message === "This abn already exists.") {
					setStatus("abn_unique");
				} else if (result === 404) {
					setStatus("not_found");
				} else {
					setStatus("unique");
				}
				setSubmitting(false);
			}
		} catch (e) {
			const error = await e;
			console.log(error.message);
		}
	};

	const tooltips = {
		business_name:
			"Please check your 'Business Name'. It should be 1-254 characters long, can include letters, numbers, spaces, hyphens, ampersand, or apostrophes, and shouldn't start or end with a space, hyphens, and ampersand. Need help? Email us at support@procuracon.com.au",
		abn: "The ABN/ACN number must conform to the standard Australian Business Number format. It should be 11-digit ABN or 9-digit ACN number. If you continue to experience difficulties with registration, please email us at support@procuracon.com.au for further assistance",
		phone:
			"Please check your 'Phone Number'. It should only contain numbers (0-9) and optionally spaces. If you need assistance, email us at support@procuracon.com.au",
	};

	return (
		<SignupSection>
			<div className="reset-arrow">
				<BackButton
					label={"Back"}
					handleOnClick={(values) => props.prev(values)}
				/>
			</div>
			<Heading marginBottom="16px">
				<span>ADD BUSINESS DETAILS</span>
			</Heading>
			<p className="instructions">
				Let us know your business details. We'll use this to verify your
				business on the Australian Business Register.
			</p>

			<Formik
				enableReinitialize
				initialValues={props.data}
				validationSchema={Yup.object({
					business_name: Yup.string()
						.trim()
						.matches(
							/^[a-zA-Z0-9\s']+(?:[-\s&][a-zA-Z0-9\s']+)*$/,
							"Business name is not valid"
						)
						.min(1, "Business name should be at least 1 character")
						.max(80, "Business name should not exceed 80 characters")
						.required("This is a required field"),
					abn: Yup.string()
						.required("This is a required field")
						.test(
							"test abn/acn",
							"Your ABN/ACN number is invalid",
							(value) => value && isValidAbnOrAcn(value)
						),
					phone_no: Yup.string()
						.required("This is a required field")
						.matches(
							/^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
							"Invalid Phone No."
						),
				})}
				onSubmit={(values, { setSubmitting, setStatus }) => {
					setStatus(false);
					handleSubmit(values, setSubmitting, setStatus);
				}}
			>
				{(props) => {
					const { setFieldValue, setStatus, isSubmitting, status, values } =
						props;
					const onSubmitValidationError = () => {
						setStatus("required");
						if (values.abn && !isValidAbnOrAcn(values.abn)) setStatus("abn");
					};
					return (
						<Form>
							<div className="row">
								<TextInput
									label="COMPANY NAME"
									required
									placeholder="Company Name"
									name="business_name"
									type="text"
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
									guideLine={tooltips.business_name}
									maxLength={80}
								/>
								<TextInput
									label="ABN/ACN"
									required
									placeholder="Your ABN/ACN"
									name="abn"
									type="text"
									className="col xl-12 text-mr"
									formatValue={formatABN}
									setFieldValue={setFieldValue}
									guideLine={tooltips.abn}
								/>
								<TextInput
									placeholder="Please enter your office phone number"
									label="BUSINESS PHONE"
									required
									name="phone_no"
									type="tel"
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
									guideLine={tooltips.phone}
								/>

								<div className="col xl-12">
									{status && (
										<FlashError
											heading={errorMessages[status].heading}
											text={errorMessages[status].description}
											margin="10px 0px 40px 0px"
										/>
									)}
								</div>
								<div className="col xl-12">
									<Submit
										type="submit"
										widthExpand
										marginTop={"32px"}
										isSubmitting={isSubmitting}
										text="Continue"
										submittingText="Sending..."
									/>
								</div>
							</div>
							<OnSubmitValidationError callback={onSubmitValidationError} />
						</Form>
					);
				}}
			</Formik>
		</SignupSection>
	);
};

const errorMessages = {
	abn: {
		heading: "ABN Verification Unsuccesful",
		description: "Please check your ABN numbers are correct.",
	},
	abn_unique: {
		heading: "ABN Duplication",
		description:
			"The ABN/ACN number you provided is already registered in our system. If this number is correct and you're trying to register an existing business, please log in with the associated account. If you are registering a new business, please ensure you've entered a unique ABN/ACN number. ",
	},
	unique: {
		heading: "Company Already Exist",
		description:
			"The business name you entered is already registered in our system. If this is your business, please log in using the associated account. If you are registering a new business, please verify the business name for uniqueness.Please note that for multi-state companies, only one company name may be registered. You should append the region name after the business name for distinct identification.If you are still encountering issues with registration, please email us at support@procuracon.com.au for further assistance.",
	},
	required: {
		heading: "Required Field",
		description: "Please review field errors in form.",
	},
	not_found: {
		heading: "Record Not Found",
		description:
			"We're sorry, Your session has expired. Please refresh the page and start again.If you continue to experience difficulties, please email us at support@procuracon.com.au",
	},
};

const mapStateToProps = (state) => {
	return {
		companies_list: state.search.searchResult,
		user: state.auth.signup_details,
	};
};

export const SignUpBusinessDetails = connect(mapStateToProps, {
	getCompanies,
	saveBusinessDetails,
})(SignUpBusinessDetailsComponent);
