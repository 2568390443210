import React, { createContext, useContext, useState } from "react";

const SearchTemplateContext = createContext();

export const SearchTemplateProvider = ({ children }) => {
	const [matchesRowHeight, setMatchesRowHeight] = useState(0);

	const value = {
		matchesRowHeight,
		setMatchesRowHeight,
	};

	return (
		<SearchTemplateContext.Provider value={value}>
			{children}
		</SearchTemplateContext.Provider>
	);
};

export const useSearchTemplate = () => {
	const context = useContext(SearchTemplateContext);
	if (context === undefined) {
		throw new Error(
			"useSearchTemplate must be used within a SearchTemplateProvider"
		);
	}
	return context;
};
