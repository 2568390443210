import styled from "styled-components";

export const SideDrawStyled = styled.div`
	background-color: white;
	width: 265px;
	will-change: transform;
	transition: ${(props) => props.theme.transitions.default};
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	transform: translateX(${(props) => (props.open ? "0" : "calc(100% - 20px)")});
	z-index: 100;
	border-left: 1px solid ${(props) => props.theme.colors.snowDark};

	.side-draw {
		&__inner {
			padding: ${(props) => props.theme.space.lg}
				${(props) => props.theme.space.sm};
			overflow-y: scroll;
			max-height: 100vh;
		}

		&__heading {
			text-align: center;
			font-size: ${(props) => props.theme.fontSizes.md};
			color: ${(props) => props.theme.colors.grayDark};
			margin: 0 0 ${(props) => props.theme.space.xs};
			padding-bottom: ${(props) => props.theme.space.xxs};
			border-bottom: 1px solid ${(props) => props.theme.colors.grayBorder};
		}

		&__toggle {
			position: absolute;
			top: 4.6rem;
			left: 0;
			transform: translateX(-50%);
		}
	}
`;
