import React, { useState } from "react";
import { Spinner } from "@chakra-ui/react";

import { TextAreaStyled } from "components/FormInputs/TextArea/styled";
import { cancel_subs } from "actions/AccountManagementActions";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Button } from "components/RFQ/Button";

function CancelSubscriptionModal({ isOpen, setIsOpen, setAccountStatus }) {
	const [message, setMessage] = useState(null);
	const [sending, setSending] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleConfirm = async () => {
		setSending(true);
		await cancel_subs(message)
			.then((res) => {
				setAccountStatus("cancelled");
				setSending(false);
				setIsOpen(false);
			})
			.catch((e) => {
				console.log(e);
				setSending(false);
				setIsOpen(false);
			});
	};

	const dialogProperty = {
		icon: `blue-circle-check`,
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: `Confirmation`,
		description: `Are you sure you want to cancel the subscription? Please let us know why.`,
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					className="!w-full !normal-case"
					btntype="primary"
					onClick={handleConfirm}
					disabled={sending}
				>
					{sending ? <Spinner /> : "Confirm"}
				</Button>
				<Button
					className="!w-full"
					btntype={"secondary"}
					onClick={handleClose}
				>
					Close
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			header={dialogProperty}
			footerComponent={<FooterComponent />}
		>
			<TextAreaStyled onChange={(e) => setMessage(e.target.value)} />
		</CustomModal>
	);
}

export default CancelSubscriptionModal;
