import React from "react";
import { connect } from "react-redux";
import { RequestAccessForm } from "./RequestAccessForm";
import { sendDocumentRequest } from "../../actions/companyActions";
import { showToast } from "utils/helpers";

export const RequestAccessDocumentComponent = ({
	companyId,
	documentId,
	userId,
	current_company,
	onSuccessfulSubmit,
	sendDocumentRequest,
	...props
}) => {
	const handleSubmit = async (values, { setErrors }) => {
		values["owner_company_id"] = companyId;
		try {
			await sendDocumentRequest(companyId, values);
			onSuccessfulSubmit();
			showToast("Request Submitted successfully.", "Success", true);
		} catch (e) {
			const error = e.response.json();
			setErrors(error.errors);
			showToast(error.message, "Error");
		}
	};

	return (
		<RequestAccessForm
			userId={userId}
			current_company={current_company}
			documentId={documentId}
			handleSubmit={handleSubmit}
			title="Request Document Access"
		/>
	);
};

const mapStateToProps = (state) => {
	const current_company = state.auth.current_company;
	const userId = state.auth.user.id;
	const companyId = state.search.activeCompany.id;

	return {
		companyId: companyId,
		current_company: current_company,
		userId: userId,
	};
};

export const RequestAccessModal = connect(mapStateToProps, {
	sendDocumentRequest,
})(RequestAccessDocumentComponent);
