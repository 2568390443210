import React, { useState } from "react";
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { Checkbox } from "components/RFQ/Checkbox";
import tw, { styled } from "twin.macro";
import { Button } from "components/RFQ/Button";
import { showToast } from "utils/helpers";
import { TabHeader } from "components/TabHeader";
import { guestFirstLoggedServices } from "services/guest-flow/rfqServices";

const ModalOverviewComp = ({ showDialog, acceptTerms, guestEmail }) => {
	const ContainerLogo = styled.div(() => [
		tw`relative w-[480px] h-[48px] text-center items-center flex`,
	]);
	const [isChecked, setIsChecked] = useState(false);

	const acceptFunc = async () => {
		const abortController = new AbortController();
		async function fetchData() {
			try {
				let res = await guestFirstLoggedServices(guestEmail, 1);
				if (res.status === 200) {
					showToast(res?.data?.message, res?.data?.title, true);
				}
				closeInterlockDialog();
			} catch (err) {
				showToast(err.message, "Error");
			}
		}
		fetchData();
		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	};

	const closeInterlockDialog = () => {
		setIsChecked(false);
		acceptTerms();
	};

	return (
		<Modal
			isOpen={showDialog}
			onClose={closeInterlockDialog}
			height="100%"
			size={"xl"}
			closeOnEsc
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<div className="overflow-hidden">
						<ContainerLogo>
							<div className="mb-5 mt-4">
								<TabHeader
									heading="Request for Quote"
									description=""
									noDivider={true}
								/>
							</div>
						</ContainerLogo>
						<img
							src="/img/interlock.svg"
							alt="interlock"
							className="mb-5 mt-5 pt-5"
						/>
						<p className="mb-5 mt-5 text-sm font-normal text-tertiary-600">
							You have been invited by Interlock Development to participate in a
							tender. Sapien condimentum quam tincidunt orci. Diam ridiculus
							vitae donec id sit. Aliquet at pellentesque vel amet viverra nibh
							egestas. Sapien odio eleifend. To view the RFx you must read and
							accept our terms & conditions.
						</p>
					</div>
				</ModalHeader>
				<ModalCloseButton onClose={closeInterlockDialog} />
				<ModalBody>
					<div className="mb-5 flex items-center justify-start">
						<div className="flex-1">
							<div className="flex items-center justify-start">
								<div className="w-30">
									<Checkbox
										checked={isChecked}
										onChange={() => {
											setIsChecked(!isChecked);
										}}
									/>
								</div>
								<div
									className="ml-3 flex-1 font-roboto font-medium text-gray-700"
									style={{ fontSize: "14px" }}
								>
									I accept the <u>terms and conditions</u>
								</div>
							</div>
						</div>
						<div className="flex-1">
							<div className="flex">
								<Button
									btntype={"base"}
									style={{ width: "100%" }}
									onClick={closeInterlockDialog}
									type="button"
									className="mr-2"
								>
									Not Now
								</Button>
								<Button
									style={{ width: "100%" }}
									onClick={acceptFunc}
									disabled={!isChecked}
									className="ml-2"
								>
									View RFx
								</Button>
							</div>
						</div>
					</div>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ModalOverviewComp;
