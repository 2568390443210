import styled from "styled-components";

export const ModalHeader = styled.div`
	padding: 16px 24px;
	background-color: ${(props) => props.theme.colors.snowDark};
`;
export const ModalBodyStyled = styled.div`
	padding: 24px;
	label {
		font-size: 15px;
		color: ${(props) => props.theme.colors.textGrey};
	}
	ul {
		padding-left: 16px;
	}
	ul li {
		font-size: 15px;
	}
	.required {
		color: #dc2538;
		margin-left: 3px;
	}
	.buttons {
		display: flex;
		flex-direction: row-reverse;
		column-gap: 16px;
		justify-content: end;
		margin-top: 8px;
	}
	.instruction-text {
		text-align: right;
		i {
			font-size: 12px;
			color: ${(props) => props.theme.colors.textGrey};
		}
	}
`;
