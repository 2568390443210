export const tourSteps = (
	isRfqFeatureEnabled,
	uiTourSeen,
	rfqTourSeen,
	userRole
) => {
	const commonMenu = [
		{
			target: ".GuidedTourIcon",
			icon: "Announcement",
			content: `We’ve added a few new features to help streamline your workflow even further. Plus, we’ve made some light changes to the layout and design of our platform to make your experience smoother and more intuitive.
		  <br /><br />
		  To re-visit the tour, click the question mark icon here.`,
			title: `We've added new features, take the tour!`,
			subtext: `Let's take a quick tour of what's new!`,
			placement: "right-end",
			disableBeacon: true,
			showCheckbox: true,
		},
		{
			target: ".MyProfile",
			title: `My Profile`,
			icon: "User",
			content: `In the <b>My Profile</b> section, you can <b>view and update your personal details</b> to keep your information up to date. <br /><br />Need to reset your password? This is where you can easily set a new one, ensuring your account stays secure and accessible.`,
			placement: "right-end",
			uiTourStep: uiTourSeen === 0,
		},
		{
			target: ".CompanyProfile",
			icon: "CompanyProfile",
			title: `Company Profile`,
			content: `The <b>Company Profile</b> holds key
	details about your company,
	including offices, projects, team
	members, and important documents.
	A complete profile helps your business stand out on Procuracon's
	marketplace, boosting visibility to
	potential clients.`,
			placement: "right",
			uiTourStep: uiTourSeen === 0,
		},
		{
			target: ".AccountSettings",
			icon: "Gear",
			title: `Account Settings`,
			content: `In <b>Account Settings</b>, Admin
	users can add or remove team
	members, and view
	subscription and billing details.
	Only Admin Users have access
	to make changes here.`,
			placement: "right",
			uiTourStep: uiTourSeen === 0,
		},
	];

	const clientMenu = [
		{
			target: ".SupplierList",
			icon: "Supplier",
			title: `Supplier Lists`,
			content: `With <b>Supplier Lists</b>, you can create
	custom folders for your preferred
	suppliers—whether it's Lead Engineers,
	Architects, Fitout Contractors, or
	Surveyors. Simply search, shortlist, and
	add them to your lists. When you start a
	new project, assign a list to quickly
	review profiles and request quotes from
	your trusted suppliers!`,
			placement: "right",
			rfqTourStep: true,
			uiTourStep: rfqTourSeen === 0,
		},
		{
			target: ".ProjectsAndAssets",
			icon: "Projects",
			title: `Projects and Assets`,
			content: `Every project is unique, and so are your suppliers! In <b>Projects and Assets</b>, you can add your preferred supplier lists to specific projects, so you'll have the right contacts and supplier data at your fingertips. Skip the extra information requests—just go straight to the RFx feature to invite suppliers best suited for each project and seek quotes.`,
			placement: "right-top",
			rfqTourStep: true,
			uiTourStep: rfqTourSeen === 0,
		},
		{
			target: ".RFx",
			icon: "Request",
			title: `RFx for Procurers`,
			content: `The <b>new RFx Feature</b> is your shortcut to
manage all requests and invitations in one
place. Create, track, and manage your
RFx’s centrally for an organised and
streamlined process.`,
			placement: "right",
			rfqTourStep: true,
			uiTourStep: rfqTourSeen === 0,
		},
		{
			target: ".SearchSuppliers",
			icon: "Search",
			title: `Search Suppliers`,
			content: `Our <b>Search Suppliers</b> feature is better
	than ever! Use Procuracon's powerful
	search engine to find and shortlist trusted
	suppliers for your projects. Explore and add
	to your lists with ease!
	`,
			subtext: `That's the end of the tour—enjoy
	exploring the new updates!`,
			placement: "right-top",
			uiTourStep: uiTourSeen === 0,
		},
	];

	const supplierMenu = [
		{
			target: ".RFx",
			icon: "Request",
			title: `RFx for Bidders`,
			content: `Welcome to your RFx hub! With the
		<b>new RFx feature</b>, you can quickly
		access invitations and respond to
		RFQs. Manage all your bids with
		ease, streamline your workflow, and
		make your procurement process
		faster and more efficient!`,
			placement: "right",
			rfqTourStep: true,
			uiTourStep: rfqTourSeen === 0,
		},
	];

	if (userRole === "Procurer") {
		return [...commonMenu, ...clientMenu];
	}

	return [...commonMenu, ...supplierMenu];
};
