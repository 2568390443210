import {
	createContext,
	useMemo,
	useCallback,
	useState,
	useEffect,
} from "react";
import { useSelector } from "react-redux";
import { getCookie, setCookie } from "utils/cookiesHelper";
import { isProcurerNBidder } from "utils/helpers";

export const ProfileContext = createContext("client");

const getUserProfileType = (role) => {
	return {
		currentRole: role === "client" ? "Procurer" : "Bidder",
		isClientProfileActive: role === "client",
	};
};

export const ProfileContextProvider = ({ children }) => {
	const role = getCookie("role");

	const [uiTourSeen, setUiTourSeen] = useState(() => {
		const rawUsersCookie = getCookie("users");
		const users = JSON.parse(rawUsersCookie || "{}");
		return users?.user?.ui_tour_seen;
	});

	const [rfqTourSeen, setRfqTourSeen] = useState(() => {
		const rawUsersCookie = getCookie("users");
		const users = JSON.parse(rawUsersCookie || "{}");
		return users?.user?.rfq_tour_seen;
	});

	const rawUsersCookie = getCookie("users");
	const users = JSON.parse(rawUsersCookie || "{}");
	const userId = users?.user?.id;

	const currentProfile = getUserProfileType(role).currentRole;

	const isClientProfileActive = getUserProfileType(role).isClientProfileActive;

	const company = useSelector((state) => state?.auth?.current_company);
	const rfqTotal = useSelector((state) => state?.supplier_rfq?.total);

	const isRfqFeatureEnabled =
		role === "client" ? company?.enable_rfx_functionality : rfqTotal > 0;

	const isAccountSwitchable = isProcurerNBidder(company);

	const changeProfileType = (role, profileType) => {
		setCookie("role", role);
		setCookie("profile_type", profileType);
		// Replace the current history state
		window.history.replaceState(null, "", window.location.href);
		// Redirect to the new URL
		window.location.replace("/");
	};

	const updateTourCookieFlags = useCallback(async (uiTour, rfqTour) => {
		try {
			const rawUsersCookie = getCookie("users");
			const currentUsers = JSON.parse(rawUsersCookie || "{}");

			if (currentUsers?.user) {
				const updatedUsers = {
					...currentUsers,
					user: {
						...currentUsers.user,
						ui_tour_seen: uiTour ? 1 : 0,
						rfq_tour_seen: rfqTour ? 1 : 0,
					},
				};

				setCookie("users", JSON.stringify(updatedUsers));
				setUiTourSeen(uiTour ? 1 : 0);
				setRfqTourSeen(rfqTour ? 1 : 0);
			}
		} catch (error) {
			console.error("Error updating tour cookie flags:", error);
		}
	}, []);

	useEffect(() => {
		const handleStorageChange = () => {
			const rawUsersCookie = getCookie("users");
			const users = JSON.parse(rawUsersCookie || "{}");
			setUiTourSeen(users?.user?.ui_tour_seen);
			setRfqTourSeen(users?.user?.rfq_tour_seen);
		};

		window.addEventListener("storage", handleStorageChange);

		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, []);

	const [forceRestartTour, setForceRestartTour] = useState(false);

	const resetTour = useCallback(async () => {
		await updateTourCookieFlags(false, false);
		setForceRestartTour(true);
	}, [updateTourCookieFlags]);

	const contextValue = useMemo(
		() => ({
			isClientProfileActive,
			currentProfile,
			isAccountSwitchable,
			changeProfileType,
			isRfqFeatureEnabled,
			userId,
			uiTourSeen,
			rfqTourSeen,
			updateTourCookieFlags,
			forceRestartTour,
			setForceRestartTour,
			resetTour,
		}),
		[
			currentProfile,
			isClientProfileActive,
			isAccountSwitchable,
			isRfqFeatureEnabled,
			userId,
			uiTourSeen,
			rfqTourSeen,
			updateTourCookieFlags,
			forceRestartTour,
			setForceRestartTour,
			resetTour,
		]
	);

	return (
		<ProfileContext.Provider value={contextValue}>
			{children}
		</ProfileContext.Provider>
	);
};
