import React from "react";

import { Shortlist } from "./Shortlist";
import { useDispatch } from "react-redux";
import { removeCompanyToListDirect } from "actions/searchActions";

export const SearchShortlist = ({
	items,
	removeFromList,
	groupId,
	directcompare,
	...props
}) => {
	const dispatch = useDispatch();
	if (!items.length) {
		return (
			<h1 style={{ textAlign: "center", marginTop: "4rem" }}>
				Shortlist is empty.
			</h1>
		);
	}

	const handleRemoveButtonClick = (event, companyId) => {
		if (directcompare) {
			dispatch(removeCompanyToListDirect(companyId));
		} else {
			removeFromList(companyId, "shortlist", groupId);
		}
	};

	return (
		<>
			<Shortlist
				path="/"
				noHeading
				items={items}
				padded
				isInSearchShortlist
				handleRemoveButtonClick={handleRemoveButtonClick}
				linkNewTab
			/>
		</>
	);
};
