import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
	LoginWrapper,
	LeftSection,
	RightSection,
	LoginSection,
} from "./styled";
import { Heading } from "../Heading";

import { verifyEmail } from "actions/authActions";
import rightImg from "./../../icons/login-right-img.jpg";
import { Button } from "../Button";
import { navigate } from "@reach/router";
import Skeleton from "react-loading-skeleton";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const EmailVerificationComponent = ({ user, verifyEmail, ...props }) => {
	const queryParams = new URLSearchParams(window.location.search);
	const token = queryParams.get("sc");
	const email = queryParams.get("email");

	if (email == null || token == null) navigate("/login", { replace: true });
	else if (user && user.email_verified) {
		if (user.company_id)
			navigate(`/account/${user.company_id}/company-profile/edit-user-profile`);
		else navigate("/search");
	}

	useEffect(() => {
		verifyEmail(email, token);
	}, [email, token, verifyEmail]);

	return (
		<LoginWrapper>
			<LeftSection>
				<LogoWrapper />

				<LoginSection>
					<Heading marginBottom="50px">
						<span className="welcome">Email Verification.</span>
						<span>Verifying your email...</span>
					</Heading>
					<Skeleton
						count={5}
						duration={0.5}
					/>
					{user && user.email_verified ? (
						<Button onClick={() => navigate("/login")}>Return to login</Button>
					) : null}
				</LoginSection>
			</LeftSection>
			<RightSection
				style={{ backgroundImage: `url(${rightImg})` }}
			></RightSection>
		</LoginWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		user: JSON.parse(localStorage.getItem("user")) || state.auth.user,
	};
};

export const EmailVerification = connect(mapStateToProps, { verifyEmail })(
	EmailVerificationComponent
);
