import { Flex } from "@chakra-ui/react";
import { Button } from "components/RFQ/Button";
import React from "react";
import {
	DOCUMENTATION,
	PROJECT_DETAILS,
	QUESTIONNAIRE,
	TENDER_COST,
} from "utils/constants";

const PreviewRFQButtonTab = ({
	currentTab,
	setCurrentTab,
	rfqDetail,
	isLoading,
	isSubmissionPreview,
}) => {
	const documentationTotal =
		rfqDetail?.documents?.filter((doc) => !doc?.isRemove).length || 0;

	const hasTenderCost =
		rfqDetail?.quote?.length > 0 || rfqDetail?.quoteType === "file";
	const hasQuestionnaire =
		rfqDetail?.questionnaire?.filter((section) => section?.id !== null).length >
			0 || rfqDetail?.returnableScheduleType === "file";

	return (
		<div>
			<Flex style={{ gap: "8px" }}>
				{!isSubmissionPreview && (
					<Button
						btntype="tab"
						active={currentTab === PROJECT_DETAILS}
						onClick={() => setCurrentTab(PROJECT_DETAILS)}
						disabled={isLoading}
					>
						Project Details
					</Button>
				)}
				{documentationTotal !== 0 && (
					<Button
						className="inline-flex gap-2"
						btntype="tab"
						active={currentTab === DOCUMENTATION}
						onClick={() => setCurrentTab(DOCUMENTATION)}
						disabled={isLoading}
					>
						<span>Documentation</span>
					</Button>
				)}
				{hasQuestionnaire || isSubmissionPreview ? (
					<Button
						btntype="tab"
						active={currentTab === QUESTIONNAIRE}
						onClick={() => setCurrentTab(QUESTIONNAIRE)}
						disabled={isLoading}
					>
						Returnable Schedules
					</Button>
				) : null}
				{hasTenderCost || isSubmissionPreview ? (
					<Button
						btntype="tab"
						active={currentTab === TENDER_COST}
						onClick={() => setCurrentTab(TENDER_COST)}
						disabled={isLoading}
					>
						Quote
					</Button>
				) : null}
			</Flex>
		</div>
	);
};

export default PreviewRFQButtonTab;
