import React from "react";
import styled from "styled-components";

export const DataRowStyled = styled.div`
	position: relative;
	color: ${(props) => props.theme.colors.grayLight};
	${"" /* height: 72px; */}
	${"" /* padding: 10px 0; */}
    ${"" /* margin-bottom: ${(props) => props.theme.space.sm}; */}
    display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1.5rem 16px;
	margin: 0 -16px;
	min-height: 74px;
	border-top: ${(props) =>
		!props.noBorder ? `1px solid ${props.theme.colors.grayBorder}` : "none"};
	z-index: 1;

	${(props) =>
		props.contentType === "company-name" &&
		`
        height: 95px;
    `}

	${(props) =>
		props.contentType === "company-name-location" &&
		`
        height: 125px;
        width: 400px !important;
    `}

    ${(props) =>
		props.sticky &&
		`
        width:300px;
        position:fixed;
        top:0;
        left:${props.sLeft}px;
        z-index:${props.sLeft < (props.isSidebarCollapsed ? 300 : 640) ? -1 : 2};
        background-color:${props.theme.colors.snow};
        &:hover{
            background-color:white;
        }
    `}

    ${(props) =>
		props.contentType === "location" &&
		`
        height: 97px;
    `}

${(props) =>
		props.contentType === "suburb-city" &&
		props.noBorder &&
		`
    min-height: 50px !important;
    padding: ${props.noBorder && "0.5rem 16px"} !important;
    `}

    ${(props) =>
		props.contentType === "company-size" && props.noBorder
			? `
    min-height: 40px !important;
    padding: ${props.noBorder && "0.5rem 16px"} !important;
    `
			: ""}

      ${(props) =>
		props.contentType === "anual-turnover" && props.noBorder
			? `
    min-height: 40px !important;
    padding: ${props.noBorder && "1rem 16px 0.5rem 16px"} !important;
    `
			: ""}

    ${(props) =>
		props.contentType === "sector" &&
		`
          height: ${props.sectorHeight && props.sectorHeight}px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.75rem;
          margin-top: ${props.compareTable ? "0.5em" : 0};
      `}
  

    ${(props) =>
		props.contentType === "lga" &&
		`
        min-height: ${props.noBorder ? "50px" : "74px"};
        height: ${props.noBorder ? "50px" : "150px"};
        padding: ${props.noBorder && "0.5rem 16px"};
    `}

    ${(props) =>
		props.contentType === "avatar-group" &&
		`
        height: 85px;
    `}

    ${(props) =>
		props.contentType === "avatar-list" &&
		`
        height: 380px;
    `}

    ${(props) =>
		props.contentType === "project-list" &&
		`
        height: 2000px;
        padding-top: ${props.theme.space.sm_2};
        padding-top: 0;
    `}

    ${(props) =>
		props.contentType === "project-images" &&
		`
        height: 230px;
    `}

    ${(props) =>
		props.contentType === "count" &&
		`
        height: 86px;
    `}

    ${(props) =>
		props.contentType === "documents" &&
		`
        ${"" /* height: 350px; */}
    `}

    p {
		margin: 0;
	}

	.document-div {
		margin-bottom: 16px;
		padding-bottom: 16px;
		width: 100%;
		border-bottom: 0.5px solid rgba(91, 107, 136, 0.5);
		position: relative;
		.documents {
			height: 80px;
			width: 100%;
			overflow: hidden;
			.extra-docs {
				background-color: ${(props) => props.theme.colors.blue};
				height: 30px;
				width: 30px;
				position: absolute;
				bottom: 5px;
				font-size: 10px;
				color: ${(props) => props.theme.colors.snow};
				border-radius: 50%;
				right: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
`;

export const DataRow = ({ children, ...props }) => {
	return (
		<DataRowStyled
			className={props.sticky ? "stick" : ""}
			{...props}
		>
			{children}
		</DataRowStyled>
	);
};
