import { createContext, useState } from "react";

export const RFQContext = createContext();

export const RFQContextProvider = ({ children }) => {
	const [rfqId, setRFQId] = useState(null);
	const [guestUserId, setGuestUserId] = useState(null);
	const [currentCompanyId, setCurrentCompanyId] = useState(null);
	const [uploadInProgresses, setUploadInProgresses] = useState({
		rfxProcurer: [],
		rfxBidder: [],
	});

	return (
		<RFQContext.Provider
			value={{
				setRFQId,
				rfqId,
				setUploadInProgresses,
				uploadInProgresses,
				setCurrentCompanyId,
				setGuestUserId,
				guestUserId,
				currentCompanyId,
			}}
		>
			{children}
		</RFQContext.Provider>
	);
};
