import { Icon } from "assets/icons/Icon";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
	MenuDivider,
} from "components/RFQ/MenuDropdown";
import React from "react";

const SubmissionEditButton = ({
	guestname,
	companystatus,
	onOpen,
	setTypeModal,
	companyId,
	viewSubmission,
	typeGuestName,
	email,
	isSubmitted,
}) => {
	const listExcludeAwards = ["unsuccessful", "decline", "awarded", "invited"];
	const listMenuEdit = [
		{
			name: "View Submission",
			icon: "edit-icon",
			value: "submission",
			divider: true,
		},
		{
			name: "Award Supplier",
			icon: "folder-plus",
			value: "award",
			divider: true,
		},
		{
			name: "Decline Supplier",
			icon: "archive",
			value: "decline",
			divider: false,
		},
	];

	const handleButtonClick = (value) => {
		if (value === "submission") {
			if (enableViewSubmission(companystatus?.toLowerCase())) {
				viewSubmission();
				if (guestname) {
					if (companyId === undefined) {
						typeGuestName({
							guestname: guestname,
							email: email,
						});
					}
				}
			}
		} else {
			if (!listExcludeAwards.includes(companystatus?.toLowerCase())) {
				setTypeModal({
					type: value,
					companyId: companyId,
				});
				if (guestname) {
					if (companyId === undefined) {
						typeGuestName({
							guestname: guestname,
							email: email,
						});
					}
				}
				onOpen();
			}
		}
	};

	const enableViewSubmission = (status) => {
		status = status?.toLowerCase();
		let enable = false;
		if (listExcludeAwards.includes(status)) {
			if (status !== "invited") {
				enable = true;
			}
		} else {
			enable = true;
		}
		return enable;
	};

	return (
		<div>
			<DropdownMenu>
				<DropdownMenuTrigger icon></DropdownMenuTrigger>
				<DropdownMenuDrawer>
					{listMenuEdit.map((menu, index) => {
						return (
							<div key={index}>
								{menu.name === "View Submission" ? (
									isSubmitted ? (
										<DropdownItem
											className={`w-full ${enableViewSubmission(companystatus?.toLowerCase()) ? "cursor-pointer" : "pointer-events-none cursor-not-allowed"}`}
											onClick={() => handleButtonClick(menu.value)}
										>
											<Icon
												icon={menu.icon}
												width={16}
												height={16}
												style={{
													color: enableViewSubmission(
														companystatus?.toLowerCase()
													)
														? "#344054"
														: "#D0D5DD",
												}}
											/>
											<p
												className={`font-roboto font-medium ${enableViewSubmission(companystatus?.toLowerCase()) ? "text-secondary-700" : "text-fg-disabled_subtle"} text-[14px] hover:text-[14px]`}
												style={{ lineHeight: "20px" }}
											>
												{menu.name}
											</p>
										</DropdownItem>
									) : null
								) : (
									<>
										<DropdownItem
											className={`w-full ${listExcludeAwards.includes(companystatus?.toLowerCase()) ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"}`}
											onClick={() => handleButtonClick(menu.value)}
										>
											<Icon
												icon={menu.icon}
												width={16}
												height={16}
												style={{
													color: listExcludeAwards.includes(
														companystatus?.toLowerCase()
													)
														? "#D0D5DD"
														: "#344054",
												}}
											/>
											<p
												className={`font-roboto font-medium ${listExcludeAwards.includes(companystatus?.toLowerCase()) ? "text-fg-disabled_subtle" : "text-secondary-700"} text-[14px] hover:text-[14px]`}
												style={{ lineHeight: "20px" }}
											>
												{menu.name}
											</p>
										</DropdownItem>
										{menu.divider && <MenuDivider />}
									</>
								)}
							</div>
						);
					})}
				</DropdownMenuDrawer>
			</DropdownMenu>
		</div>
	);
};

export default SubmissionEditButton;
