import api from "api";
import * as constants from "./actionTypes";
import { processFormData, showToast } from "../utils/helpers";
import { navigate } from "@reach/router";
import apiV2 from "api-v2";

export const getGuestUser = (id) => {
	return async (dispatch) => {
		(async () => {
			try {
				const res = await api.get(`guest/user/${id}`).json();
				localStorage.setItem("guest_user", JSON.stringify(res.user));
				await dispatch({
					type: constants.AUTHENTICATE_GUEST_USER,
					payload: res.user,
				});
			} catch (e) {
				const error = await e.response.json();
				showToast(error.message, "Error");
			}
		})();
	};
};

export const guestForgotPassword = (values, setSubmitting) => {
	return async () => {
		const formData = processFormData(values);

		try {
			const status = await api
				.post("guest/forget-password", { body: formData, timeout: false })
				.json();

			if (status.status) {
				setSubmitting(false);
				return status;
			} else {
				setSubmitting(false);
				return status;
			}
		} catch (e) {
			const error = await e.response.json();
			showToast(error.message, "Error");
		}
		setSubmitting(false);
	};
};

export const guestResetPassword = (values, setSubmitting) => {
	return async () => {
		const formData = processFormData(values);

		try {
			const status = await api
				.post("guest/reset-password", { body: formData, timeout: false })
				.json();
			if (status.success) {
				showToast("Reset Password. Redirecting now.", "Success", true);
				navigate("/guest/login");
			} else {
				showToast(status.message, "Error");
			}
		} catch (e) {
			const error = await e.response.json();
			showToast(error.message, "Error");
		}

		setSubmitting(false);
	};
};

export const guestFirseLoggedIn = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({ type: "FIRSTLOGGEDIN", payload: payload });
		} catch (err) {}
	};
};

export const getSharedCompanyProfile = async () => {
	try {
		const res = await apiV2.get("shared-company-profile");
		return res;
	} catch (error) {
		throw error;
	}
};
