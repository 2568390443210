import { Flex, Image, Text } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useEffect, useState } from "react";
import Select, { components, createFilter } from "react-select";
import { styled } from "twin.macro";
import tw, { css } from "twin.macro";
import { cn } from "utils/helpers";

const typesDropdown = {
	dot: tw`before:w-1 before:h-1 before:bg-utility-success-700 before:rounded-full before:mr-1`,
};

const controlSize = {
	sm: tw`!max-h-[40px] !min-h-[40px] !py-0 !pr-4 !pl-3 text-sm`,
	base: tw`!max-h-[50px] !min-h-[50px] !py-3 !pr-3 !pl-3`,
	md: tw`p-4`,
	lg: tw`max-h-[56px] p-4`,
};

export const CustomSelectStyled = styled.div(
	({ type = "default", isSelected, isFocused }) => [
		tw`flex px-3 py-3 gap-2 items-center -my-[3px] `,
		tw`disabled:bg-gray-200 disabled:bg-opacity-25 disabled:pointer-events-none`,
		typesDropdown[type],
		css`
			background-color: ${isSelected
				? "#F9FAFB"
				: isFocused
					? "#EEF5FFB2"
					: "white"};
			.hovered-icon-class {
				display: ${isSelected || isFocused ? "block" : "none"};
				content: '""';
				width: ${isSelected || isFocused ? "12px" : "0px"};
				height: ${isSelected || isFocused ? "12px" : "0px"};
				margin-left: ${isSelected || isFocused ? "auto" : "none"};
				background-color: ${isSelected ? "#DEF9FA" : "none"};
			}
			&:hover {
				background-color: #eef5ffb2;
				.hovered-icon-class {
					content: '""';
					display: block;
					width: 12px;
					height: 12px;
					margin-left: auto;
					background-color: rgba(249, 250, 251, 1);
				}
			}
		`,
	]
);

const CustomSingleValueStyled = styled.div(({ type = "default" }) => [
	tw`flex pr-3 py-1 gap-2 items-center !text-primary-700`,
	typesDropdown[type],
]);

const CustomMultiValueStyled = styled.div(({ type = "default" }) => [
	tw`flex py-0 gap-2 items-center pr-0 !text-primary-700`,
	typesDropdown[type],
]);

const CustomValueContainerStyled = styled.div(() => [
	tw`flex gap-2 items-center  focus-within:!text-primary-700`,
]);

const controlStyles = () => tw`
    focus-within:ring-4  w-full h-max shadow-none
    overflow-hidden flex gap-1.5 items-center
    border border-gray-300 rounded-md min-h-[56px] p-4 mt-1.5
    disabled:bg-gray-100 disabled:bg-opacity-25 disabled:pointer-events-none
    `;

const valueContainerStyles = () => [tw`rounded-md content-center`];

const InputDropdown = ({
	options,
	className,
	defaultValue,
	isDisabled,
	isLoading,
	isClearable,
	isMultiple,
	isRtl,
	variant,
	label,
	type,
	name,
	hint,
	isContainImg = false,
	isSearchable,
	value,
	onChange,
	setValue,
	optionComponent,
	icon,
	valueComponent,
	prefixValue,
	endPrefix,
	emptyOptionsText,
	noborder,
	size = "md",
	setFieldTouched,
	disabledTable,
	...props
}) => {
	const multipleValue = isMultiple
		? value?.map((val) =>
				options?.find((option) => option.value === val.toString())
			)
		: null;
	const [selectedOption, setSelectedOption] = useState(
		isMultiple
			? multipleValue
			: options?.find((option) => option.value === value) || null
	);

	useEffect(() => {
		if (options) {
			setSelectedOption(
				isMultiple
					? multipleValue
					: options?.find((option) => option.value === value)
			);
		}
		if (!value && value !== 0 && setValue) {
			setSelectedOption(null);
		}
		//eslint-disable-next-line
	}, [options, value]);

	const selectStyles = () => ({
		control: (styles, { isDisabled }) => ({
			...styles,

			// border: (props.error && props.touched ) && '#dd382b solid 1px !important',
			...controlStyles(),
			...controlSize[size],
			marginTop: "0px",
			backgroundColor: disabledTable && "#fafafa",
			border:
				noborder !== undefined
					? noborder === true
						? "none"
						: props.error && props.touched
							? "#dd382b solid 1px !important"
							: "#D0D5DD solid 1px !important"
					: "unset",
			...(props?.touched && props?.error
				? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary`
				: tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary`),
		}),
		options: (styles, base, { data }) => ({
			...base,
			...styles,
			...typesDropdown(data.type),
		}),
		input: (base, state) => ({
			...base,
			margin: 0,
			padding: 0,
		}),
		placeholder: (styles, { isMulti }) => ({
			...styles,
			...tw`text-placeholder`,
			position: "absolute",
			margin: "0px",
		}),
		singleValue: (styles) => ({
			...styles,
			paddingTop: "0px",
			paddingBottom: "0px",
			position: "absolute",
			color: disabledTable && "#333333",
			backgroundColor: disabledTable && "#fafafa",
			...valueContainerStyles(),
		}),
		valueContainer: (styles) => ({
			...styles,
			padding: 0,
			backgroundColor: disabledTable && "#fafafa",
			...valueContainerStyles(),
		}),
		multiValueLabel: (styles, state) => ({
			...styles,
			textTransform: state.selectProps.capitalize ? "capitalize" : "none",
		}),

		multiValue: (styles, state) => ({
			...styles,
			...tw`!bg-transparent !border !rounded-lg !border-gray-300`,
		}),

		multiValueRemove: (styles, state) => ({
			...styles,
			...tw`!bg-transparent hover:!bg-transparent hover:!text-gray-700 transition-colors`,
		}),
		menu: (base) => {
			return { ...base, zIndex: 9999 };
		},
		menuPortal: (base) => {
			return { ...base, zIndex: 99999 };
		},
		dropdownIndicator: (base) => {
			if (props?.fullIndicator)
				return {
					...base,
					position: "absolute",
					width: "100%",
					justifyContent: "end",
					left: 0,
				};
			else return { ...base, padding: "0 0 0 16px" };
		},
		clearIndicator: (base) => {
			return { ...base, padding: "0" };
		},
	});

	const handleSelectChange = (value) => {
		if (setValue) {
			if (isMultiple) {
				const values = value.map((val) => val.value);
				setValue(values);
			} else {
				setValue(value.value);
			}
		} else {
			onChange(value);
		}
		setSelectedOption(value);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 46) {
			setSelectedOption(null);
		}
		if (e.keyCode === 8) {
			setSelectedOption(null);
		}
	};
	const customOption = ({ innerProps, data, isSelected, isFocused }) => (
		<CustomSelectStyled
			{...innerProps}
			type={type}
			isSelected={isSelected}
			isFocused={isFocused}
		>
			{isContainImg && (
				<>
					{data.icon === null ? (
						<Icon
							icon="persona"
							color="rgba(102, 112, 133, 1)"
						/>
					) : (
						<Icon
							icon={data.icon}
							style={{ color: "#667085" }}
							width={20}
							height={20}
							className="rounded-full"
							alt="avatar"
						/>
					)}
				</>
			)}
			<Text size="sm">{data.label}</Text>
			<Text
				size="sm"
				className="text-gray-600"
			>
				{data.tag && "@" + data.tag}
			</Text>
			{props.checkIcon && isSelected && (
				<Icon
					icon="check-only"
					className="ml-auto !text-royal-blue"
				/>
			)}
		</CustomSelectStyled>
	);

	const SingleValue = (props) => {
		const { label, tag, icon } = props.getValue()[0];
		return (
			<components.SingleValue {...props}>
				<CustomSingleValueStyled type={type}>
					{isContainImg && (
						<>
							{icon === null ? (
								<Icon
									icon="persona"
									color="rgba(102, 112, 133, 1)"
								/>
							) : (
								<Icon
									icon={icon}
									alt="avatar"
									style={{ color: "#667085" }}
									width={20}
									height={20}
									className="rounded-full"
								/>
							)}
						</>
					)}
					<Text
						size="sm"
						className="font-medium !text-gray-500"
					>
						{label}
					</Text>
					<Text size="sm">{endPrefix}</Text>
					<Text
						size="sm"
						className="text-gray-600"
					>
						{tag && "@" + tag}
					</Text>
				</CustomSingleValueStyled>
			</components.SingleValue>
		);
	};

	const MultiValue = (props) => {
		const { data } = props;

		return (
			<components.MultiValue {...props}>
				<CustomMultiValueStyled
					key={data.value}
					type={type}
				>
					{isContainImg && (
						<>
							{data.icon === null || data.icon === "" ? (
								<Icon
									icon="persona"
									color="rgba(102, 112, 133, 1)"
								/>
							) : (
								<Image
									src={data.icon}
									alt="avatar"
									width={20}
									height={20}
									className="rounded-full"
								/>
							)}
						</>
					)}
					<Text size="sm">{data.label}</Text>
				</CustomMultiValueStyled>
			</components.MultiValue>
		);
	};

	const ValueContainer = ({ children, ...props }) => {
		return (
			<components.ValueContainer {...props}>
				<Flex
					gap={1}
					align={"center"}
				>
					{icon && (
						<Icon
							icon={icon}
							className="content-center text-gray-400"
						/>
					)}
					{prefixValue && (
						<Text
							size="sm"
							weight="medium"
							className="pl-1"
						>
							{prefixValue}
						</Text>
					)}
					<CustomValueContainerStyled className="flex-wrap">
						{isSearchable}
						{children}
					</CustomValueContainerStyled>
				</Flex>
			</components.ValueContainer>
		);
	};

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className="custom-css-class">
					{emptyOptionsText || "No options"}
				</span>
			</components.NoOptionsMessage>
		);
	};

	return (
		<div
			className={cn("bg-white", className)}
			id={`select-${name}`}
		>
			<Select
				{...props}
				className={`${cn(variant)} mt-0`}
				classNamePrefix="select"
				defaultValue={selectedOption}
				isDisabled={isDisabled}
				isLoading={isLoading}
				isMulti={isMultiple}
				styles={selectStyles()}
				isClearable={isClearable}
				isRtl={isRtl}
				value={selectedOption}
				onKeyDown={handleKeyDown}
				isSearchable={isSearchable}
				name={name}
				isContainImg={isContainImg}
				onChange={(value) => handleSelectChange(value)}
				onBlur={() => {
					if (setFieldTouched) {
						setFieldTouched(name, true);
					}
				}}
				components={{
					IndicatorSeparator: () => null,
					Option: optionComponent || customOption,
					SingleValue: valueComponent || SingleValue,
					MultiValue,
					ValueContainer,
					NoOptionsMessage,
				}}
				options={options}
				menuPosition="fixed"
				menuPlacement="auto"
				menuPortalTarget={document.body}
				filterOption={createFilter({ ignoreAccents: false })}
			></Select>
			<Text
				size="sm"
				className={cn(props?.color === "error" && "text-utility-error-500")}
			>
				{hint}
			</Text>
		</div>
	);
};

export default InputDropdown;
