import {
	Card,
	Flex,
	CircularProgress,
	CircularProgressLabel,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useEffect } from "react";
import { formatBytes } from "utils/formatBytes";
import { cn, downloadFileObject, filenameSpliter } from "utils/helpers";
import { Badge } from "../Badge";
import useChunkFileUpload from "hooks/useChunkFileUpload";

export const FileCard = ({
	file,
	setFiles,
	isPreview = false,
	uploadProgress,
	categoryTag,
	className,
	fileExtraData,
	handleFileUploadComplete,
	handleFileUploadStart,
	handleFileUploadError,
	isDraggable,
}) => {
	const filename =
		file instanceof File
			? file?.path
			: file?.document ||
				file?.name ||
				file?.path ||
				file?.file_name ||
				file?.title ||
				"-";
	const description = file?.description;
	const filesize =
		file instanceof File
			? formatBytes(file?.size)
			: !file?.file_size
				? null
				: formatBytes(file.file_size);
	const ext = filenameSpliter(filename).ext;

	const { uploadFile, progress, isUploading, error } = useChunkFileUpload({
		handleFileUploadComplete: handleFileUploadComplete,
	});

	// Calculate the background color based on the upload progress
	const bgColor =
		uploadProgress < 50
			? "red.50"
			: uploadProgress < 75
				? "yellow.50"
				: "green.50";

	useEffect(() => {
		if (file instanceof File && fileExtraData) {
			uploadFile(file, fileExtraData);
			// this function adds loading status to an array so it should be only called when file is uploaded
			if (handleFileUploadStart && file instanceof File) {
				handleFileUploadStart();
			}
		}
		// eslint-disable-next-line
	}, [file]);

	useEffect(() => {
		if (handleFileUploadError && error) {
			handleFileUploadError();
		}
		// eslint-disable-next-line
	}, [error]);

	return (
		<Card
			className={cn(
				`border-gray-outline hover:text-purple group my-4 border px-4 py-3 hover:border-utility-brand-100 hover:bg-utility-brand-100 ${bgColor} min-h-[72px] justify-center`,
				className
			)}
			radius="sm"
		>
			<Flex className="relative items-center justify-between gap-4">
				<Flex className="relative items-center justify-between gap-4">
					{!isPreview && isDraggable && (
						<Icon
							icon={"sixDots"}
							size="md"
						/>
					)}
					<Icon
						icon={ext}
						size="md"
					/>
					<div>
						<p className="text-sm font-semibold">{filename}</p>
						{filesize && <p className="text-sm font-thin">{filesize}</p>}
						{description && <p className="pt-1 text-sm">{description}</p>}
					</div>
				</Flex>
				{categoryTag ? (
					<div className="flex items-center gap-6">
						<Badge
							size="lg"
							className="!text-sm !font-medium"
						>
							{categoryTag}
						</Badge>
						<a
							href={file?.path || ""}
							target="_blank"
							rel="noreferrer"
							download={filename ? "download" : null}
							className={cn(
								"flex rounded-full bg-gray-200 p-2 hover:bg-red-200",
								isPreview && "hover:bg-utility-brand-200"
							)}
						>
							<Icon
								icon={isPreview ? "download" : "trash"}
								style={{
									width: "18px",
									height: "18px",
									cursor: "pointer",
									color: "black",
								}}
							/>
						</a>
					</div>
				) : (
					<div className="flex items-center">
						{!isPreview && (
							<div className="flex group-hover:hidden">
								<Icon icon="check" />
							</div>
						)}
						{file?.path && !(file instanceof File) && isPreview ? (
							<a
								href={file?.path || ""}
								target="_blank"
								rel="noreferrer"
								download={filename ? "download" : null}
								className={cn(
									"hidden rounded-full bg-gray-200 p-2 hover:bg-red-200 group-hover:flex",
									isPreview && "hover:bg-utility-brand-200"
								)}
							>
								<Icon
									icon={isPreview ? "download" : "trash"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</a>
						) : (
							<button
								className={cn(
									"hidden rounded-full bg-gray-200 p-2 hover:bg-red-200 group-hover:flex",
									isPreview && "hover:bg-utility-brand-200"
								)}
								onClick={() =>
									isPreview ? downloadFileObject(file) : setFiles()
								}
							>
								<Icon
									icon={isPreview ? "download" : "trash"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</button>
						)}
						{isUploading && progress !== 100 && (
							<CircularProgress
								value={`${error ? 100 : progress}`}
								color={`${error ? "red" : "green.400"}`}
							>
								<CircularProgressLabel>
									{error ? (
										<button
											onClick={() => {
												uploadFile(file);
												handleFileUploadStart();
											}}
										>
											Retry
										</button>
									) : (
										`${progress}%`
									)}
								</CircularProgressLabel>
							</CircularProgress>
						)}
					</div>
				)}
			</Flex>
		</Card>
	);
};
