import { SIDEBARCOLLAPSE } from "constants/sidebar";

const initial = {
	sidebarcollapse: false,
};

export const sidebarReducer = (state = initial, action) => {
	switch (action.type) {
		case SIDEBARCOLLAPSE:
			return {
				...state,
				sidebarcollapse: action.payload,
			};
		default:
			return state;
	}
};
