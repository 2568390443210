import { Flex } from "@chakra-ui/core";
import { TabHeader } from "components/TabHeader";
import React from "react";
import { DashboardStyled } from "./styled";

const Dashboard = () => {
	return (
		<DashboardStyled>
			<TabHeader
				heading="Welcome back, Tony"
				description="Welcome to your Dashboard."
			/>
			<div id="layout-dashboard">
				<div id="layout-perfomance">
					<h2>My Activity</h2>
					<Flex></Flex>
				</div>
			</div>
		</DashboardStyled>
	);
};

export default Dashboard;
