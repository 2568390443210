import Cookies from "js-cookie";

const setCookie = (
	name,
	value,
	options = {
		expires: 1,
		secure: true,
		sameSite: "strict",
	}
) => {
	try {
		Cookies.set(name, value, options);
		// console.log(`Cookie '${name}' set successfully`);
	} catch (error) {
		// console.error(`Error setting cookie '${name}':`, error);
	}
};

const getCookie = (name) => {
	try {
		const value = Cookies.get(name);
		if (value === undefined) {
			// console.log(`Cookie '${name}' not found`);
			return null;
		}
		// console.log(`Cookie '${name}' retrieved successfully`);
		return value;
	} catch (error) {
		// console.error(`Error getting cookie '${name}':`, error);
		return null;
	}
};

const removeCookie = (name, options = {}) => {
	try {
		Cookies.remove(name, options);
		// console.log(`Cookie '${name}' removed successfully`);
	} catch (error) {
		// console.error(`Error removing cookie '${name}':`, error);
	}
};

const removeAllCookie = () => {
	Object.keys(Cookies.get()).forEach((cookieName) => {
		Cookies.remove(cookieName);
	});
};

export { setCookie, getCookie, removeCookie, removeAllCookie };
