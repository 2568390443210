import { AddendumMessage } from "../AddendumMessage";
import { AddendumConvo } from "../AddendumConvo";
import { AddendumLoading } from "pages/RFQSubmission/addendum/AddendumLoading";

const AddendumClient = ({
	rfqDetail,
	addendumData,
	setAddendumData,
	isCommunication,
	isDataLoading,
}) => {
	return (
		<div
			className={`${!isCommunication && "border shadow-md"} mb-10 overflow-hidden rounded-2xl border-solid border-utility-gray-200 bg-white pb-10`}
		>
			{!isCommunication && (
				<div className="mb-8 bg-gray-utility-100 p-10">
					<>
						<h5 className="mb-5 font-roboto text-[14px] font-medium text-primary-900">
							Add an addendum
						</h5>

						<AddendumMessage
							nested={false}
							setAddendumData={setAddendumData}
							rfqId={rfqDetail?.id}
						/>
					</>
				</div>
			)}
			{isDataLoading ? (
				<AddendumLoading />
			) : addendumData?.length > 0 ? (
				<AddendumConvo
					addendumData={addendumData}
					setAddendumData={setAddendumData}
					rfqDetail={rfqDetail}
					isCommunication={isCommunication}
				/>
			) : (
				isCommunication && (
					<div className="text-center">
						<h1 className="text-text-bold whitespace-pre text-xl">
							There are no questions from bidders at this time.
						</h1>
						<p className="text-sm">
							Check back here to review any inquiries as they come in.You’ll be
							notified via email when a bidder submits a question.
						</p>
					</div>
				)
			)}
		</div>
	);
};

export default AddendumClient;
