import { Button } from "components/RFQ/Button";
import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState, useCallback } from "react";
import tw, { styled, css } from "twin.macro";
import { ReactComponent as BackIcon } from "../../../assets/icons/flip-backward.svg";
import { navigate } from "@reach/router";
import { Form, Formik } from "formik";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import { FieldForm } from "components/RFQ/Input/Input";
import { DateInput } from "components/DateInput";
import { TextArea } from "components/FormInputs/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
	createSupplierList,
	getSupplierListById,
} from "actions/RFQ/supplierListsActions";
import * as yup from "yup";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import apiV2 from "api-v2";
import moment from "moment";
import { Icon } from "assets/icons/Icon";

// API response messages
const responseMessages = {
	create: {
		success: "Supplier list created",
		error: "Failed to create the supplier list. Please try again.",
	},
	update: {
		success: "Supplier list updated",
		error: "Failed to update the supplier list. Please try again.",
	},
	unauthorized: "You are not authorised to perform this action",
	loading: "Processing...",
};

// List type descriptions
const listTypeDescriptions = {
	panel:
		"Panel (Admin Permissions Only)\nA Panel is more formal, involves a competitive process, suppliers are contractually engaged with a defined duration.",
	supplierList:
		"This Supplier List is less formal, used as a guideline for procurement, offers flexibility, and typically does not involve binding contracts. Also known as a Preferred Supplier List.",
};

// Form validation schema
const validationSchema = yup.object().shape({
	name: yup
		.string()
		.required("Enter a supplier list name")
		.max(100, "Supplier list name must be 100 characters or less"),

	code: yup.string().max(50, "Supplier Code must be 50 characters or less"),

	start_date: yup
		.date()
		.required("Select a start date")
		.min(moment().startOf("day"), "Start date must be today or later"),

	end_date: yup
		.date()
		.required("Select an end date")
		.min(yup.ref("start_date"), "End date must be after start date"),

	description: yup
		.string()
		.required("Add a supplier list description")
		.max(1500, "Description must be 1500 characters or less"),
});

const NewSupplierContainer = styled.div(() => [
	tw`relative overflow-hidden`,
	css`
		.buttons-container {
			gap: 8px;
		}
		.container-content {
			padding-right: 24px;
			padding-left: 24px;
		}
	`,
]);

const BackButton = ({ handleBackButtonClick }) => (
	<Button
		btntype="icon"
		onClick={handleBackButtonClick}
		type="button"
	>
		<BackIcon style={{ width: "28px", height: "28px", marginRight: "0px" }} />
	</Button>
);

const SupplierForm = ({
	isNew,
	isEdit,
	listId,
	isAddSupplier,
	listIDCompanies,
	closeDialog,
}) => {
	const dispatch = useDispatch();
	const toast = useToast();
	const isLoading = useSelector((state) => state.rfq?.supplierLists?.loading);
	const { company_id, company_admin } = useSelector(
		(state) => state?.auth?.user
	);

	const [supplierList, setSupplierList] = useState(null);
	const [loadList, setLoadList] = useState(false);

	const options = [
		{ label: "My Suppliers", value: 0 },
		{ label: "Panel List", value: 1 },
	];

	const handleBackButtonClick = useCallback(() => {
		navigate(isNew ? "./" : "../");
	}, [isNew]);

	useEffect(() => {
		const fetchSupplierList = async () => {
			if (listId && !isNew && !supplierList) {
				setLoadList(true);
				try {
					const response = await getSupplierListById(listId);
					setSupplierList(response.data);
				} catch (error) {
					toast({
						title: "Error",
						description: "Failed to load supplier list details",
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				} finally {
					setLoadList(false);
				}
			}
		};

		fetchSupplierList();
	}, [listId, isNew, supplierList, toast]);

	const initialValues = {
		name: "",
		is_company_approved_list: 0,
		code: "",
		start_date: moment(),
		end_date: moment().add(6, "months"),
		description: "",
	};

	const initialValuesSupplier = {
		name: supplierList?.name || "",
		is_company_approved_list: supplierList?.is_company_approved_list || 0,
		code: supplierList?.code || "",
		start_date: supplierList?.startDate || moment().toDate(),
		end_date: supplierList?.endDate || moment().add(1, "d").toDate(),
		description: supplierList?.description || "",
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			if (isAddSupplier) {
				const formattedValues = {
					...values,
					isUpdate: 0,
					end_date: moment(values.end_date).format("YYYY-MM-DD"),
					start_date: moment(values.start_date).format("YYYY-MM-DD"),
					suppliers: listIDCompanies,
				};

				const response = await apiV2.post("supplier/save", formattedValues);

				if (response.status === 200) {
					closeDialog();
					toast({
						title: "Success",
						description: responseMessages.create.success,
						status: "success",
						duration: 5000,
						isClosable: true,
					});

					const { id: supplierlistId, name: supplierlistName } =
						response?.data?.data;
					navigate(
						`/account/${company_id}/supplier-list/${supplierlistId}/${supplierlistName}`
					);
				}
			} else if (isNew) {
				await dispatch(createSupplierList(values));
				toast({
					title: "Success",
					description: responseMessages.create.success,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else if (isEdit) {
				if (!company_admin && supplierList?.is_company_approved_list === 1) {
					toast({
						title: "Error",
						description: responseMessages.unauthorized,
						status: "error",
						duration: 5000,
						isClosable: true,
					});
					return;
				}

				await dispatch(createSupplierList(values, listId));
				toast({
					title: "Success",
					description: responseMessages.update.success,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "Error",
				description: error?.message || responseMessages.create.error,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<NewSupplierContainer>
			{!loadList ? (
				<Formik
					initialValues={isNew ? initialValues : initialValuesSupplier}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{({ values, resetForm, dirty, isValid }) => (
						<Form>
							{!isAddSupplier && (
								<TabHeader
									icon={
										<BackButton handleBackButtonClick={handleBackButtonClick} />
									}
									heading={
										isNew
											? "Create Supplier List"
											: `Update Supplier List "${supplierList?.name}"`
									}
									isLoading={loadList}
								/>
							)}
							<div className="container-content relative h-full min-h-[60vh]">
								{company_admin && (
									<Flex
										gap={8}
										marginBottom={8}
									>
										<div className="w-full space-y-2 rounded-md bg-[#EEF5FFB2] p-8">
											<FieldForm
												className={
													isAddSupplier
														? "w-[calc(100%-1rem)]"
														: "w-[calc(50%-1rem)]"
												}
												label="List type"
												name="is_company_approved_list"
												type="select"
												placeholder="My Suppliers"
												options={options}
											/>
											<p
												className={`${isAddSupplier ? "w-full" : "w-1/2"} whitespace-break-spaces`}
											>
												{values?.is_company_approved_list === 1
													? listTypeDescriptions.panel
													: listTypeDescriptions.supplierList}
											</p>
										</div>
									</Flex>
								)}

								<Flex
									gap={8}
									marginBottom={8}
								>
									<FieldForm
										label="Name of Supplier List"
										placeholder="E.g. Lead Design Architects"
										name="name"
										required={true}
										maxLength={100}
									/>
									<FieldForm
										label="Supplier List Code"
										placeholder="E.g. 0001 LDA"
										name="code"
										maxLength={50}
									/>
								</Flex>

								<Flex gap={8}>
									<DateInput
										label="Start Date"
										name="start_date"
										className="!font-regular"
										placeholder="Select start date"
										required={true}
										minDate={moment().toDate()}
									/>
									<DateInput
										label="End Date"
										name="end_date"
										className="!font-regular"
										placeholder="Select end date"
										required={true}
										minDate={moment(values?.start_date).add(1, "d").toDate()}
									/>
								</Flex>

								<Flex>
									<TextArea
										label="Supplier List Description"
										name="description"
										characterLimit={500}
										rfq
										hint="Provide a brief description of your supplier list."
										className="mt-2 w-full"
										placeholder="E.g. Lead Design Architects for Non-Residential Projects. For Projects valued up to £40M"
										required={true}
									/>
								</Flex>

								{!isAddSupplier ? (
									<Flex
										justifyContent="end"
										className="gap-8 py-12"
									>
										<NoBorderButton
											onClick={() => {
												resetForm();
												handleBackButtonClick();
											}}
											text="Cancel"
											type="button"
											className="bg-white text-[14px] text-tertiary-600"
										/>
										<div className="buttons-container flex">
											<Button
												type="submit"
												disabled={isLoading || !isValid}
												className="min-h-12 disabled:!opacity-50"
											>
												{isLoading ? (
													<Spinner />
												) : isNew ? (
													<>
														<Icon
															icon="plus"
															className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
														/>
														Create Supplier List
													</>
												) : (
													"Update Supplier List"
												)}
											</Button>
										</div>
									</Flex>
								) : (
									<div className="buttons-container flex">
										<Button
											width="100%"
											type="submit"
											disabled={!isValid || !dirty || isLoading}
											className="mb-5 mt-5 min-h-12 disabled:!opacity-50"
										>
											{isLoading ? (
												<Spinner />
											) : (
												<>
													<Icon
														icon="plus"
														className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
													/>
													Create Supplier List
												</>
											)}
										</Button>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<Spinner
					className="absolute left-1/2 top-[30vh] -translate-x-1/2"
					size="xl"
				/>
			)}
		</NewSupplierContainer>
	);
};

export default SupplierForm;
