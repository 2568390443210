import { Logo, Tagline } from "../Login/styled";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import styled from "styled-components";

export const Wrapper = styled.div`
	a {
		margin-bottom: 140px;
		display: flex;
	}
`;

export const LogoWrapper = () => {
	return (
		<Wrapper>
			<a href="/">
				<Logo
					src={logo}
					alt="Procuracon Logo"
				/>
				<Tagline
					src={tagline}
					alt="Build Possible"
				/>
			</a>
		</Wrapper>
	);
};
