import { checkAccessToken } from "actions/authActions";
import ky from "ky";
import { getCookie, removeAllCookie } from "utils/cookiesHelper";
import { showToast } from "utils/helpers";

const path = window.location.pathname;
const notLoggedinPath = [
	"/login",
	"/two-factor",
	"/two-factor-setup",
	"/guest/login",
	"/guest/signup",
].includes(path);

const guestUser = localStorage.getItem("guest_user");

export default ky.extend({
	prefixUrl: process.env.REACT_APP_API_URL,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = getCookie("access_token");
				if (token) {
					request.headers.set("Authorization", `Bearer ${token}`);
				}
			},
		],
		afterResponse: [
			async (request, options, response) => {
				if (!response.ok) {
					const error = await response.json();
					switch (response.status) {
						case 404:
							showToast(error?.message, "Error");
							break;
						case 401:
							if (!notLoggedinPath) {
								await checkAccessToken().then((status) => {
									if (!status) {
										localStorage.clear();
										removeAllCookie();
										window.location.href = guestUser
											? "/guest/login"
											: "/login";
									}
								});
							}
							break;
						default:
							return error;
					}
				}
			},
		],
	},
});

const Clientweb = ky.extend({
	hooks: {
		beforeRequest: [
			(request) => {
				const token = getCookie("access_token");
				if (token) {
					request.headers.set("Authorization", `Bearer ${token}`);
				}
			},
		],
		afterResponse: [
			async (request, options, response) => {
				if (!response.ok) {
					const error = await response.json();
					switch (error?.status) {
						case 404:
							showToast(error?.error, "Error");
							break;
						case 401:
							await checkAccessToken().then((status) => {
								if (!status) {
									removeAllCookie();
									localStorage.clear();
									window.location.href = guestUser ? "/guest/login" : "/login";
								}
							});
							break;
						default:
							return error;
					}
				}
			},
		],
	},
	prefixUrl: process.env.REACT_APP_API_URL,
});

export { Clientweb };
