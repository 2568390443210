export const locationFilter = [
	{
		id: "1",
		value: "WA",
		label: "Western Australia",
		total: "18",
	},
	{
		id: "2",
		value: "VIC",
		label: "Victoria",
		total: "12",
	},
	{
		id: "3",
		value: "TAS",
		label: "Tasmania",
		total: "10",
	},
	{
		id: "4",
		value: "SA",
		label: "South Australia",
		total: "18",
	},
	{
		id: "5",
		value: "QLD",
		label: "Queensland",
		total: "18",
	},
	{
		id: "6",
		value: "NT",
		label: "Northen Territory",
		total: "18",
	},
	{
		id: "7",
		value: "NSW",
		label: "New South Wales",
		total: "18",
	},
	{
		id: "8",
		value: "ACT",
		label: "Australian Capital Territory",
		total: "18",
	},
];
export const sectorFilter = [
	{
		id: "1",
		value: "1",
		label: "Civil, Arts & Culture",
		total: "18",
	},
	{
		id: "2",
		value: "2",
		label: "Commercial (Office)",
		total: "12",
	},
	{
		id: "3",
		value: "3",
		label: "Defence, Public Safety & Utilities",
		total: "10",
	},
	{
		id: "4",
		value: "4",
		label: "Education & Science",
		total: "18",
	},
	{
		id: "5",
		value: "5",
		label: "Health, Aged Care & Wellbeing",
		total: "18",
	},
	{
		id: "6",
		value: "6",
		label: "Hospitality, Tourism & Entertainment",
		total: "18",
	},
	{
		id: "7",
		value: "7",
		label: "Industrial",
		total: "18",
	},
	{
		id: "8",
		value: "8",
		label: "Civil, Infrastructure & Utilities",
		total: "18",
	},
	{
		id: "9",
		value: "9",
		label: "Mining, Oil & Gas",
		total: "18",
	},
	{
		id: "10",
		value: "10",
		label: "Residential & Retirement",
		total: "18",
	},
	{
		id: "11",
		value: "11",
		label: "Retail",
		total: "18",
	},
	{
		id: "12",
		value: "12",
		label: "Sport, Fitness, Parks & Recreation",
		total: "18",
	},
	{
		id: "13",
		value: "13",
		label: "Transport",
		total: "18",
	},
	{
		id: "14",
		value: "14",
		label: "Urban and Public Realm",
		total: "18",
	},
];

export const projectStatus = [
	{
		id: "1",
		value: "active",
		label: "Active",
		total: "18",
	},
	{
		id: "2",
		value: "cancelled",
		label: "Cancelled",
		total: "18",
	},
	{
		id: "3",
		value: "complete",
		label: "Complete",
		total: "18",
	},
	{
		id: "4",
		value: "on_hold",
		label: "On Hold",
		total: "18",
	},
];
export const RFQStatus = [
	{
		id: "1",
		value: "active",
		label: "Active",
		total: "18",
	},
	{
		id: "2",
		value: "draft",
		label: "Draft",
		total: "18",
	},
	{
		id: "3",
		value: "evaluation",
		label: "Evaluation",
		total: "18",
	},
	{
		id: "4",
		value: "archived",
		label: "Archived",
		total: "18",
	},
];
