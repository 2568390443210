import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	DrawerFooter,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";
import { AccordionSectionProject } from "../AccordionSectionProject";
import { Tags } from "components/Tags";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { Button } from "../Button";

const FormikStyled = styled(Formik)(() => [tw`overflow-y-auto`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
	tw`text-black py-6 border-b`,
	css`
		.close-text {
			font-weight: 400;
			font-size: 14px;
			cursor: pointer;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			align-items: center;
		}

		.clear-container {
			display: flex;
			justify-items: center;
			place-items: center;
		}
	`,
]);

const AppliedFilters = styled.div(() => [
	tw`grid`,
	css`
		.title-filter {
			font-size: 14px;
			font-weight: 600;
			color: black;
			margin-bottom: 16px;
		}
		.chip-container {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
		}
	`,
]);

const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-0`]);

const FilterProject = ({
	onClose,
	isOpen,
	filters,
	setFilters,
	currentTab,
	...props
}) => {
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [prevValues, setPrevValues] = useState(filters);
	const filterOptions =
		useSelector((state) => state.rfq.projects.filterOptions?.[currentTab]) ||
		[];

	const locationFilter =
		filterOptions.length > 0
			? filterOptions?.find((filter) => filter.name === "location")?.item
			: [];
	const projectStatus =
		filterOptions.length > 0
			? filterOptions?.find((filter) => filter.name === "status")?.item
			: [];
	const sectorFilter =
		filterOptions.length > 0
			? filterOptions?.find((filter) => filter.name === "sector")?.item
			: [];

	const initialValues = {
		location: prevValues?.location || [],
		status: prevValues?.status || [],
		sector: prevValues?.sector || [],
	};

	const handleSubmit = async (filters, actions) => {
		if (isSubmiting) {
			setFilters(filters);
			setIsSubmiting(false);
			onClose(true);
		} else {
			actions.resetForm();
		}
	};

	return (
		<div>
			<Drawer
				placement={"right"}
				isOpen={isOpen}
				onClose={onClose}
				size="md"
				{...props}
			>
				<DrawerOverlay onClick={onClose} />
				<FormikStyled
					initialValues={initialValues}
					enableReinitialize={true}
					onSubmit={handleSubmit}
				>
					{({ values, setValues, resetForm }) => {
						setPrevValues(values);
						return (
							<Form>
								<DrawerContent>
									<DrawerHeaderStyled borderBottomWidth="1px">
										<div className="header-container">
											<p className="font-roboto text-base font-bold">
												Filter Projects/Assets
											</p>
											<div className="clear-container">
												<Button
													btntype="plain"
													type={"button"}
													className="close-text font-roboto font-medium"
													style={{ fontSize: "14px" }}
													onClick={() =>
														setValues({ location: "", status: "", sector: "" })
													}
												>
													Clear all filters
												</Button>
												<Button
													btntype="plain-icon"
													type={"button"}
													onClick={onClose}
												>
													<Icon icon={"close"} />
												</Button>
											</div>
										</div>
									</DrawerHeaderStyled>
									<DrawerHeaderStyled>
										<AppliedFilters>
											<p className="title-filter">
												<div
													className="font-roboto font-semibold text-primary-900"
													style={{ fontSize: "14px", lineHeight: "20px" }}
												>
													Applied Project/Asset filters
												</div>
											</p>
											<div className="chip-container">
												{values?.location &&
													locationFilter
														.filter((data) =>
															values?.location.includes(data.value)
														)
														.map((filteredData) => {
															const handleDeleteLocation = (deletedValue) => {
																setValues((prevValues) => ({
																	...prevValues,
																	location: prevValues.location.filter(
																		(newVal) => newVal !== deletedValue
																	),
																}));
															};
															return (
																<Tags
																	key={filteredData.value}
																	deletable
																	handleDelete={() =>
																		handleDeleteLocation(filteredData.value)
																	}
																>
																	<div style={{ fontSize: "14px" }}>
																		{filteredData.label}
																	</div>
																</Tags>
															);
														})}
												{values?.status &&
													projectStatus
														.filter((data) =>
															values?.status.includes(data.value)
														)
														.map((filteredData) => {
															const handleDeleteStatuses = (deletedValue) => {
																setValues((prevValues) => ({
																	...prevValues,
																	status: prevValues.status.filter(
																		(newVal) => newVal !== deletedValue
																	),
																}));
															};
															return (
																<Tags
																	key={filteredData.value}
																	deletable
																	handleDelete={() =>
																		handleDeleteStatuses(filteredData.value)
																	}
																>
																	{filteredData.label}
																</Tags>
															);
														})}
												{values?.sector &&
													sectorFilter
														.filter((data) =>
															values?.sector.includes(data.value?.toString())
														)
														.map((filteredData) => {
															const handleDeleteSector = (deletedValue) => {
																setValues((prevValues) => ({
																	...prevValues,
																	sector: prevValues.sector.filter(
																		(newVal) => newVal !== deletedValue
																	),
																}));
															};
															return (
																<Tags
																	key={filteredData.value}
																	deletable
																	handleDelete={() =>
																		handleDeleteSector(
																			filteredData.value?.toString()
																		)
																	}
																>
																	{filteredData.label}
																</Tags>
															);
														})}
											</div>
										</AppliedFilters>
									</DrawerHeaderStyled>
									<DrawerBodyStyled>
										<AccordionSectionProject filterOptions={filterOptions} />
									</DrawerBodyStyled>
									<DrawerFooter>
										<Button
											size="full"
											type="submit"
											onClick={() => setIsSubmiting(true)}
										>
											Apply Filters
										</Button>
									</DrawerFooter>
								</DrawerContent>
							</Form>
						);
					}}
				</FormikStyled>
			</Drawer>
		</div>
	);
};

export default FilterProject;
