import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

export const ErrorStyled = styled.p`
	font-family: "roboto", sans-serif;
	color: ${(props) => props.theme.colors.danger};
	${tw`-mt-5 text-utility-error-500`}
	font-size: ${(props) => props.theme.fontSizes.sm};
	left: 0px;
	${(props) =>
		props.leftAlign &&
		`
        left:0px;
    `}
`;

export const ErrorText = ({ text, ...props }) => {
	return <ErrorStyled {...props}>{text}</ErrorStyled>;
};

export const Error = ({ meta, errorBottom, text, leftAlign, ...props }) => {
	if ((meta.touched && meta.error) || text) {
		return (
			<ErrorText
				errorBottom={errorBottom}
				text={
					text ||
					(Array.isArray(meta.error)
						? meta.error[0]
						: meta.error.value || meta.error)
				}
				leftAlign={leftAlign}
				{...props}
			/>
		);
	} else {
		return null;
	}
};
