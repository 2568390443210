import React from "react";
import styled from "styled-components";

export const CompanyTileStyled = styled.div`
	background-color: transparent;
	display: flex;
	align-items: center;

	.company-tile {
		&__img {
			width: 75px;
			border-radius: ${(props) => props.theme.radii.default};
		}

		&__title {
			font-size: ${(props) => props.theme.fontSizes.sm};
			color: ${(props) => props.theme.colors.navy};
			margin: 0;
		}
	}
`;

export const CompanyTile = ({ title, ...props }) => {
	return (
		<CompanyTileStyled className="company-tile">
			{/* <img src={process.env.PUBLIC_URL + '/img/placeholder-sm.jpg'} className='company-tile__img' alt='' /> */}
			<h3 className="company-tile__title">{title}</h3>
		</CompanyTileStyled>
	);
};

CompanyTile.defaultProps = {
	title: "Project Name",
};
