import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

import { TileGroupStyled, Content } from "./styled";
import { CompanyTile } from "../CompanyTile";
import { ActionButton } from "../ActionButton";

export const TileGroup = ({ projects, ...props }) => {
	const [height, setHeight] = useState(props.highlight ? "auto" : 0);

	const handleOpen = () => {
		setHeight(height === 0 ? "auto" : 0);
	};

	return (
		<TileGroupStyled
			{...props}
			className="tile-group"
		>
			<div
				className="tile-group__header"
				onClick={handleOpen}
			>
				<h3 className="tile-group__title">{props.heading}</h3>
				<ActionButton
					size="xs"
					icon="expand"
					open={height !== 0}
					className="tile-group__toggle"
				/>
			</div>

			<Content
				as={AnimateHeight}
				duration={500}
				height={height}
			>
				<div>
					{projects ? (
						<>
							{projects.length ? (
								<>
									{projects.map((project) =>
										project ? (
											<CompanyTile
												key={project.id}
												title={project.name}
											/>
										) : (
											""
										)
									)}
								</>
							) : (
								<CompanyTile title="No projects found." />
							)}
						</>
					) : (
						<>
							<CompanyTile />
							<CompanyTile />
						</>
					)}
				</div>
			</Content>
		</TileGroupStyled>
	);
};
