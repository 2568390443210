import React from "react";
import styled from "styled-components";

import { ReactComponent as Cross } from "../../icons/exclamation-circle.svg";

const NoResultsStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	h2 {
		margin: 0 0 0 1rem;
	}

	svg {
		color: ${(props) => props.theme.colors.gray};
	}
`;

export const NoResults = ({ text, ...props }) => {
	return (
		<NoResultsStyled>
			<Cross />
			<h2>{text ? text : "No results found"}</h2>
		</NoResultsStyled>
	);
};
