import styled from "styled-components";

import { DocumentItemStyled } from "../DocumentItem/styled";

export const CompanyResultCardStyled = styled.div`
	background: ${(props) => props.theme.colors.snow};
	padding: ${(props) => props.theme.space.xs};
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	transition: ${(props) => props.theme.transitions.default};
	border-radius: 4px;
	width: ${(props) => (props.compareCard ? "400px" : "300px")};
	min-width: ${(props) => (props.compareCard ? "400px" : "300px")};
	height: 100%;

	&::hover {
		background: white;
		.stick {
			background-color: white;
			border-bottom: 1px solid ${(props) => props.theme.colors.snow};
		}
	}

	${DocumentItemStyled} {
		margin-bottom: ${(props) => props.theme.space.xs};

		.document {
			&__title {
				font-size: ${(props) => props.theme.fontSizes.sm};
			}
		}
	}

	.updated_at {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		color: ${(props) => props.theme.colors.gray};
		line-height: 12px;
		letter-spacing: 0.04em;
	}
	.stick {
		background-color: ${(props) => props.theme.colors.snow};
	}
	.matchesLabel {
		padding: 6px 1.25rem;
		background: #e4e4e4;
		border-radius: 4px;
	}
	.matched {
		background: #2fc67d;
		color: white;
	}
	.countLabel {
		display: block;
		width: 50%;
		text-align: center;
		padding: 8px 1.25rem;
		border-radius: 4px;
		background: #003acd;
		color: white;
		font-weight: 500;
	}
	.matchesContainer {
		width: 100%;
	}
`;

export const Buttons = styled.div`
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: ${(props) => props.theme.space.xxs};

	button {
		margin-right: ${(props) => props.theme.space.xxs};
		width: 100%;
		flex-shrink: 1;

		&:last-of-type {
			margin-right: 0;
		}
	}
`;

export const Header = styled.div`
	width: 100%;
	height: 150px;
	position: relative;
	margin-bottom: ${(props) => props.theme.space.xxs};
`;

export const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.55;
	border-radius: 4px;
`;

export const Logo = styled.img`
	width: 100px;
	height: 100px;
	object-fit: contain;
	border-radius: 50%;
	background: white;
	display: block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: -1rem;
	padding: 10px;
	box-shadow: 3px 3px 9px 2px #b1b1b1;
`;

export const HeaderOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeaderControls = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: ${(props) => props.theme.space.xxs};
	margin-bottom: 0;

	> div {
		button {
			margin-right: ${(props) => props.theme.space.xxs};

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
`;

export const CompanyName = styled.h2`
	color: ${(props) => props.theme.colors.grayDark};
	font-size: ${(props) => props.theme.fontSizes.xl};
	margin: 0 0 5px 0;
	font-weight: 700;

	&.sticky-header {
		position: fixed;
		top: 0px;
		background-color: ${(props) => props.theme.colors.white};
		width: 100%;
		padding: 30px 30px 30px 10px;
		z-index: 999;
	}

	&.hide {
		display: none;
	}
`;
