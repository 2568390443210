import React from "react";
import ReactTooltip from "react-tooltip";
import { PassRule, ValidatorWrapper } from "./styled";
import { ReactComponent as Wrong } from "../../../icons/wrong.svg";
import { ReactComponent as Right } from "../../../icons/right.svg";
import PropTypes from "prop-types";

export const PasswordValidator = ({
	dataId,
	className,
	arrowColor,
	place,
	passwordRules,
	isPAPLUser,
}) => {
	return (
		<ValidatorWrapper>
			<ReactTooltip
				className={`pass-validation ${className}`}
				id={dataId}
				place={place}
				arrowColor={arrowColor}
			>
				<PassRule className="title">
					Password must meet the following requirements:
				</PassRule>
				<PassRule className={`rule ${passwordRules.letter ? "valid" : ""} `}>
					{passwordRules.letter ? (
						<Right />
					) : (
						<Wrong
							height={15}
							width={15}
						/>
					)}{" "}
					At least &nbsp; <b> one letter</b>
				</PassRule>
				<PassRule className={`rule ${passwordRules.capital ? "valid" : ""} `}>
					{passwordRules.capital ? (
						<Right />
					) : (
						<Wrong
							height={15}
							width={15}
						/>
					)}{" "}
					At least one &nbsp;<b> capital letter</b>
				</PassRule>
				<PassRule className={`rule ${passwordRules.number ? "valid" : ""} `}>
					{passwordRules.number ? (
						<Right />
					) : (
						<Wrong
							height={15}
							width={15}
						/>
					)}{" "}
					At least &nbsp;<b> one number</b>
				</PassRule>
				<PassRule className={`rule ${passwordRules.count ? "valid" : ""} `}>
					{passwordRules.count ? (
						<Right />
					) : (
						<Wrong
							height={15}
							width={15}
						/>
					)}{" "}
					Be at least &nbsp;<b> {isPAPLUser ? 15 : 8} characters</b>
				</PassRule>
				<PassRule
					className={`rule ${passwordRules.specialCharacter ? "valid" : ""} `}
				>
					{passwordRules.specialCharacter ? (
						<Right />
					) : (
						<Wrong
							height={15}
							width={15}
						/>
					)}{" "}
					Be at least &nbsp;<b> one special character</b>
				</PassRule>
			</ReactTooltip>
		</ValidatorWrapper>
	);
};

PasswordValidator.defaultProps = {
	arrowColor: "#e3e6ea",
	place: "bottom",
};

PasswordValidator.propTypes = {
	dataId: PropTypes.string,
	passwordRules: PropTypes.object,
};
