import { Icon } from "assets/icons/Icon";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { useField } from "formik";
import React, { useState } from "react";
import LabelForm from "../LabelForm/LabelForm";

const TableText = ({ name, rowIndex, colIndex, value }) => {
	const [, meta] = useField(`${name}-${rowIndex}`);

	const renderTextValue =
		colIndex === 1
			? meta?.value || "<p class='text-gray-400'>Answer here</p>"
			: value;

	return (
		<div className="relative">
			<div
				className={`${colIndex === 0 && "font-bold"} rfx-response-table`}
				dangerouslySetInnerHTML={{ __html: renderTextValue }}
			/>
			{meta.error && colIndex === 1 && (
				<p className="mt-1 text-sm text-red-500">{meta.error}</p>
			)}
		</div>
	);
};

export default function QuestionTypeTableBidder({ tableQuestions, ...props }) {
	const headers = tableQuestions.map((row) => row.header);
	const values = tableQuestions[0]?.values?.map((_, index) =>
		tableQuestions.map((row) => (row?.values ? row.values[index] : ""))
	);
	const [editIndex, setEditIndex] = useState({});

	return (
		<div>
			<LabelForm
				label={props?.question}
				required={props?.required}
			/>
			<table className="w-full table-auto rounded-xl border-b border-solid border-[#EAECF0] bg-white">
				<thead>
					<tr>
						{headers?.map((header, index) => (
							<th
								key={index}
								className="w-1/5 bg-[#F9FAFB] p-2 text-left font-roboto font-medium text-tertiary-600"
							>
								<h2 className="font-bold">{header}</h2>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{values?.map((row, rowIndex) => (
						<tr
							key={rowIndex}
							className="border-b border-[#EAECF0]"
						>
							{row.map((value, colIndex) => (
								<td className="border border-[#EAECF0] px-3 text-left align-top font-roboto font-normal text-primary-500">
									{editIndex?.rowIndex === rowIndex && colIndex === 1 ? (
										<div className="flex w-full items-start gap-2">
											{props?.name && (
												<>
													<FormatedInput
														name={
															`${props?.name}-${rowIndex}` || "questionnaire"
														}
														placeholder="value"
														onClick={() => setEditIndex({ colIndex, rowIndex })}
														className="w-full"
													/>
													<button onClick={() => setEditIndex({})}>
														<Icon icon="check" />
													</button>
												</>
											)}
										</div>
									) : (
										<p
											className="py-2"
											onClick={() =>
												colIndex === 1 &&
												!props?.disabled &&
												setEditIndex({ colIndex, rowIndex })
											}
										>
											{
												<TableText
													name={props?.name}
													rowIndex={rowIndex}
													colIndex={colIndex}
													value={value}
												/>
											}
											{/*  */}
										</p>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
