import { Skeleton } from "@chakra-ui/react";
import React from "react";
import tw, { styled, css } from "twin.macro";

const GridViewStyled = styled.div(() => [tw`py-8 flex gap-6 flex-wrap`]);

const ProjectCardSkeletonStyled = styled.div(() => [
	tw`w-[350px]  min-h-[370px] rounded-md border-t-8 bg-gray-50 relative drop-shadow-lg mb-8`,
	css`
		#card-content {
			padding: 24px;
			background-color: white;
			height: 100%;
			max-height: 280px;
			position: relative;
			display: grid;
			align-content: space-between;
			bottom: 0;
		}
		#header-card {
			height: 104px;
		}
	`,
]);

const ProjectTileSkeleton = () => {
	return (
		<ProjectCardSkeletonStyled>
			<div id="header-card"></div>
			<div id="card-content">
				<Skeleton
					height="40px"
					mb="8px"
					startColor="#667085"
				/>
				<Skeleton
					height="40px"
					mb="8px"
					startColor="#667085"
				/>
				<Skeleton
					height="40px"
					mb="8px"
					startColor="#667085"
				/>
				<Skeleton
					height="40px"
					mb="8px"
					startColor="#667085"
				/>
			</div>
		</ProjectCardSkeletonStyled>
	);
};

const ProjectTile = () => {
	return (
		<GridViewStyled>
			{[...Array(6)].map(() => (
				<ProjectTileSkeleton />
			))}
		</GridViewStyled>
	);
};

export default ProjectTile;
