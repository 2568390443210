import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
} from "@chakra-ui/react";
import apiV2 from "api-v2";
import { navigate } from "@reach/router";
import { setProjectArchived } from "actions/RFQ/projectsAction";
import { setAwardedSupplier } from "actions/RFQ/rfqsAction";
import { Icon } from "assets/icons/Icon";
import { Image } from "assets/img";
import { DateInput } from "components/DateInput";
import { Button } from "components/RFQ/Button";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import tw, { styled } from "twin.macro";
import { cn } from "utils/helpers";
import * as yup from "yup";
import moment from "moment";
import { showToast } from "utils/helpers";
import { VStack } from "@chakra-ui/react";

const validationSchemaAward = yup.object().shape({
	company: yup.string().required("Required"),
	rfq: yup.string().required("Required"),
	message: yup.string().required("Required"),
	file: yup
		.mixed()
		.test("is-string-or-file", "Must be a file", (value) => {
			return typeof value === "string" || value?.[0] instanceof File;
		})
		.required("Required"),
});

const validationSchemaAwardGuest = yup.object().shape({
	rfq: yup.string().required("Required"),
	message: yup.string().required("Required"),
	file: yup
		.mixed()
		.test("is-string-or-file", "Must be a file", (value) => {
			return typeof value === "string" || value?.[0] instanceof File;
		})
		.required("Required"),
});
const validationSchemaDeclineGuest = yup.object().shape({
	rfq: yup.string().required("Required"),
	message: yup.string().required("Required"),
});
const validationSchemaDecline = yup.object().shape({
	company: yup.string().required("Required"),
	rfq: yup.string().required("Required"),
	message: yup.string().required("Required"),
});

const validationSchemaProject = yup.object().shape({
	rfq: yup.string().required("Required"),
	reason: yup.string().required("Required"),
	message: yup.string().required("Required"),
});

const validationSchemaPause = yup.object().shape({
	date: yup.date().required("Required"),
	rfq: yup.string().required("Required"),
	message: yup.string().required("Required"),
});

const ContainerLogo = styled.div(() => [
	tw`relative w-[480px] h-[48px] text-center items-center flex`,
]);

const AwardSupplierModal = ({
	isOpen,
	company,
	onClose,
	rfq,
	typeModal,
	setLoadRFQData,
	refreshData,
	guestName,
	guestEmail,
	isSubmissionPreview,
}) => {
	const companyId = typeModal?.companyId;
	const projectId = typeModal?.projectId;
	const supplierInvitedList = rfq?.supplierInvited?.all || [];
	const listExcludeAwards = [
		"unsuccessful",
		"decline",
		"awarded",
		"invited",
		"inprogress",
	];
	const dispatch = useDispatch();

	let filterInvitedList = supplierInvitedList.filter((f) => {
		return !listExcludeAwards.includes(f?.status?.toLowerCase());
	});

	const projectsOptions = company
		? [{ value: company?.id, label: company?.name }]
		: filterInvitedList?.map((company, index) => ({
				value: company?.company?.id
					? company?.company?.id
					: filterInvitedList[index]?.email,
				label: company?.company?.name
					? company?.company?.name
					: filterInvitedList[index]?.name,
			}));

	const reasonOptions = [
		{ value: "finish", label: "Finished Project" },
		{ value: "service", label: "Services Longer Needed" },
	];

	const IntialValueFeedback = {
		rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
		company: guestEmail || companyId || "",
		message: "",
		status: 1,
		file: null,
	};
	const InitialValueArchive = {
		rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
		reason: "",
		message: "",
	};
	const InitialValueDelete = {
		rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
		status: 0,
		company: companyId || "",
		reason: "",
		message: "",
	};
	const InitialValuePause = {
		rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
		company: companyId || "",
		date: "",
		message: "",
	};

	let InitialValueModal = null;
	let validationSchema = null;
	switch (typeModal.type) {
		case "award":
			InitialValueModal = IntialValueFeedback;
			validationSchema =
				guestName !== undefined
					? validationSchemaAwardGuest
					: validationSchemaAward;
			break;
		case "archive":
			InitialValueModal = InitialValueArchive;
			validationSchema = validationSchemaProject;
			break;
		case "decline":
			InitialValueModal = InitialValueDelete;
			validationSchema =
				guestName !== undefined
					? validationSchemaDeclineGuest
					: validationSchemaDecline;
			break;
		case "pause":
			InitialValueModal = InitialValuePause;
			validationSchema = validationSchemaPause;
			break;
		case "cancel":
			InitialValueModal = InitialValueDelete;
			validationSchema = validationSchemaProject;
			break;
		default:
			InitialValueModal = IntialValueFeedback;
			validationSchema = validationSchemaAward;
			break;
	}

	let content = {};
	switch (typeModal.type) {
		case "award":
			content = {
				title: "Award this supplier",
				icon: "check-circle",
				iconColor: "text-royal-blue bg-soft-blue",
				description:
					"Congratulations on your selection. Please notify the chosen bidder using the message box below.",
				selectionLabel: "Company name",
				name: "company",
				typeField: "select",
				messageLabel: "Message",
				options: projectsOptions,
				hint: "This is a hint text to help user.",
				isFileUpload: true,
				isDisabled: true,
			};
			break;
		case "decline":
			content = {
				title: "Decline this supplier",
				icon: "x-square",
				iconColor: "bg-utility-warning-secondary text-fg-warning",
				description:
					"Please notify the declined bidder using the message box below.",
				selectionLabel: "Company name",
				name: "company",
				typeField: "select",
				messageLabel: "Message",
				options: projectsOptions,
				hint: "This is a hint text to help user.",
				isFileUpload: false,
				isDisabled: true,
			};
			break;
		case "archive":
			content = {
				title: "Archive Project",
				icon: "archive",
				iconColor: "bg-utility-gray-100 text-primary-500",
				description:
					"Store and preserve project information for long-term access and reference.",
				selectionLabel: "Reason",
				options: reasonOptions,
				name: "reason",
				typeField: "select",
				messageLabel: "Notes",
				hint: "This is a hint text to help user.",
				isFileUpload: false,
				isDisabled: false,
			};
			break;
		case "pause":
			content = {
				title: "Pause Project",
				icon: "pause-square",
				iconColor: "bg-utility-gray-100 text-primary-500",
				description:
					"Temporarily halt project activities and operations until further notice.",
				selectionLabel: "Estimated Restart Date",
				name: "date",
				typeField: "date",
				messageLabel: "Message ",
				hint: "This is a hint text to help user.",
				isFileUpload: false,
				isDisabled: false,
			};
			break;
		case "cancel":
			content = {
				title: "Cancel Project",
				icon: "trash-03",
				iconColor: "bg-utility-gray-100 text-primary-500",
				description:
					"Temporarily halt project activities and operations until further notice.",
				selectionLabel: "Reason",
				name: "reason",
				options: reasonOptions,
				typeField: "select",
				messageLabel: "Notes",
				hint: "This is a hint text to help user.",
				isFileUpload: false,
				isDisabled: false,
			};
			break;
		default:
			content = {};
			break;
	}

	const pauseHandler = async (values) => {
		try {
			let formData = new FormData();
			formData.append(
				"restart_date",
				moment(values?.date).format("YYYY-MM-DD")
			);
			formData.append("message", values?.message);
			let res = await apiV2.post(`project/${projectId}/pause`, formData);
			if (res.status === 200) {
				showToast(res?.data?.message, "Success", true);
			}
		} catch (err) {
			showToast(err?.response?.data?.message, "Error");
		}
		onClose();
	};

	const handleRFQActionSubmit = async (values) => {
		try {
			!isSubmissionPreview && setLoadRFQData(true);
			if (typeModal.type === "archive") {
				archivedHandler(projectId);
			} else if (typeModal.type === "cancel") {
				// cancelHandler(projectId);
			} else if (typeModal.type === "pause") {
				await pauseHandler(values);
			} else {
				values.email = guestEmail;
				let res = await setAwardedSupplier(rfq?.id, values, typeModal.type);
				refreshData();
				if (res) {
					onClose();
				}
			}
		} catch (error) {
			console.log("error", error);
		}
		!isSubmissionPreview && setLoadRFQData(false);
	};

	const archivedHandler = async (id) => {
		await dispatch(setProjectArchived(id)).then((data) => {
			if (data?.status) {
				navigate("../../projects/");
			}
		});
	};

	return (
		<div className="translate-y-1/2 overflow-hidden">
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				height="100%"
				size={"xl"}
				closeOnEsc
			>
				<ModalOverlay />
				<ModalContent>
					<Formik
						initialValues={InitialValueModal}
						onSubmit={handleRFQActionSubmit}
						validationSchema={validationSchema}
					>
						{({ isSubmitting, isValid, dirty, setFieldValue }) => (
							<Form>
								<ModalHeader>
									<div className="overflow-hidden">
										<ContainerLogo>
											<Image
												img="background-pattern-02"
												className="absolute -left-5 -top-10 z-0"
												style={{ width: "180px" }}
											/>
											<div
												className={cn(
													"h-10 w-10 content-center items-center rounded-full",
													content.iconColor
												)}
												style={{ textAlign: "-webkit-center" }}
											>
												<Icon icon={content.icon} />
											</div>
										</ContainerLogo>
										<h2 className="relative z-20 text-lg font-semibold text-primary-900">
											{content.title}
										</h2>
										<p className="text-sm font-normal text-tertiary-600">
											{content.description}
										</p>
									</div>
								</ModalHeader>
								<ModalCloseButton onClose={onClose} />
								<ModalBody>
									<VStack
										id="award-supplier-modal-body"
										spacing={6}
										align="stretch"
									>
										<FieldForm
											label="RFx"
											name="rfq"
											type="text"
											disabled={true}
											icon="file-attachment-04"
											className={"text-primary-500"}
										/>
										{content.typeField === "date" ? (
											<DateInput
												icon={true}
												label={content.selectionLabel}
												name="date"
												className="mb-1"
											/>
										) : (
											<>
												{content.selectionLabel === "Company name" ? (
													<>
														{!guestName ? (
															<FieldForm
																label={content.selectionLabel}
																name={content.name}
																type="select"
																value={company?.id}
																options={content.options}
																isDisabled={companyId ? true : false}
															/>
														) : (
															<>
																<FieldForm
																	label={content.selectionLabel}
																	name={content.name}
																	type="select"
																	value={guestName}
																	options={[
																		{
																			value: guestName,
																			label: guestName,
																		},
																	]}
																	isDisabled={true}
																/>
															</>
														)}
													</>
												) : (
													<>
														<FieldForm
															label={content.selectionLabel}
															name={content.name}
															type="select"
															options={content.options}
															isDisabled={companyId ? true : false}
														/>
													</>
												)}
											</>
										)}
										<FieldForm
											type="textArea"
											label={content.messageLabel}
											name="message"
											placeholder="Enter a description..."
										/>
										{content.isFileUpload && (
											<FileUpload
												type="file"
												name="file"
												className="my-4 w-full"
												types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
												defaultFileName="Document"
												isDisabled={true}
												maxFileSize={50}
											/>
										)}
									</VStack>
								</ModalBody>
								<ModalFooter>
									<Flex
										gap={3}
										className="!w-full"
									>
										<Button
											btntype={"base"}
											style={{ width: "100%" }}
											onClick={onClose}
											type="button"
										>
											Cancel
										</Button>
										<Button
											style={{ width: "100%" }}
											disabled={!isValid || !dirty}
										>
											{isSubmitting ? <Spinner></Spinner> : "Confirm"}
										</Button>
									</Flex>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default AwardSupplierModal;
