import React, { useState, useRef } from "react";
import { Icon } from "assets/icons/Icon";
import { Flex } from "@chakra-ui/core";
import { cn } from "utils/helpers";
import tw, { styled, css } from "twin.macro";
import useOnClickOutside from "utils/useClickOutside";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "components/Popover/Popover";

const DropdownMenuTriggerStyled = styled(Flex)(({ className }) => [
	tw`w-fit m-1`,
	className,
]);

const IconDotStyled = styled.div(({ noBg }) => [
	tw`rounded-full bg-white w-7 h-7 text-center content-center cursor-pointer`,
	noBg && tw`bg-transparent`,
	css`
		svg {
			position: relative;
			margin-left: auto;
			margin-right: auto;
		}
	`,
]);

export const DropdownMenuTrigger = ({
	children,
	className,
	setIsOpen,
	isOpen,
	icon,
	noBg,
	mutedIcon,
	...props
}) => {
	return (
		<DropdownMenuTriggerStyled
			className={className}
			{...props}
			onClick={() => setIsOpen(!isOpen)}
		>
			{children}
			{icon && (
				<IconDotStyled noBg={noBg}>
					<Icon
						icon="dots-vertical"
						className={mutedIcon && "text-fg-senary-300"}
					/>
				</IconDotStyled>
			)}
		</DropdownMenuTriggerStyled>
	);
};

const DropdownMenuDrawerStyled = styled.div(({ className }) => [
	tw`grid bg-white border -right-7 min-w-52 z-50 border-gray-200 mt-2 rounded-xl absolute p-1.5`,
	className,
]);

export const DropdownMenuDrawer = ({ children, className, ...props }) => {
	return (
		<DropdownMenuDrawerStyled
			className={`${className} dropdown-shadow`}
			{...props}
		>
			{children}
		</DropdownMenuDrawerStyled>
	);
};

export const MenuDivider = styled.div(() => [
	tw`border-gray-200 border-b text-gray-200 my-1 w-[calc(100% + 14px)] mx-[-8px]`,
]);

const DropdownItemStyled = styled(Flex)(({ selected, className }) => [
	tw`relative gap-2 items-center hover:bg-utility-blue-light-50 cursor-pointer p-2.5 hover:rounded-md transition-all [transition-duration: 0.18s]`,
	selected && tw`bg-utility-blue-light-50`,
	className,
]);

export const DropdownItem = ({ children, className, selected, ...props }) => {
	return (
		<DropdownItemStyled
			className={className}
			selected={selected}
			{...props}
		>
			{children}
		</DropdownItemStyled>
	);
};

export const DropdownMenu = ({ children, className, keyFor, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dropdownMenuTrigger = React.cloneElement(children[0], {
		setIsOpen,
		isOpen,
	});
	const dropdownMenuDrawer = React.cloneElement(children[1], {
		isOpen,
		setIsOpen,
		ref: dropdownRef,
	});

	useOnClickOutside(dropdownRef, () => {
		setIsOpen(false);
	});

	return (
		<div
			ref={dropdownRef}
			className={cn("relative", className)}
			{...props}
		>
			<Popover
				placement="bottom-start"
				isOpen={isOpen}
				openHandler={() => setIsOpen(!isOpen)}
				closeHandler={() => setIsOpen(false)}
				offset={[0, 0]}
				keyFor={keyFor}
			>
				<PopoverTrigger>{dropdownMenuTrigger}</PopoverTrigger>
				<PopoverContent>{dropdownMenuDrawer}</PopoverContent>
			</Popover>
		</div>
	);
};
