/**
 * function to comparing reference date with target date
 * so can return string like today,yesterday,this week
 * @param {*} referenceDate
 * @param {*} targetDate
 * @returns
 */

function getDateRelation(referenceDate, targetDate) {
	// Parse the referenceDate and targetDate strings into Date objects
	const reference = new Date(referenceDate);
	const target = new Date(targetDate);

	// Get the start of the current week (Monday)
	const startOfWeek = new Date(reference);
	const diff = reference.getDay() - 1; // Calculate how many days to subtract to get to Monday
	startOfWeek.setDate(startOfWeek.getDate() - diff);
	startOfWeek.setHours(0, 0, 0, 0); // Set hours to 00:00:00 to compare dates accurately

	// Get today's date
	const today = new Date();
	today.setHours(0, 0, 0, 0); // Set hours to 00:00:00 to compare dates accurately

	// Get yesterday's date
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	// Compare targetDate with today, yesterday, and startOfWeek
	if (target.toDateString() === today.toDateString()) {
		return "Today";
	} else if (target.toDateString() === yesterday.toDateString()) {
		return "Yesterday";
	} else if (target >= startOfWeek && target <= today) {
		return "This week";
	} else {
		return targetDate;
	}
}

/**
 * function to get current date
 * @returns
 */
function getCurrentDate() {
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
	const day = ("0" + currentDate.getDate()).slice(-2);

	return `${year}-${month}-${day}`;
}

export { getDateRelation, getCurrentDate };
