import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImageUploadStyled, ImagePreview, DeleteStyled } from "./styled";
import { Button } from "../../Button";
import { ReactComponent as InfoIcon } from "./../../../icons/info.svg";
import { Error } from "../Error";
import ReactTooltip from "react-tooltip";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { showToast, cn } from "utils/helpers";
import { useField } from "formik";

export const ImageUpload = ({
	setFieldValue,
	name,
	image,
	guideLine,
	required,
	isFullHeight,
	...props
}) => {
	const [files, setFiles] = useState([{ preview: image }]);
	const [, meta] = useField(name);
	const maxFileSize = 10485760;
	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			if (acceptedFiles[0].size <= maxFileSize) {
				setFieldValue(name, acceptedFiles[0]);
				if (acceptedFiles[0].size <= maxFileSize) {
					setFieldValue(name, acceptedFiles[0]);

					setFiles(
						acceptedFiles.map((file) =>
							Object.assign(file, {
								preview: URL.createObjectURL(file),
							})
						)
					);
				} else {
					showToast(`Maximum file size is ${10}MB.`, "Error");
				}

				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			} else {
				showToast(`Maximum file size is ${10}MB.`, "Error");
			}
		},
	});
	const deleteHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setFieldValue(name, "");
		setFiles({ preview: "" });
	};

	return (
		<div className={cn(`relative mb-4 flex flex-col pb-4`, props.className)}>
			{props.label && (
				<LabelForm
					label={props.label}
					required={required}
				>
					<div>
						{guideLine && (
							<InfoIcon
								data-tip
								data-for={props.name}
							/>
						)}
						<ReactTooltip
							className="guide"
							id={props.name}
							place="right"
							arrowColor="transparent"
						>
							{guideLine}
						</ReactTooltip>
					</div>
				</LabelForm>
			)}
			<ImageUploadStyled
				{...getRootProps({ className: "dropzone" })}
				{...props}
				className={cn(isFullHeight && "!grow")}
			>
				<input {...getInputProps()} />
				{files[0]?.preview ? (
					<ImagePreview>
						<img
							src={files[0].preview}
							alt={files[0].name}
						/>
						{props?.module === "key-personel" && (
							<DeleteStyled onClick={deleteHandler} />
						)}
					</ImagePreview>
				) : (
					<>
						<p style={{ padding: "10px", margin: 0, fontSize: "14px" }}>
							Drop file here to upload...
						</p>
						<Button
							btntype={"secondary"}
							size={"xs"}
							width={"140px"}
							textfont={"14px"}
							boxshadow={false}
							type="button"
						>
							<span>File Upload</span>
						</Button>
					</>
				)}
			</ImageUploadStyled>
			<Error
				errorBottom
				meta={meta}
				className={"!bottom-0 !left-0 text-sm !text-utility-error-500"}
			/>
		</div>
	);
};
