import React, { useEffect } from "react";
import { useField } from "formik";
import { IndigenousCheckBoxWrapper } from "components/CompanyInformationForm/styled";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import TimePicker from "react-time-picker";
import tw, { styled } from "twin.macro";
import { css } from "@emotion/react";
import { Icon } from "assets/icons/Icon";

const InputWrapper = styled.div(({ isError }) => [
	tw`flex gap-4 items-center px-3 py-4 max-h-[58px] h-[58px] border border-gray-300 rounded-md 
    focus-within:border-utility-brand-300 focus-within:ring-4 ring-ring-blue-primary`,
	isError &&
		tw`border-red-500 focus-within:border-utility-error-300 focus-within:ring-4 !ring-ring-red-primary`,
	css`
		select {
			background: white;
		}
		.react-time-picker__wrapper {
			${tw`flex min-w-[124px] max-w-[124px]`}
			input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			input[type="number"] {
				-moz-appearance: textfield;
			}
		}
		.react-time-picker__inputGroup {
			${tw`w-full min-w-[140px] max-w-[140px]`}
		}
		.react-time-picker__inputGroup__hour {
			${tw`min-w-4 outline-none`}
		}
		.react-time-picker__inputGroup__minute {
			${tw`min-w-4 outline-none`}
		}
		.react-time-picker__inputGroup__divider {
			${tw`pl-1 pr-2 outline-none`}
		}
		.react-time-picker__inputGroup__amPm {
			${tw`outline-none -ml-2 `}
		}
		.select-timezone {
			${tw`outline-none -ml-1`}
		}
	`,
]);

const TimePickerField = ({
	className,
	label,
	selectedIcon,
	name,
	nameTimezone,
	hasDefaultValue = false,
	...props
}) => {
	const [timezoneField] = useField(nameTimezone);
	const [field, meta, helper] = useField(name);
	const isError = meta.touched && meta.error;

	useEffect(() => {
		if (hasDefaultValue && !field.value) {
			const now = new Date();
			const currentTime = now.toLocaleTimeString("en-AU", {
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
			});
			helper.setValue(currentTime);
		}
	}, [hasDefaultValue, field.value, helper]);

	return (
		<IndigenousCheckBoxWrapper>
			<LabelForm label={label}></LabelForm>
			<InputWrapper isError={isError}>
				<Icon icon="clock" />
				<TimePicker
					clearIcon={null}
					disableClock
					format="hh:mm a"
					amPmAriaLabel="Select AM/PM"
					onChange={helper.setValue}
					value={field.value}
				/>
				<select
					className="select-timezone focusable"
					{...timezoneField}
				>
					<option
						value="AWST"
						selected
					>
						AWST
					</option>
					<option value="AEST">AEST</option>
				</select>
			</InputWrapper>
			{meta.touched && meta.error && (
				<p className="mt-1 text-sm text-red-500">{meta.error}</p>
			)}
		</IndigenousCheckBoxWrapper>
	);
};

export default TimePickerField;
