import { Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { STATUS_DRAFT, STATUS_EVALUATION } from "utils/constants";
import { Icon } from "assets/icons/Icon";
const { navigate } = require("@reach/router");
const { createRfq } = require("actions/RFQ/rfqsAction");
const { Button } = require("components/RFQ/Button");

export const RFQButtonActions = ({ project, rfq }) => {
	const [loadCreatingRfq, setLoadCreatingRfq] = useState(false);
	const rfqId = rfq?.id || null;
	const isHaveRFQ = rfqId !== null;
	const statusName =
		typeof rfq?.status === "string" ? rfq?.status?.toUpperCase() : rfq?.status;
	const isDraft = statusName === STATUS_DRAFT;
	const isPastDeadline = statusName === STATUS_EVALUATION;

	const fullNameRFQWithSlug = project?.name;
	const slug = fullNameRFQWithSlug?.toLowerCase().replace(/ /g, "-") + "";

	const renderButtonText = () => {
		if (!isHaveRFQ)
			return (
				<>
					{" "}
					<Icon
						icon="plus"
						className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
					/>
					Create RFx
				</>
			);
		if (!isPastDeadline && !isDraft) return "View RFx";
		if (isPastDeadline) return "View responses";
		if (isDraft) return "View Draft";
	};

	const handleClick = () => {
		if (!isHaveRFQ) {
			setLoadCreatingRfq(true);
			const redirectUrl = (id) => `../../request-for-quote/create-rfx/${id}`;
			const data = {
				project_id: project.id,
				supplier_list_id: rfq?.supplierListId,
			};
			createRfq(data)
				.then((res) => {
					if (res) {
						navigate(redirectUrl(res.id), { state: { rfqData: res } });
						window.scrollTo(0, 0);
						setLoadCreatingRfq(false);
					}
				})
				.catch((err) => {
					setLoadCreatingRfq(false);
					console.log(err);
				});
		} else if (isDraft) {
			navigate(`../../request-for-quote/edit-rfx/${rfqId}`);
			window.scrollTo(0, 0);
		} else {
			navigate(`../../request-for-quote/${rfqId}/all/${slug}`);
			window.scrollTo(0, 0);
		}
	};

	return (
		<Button
			btntype={isHaveRFQ && "secondary"}
			onClick={handleClick}
			disabled={loadCreatingRfq}
			className="mx-auto"
		>
			{loadCreatingRfq ? <Spinner /> : renderButtonText()}
		</Button>
	);
};
