import { CardQuestioner } from "components/RFQ/CardQuestioner";
import React, { useMemo } from "react";
import { QuoteContent } from "./QuoteContent";

const QuoteCard = ({
	cardTitle,
	initialDataItem,
	handleChangeInput,
	cardDescription,
	value,
	dragHandle,
	tableVariable,
	index,
	quotesTable,
	setQuotesTable,
	unitMeasures,
	isPreview,
	disable,
	type = "template",
}) => {
	const isQuoteDeletable = useMemo(() => {
		const nonRemovedQuotes = quotesTable?.filter((quote) => !quote.isRemove);
		const isDeletable = index !== 0 && nonRemovedQuotes?.length > 1;
		return isDeletable;
	}, [index, quotesTable]);

	const deleteQuotesHandler = () => {
		let newQuotes = [...quotesTable];
		if (newQuotes[index]?.id) {
			newQuotes[index].isRemove = 1;
		} else {
			newQuotes.splice(index, 1);
		}
		setQuotesTable(newQuotes);
	};

	const copyQuotesHandler = () => {
		let newQuotes = [...quotesTable];
		newQuotes.push({
			...newQuotes[index],
			id: null,
			isRemove: 0,
			sort_order: newQuotes.length + 1,
		});
		setQuotesTable(newQuotes);
	};

	return (
		<div className="my-6 w-full">
			<CardQuestioner
				dragHandle={dragHandle}
				divider={false}
				isQuote
				sectionIndex={index}
				isDeletable={isQuoteDeletable}
				deleteQuotesHandler={deleteQuotesHandler}
				copyQuotesHandler={copyQuotesHandler}
				isPreview={isPreview}
			>
				<QuoteContent
					cardTitle={cardTitle}
					initialDataItem={initialDataItem}
					handleChangeInput={handleChangeInput}
					cardDescription={cardDescription}
					value={value}
					tableVariable={tableVariable}
					index={index}
					unitMeasures={unitMeasures}
					isPreview={isPreview}
					type={type}
					disable={disable}
				/>
			</CardQuestioner>
		</div>
	);
};

export default QuoteCard;
