import styled from "styled-components";

export const SearchUpgradeWrapper = styled.div`
	display: flex;
	.side-content {
		padding: 73px 43px;
		font-family: Abolition;
		p {
			font-family: Arial;
			font-size: 15px;
			font-weight: 400;
			color: #565656;
			span {
				font-weight: 700;
				color: ${(props) => props.theme.colors.royalBlue};
			}
		}
	}
`;
