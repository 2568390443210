import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";
import { ReactComponent as OpenEye } from "./../../../icons/eye.svg";
import { ReactComponent as CloseEye } from "./../../../icons/close-eye.svg";
import { ButtonWithIcon } from "../../Button";
import { Error } from "../Error";
import { ReactComponent as Pencil } from "./../../../icons/pencil.svg";

import { ReactComponent as InfoIcon } from "./../../../icons/info.svg";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { Icon } from "assets/icons/Icon";
import tw from "twin.macro";

export const FormLabel = styled.label`
	font-size: ${(props) => (props.rfq ? "14px" : props.theme.fontSizes.xs)};
	color: ${(props) =>
		props.rfq ? props.theme.colors.secondary : props.theme.colors.grayLight};
	font-weight: ${(props) => (props.rfq ? "600" : "700")};
	margin-bottom: ${(props) => (props.rfq ? "0" : props.theme.space.xxs)};
	padding-bottom: ${(props) => (props.rfq ? "1px" : "0px")};
	letter-spacing: ${(props) => (props.rfq ? "0px" : "1.25px")};
	display: ${(props) => (props.rfq ? "block" : "flex")};
	flex-direction: row;
	align-items: center;
	> span {
		color: #dc2538;
		margin-left: 3px;
	}

	> svg {
		margin-left: 4px;
		cursor: pointer;
	}
	.guideline {
		svg {
			position: inherit;
			margin-left: 5px;
		}
	}
	.guide {
		border: 0.5px solid #8492a6;
		border-radius: 4px;
		letter-spacing: 0.1px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		font-size: 13px;
		text-transform: none;
		padding: 4px 8px;
		font-weight: normal;
		max-width: 400px;
	}
`;

export const TextInputStyles = css`
	background: white;
	border: 1px solid #e7eaee;
	border-radius: 6px;
	padding: 1rem;
	width: 100%;
	margin-bottom: 25px;
	color: ${(props) => props.theme.colors.gray};
	transition: ${(props) => props.theme.transitions.default};
	height: 58px;
	max-height: 58px;
	outline: none;
	::-webkit-input-placeholder {
		color: #6f7c8f;
		font-size: ${(props) =>
			props.placeholderSize ? props.placeholderSize : props.theme.fontSizes.lg};
	}
	:disabled {
		cursor: not-allowed;
		&:hover,
		&:focus {
			background-color: #d7d7d7;
		}
	}

	&::hover {
		outline: none;
		border-color: ${(props) => props.theme.colors.royalBlueLight};
	}
	&:focus {
		${(props) =>
			props?.isError
				? tw`border-utility-error-300 ring-4 ring-ring-red-primary`
				: tw`border-utility-brand-300 ring-4 ring-ring-blue-primary`}
	}
`;

export const TextInputStyled = styled.input`
	${TextInputStyles}
	${(props) => props?.noMargin && tw`!mb-0`}
`;

export const TextInputWrapper = styled.div`
	position: relative;
	transition: 0.15s all ease;
	.change-password {
		position: absolute;
		right: 32px;
		top: 35px;
	}
	.svg-icon {
		position: absolute;
		right: ${(props) => (props?.rfq ? "20px" : "30px")};
		top: ${(props) => (props?.rfq ? "38px" : "45px")};
		cursor: pointer;
	}
	.svg-icon-left {
		position: absolute;
		left: 20px;
		top: 45px;
		cursor: pointer;
	}

	${(props) =>
		props.error &&
		`
        padding-bottom: 0.5rem;
        border:${props.theme.colors.danger};
        input{
            border:1px solid ${props.theme.colors.danger};
        }
    `}
	flex-grow:${(props) => (props.flexGrow ? props.flexGrow : 0)};
`;
export const TextInput = ({
	label,
	placeholderSize,
	required,
	placeholder,
	className,
	inputClassName,
	icon,
	customIcon,
	type,
	rfq,
	formatValue,
	disableToggler,
	errorText,
	guideLine,
	onChange,
	iconPosition,
	...props
}) => {
	const [field, meta] = useField(props);
	const [visible, setVisible] = useState(false);
	const [pHolder, setPHolder] = useState(placeholder);
	const inputElement = useRef();
	const [toggleButton, setToggleButton] = useState(
		disableToggler || props.disabled ? true : false
	);

	useEffect(() => setToggleButton(props.disabled), [props.disabled]);
	const onKeyUp = (e) => {
		if (formatValue) {
			let val = e.target.value;
			let key = e.keyCode || e.charCode;
			if (key === 8) return;
			val = formatValue(val);
			props.setFieldValue(props.name, val);
		}
	};
	return (
		<TextInputWrapper
			error={(meta && meta.error && meta.touched) || errorText}
			flexGrow={props.flexGrow}
			className={className}
			rfq={rfq}
		>
			{label && (
				<LabelForm
					htmlFor={props.id || props.name}
					label={label}
					required={required}
				>
					<div>
						{guideLine && (
							<div className="guideline">
								<InfoIcon
									data-tip
									data-for={props.name}
								/>
								<ReactTooltip
									className="guide"
									id={props.name}
									place="right"
									arrowColor="transparent"
								>
									{guideLine}
								</ReactTooltip>
							</div>
						)}
					</div>
				</LabelForm>
			)}
			{disableToggler && toggleButton && (
				<div className="change-password">
					<ButtonWithIcon
						icon={<Pencil />}
						text={"CHANGE"}
						btntype={"secondary"}
						width={"8rem"}
						onClick={() => {
							props.handleToggler(!toggleButton);
							setToggleButton(!toggleButton);
							setPHolder("");
							inputElement.current.focus();
						}}
					/>
				</div>
			)}
			{icon && !toggleButton ? (
				<>
					{customIcon ? (
						<>
							<Icon
								icon={customIcon}
								className="svg-icon-left"
							/>
						</>
					) : (
						<>
							{!visible ? (
								<OpenEye
									onClick={() => setVisible(true)}
									className="svg-icon"
								/>
							) : (
								<CloseEye
									onClick={() => setVisible(false)}
									className="svg-icon"
								/>
							)}
						</>
					)}
				</>
			) : null}
			{props?.rfqIcon && (
				<Icon
					icon={props?.rfqIcon}
					className="absolute left-3 top-10"
				/>
			)}
			<TextInputStyled
				ref={inputElement}
				onKeyUp={onKeyUp}
				placeholder={props?.fixedPlaceholder ? placeholder : pHolder}
				placeholderSize={placeholderSize}
				onChange={onChange}
				type={icon && visible ? "text" : type}
				isError={(meta && meta.error && meta.touched) || errorText}
				className={inputClassName}
				{...field}
				{...props}
				style={{
					paddingRight: "10px",
					paddingLeft:
						iconPosition === "left" || props?.rfqIcon ? "50px" : "10px",
					margin: props?.noMargin && "0 !important",
				}}
			/>
			<Error
				meta={meta}
				text={errorText}
			/>
		</TextInputWrapper>
	);
};
