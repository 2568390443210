import styled from "styled-components";

export const FullDrawStyled = styled.div`
	background-color: ${(props) => props.theme.colors.snowDarkOpacity};
	width: 100%;
	transition: ${(props) => props.theme.transitions.default};
	position: fixed;
	top: 0;
	left: 68px;
	right: 0;
	height: 100%;
	transform: translateX(${(props) => (props.open ? 0 : "100%")});
	z-index: 100;
	border-left: 1px solid ${(props) => props.theme.colors.snowDark};
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	padding-top: 9rem;
	padding-right: 80px;
	will-change: transform;

	.fullDraw {
		max-width: 1400px;
		padding: 0 ${(props) => props.theme.space.md}
			${(props) => props.theme.space.lg};
		min-width: 100%;

		&__title {
			color: ${(props) => props.theme.colors.black};
			font-weight: 500;
			font-size: ${(props) => props.theme.fontSizes.xxl};
			margin-bottom: ${(props) => props.theme.space.sm};
		}

		&__exit-wrapper {
			position: absolute;
			top: 56px;
			right: 143px;
		}

		&__project-row {
			margin-bottom: ${(props) => props.theme.space.xs};
			margin-bottom: 1rem;
			display: flex;
			border-radius: 4px;
			align-items: center;
			justify-content: space-between;
			border-radius: 4px;

			.project-link__cell {
				flex: 1 0 0;

				&:first-child {
					flex: 0 0 0;
				}

				&:last-of-type {
					min-width: 150px;
				}
			}
		}

		&__company-list {
			.project-link__cell--title {
				min-width: 300px;
			}
		}
	}
`;
