import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { Button } from "../Button";
import { ButtonStyled } from "../Button/styled";

export const ButtonLink = styled(({ children, to, ...rest }) => (
	<Link to={to}>
		<Button
			as="span"
			{...rest}
		>
			{children}
		</Button>
	</Link>
))`
	${ButtonStyled}
`;
