import * as constants from "../actions/RFQ/actionTypes";

const INITIAL_TEMPLATE_STATE = {
	template: {},
	loading: false,
	error: "",
};

export const templateReducer = (state = INITIAL_TEMPLATE_STATE, action) => {
	switch (action.type) {
		case constants.GET_TEMPLATE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case constants.GET_TEMPLATE_SUCCESS:
			return {
				...state,
				loading: false,
				template: action.payload,
				error: "",
			};

		case constants.GET_TEMPLATE_FAILED:
			return {
				...state,
				loading: false,
				template: {},
				error: action.payload,
			};

		case constants.ADD_TEMPLATE:
			return {
				...state,
				template: [...state.template, action.payload],
			};

		case constants.IS_LOADING:
			return {
				...state,
				loading: action.payload,
			};

		default:
			return state;
	}
};
