import styled from "styled-components";

export const EditUserProfileStyled = styled.div`
	form {
		padding-left: 48px;
		padding-right: 48px;
	}
	h3 {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		color: #253858;
	}

	.social-invitations {
		display: none;
		float: right;
		margin-top: 65px;
		button {
			margin-left: 10px;
		}
	}
	.twofactor-container {
		padding: 24px 0px 24px 48px;
	}
	.twofactor-title {
		font-weight: 500;
		color: #344054;
		margin-bottom: 4px !important;
	}
	.step-box {
		padding: 24px;
		border: solid 1px #d0d5dd;
		box-shadow: 0px 1px 2px 0px #1018280d;
		border-radius: 8px;
		margin: 20px 0;
		.step-header {
			margin: 2px 0 !important;
			font-size: 14px;
			font-weight: 400;
		}
		h4 {
			margin: 0 0 8px 0 !important;
			font-size: 16px;
			font-weight: 700;
			color: #344054;
		}
	}
	.qr-container {
		display: flex;
		justify-content: center;
		padding: 24px;
	}
	.separator-text {
		display: flex;
		justify-content: center;
		border: 1px solid #d0d5dd;
		width: 100%;
		height: 1px;
		margin: 18px 0 30px 0;
		p {
			margin-top: -16px;
			padding: 4px 8px;
			background-color: white;
			font-size: 14px;
			height: 18px;
		}
	}
	.copy-field {
		padding: 0px 0 0 12px;
		display: flex;
		border: 1px solid #d0d5dd;
		align-items: center;
		border-radius: 8px;
		span {
			width: 100%;
			border-right: 1px solid #d0d5dd;
			height: 40px;
			line-height: 3;
			color: #d92d20;
		}
		button {
			cursor: pointer;
			min-width: 48px;
			border: none;
			background-color: transparent;
			margin-top: 2px;
			height: 20px;
		}
	}
	.enabled-button {
		background-color: white !important;
		border: 1px solid #d0d5dd !important;
		box-shadow: 0px 1px 2px 0px #1018280d;
		color: #00976b !important;
	}
`;
