import React from "react";
import styled from "styled-components";

export const FormButtonRowStyled = styled.div`
	display: flex;
	margin-top: ${(props) => props.theme.space.sm_2};

	> div {
		display: flex;
	}

	button {
		&:last-child {
			margin-left: auto;
		}
	}
`;

export const FormButtonRow = (props) => {
	return <FormButtonRowStyled {...props}>{props.children}</FormButtonRowStyled>;
};
