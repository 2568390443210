import React, { Suspense } from "react";
import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { RenderOnViewportEntry } from "components/RFQ/RenderOnViewportEntry/RenderOnViewportEnter";
import tw, { styled, css } from "twin.macro";
import ProjectTile from "components/Pages/Projects/ProjectTile/ProjectTile";

import { Button } from "components/RFQ/Button";
import { Icon } from "assets/icons/Icon";

// --- note: This section is for future API integration Lazy Loading
const CompanyCard = React.lazy(() => import("./CompanyCard/CompanyCard"));
//----------------------------------------------

const GridViewStyled = styled.div(() => [tw`py-8 flex gap-6 flex-wrap`]);

// --- note: This section is for future API integration Lazy Loading
const SuspenseStyled = styled(Suspense)(() => [tw`py-8 flex gap-6 flex-wrap`]);
//----------------------------------------------

const CompanyCardSkeletonStyled = styled.div(() => [
	tw`w-full min-h-[330px] rounded-md border-t-8 bg-gray-50 relative drop-shadow-lg mb-3`,
	css`
		#card-content {
			padding: 24px;
			padding-top: 40px;
			background-color: white;
			height: 100%;
			max-height: 250px;
			min-width: 280px;
			position: relative;
			display: grid;
			align-content: space-between;
			bottom: 0;
		}
		#header-card {
			height: 80px;
			position: relative;
		}
	`,
]);

const GridViewProjects = ({
	suppliers,
	suppliersStatus,
	loadList,
	MenuDropdown,
	handleSearchSupplier,
}) => {
	const isLoading = loadList || suppliersStatus === "loading";

	return (
		<GridViewStyled>
			<RenderOnViewportEntry
				loadingComponent={<ProjectTile />}
				className="grid w-full min-w-[680px] grid-cols-2 gap-6 xl:grid-cols-3 xxl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6"
			>
				{isLoading ? (
					[...Array(8)].map(() => <CompanyCardSkeleton />)
				) : (
					<>
						{suppliers?.length > 0 ? (
							<SuspenseStyled>
								{suppliers.map((company, index) => {
									return (
										<CompanyCard
											company={company}
											MenuDropdown={MenuDropdown}
										/>
									);
								})}
							</SuspenseStyled>
						) : (
							<div className="col-span-full">
								<NotFoundDatas
									text={`No Suppliers in List`}
									description={"To get started, add suppliers to your list"}
									className="mx-auto"
								>
									<Button
										onClick={handleSearchSupplier}
										style={{ maxHeight: "48px" }}
									>
										<Flex className="items-center gap-[6px]">
											<div className="flex h-5 w-5 items-center justify-center">
												<Icon
													icon="search"
													style={{
														width: "12px",
														height: "12px",
														color: "#fff",
														fontWeight: "bold",
													}}
												/>
											</div>
											<div className="font-roboto text-base font-semibold capitalize">
												Search Suppliers
											</div>
										</Flex>
									</Button>
								</NotFoundDatas>
							</div>
						)}
					</>
				)}
			</RenderOnViewportEntry>
		</GridViewStyled>
	);
};

const CompanyCardSkeleton = () => {
	return (
		<CompanyCardSkeletonStyled>
			<div id="header-card">
				<SkeletonCircle
					startColor="#d2d2d2"
					size="48px"
					className="absolute -bottom-4 left-6 z-10"
				/>
			</div>
			<div id="card-content">
				<Skeleton
					height="40px"
					mb="24px"
					startColor="#d2d2d2"
				/>
				<div>
					<Skeleton
						height="30px"
						startColor="#d2d2d2"
					/>
					<span className="my-3 block w-full border-b border-gray-200" />
					<Skeleton
						height="30px"
						mb="4px"
						startColor="#d2d2d2"
					/>
				</div>
			</div>
		</CompanyCardSkeletonStyled>
	);
};

export default GridViewProjects;
