import styled from "styled-components";

export const ResourceProjectsStyled = styled.div`
	margin-top: 1.25rem;
`;

export const ProjectItemsStyled = styled.div`
	margin-bottom: ${(props) => props.theme.space.md};
`;

export const ProjectItemStyled = styled.div`
	margin-bottom: ${(props) => props.theme.space.xs};
`;
