import update from "immutability-helper";

import * as constants from "../actions/actionTypes";

const INITIAL_SEARCH_STATE = {
	searchResult: [],
	paginationLink: null,
	paginationMeta: null,
	searchMeta: {},
	compareList: [],
	shortlist: [],
	lockedSearchItems: [],
	lockedCompareItems: [],
	activeCompany: {
		specialityArray: [
			{ id: 1, special_type: "Civil, Infrastructure & Utilities" },
			{ id: 2, special_type: "Construction (Built Form)" },
			{ id: 3, special_type: "Fitout and Refurbishment" },
			{ id: 4, special_type: "Remedial" },
			{ id: 5, special_type: "Operations & Maintenance" },
		],
	},
	isSearching: false,
	hasSearched: false,
	isLoading: true,
	isLoadingNext: false, //infinity
	projectId: 1,
	groupId: "",
	group: [],
	regionOptions: [],
	searchCriteria: "",
	formattedSearchCriteria: {},
	disciplines: [],
	types: [],
	addingCompareCard: {},
	addingShortlistCard: {},
	addingProjectCompare: {},
	isAddingProject: false,
	calculation: {},
	activeSupplier: {},
	error: null,
};

export const searchReducer = (state = INITIAL_SEARCH_STATE, action) => {
	switch (action.type) {
		case constants.SEARCH_COMPANIES:
			const results = Object.values(action.payload.results.results.data);

			const { data, ...searchMeta } = action.payload.results.results;

			//remove page body param from page criteria
			const { page, ...searchValues } = action.payload.values;

			return {
				...state,
				groupId:
					action.payload.meta !== undefined
						? action.payload.meta.group_id
						: state.groupId,
				searchResult: action.payload.paged
					? [...state.searchResult, ...results]
					: results,
				searchCriteria: searchValues,
				searchMeta: searchMeta,
			};
		case constants.SEARCH_COMPANIES_DIRECT:
			return {
				...state,
				searchResult: action.payload,
			};
		case constants.REMOVE_COMPANY_FROM_RESULTS:
			return {
				...state,
				searchResult: state.searchResult.filter(
					(company) => company.id !== action.payload
				),
				searchMeta: {
					...state.searchMeta,
					total: state.searchMeta.total - 1,
				},
			};

		case constants.REMOVE_COMPANY_FROM_COMPARE:
			return {
				...state,
				compareList: state.compareList.filter(
					(company) => company.id !== action.payload
				),
				lockedCompareItems: state.lockedCompareItems.filter(
					(companyId) => companyId !== action.payload
				),
			};

		case constants.REMOVE_COMPANY_FROM_SHORTLIST:
			return {
				...state,
				shortlist: state.shortlist.filter(
					(company) => company.id !== action.payload
				),
			};
		case constants.ADD_COMPANY_TO_COMPARE:
			return {
				...state,
				compareList: [...state.compareList, action.payload],
			};
		case constants.ADD_COMPANY_TO_COMPARE_DIRECT:
			return {
				...state,
				compareList: [...state.compareList, action.payload],
			};
		case constants.ADD_ALL_COMPANY_TO_COMPARE_DIRECT:
			return {
				...state,
				compareList: action.payload,
			};
		case constants.ADD_COMPANY_TO_SHORTLIST:
		case constants.ADD_COMPANY_TO_SHORTLIST_DIRECT:
			return {
				...state,
				shortlist: [...state.shortlist, action.payload],
			};

		case constants.ADD_ALL_COMPANY_TO_SHORTLIST_DIRECT:
			return {
				...state,
				shortlist: action.payload,
			};
		case constants.SET_ADD_PROJECT_INDEX:
			return {
				...state,
				addProjectIndex: action.payload,
			};

		case constants.REMOVE_COMPANY_CARD_PROJECT:
			return {
				...state,
				compareList: update(state.compareList, {
					[action.payload.companyIndex]: action?.payload?.isDirectCompare
						? {
								projects: {
									[action.payload.index]: { $set: null },
								},
							}
						: {
								compare_projects: {
									[action.payload.index]: { $set: null },
								},
							},
				}),
			};
		case constants.CREATE_SUPPLIER_LIST:
			return {
				...state,
				activeSupplier: action.payload,
			};
		case constants.ADD_SUPPLIER_LIST:
			let tempActiveSupplier = { ...state.activeSupplier };
			tempActiveSupplier.active.push(action.payload);
			return {
				...state,
				activeSupplier: tempActiveSupplier,
			};
		case constants.REMOVE_SUPPLIER_LIST:
			return {
				...state,
				activeSupplier: {},
			};
		case constants.ADD_COMPANY_CARD_PROJECT:
			return {
				...state,
				compareList: state.compareList.map((company) =>
					company.id === action.payload.companyId
						? action?.payload?.isDirectCompare
							? {
									...action.payload.data.company,
									projects: action.payload.data.projects,
								}
							: {
									...action.payload.data.company,
									compare_projects: action.payload.data.projects,
								}
						: company
				),
			};

		//attempt with immutable js - leaving for reference later
		// return update(state, {
		//     compareList: {
		//         [action.payload.companyIndex]: {
		//             compare_projects: {
		//                 [action.payload.index - 1]: {
		//                     $set: action.payload.projects
		//                 }
		//             }
		//         }
		//     }
		// });

		// return {
		//     ...state,
		//     compareList: update(state.compareList, { $set: [] })
		// };

		case constants.ADD_SEARCH_RESULT_LOCKED_ITEM:
			return {
				...state,
				lockedSearchItems: [...state.lockedSearchItems, action.payload.id],
				searchResult: state.searchResult.map((company) =>
					company.id === action.payload.id
						? { ...company, locked: true }
						: company
				),
			};

		case constants.REMOVE_SEARCH_RESULT_LOCKED_ITEM:
			return {
				...state,
				lockedSearchItems: state.lockedSearchItems.filter(
					(id) => id !== action.payload.id
				),
				searchResult: state.searchResult.map((company) =>
					company.id === action.payload.id
						? { ...company, locked: false }
						: company
				),
			};

		case constants.ADD_COMPARE_LOCKED_ITEM:
			return {
				...state,
				lockedCompareItems: [...state.lockedCompareItems, action.payload.id],
				compareList: state.compareList.map((company) =>
					company.id === action.payload.id
						? { ...company, locked: true }
						: company
				),
			};

		case constants.REMOVE_COMPARE_LOCKED_ITEM:
			return {
				...state,
				lockedCompareItems: state.lockedCompareItems.filter(
					(id) => id !== action.payload.id
				),
				compareList: state.compareList.map((company) =>
					company.id === action.payload.id
						? { ...company, locked: false }
						: company
				),
			};

		case constants.IS_SEARCHING:
			return {
				...state,
				isSearching: action.payload,
			};

		case constants.HAS_SEARCHED:
			return {
				...state,
				hasSearched: action.payload,
			};

		case constants.GET_COMPANIES:
			return {
				...state,
				searchResult: action.payload?.data,
				paginationLink: action?.payload?.links,
				paginationMeta: action?.payload?.meta,
			};

		case constants.GET_COMPANIES_PER_PAGE:
			return {
				...state,
				searchResult: [...state.searchResult, ...action.payload.data],
				searchMeta: action.payload.meta,
			};

		case constants.GET_COMPANIES_SEARCH:
			return {
				...state,
				searchResult: action.payload.data,
				searchMeta: action.payload.meta,
			};

		case constants.GET_COMPANY:
			return {
				...state,
				activeCompany: action.payload,
			};

		case constants.IS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case constants.IS_ADDING_PROJECT:
			return {
				...state,
				isAddingProject: action.payload,
			};
		case constants.IS_LOADING_NEXT:
			return {
				...state,
				isLoadingNext: action.payload,
			};

		case constants.SUBMIT_COMPANY_PROFILE_FORM:
			return {
				...state,
				activeCompany: action.payload,
			};
		case constants.DELETE_ADDRESS:
			return update(state, {
				activeCompany: {
					locations: { $set: action.payload },
				},
			});

		case constants.UPDATE_SUBSCRIPTION_ACCOUNT:
			return update(state, {
				activeCompany: {
					account_type_id: { $set: action.payload.account_type_id },
				},
			});

		case constants.UPDATE_SUBSCRIPTION_PROFILE:
			return update(state, {
				activeCompany: {
					profile_type: {
						id: { $set: action.payload.profile_type_id },
					},
				},
			});

		case constants.UPDATE_SUBSCRIPTION_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};

		case constants.UPDATE_LAST4:
			return update(state, {
				activeCompany: {
					last4: { $set: action.payload },
				},
			});

		case constants.REORDER_SEARCH_RESULTS:
			return {
				...state,
				searchResult: update(state.searchResult, { $set: action.payload }),
			};

		case constants.REORDER_COMPARE_RESULTS:
			return {
				...state,
				compareList: update(state.compareList, { $set: action.payload }),
			};

		case constants.GET_REGION_OPTIONS:
			return {
				...state,
				regionOptions: action.payload,
			};

		case constants.GET_DISCIPLINES_BY_PROFILE_TYPE:
			return {
				...state,
				disciplinesByProfile: action.payload,
			};

		case constants.SET_GROUP_ID:
			return {
				...state,
				groupId: action.payload,
			};

		case constants.GET_GROUP:
			return {
				...state,
				group: action.payload,
				groupId: action.payload.id,
				searchCriteria: JSON.parse(action.payload.search_criteria),
			};

		case constants.GET_COMPARE_LIST:
			return {
				...state,
				compareList: action.payload ? action.payload : [],
			};

		case constants.GET_SHORTLIST:
			return {
				...state,
				shortlist:
					Array.isArray(action.payload) && action.payload ? action.payload : [],
			};

		case constants.ADD_TO_GROUP:
			return {
				...state,
				compareList: action.payload,
			};

		case constants.ADD_PROJECT_TO_COMPARE:
			return {
				...state,
				compareList: action.payload,
			};

		case constants.CLEAR_LISTS:
			return {
				...state,
				group: [],
				groupId: "",
				compareList: [],
				shortlist: [],
				results: [],
				lockedSearchItems: [],
				lockedCompareItems: [],
			};

		case constants.GET_ALL_DISCIPLINES:
			return {
				...state,
				disciplines: action.payload,
			};

		case constants.GET_ALL_TYPES:
			return {
				...state,
				types: action.payload,
			};

		case constants.ADDING_CARD_TO_COMPARE:
			return {
				...state,
				addingCompareCard: { ...state.addingCompareCard, ...action.payload },
			};
		case constants.ADDING_PROJECT_TO_COMPARE:
			return {
				...state,
				addingProjectCompare: {
					...state.addingProjectCompare,
					...action.payload,
				},
			};

		case constants.ADDING_CARD_TO_SHORTLIST:
			return {
				...state,
				addingShortlistCard: {
					...state.addingShortlistCard,
					...action.payload,
				},
			};

		case constants.GET_PRORATE_AMOUNT:
			return {
				...state,
				prorateAmount: action.payload,
			};

		case constants.GET_CALCULATION:
			return {
				...state,
				calculation: action.payload,
			};

		case constants.SET_ACCOUNT_STATUS:
			return update(state, {
				activeCompany: {
					account_status: { $set: action.payload },
				},
			});
		case constants.UPDATE_PLAN_DATA:
			return update(state, {
				activeCompany: {
					plan: { $set: action.payload.plan },
					description: { $set: action.payload.description },
				},
			});

		default:
			return state;
	}
};
