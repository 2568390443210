import { Icon } from "assets/icons/Icon";
import { Attachment } from "../addendum.types";

type AddendumAttachmentProps = {
	attachment: Attachment;
	isCommunication: boolean;
};

export const AddendumAttachment = ({
	attachment,
	isCommunication,
}: AddendumAttachmentProps) => {
	return (
		<a
			href={attachment?.file_path}
			type="button"
			download
			className={`flex w-[10rem] items-center gap-3 rounded-full px-2 py-2 ${isCommunication ? "border text-black hover:bg-white" : "text-primary bg-white hover:bg-[#6b8be6] hover:text-white"} `}
		>
			{/* @ts-ignore icon type is valid but not properly typed */}
			<Icon
				icon={attachment?.file_type}
				style={{ height: 24, width: 24 }}
			/>
			<p className="line-clamp-1 w-full overflow-hidden text-ellipsis text-xs">
				{attachment?.file_name}
			</p>
		</a>
	);
};
