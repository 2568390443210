import styled from "styled-components";
import { HeadingStyled } from "../Heading";

export const LoginWrapper = styled.div`
	display: flex;
	width: 100vw;
	min-height: 100vh;
	position: relative;
	background: white;
`;

export const LeftSection = styled.div`
	width: 60%;
	padding: 40px;
	box-shadow: 10px 0px 20px 10px rgba(0, 0, 0, 0.15);
	${(props) => props.theme.mediaQueries.xxl} {
		width: 50%;
	}
	z-index: 1;
`;

export const Logo = styled.img`
	max-width: 160px;
	cursor: pointer;
`;

export const Tagline = styled.img`
	margin-left: 10px;
	height: 36px;
	margin-top: 1px;
`;

export const LoginSection = styled.div`
	margin: 80px 0px 0px 80px;
	max-width: 519px;

	${HeadingStyled} {
		font-size: 3.55rem;
		line-height: 0.9;
		color: #273444;
		max-width: 435px;
		.welcome {
			margin-bottom: 40px;
		}

		span {
			display: block;
		}
	}

	.guest-link {
		margin-top: 16px;
		text-align: center;
		font-size: ${(props) => props.theme.fontSizes.md};
		color: ${(props) => props.theme.colors.grayLight};
		font-family: "Roboto";
		span {
			font-family: "Roboto";
			color: ${(props) => props.theme.colors.royalBlueLight};
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;

export const InputLabel = styled.label`
	font-size: 0.9rem;
	text-transform: uppercase;
	color: #8492a6;
	display: block;
`;

export const RightSection = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	margin-left: auto;
	width: 40%;
	${(props) => props.theme.mediaQueries.xxl} {
		width: 50%;
	}
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right bottom;
	height: 100vh;
`;

export const BottomTag = styled.div`
	margin-top: 46px;
	text-align: center;
	font-size: ${(props) => props.theme.fontSizes.md};
	color: ${(props) => props.theme.colors.grayLight};
	font-family: "Roboto";
	a {
		font-family: "Roboto";
		color: ${(props) => props.theme.colors.royalBlueLight};
		text-decoration: underline;
	}
`;

export const BottomLinks = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	font-family: "Roboto";
	a {
		color: ${(props) => props.theme.colors.royalBlueLight};
		font-size: ${(props) => props.theme.fontSizes.md};
		text-decoration: underline;
	}
`;
