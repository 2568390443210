import api from "api";
import * as constants from "./actionTypes";
import { getCookie } from "utils/cookiesHelper";

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const getAccountTypes = () => {
	return async (dispatch) => {
		(async () => {
			const accountTypes = await getApiWithToken().get("account-types").json();
			await dispatch({
				type: constants.GET_ACCOUNT_TYPES,
				payload: accountTypes.data,
			});
		})();
	};
};
