import { createContext, useState } from "react";

export const SubmissionSmartFieldPreviewContext = createContext();

export const SubmissionSmartFieldPreviewContextProvider = ({ children }) => {
	const [modalDetail, setModalDetail] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<SubmissionSmartFieldPreviewContext.Provider
			value={{ modalDetail, setIsModalOpen, isModalOpen, setModalDetail }}
		>
			{children}
		</SubmissionSmartFieldPreviewContext.Provider>
	);
};
