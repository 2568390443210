import styled from "styled-components";

export const ValidatorWrapper = styled.div`
	.pass-validation {
		background: #fff;
		border: 1px solid rgba(132, 146, 166, 0.2);
		box-shadow: rgb(0 0 0 / 10%) 5px 5px 20px;
		border-radius: 4px;
		padding: 16px;
		max-width: 350px;
		span {
			display: flex;
			align-items: center;
			font-size: ${(props) => props.theme.fontSizes.md};
			margin-top: 10px;
			margin-left: 10px;
			color: ${(props) => props.theme.colors.danger};
			svg {
				margin-right: 12px;
			}
		}
		.title {
			color: ${(props) => props.theme.colors.grayLight};
			margin-left: 0px;
		}
		.valid {
			color: ${(props) => props.theme.colors.mint} !important;
		}
	}
`;

export const PassRule = styled.span``;
