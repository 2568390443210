import React, { memo, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { CompanyResultCard } from "../../components/CompanyResultCard";
import { ResultsList } from "../../layout/SearchTemplate/ResultsList";
import { SearchResultsTable } from "../../layout/SearchTemplate/SearchResultsTable";
import { Arrows } from "../../layout/SearchTemplate/styled";
import { reorderResults, searchCompanies } from "actions/searchActions";
import {
	removeFromList,
	addToList,
	addProjectToCompareCard,
} from "actions/searchActions";
import {
	getSpecialities,
	getTrades,
	getDisciplinesByProfileType,
	getExpertiseDisciplines,
	getCompany,
} from "actions/companyActions";

const ResultsWrap = styled.div`
	width: auto;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;
	padding-right: 120px;
	scroll-behavior: smooth;
`;

const SearchResultsConnected = memo(
	React.forwardRef(
		(
			{
				results,
				addToList,
				removeFromList,
				lockedItems,
				reorderResults,
				isCompare,
				projectId,
				groupId,
				addProjectToCompareCard,
				isResults,
				compareSlideoutClick,
				cardType,
				compareList,
				searchCompanies,
				searchCriteria,
				searchMeta,
				isLoadingNext,
				handleScrollEnd,
				isFullDrawOpen,
				getSpecialities,
				getTrades,
				getCompany,
				getDisciplinesByProfileType,
				getExpertiseDisciplines,
				trades,
				specialities,
				disciplines,
				projects,
				currentCompanyId,
				activeCompany,
				sticked,
				sleft,
				directCompare,
				...props
			},
			ref
		) => {
			// a little function to help us with reordering the result
			const reorder = (list, startIndex, endIndex) => {
				const result = Array.from(list);
				const [removed] = result.splice(startIndex, 1);
				result.splice(endIndex, 0, removed);
				return result;
			};

			const onDragEnd = (result, cardType) => {
				if (!result.destination) {
					return;
				}

				const reorderedResults = reorder(
					results,
					result.source.index,
					result.destination.index
				);
				reorderResults({ cardType, items: reorderedResults });
			};

			const scrollableEl = useRef(null);
			const [scrollOffset, setScrollOffset] = useState(0);

			const scrollToLeft = () => {
				if (scrollOffset > 0) {
					setScrollOffset(scrollOffset - 300);
				}
			};

			const scrollToRight = () => {
				let el = document.querySelectorAll(
					'[data-rbd-droppable-id="droppable"]'
				)[0];
				let maxScrollLeft = el.scrollWidth - el.clientWidth;

				if (scrollOffset >= 0 && scrollOffset <= maxScrollLeft) {
					setScrollOffset(scrollOffset + 300);
				}
			};

			useEffect(() => {
				if (scrollableEl.current !== null) {
					document.querySelectorAll(
						'[data-rbd-droppable-id="droppable"]'
					)[0].scrollLeft = scrollOffset;
				}
			}, [scrollOffset]);

			useEffect(() => {
				if (scrollableEl.current !== null) {
					let elements = scrollableEl.current
						.getElementsByTagName("div")[0]
						.getElementsByClassName("company-name");

					if (isFullDrawOpen) {
						addClassName(elements, "hide");
					} else {
						removeClassName(elements, "hide");
					}
				}
			}, [isFullDrawOpen]);

			const sectorRef = document.getElementById("matchesRow");
			const sectorRef2 = document.getElementById("matchesRow2");
			const [sectorHeight, setSectorHeight] = useState(null);
			const [sectorHeight2, setSectorHeight2] = useState(null);

			useEffect(() => {
				if (isCompare) {
					if (searchCriteria.profile_type === 2) {
						getSpecialities();
					}
					if (searchCriteria.profile_type === 3) {
						getSpecialities();
						getTrades();
					}
					if (searchCriteria.profile_type === 4) {
						getDisciplinesByProfileType();
						getExpertiseDisciplines();
					}
				}
			}, [
				getSpecialities,
				getTrades,
				getDisciplinesByProfileType,
				getExpertiseDisciplines,
				searchCriteria,
				isCompare,
			]);

			useEffect(() => {
				if (!activeCompany.hasOwnProperty("id")) {
					getCompany(currentCompanyId);
				}
			}, [isCompare, currentCompanyId, getCompany, activeCompany]);

			useEffect(() => {
				if (sectorRef) {
					if (sectorHeight !== sectorRef.clientHeight) {
						setSectorHeight(sectorRef.clientHeight);
					}
				}
			}, [sectorHeight, sectorRef, trades, specialities, disciplines]);

			useEffect(() => {
				if (sectorRef2) {
					if (sectorHeight2 !== sectorRef2.clientHeight) {
						setSectorHeight2(sectorRef2.clientHeight);
					}
				}
			}, [sectorHeight2, sectorRef2, specialities, trades]);

			const addClassName = (elements, className) => {
				for (let i = 0; i < elements.length; i++) {
					elements[i].classList.add(className);
				}
			};

			const removeClassName = (elements, className) => {
				for (let i = 0; i < elements.length; i++) {
					elements[i].classList.remove(className);
				}
			};

			return (
				<>
					{results.length || lockedItems.length ? (
						<>
							<Arrows>
								<button
									className="left"
									onClick={scrollToLeft}
								>
									<svg
										viewBox="0 0 14 26"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M0.999999 1L13 13L1 25"
										/>
										<path
											d="M0.999999 0.999999L13 13L1 25"
											stroke="currentColor"
											stroke-width="5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</button>
								<button
									className="right"
									onClick={scrollToRight}
								>
									<svg
										width="14"
										height="26"
										viewBox="0 0 14 26"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M0.999999 1L13 13L1 25"
										/>
										<path
											d="M0.999999 0.999999L13 13L1 25"
											stroke="currentColor"
											stroke-width="5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</button>
								<span>SCROLL TO VIEW</span>
							</Arrows>
							<SearchResultsTable
								compareTable={isCompare}
								searchCriteria={searchCriteria}
								sectorHeight={sectorHeight}
								sectorHeight2={sectorHeight2}
								directCompare={directCompare}
							>
								<ResultsList
									lockedSearchItems={lockedItems}
									isCompare={isCompare}
									cardType={cardType}
									compareSlideoutClick={compareSlideoutClick}
									id="ResultsList"
									ref={scrollableEl}
								>
									<DragDropContext
										onDragEnd={(result) => onDragEnd(result, cardType)}
									>
										<Droppable
											droppableId="droppable"
											direction="horizontal"
										>
											{(provided, snapshot) => (
												<ResultsWrap
													ref={provided.innerRef}
													{...provided.droppableProps}
													onScroll={(e) => {
														if (handleScrollEnd) {
															handleScrollEnd(e.target);
														}
													}}
												>
													{results.map((company, index) => (
														<Draggable
															key={company.id}
															draggableId={`${company.id}`}
															index={index}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	{!company.locked && (
																		<CompanyResultCard
																			key={`search_${company.id}`}
																			company={company}
																			handleShortlistClick={addToList}
																			compareSlideoutClick={
																				compareSlideoutClick
																			}
																			compareCard={isCompare}
																			cardType={cardType}
																			isResults={isResults}
																			sticked={sticked}
																			index={index}
																			sLeft={
																				sleft + index * (isCompare ? 400 : 300)
																			}
																		/>
																	)}
																</div>
															)}
														</Draggable>
													))}

													{provided.placeholder}

													{isLoadingNext && (
														<div
															style={{
																display: "flex",
																alignItems: "center",
																padding: " 0 40px",
															}}
														>
															<h4>Loading...</h4>
														</div>
													)}
												</ResultsWrap>
											)}
										</Droppable>
									</DragDropContext>
								</ResultsList>
							</SearchResultsTable>
						</>
					) : (
						<h1 style={{ textAlign: "center", marginTop: "4rem" }}>
							{isCompare ? "Compare list is empty." : "No results found."}{" "}
						</h1>
					)}
				</>
			);
		}
	)
);

SearchResultsConnected.defaultProps = {
	results: [],
};

const mapStateToProps = (state) => {
	return {
		projectId: state.search.projectId,
		groupId: state.search.groupId,
		searchCriteria: state.search.searchCriteria,
		searchMeta: state.search.searchMeta,
		disciplines: state.search.disciplines,
		trades: state.companyProfile.trades,
		specialities: state.companyProfile.specialities,
		projects: state.search.activeCompany.past_projects,
		currentCompanyId: state.auth.current_company.id,
		activeCompany: state.search.activeCompany,
	};
};

export const SearchResults = connect(mapStateToProps, {
	reorderResults,
	removeFromList,
	addToList,
	addProjectToCompareCard,
	searchCompanies,
	getSpecialities,
	getTrades,
	getExpertiseDisciplines,
	getDisciplinesByProfileType,
	getCompany,
})(SearchResultsConnected);
