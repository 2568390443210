import React, { useContext, useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { TabHeader } from "components/TabHeader";
import { PreviewRFQButtonTab } from "components/Pages/CreateRFQ/PreviewRFQButtonTab";
import * as constant from "../../../../utils/constants";

import { Form, Formik } from "formik";

import { Button } from "components/RFQ/Button";
import { Questionnaire } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire";
import { TenderCost } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/TenderCost";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { getSubmission } from "actions/RFQ/submissionAction";
import moment from "moment";
import {
	// downloadRFqSubmissionPDF,
	getUnitMeasures,
} from "actions/RFQ/rfqsAction";
import { Avatar } from "components/RFQ/Avatar";
import { SkeletonBase } from "components/Skeleton";
import { ProjectDetailCard } from "components/ProjectCard";
import { Modal } from "components/Modal";
import AwardSupplierModal from "../Modals/AwardSupplierModal";
import {
	SubmissionSmartFieldPreviewContext,
	SubmissionSmartFieldPreviewContextProvider,
} from "context/RFQSubmittedResponseContext";
import { useSelector } from "react-redux";
import ResourceDetailCard from "components/ResourceDetailCard/ResourceDetailCard";
import { navigate, useLocation } from "@reach/router";
import { BackButton } from "components/RFQ/BackButton";
import { isRFQReadyForAward } from "utils/helpers";
import { FileCard } from "components/RFQ/FileCard";
// import { Icon } from "assets/icons/Icon";

const DetailModal = ({ company, procurementMethods }) => {
	const { modalDetail, isModalOpen, setIsModalOpen } = useContext(
		SubmissionSmartFieldPreviewContext
	);

	const getDetailView = () => {
		switch (modalDetail?.type) {
			case "project":
				return (
					<ProjectDetailCard
						data={{
							...modalDetail?.data,
							company,
							procurement_methods: procurementMethods,
						}}
					/>
				);
			case "resource":
				return <ResourceDetailCard data={modalDetail?.data} />;
			default:
				return <></>;
		}
	};

	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={() => {
				setIsModalOpen(false);
			}}
			width="50%"
			className="w-1/2 max-w-[1000px]"
			scroll
			shouldCloseOnOverlayClick
			innerClassName={"!max-h-[90vh]"}
		>
			{getDetailView()}
		</Modal>
	);
};

const RFQSubmissionView = ({ companyUid, rfxUid, isGuest }) => {
	const listExcludeAwards = ["unsuccessful", "decline", "awarded", "invited"];
	const [currentTab, setCurrentTab] = useState("questionnaire");
	const [submissionData, setSubmissionData] = useState(null);
	const [loadSubmissionData, setLoadSubmissionData] = useState(false);
	const [unitMeasures, setUnitMeasures] = useState([]);
	const [typeModal, setTypeModal] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const documentationTotal = 0;
	const procurementMethods = useSelector(
		(state) => state?.companyProfile?.procurementMethods
	);
	const sectors = useSelector((state) => state?.companyProfile?.sectors);
	const locationState = useLocation();

	useEffect(() => {
		(isGuest
			? getSubmission(rfxUid, companyUid, true)
			: getSubmission(rfxUid, companyUid, false)
		).then((res) => {
			if (res) {
				setSubmissionData(res);
				getUnitMeasures().then((res) => {
					setUnitMeasures(
						res.map((unit) => ({ label: unit.name, value: unit.id }))
					);
					setLoadSubmissionData(false);
				});
			} else {
				setLoadSubmissionData(false);
			}
		});
	}, [companyUid, rfxUid, isGuest, locationState]);

	const initialDataChecker = (submissionData) => {
		const displayAnswer = (answer) => {
			switch (answer?.type) {
				case "project_experience":
				case "key_personnel":
					return isGuest ? answer?.value?.[0] || " - " : answer?.value || " - ";
				case "checkbox":
					return (
						answer?.value?.map((val) => answer?.option[val]).join(", ") || " - "
					);
				case "select":
				case "radio":
					return Array.isArray(answer?.value)
						? answer?.option[answer.value[0]]
						: !isNaN(answer?.value)
							? answer?.option[answer.value]
							: " - ";
				case "table":
					return answer?.table?.map((row, rowIndex) => {
						if (rowIndex === 1) {
							return { ...row, values: answer?.value };
						}
						return row;
					});
				default:
					return answer?.value || " - ";
			}
		};

		const sections = submissionData?.returnableSchedule?.map((section, i) => ({
			id: section.id || null,
			name: section.name || "",
			description: section.description || "",
			sort_order: i + 1,
			isRemove: 0,
			questionnaire: section.question?.map((question, j) => ({
				id: question.id || null,
				name: question.name || "",
				fields: question?.fields?.option || [],
				sort_order: j + 1,
				required: question.required ? 1 : 0,
				rfq_question_type_id: question.type || null,
				isRemove: 0,
				values: displayAnswer(question?.rfqAnswer?.answer) || " - ",
			})),
		}));
		const quotes = submissionData?.quote?.map((quote) => ({
			id: quote.id || null,
			name: quote.name || "",
			description: quote.description || "",
			sort_order: quote.sortOrder || null,
			required: quote.required ? 1 : 0,
			isRemove: 0,
			items: quote?.items.map((item, k) => ({
				id: item.id || null,
				name: item.name || "",
				unit_id: item.unit?.id,
				sort_order: k + 1,
				isRemove: 0,
				rate: item?.rqfQuoteAnswer?.answer?.value?.rate || 0,
				total: item?.rqfQuoteAnswer?.answer?.value?.quantity || 0,
			})),
		}));

		return {
			sections: sections || [],
			quote: quotes || [],
			documents: submissionData?.document || [],
		};
	};

	const initialDataQuestionnaire = initialDataChecker(
		submissionData?.submission
	)?.sections;
	const initialDataTenderCost = initialDataChecker(
		submissionData?.submission
	)?.quote;

	const onOpen = () => {
		setIsModalOpen(true);
	};

	const navigateBack = () => {
		navigate(isGuest ? `../../../${rfxUid}/rfx` : `../../${rfxUid}/rfx`);
	};

	return (
		<SubmissionSmartFieldPreviewContextProvider>
			<TabHeader
				heading={"Supplier RFx Submission"}
				icon={<BackButton handleBackButtonClick={navigateBack} />}
			>
				{isRFQReadyForAward(submissionData?.rfq?.status) &&
					!listExcludeAwards.includes(
						submissionData?.status?.toLowerCase()
					) && (
						<Flex
							gap={4}
							align={"center"}
						>
							<Button
								className={
									loadSubmissionData ||
									listExcludeAwards.includes(
										submissionData?.status?.toLowerCase()
									)
										? "pointer-events-none cursor-not-allowed"
										: "cursor-pointer"
								}
								btntype={"plain-color"}
								onClick={() => {
									setTypeModal({
										type: "award",
										companyId: !isGuest && companyUid,
									});
									onOpen();
								}}
								disabled={
									loadSubmissionData ||
									listExcludeAwards.includes(
										submissionData?.status?.toLowerCase()
									)
								}
							>
								Award Supplier
							</Button>
							<Button
								className={
									loadSubmissionData ||
									listExcludeAwards.includes(
										submissionData?.status?.toLowerCase()
									)
										? "pointer-events-none cursor-not-allowed"
										: "cursor-pointer"
								}
								btntype={"plain-error"}
								onClick={() => {
									setTypeModal({
										type: "decline",
										companyId: !isGuest && companyUid,
									});
									onOpen();
								}}
								disabled={
									loadSubmissionData ||
									listExcludeAwards.includes(
										submissionData?.status?.toLowerCase()
									)
								}
							>
								Decline
							</Button>
						</Flex>
					)}
			</TabHeader>
			<Box className="grid min-h-80 w-full content-between space-y-6 rounded-xl border border-gray-200 bg-gray-50 p-8 shadow-xl">
				<div>
					<Avatar
						size="lg"
						avatar={submissionData?.rfq?.project?.logoPath}
						defaultImage="company"
						className={`${loadSubmissionData ? "animate-pulse bg-gray-300" : "bg-white"}`}
					/>
				</div>
				<TabHeader
					noDivider={true}
					heading={`${submissionData?.rfq?.name || " - "}`}
					description={submissionData?.rfq?.description || " - "}
					isLoading={loadSubmissionData}
				/>
				{!loadSubmissionData ? (
					<div id="content-title">
						<Flex
							gap={4}
							className="my-4"
						>
							<p className="min-w-48 font-roboto text-base font-normal text-secondary-500">
								Status
							</p>
							<StatusBadge status={submissionData?.status} />
						</Flex>
						<Flex
							gap={4}
							className="my-4"
						>
							<p
								className="min-w-48 font-roboto font-normal text-secondary-500"
								style={{ lineHeight: "24px", fontSize: "16px" }}
							>
								Company
							</p>
							<p
								className="font-roboto font-semibold text-primary-900"
								style={{ fontSize: "16px", lineHeight: "24px" }}
							>
								{isGuest
									? submissionData?.name
									: submissionData?.company?.name || "-"}
							</p>
						</Flex>
						<Flex
							gap={4}
							className="my-4"
						>
							<p
								className="min-w-48 font-roboto font-normal text-secondary-500"
								style={{ lineHeight: "24px", fontSize: "16px" }}
							>
								Last Update
							</p>
							<p
								className="font-roboto font-semibold text-primary-900"
								style={{ fontSize: "16px", lineHeight: "24px" }}
							>
								{moment(submissionData?.lastUpdated).format("DD MMM YYYY") ||
									" - "}
							</p>
						</Flex>
						<Flex
							gap={4}
							className="my-4"
						>
							<p
								className="min-w-48 font-roboto font-normal text-secondary-500"
								style={{ lineHeight: "24px", fontSize: "16px" }}
							>
								Company Size
							</p>
							<p
								className="font-roboto font-semibold text-primary-900"
								style={{ fontSize: "16px", lineHeight: "24px" }}
							>
								{submissionData?.company?.size_name || " - "}
							</p>
						</Flex>
					</div>
				) : (
					<div className="space-y-4">
						<SkeletonBase
							width="512px"
							className="mb-6"
						/>
						{Array(4)
							.fill(0)
							.map(() => (
								<Flex>
									<SkeletonBase
										width="120px"
										className="mr-12"
									/>
									<SkeletonBase
										width="240px"
										className="mr-12"
									/>
								</Flex>
							))}
					</div>
				)}
			</Box>
			<div className="my-6">
				<PreviewRFQButtonTab
					setCurrentTab={setCurrentTab}
					currentTab={currentTab}
					rfqDetail={submissionData?.rfq}
					isLoading={loadSubmissionData}
					documentationTotal={documentationTotal}
					isSubmissionPreview
				/>
			</div>
			{submissionData && !loadSubmissionData && (
				<Formik initialValues={{ quote: initialDataTenderCost || [] }}>
					<Form>
						<div>
							{currentTab === constant.QUESTIONNAIRE && (
								<div>
									{submissionData?.submission?.returnable_schedule_file ? (
										<div className="max-w-[50%] px-2">
											<h1 className="font-bold">
												Returnable Schedule Response
											</h1>
											<FileCard
												file={{
													name: submissionData?.submission
														?.returnable_schedule_file?.file_name,
													path: submissionData?.submission
														?.returnable_schedule_file?.file_path,
												}}
												isPreview
											/>
										</div>
									) : (
										<Questionnaire
											questionnaire={initialDataQuestionnaire}
											preview
											isGuest={submissionData?.email ? true : false}
										/>
									)}
								</div>
							)}
							{currentTab === constant.TENDER_COST && (
								<div>
									{submissionData?.submission?.quote_file ? (
										<div className="max-w-[50%] px-2">
											<h1 className="font-bold">Quote Response</h1>
											<FileCard
												file={{
													name: submissionData?.submission?.quote_file
														?.file_name,
													path: submissionData?.submission?.quote_file
														?.file_path,
												}}
												isPreview
											/>
										</div>
									) : (
										<TenderCost
											tables={initialDataTenderCost}
											unitMeasures={unitMeasures}
											disable={true}
										/>
									)}
								</div>
							)}
						</div>
					</Form>
				</Formik>
			)}
			<DetailModal
				procurementMethods={procurementMethods}
				company={submissionData?.company}
				sectors={sectors}
			/>
			{(typeModal?.type === "award" || typeModal?.type === "decline") &&
				submissionData && (
					<AwardSupplierModal
						isOpen={isModalOpen}
						guestName={submissionData?.name}
						guestEmail={submissionData?.email}
						rfq={{ ...submissionData?.rfq, id: submissionData?.rfqId }}
						refreshData={navigateBack}
						onClose={() => setIsModalOpen(false)}
						company={submissionData?.company}
						isSubmissionPreview
						typeModal={typeModal}
					/>
				)}
		</SubmissionSmartFieldPreviewContextProvider>
	);
};

export default RFQSubmissionView;
