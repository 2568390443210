import { Image } from "assets/img";
import React from "react";
import tw, { styled } from "twin.macro";

const ContainerNotFound = styled.div(() => [
	tw`relative w-[480px] h-[480px] text-center items-center flex`,
]);

const NotFoundDatas = ({
	text,
	children,
	description,
	noDesc = false,
	className,
	...props
}) => {
	return (
		<ContainerNotFound
			className={className}
			{...props}
		>
			<Image
				img="background-notfound"
				className="absolute z-0"
			/>
			<div
				id="content"
				className="relative !z-10 grid min-w-[480px]"
			>
				<Image
					img="notfound-illustration"
					className="flex place-content-center"
				/>
				<p className="text-xl font-semibold">{text}</p>
				{!noDesc && !description && (
					<p className="text-base">
						To get started, simply import relevant groups from your Supplier
						List and begin building your project team.
					</p>
				)}
				{description && <p className="text-base">{description}</p>}
				<div className="mt-8 flex place-content-center">{children}</div>
			</div>
		</ContainerNotFound>
	);
};

export default NotFoundDatas;
