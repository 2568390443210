import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
	LoginWrapper,
	LeftSection,
	RightSection,
	LoginSection,
} from "./styled";
import { Heading } from "../Heading";

import { logoutUser } from "../../actions/authActions";
import rightImg from "./../../icons/login-right-img.jpg";
import { Button } from "../Button";
import { navigate } from "@reach/router";
import _ from "lodash";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const SuspendedComponent = ({ user, ...props }) => {
	useEffect(() => {
		if (_.isEmpty(user)) navigate("/login", { replace: true });
	}, [user]);
	const logout = async () => {
		await props.logoutUser();
	};

	return (
		<LoginWrapper>
			<LeftSection>
				<LogoWrapper />

				<LoginSection>
					<Heading marginBottom="50px">
						<span>Account Suspended</span>
					</Heading>
					<p className="instructions">
						Your Account has been suspended. Please contact
						<a href="mailto:support@procuracon.com.au"> Procuracon Support </a>
						for more information.
					</p>

					<Button onClick={logout}>Logout</Button>
				</LoginSection>
			</LeftSection>
			<RightSection
				style={{ backgroundImage: `url(${rightImg})` }}
			></RightSection>
		</LoginWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export const Suspended = connect(mapStateToProps, { logoutUser })(
	SuspendedComponent
);
