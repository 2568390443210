import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";
import { PageWrap, ContentContainer } from "../components/Layout";
import { Heading } from "../components/Heading";
import { TabContent } from "components/Tabs/styled";
import Loader from "react-spinners/BeatLoader";
import { shareProfile } from "actions/authActions";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "actions/authActions";
import { getCompany } from "actions/companyActions";
import { showToast } from "utils/helpers";
import { getGuestUser } from "actions/guestActions";

const ShareProfileComponent = (props) => {
	const [loading, setLoading] = useState(true);
	const queryParams = new URLSearchParams(window.location.search);
	const invitetoken = queryParams.get("sc");
	const guestUser = localStorage.getItem("guest_user");
	const dispatch = useDispatch();

	const loadCurrentUser = async () => {
		if (guestUser) {
			await dispatch(getGuestUser(JSON.parse(guestUser)?.id));
		} else {
			await dispatch(getCurrentUser());
			await dispatch(getCompany());
		}
	};

	const handleShareProfile = useCallback(async () => {
		const result = await props.shareProfile(invitetoken);
		if (result.success) {
			showToast(result?.message, "Success", true);
			if (result?.message === "You are already connected to this profile!") {
				navigate(`/`);
			} else {
				navigate(`/company/${result?.data?.companyId}/projects`);
			}
			await loadCurrentUser();
			localStorage.removeItem("ST");
		} else {
			showToast(result?.errors?.invitetoken[0] || result?.message, "Error");
			navigate(`/`);
			await loadCurrentUser();
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [props, invitetoken, dispatch]);

	useEffect(() => {
		if (loading) {
			handleShareProfile();
		}
		// eslint-disable-next-line
	}, [loading]);

	return (
		<PageWrap>
			<TabContent>
				<ContentContainer>
					<Heading size="xl">Share Profile</Heading>
					<>
						<p>Connecting profile to your account...</p>
						<Loader />
					</>
				</ContentContainer>
			</TabContent>
		</PageWrap>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		company: state.auth.current_company,
		companyId: state.search.activeCompany.id,
	};
};

export const ShareProfile = connect(mapStateToProps, {
	shareProfile,
	getCurrentUser,
	getCompany,
})(ShareProfileComponent);
