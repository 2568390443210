import { getCompanyUsers } from "actions/AccountManagementActions";
import { useEffect } from "react";
import { FieldForm } from "../Input";
import { useDispatch, useSelector } from "react-redux";

export default function UserSelectDropDown({
	name,
	label,
	placeholder,
	description,
}) {
	const company = useSelector((state) => state?.auth?.current_company);
	const companyUsers = useSelector((state) =>
		state?.accountManagement?.companyUsers?.map((user) => ({
			id: user?.id,
			label: user?.full_name,
			value: user?.id,
		}))
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanyUsers(company?.id));
		// eslint-disable-next-line
	}, []);

	return (
		<FieldForm
			label={label}
			placeholder={placeholder}
			name={name}
			description={description}
			type="select"
			required={true}
			options={companyUsers}
			isSearchable={false}
		/>
	);
}
