import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import { FieldForm } from "components/RFQ/Input";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

export default function QuestionTypeTable({ fieldName }) {
	const [, meta, helper] = useField(`${fieldName("fields")}`);
	const [editIndex, setEditIndex] = useState({});
	const [editHeadingTextIndex, setEditHeadingTextIndex] = useState(null);

	const headers = meta?.value?.map((row) => row.header);

	const values = meta?.value[0]?.values?.map((_, index) =>
		meta?.value?.map((row) =>
			row?.values?.length > 0 ? row?.values[index] : ""
		)
	);
	//required "null" instead of "" or null because BE does not store "" or null and ["",""] becomes undefined in response from BE
	const handleNewRowAdd = () => {
		if (meta?.value.length > 0) {
			const newValue = meta?.value?.map((row) => {
				if (row?.values) {
					return { ...row, values: [...row?.values, ""] };
				}

				return {
					...row,
					values: [""],
				};
			});
			helper.setValue(newValue);
		}
	};

	const handleRowDelete = (rowIndex) => {
		if (meta?.value.length > 0) {
			const newValue = meta?.value?.map((row) => {
				row?.values?.splice(rowIndex, 1);
				return row;
			});

			helper.setValue(newValue);
		}
	};

	//required "null" instead of "" or null because BE does not store "" or null and ["",""] becomes undefined in response from BE
	useEffect(() => {
		if (meta?.value.length === 0 || typeof meta?.value[0] !== "object") {
			helper.setValue([
				{ header: "Questions", values: [""] },
				{ header: "Answers", values: [""] },
			]);
		}
	}, [meta, helper]);

	return (
		<div>
			<table className="w-full table-auto rounded-xl border-b border-solid border-[#EAECF0] bg-white">
				<thead>
					<tr>
						{headers?.map((header, index) => (
							<th
								key={index}
								className={`bg-[#F9FAFB] p-2 text-left font-roboto font-medium text-tertiary-600 ${index === 0 ? "w-[30%]" : "w-[50%]"}`}
							>
								{editHeadingTextIndex === index || header === "" ? (
									<div className="flex gap-2">
										<FieldForm
											name={`${fieldName("fields")}[${index}][header]`}
											style={{
												width: "100%",
											}}
											placeholder={`${index === 0 ? "Questions" : "Answers"}`}
											onClick={() => setEditHeadingTextIndex(index)}
										/>
										<button onClick={() => setEditHeadingTextIndex(null)}>
											<Icon icon="check" />
										</button>
									</div>
								) : (
									<h2
										onClick={() => setEditHeadingTextIndex(index)}
										className="font-bold"
									>
										{header}
									</h2>
								)}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{values?.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{row.map((value, colIndex) => (
								<td
									key={`${rowIndex}-${colIndex}`}
									className="border border-[#EAECF0] px-3 font-roboto font-normal text-primary-500"
								>
									<div className="flex flex-wrap gap-4">
										{(editIndex?.colIndex === colIndex &&
											editIndex?.rowIndex === rowIndex) ||
										value === "" ? (
											<div className="flex flex-grow gap-2">
												<FieldForm
													name={`${fieldName("fields")}[${colIndex}][values][${rowIndex}]`}
													style={{
														width: "100%",
													}}
													placeholder={`${colIndex === 0 ? "Question" : "Leave empty for supplier response or prefill answer"}`}
													onClick={() => setEditIndex({ colIndex, rowIndex })}
												/>
												<button onClick={() => setEditIndex({})}>
													<Icon icon="check" />
												</button>
											</div>
										) : (
											<p
												className="flex-grow py-2"
												onClick={() => setEditIndex({ colIndex, rowIndex })}
											>
												{value}
											</p>
										)}
										{colIndex === 1 && values?.length !== 1 && (
											<button
												className="justify-self-end"
												onClick={() => handleRowDelete(rowIndex)}
												type="button"
											>
												<Icon icon="trash-stroke" />
											</button>
										)}
									</div>
								</td>
							))}
						</tr>
					))}
					<tr>
						<td>
							<Button
								btntype={"plain"}
								onClick={() => handleNewRowAdd()}
								type="button"
								style={{ paddingLeft: "8px" }}
							>
								<Flex
									gap={1}
									align={"center"}
								>
									<span className="text-royal-blue-500">+</span>
									<span>Add row</span>
								</Flex>
							</Button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
