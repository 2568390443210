import { combineReducers } from "redux";
import { projectReducer } from "./projectReducer";
import { supplierListsReducer } from "./supplierListsReducer";
import { rfqsReducer } from "./rfqsReducer";
import { templateReducer } from "./templateReducer";

export const rfqReducers = combineReducers({
	projects: projectReducer,
	supplierLists: supplierListsReducer,
	rfqs: rfqsReducer,
	template: templateReducer,
});
