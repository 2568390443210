import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Sectors } from "components/FormInputs/Sectors";
import { Submit } from "components/FormInputs/Submit";
import { ProfileType } from "components/FormInputs/ProfileType";
import { CompanySize } from "components/FormInputs/CompanySize";
import { Region } from "../FormInputs/Region";
import { ExpertiseDisciplines } from "../FormInputs/ExpertiseDisciplines";
import { Specialities } from "components/FormInputs/Specialities";
import { Trades } from "../FormInputs/Trades";
import { VStack, Grid, GridItem } from "@chakra-ui/react";

export const SupplierSearchForm = ({
	searchCompanies,
	projectId,
	...props
}) => {
	const initialValues = {
		location: "",
		sector_id: "",
		size: "",
		expertise: "",
		discipline: "",
		specialities: "",
		trades: "",
		profile_type: "",
		company_id: "",
		project_id: projectId,
	};

	const handleSearchSubmit = (values) => {
		if (values.trades?.length === 0) {
			delete values["trades"];
		}
		if (values.specialities?.includes(6)) {
			values.specialities = [1, 2, 3, 4, 5];
		}
		searchCompanies(values);
	};

	const validationSchema = Yup.object({
		location: Yup.string().required(),
		sector_id: Yup.number().required(),
		profile_type: Yup.number().required(),
		size: Yup.string().required(),
		// don't enforce validation on these fields if profile_type is subcontractor (id 2)
		expertise: Yup.string().when("profile_type", {
			is: (profile_type) => profile_type === 4,
			then: Yup.string().required(),
		}),
		discipline: Yup.string().when("profile_type", {
			is: (profile_type) => profile_type === 4,
			then: Yup.string().required(),
		}),
		trades: Yup.string().when("profile_type", {
			is: (profile_type) => profile_type === 3,
			then: Yup.string().required(),
		}),
		specialities: Yup.string().when("profile_type", {
			is: (profile_type) => profile_type === 2 || profile_type === 3,
			then: Yup.string().required(),
		}),
	});
	return (
		<div className="mx-auto min-w-[calc(100%-140px)] max-w-[1200px] rounded bg-gray-100 p-10">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values) => handleSearchSubmit(values)}
				validationSchema={validationSchema}
			>
				{(props) => {
					const { isSubmitting, values, setFieldValue } = props;

					const handleProfileTypeChange = (data, field) => {
						setFieldValue(field.name, data.value);
						setFieldValue("expertise", "");
						setFieldValue("discipline", "");
						setFieldValue("size", "");
						if (values.hasOwnProperty("trades")) {
							setFieldValue("trades", []);
						}
						if (values.hasOwnProperty("specialities")) {
							setFieldValue("specialities", []);
						}
					};

					return (
						<Form>
							<VStack
								id="wrapper__supplier-search-form"
								spacing={2}
								align="stretch"
							>
								<Grid
									templateColumns="repeat(2, 1fr)"
									gap={2}
									columnGap={6}
								>
									<Region
										name="location"
										label="Location"
									/>
									<Sectors
										label="Sector"
										required="false"
										name="sector_id"
										defaultValue={initialValues.sector_id}
										values={values}
										singleValue
									/>
									<ProfileType
										name="profile_type"
										label="Type"
										className="col xl-6"
										hideClient
										required
										onChange={handleProfileTypeChange}
									/>
									<CompanySize
										name="size"
										required="false"
										label="Size"
										value={values.size}
										profileType={values.profile_type}
										filter
										noOptionsMessage="Please select a supplier type to load options"
									/>
									{(values.profile_type === 2 || values.profile_type === 3) && (
										<GridItem colSpan={1}>
											<Specialities
												profileType={values.profile_type}
												required
											/>
										</GridItem>
									)}
									{/* subcontractor */}
									{values.profile_type === 3 && (
										<GridItem colSpan={1}>
											<Trades
												required
												profileType={values.profile_type}
												maxWidth="100%"
											/>
										</GridItem>
									)}

									{/* consultant */}
									{values.profile_type === 4 && (
										<GridItem colSpan={2}>
											<ExpertiseDisciplines
												label="Services provided by company"
												name="discipline"
												values={values}
												profileType={values.profile_type}
												required
												maxWidth="100%"
											/>
										</GridItem>
									)}
								</Grid>
							</VStack>
							<Submit
								type="submit"
								width="100%"
								isSubmitting={isSubmitting}
								text="Search"
								submittingText="Searching..."
								mt="xs"
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
