import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import api from "api";

import { SearchBox } from "components/SearchBox";
import { CompanyResourcesListing } from "../../components/CompanyResourceListing/CompanyResourceListing";
import { Toolbar } from "../../components/Toolbar";
import { Modal } from "components/Modal";
import ResourceDetailCard from "components/ResourceDetailCard/ResourceDetailCard";

export const CompanyResources = ({ resources, companyId, guestUser }) => {
	const [searchResults, setSearchResults] = useState(resources);
	const [isSearching, setIsSearching] = useState(false);

	useEffect(() => {
		setSearchResults(resources);
	}, [resources]);

	const tableHeaders = [
		{ label: "" },
		{ label: "Name", key: "first_name" },
		{ label: "Current Position", key: "position" },
		{ label: "Industry Experience", key: "experience" },
		{ label: "Top 3 Sectors", key: "sectors[0].name" },
		{ label: "" },
	];

	const urlParams = new URLSearchParams(window.location.search);
	const resourceId = urlParams.get("resource");
	const [isModalOpen, setIsModalOpen] = useState(resourceId);

	const onFilterSubmit = async (values) => {
		const { search, sector } = values;
		setIsSearching(true);

		try {
			const filteredResources = await api
				.get(
					`companies/${companyId}/key-personnel?filter[company_id]=${companyId}&search=${search}&filter[sector_ids]=${sector}&email=${guestUser.email}`
				)
				.json();
			setSearchResults([...filteredResources.data]);
		} catch (e) {
			console.log(e);
		}

		setIsSearching(false);
	};

	console.log(searchResults);

	return (
		<>
			<Toolbar>
				<SearchBox
					placeholder="Search resources"
					handleFormSubmit={onFilterSubmit}
					companyId={companyId}
					searchInput
					sectorFilter
					multiSector
					whiteInput
				/>
			</Toolbar>

			{!isSearching ? (
				<CompanyResourcesListing
					tableHeaders={tableHeaders}
					resources={searchResults}
				/>
			) : (
				<Skeleton count={5} />
			)}

			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				width="50%"
				className="w-1/2 max-w-[1000px]"
				scroll
				shouldCloseOnOverlayClick
				innerClassName={"!max-h-[90vh]"}
			>
				<ResourceDetailCard
					data={searchResults?.find(
						(result) => result?.id === parseInt(resourceId)
					)}
				/>
				;
			</Modal>
		</>
	);
};
