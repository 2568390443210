import styled from "styled-components";

import { CompanyResultCardStyled } from "../../components/CompanyResultCard/styled";

export const SearchTemplateStyled = styled.div`
	position: relative;
	background-color: white;
	height: 100vh;
	display: flex;
	overflow-y: scroll;
	flex-direction: column;
`;

export const SearchHeader = styled.div`
	background-color: white;
	width: 100%;
	padding: ${(props) => props.theme.space.lg};
	padding-bottom: 0;
	border-bottom: 1px solid ${(props) => props.theme.colors.royalBlue};
`;

export const TableBody = styled.div`
	background-color: ${(props) => props.theme.colors.snow};
	padding: ${(props) => props.theme.space.lg};
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	max-width: 100%;
	overflow-x: hidden;
	height: 100%;
	padding-right: 290px;
	padding-bottom: 0;
	padding-top: ${(props) =>
		props.paddingTop ? props.paddingTop : props.theme.space.lg};
`;

export const DataHeadings = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 250px;
	height: 100%;
	padding-top: calc(
		19.5rem + ${(props) => (props.compareTable ? "-6px" : "0px")}
	);
	/* margin-top: 1rem; */
	min-width: 250px;
	flex-grow: 1;

	h3 {
		position: sticky;
		top: 200px;
		color: ${(props) => props.theme.colors.navy};
		font-size: ${(props) => props.theme.fontSizes.md};
		font-weight: bold;
		margin: 0;
	}
`;

export const Viewport = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
`;

export const LockedItems = styled.div`
	display: flex;
	background: white;
	${"" /* padding: ${(props) => props.theme.space.md}; */}
	border-right: 1px solid ${(props) => props.theme.colors.grayBorder};
	margin-right: ${(props) => props.theme.space.md};
	z-index: 10;
	margin-right: ${(props) => props.theme.space.xs};
	box-shadow: rgb(231, 231, 231) 4px 0px 11px;

	${CompanyResultCardStyled} {
		background-color: white;
	}
`;

export const Arrows = styled.div`
	display: flex;
	align-items: center;
	padding: 0 40px 0 40px;
	button {
		cursor: pointer;
		position: relative;
		display: inline-block;
		padding: 10px;
		background-color: ${(props) => props.theme.colors.mintDark};
		border-radius: 15px;
		width: 30px;
		height: 30px;
	}

	svg {
		width: 10px;
		height: 10px;
		color: ${(props) => props.theme.colors.white};
		position: absolute;
		top: 10px;
		left: 10px;
	}

	.left {
		margin-right: 10px;
		svg {
			transform: rotate(180deg);
		}
	}
	span {
		margin-left: ${(props) => props.theme.space.xxs};
		font-size: 10px;
		color: ${(props) => props.theme.colors.grayLight};
		letter-spacing: 0.04em;
	}
`;

export const HeadingInner = styled.div`
	font-size: 48px;
`;
