import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { connect } from "react-redux";

import { ExpertiseRepeaterStyled } from "./styled";
import { Select } from "../Select";
import { MultiSelect } from "../MultiSelect";
import {
	getExpertiseDisciplines,
	getDisciplinesByProfileType,
} from "../../../actions/companyActions";

const ExpertiseDisciplinesConnected = ({
	className,
	name,
	values,
	options,
	getExpertiseDisciplines,
	getDisciplinesByProfileType,
	profileType,
	required,
	maxWidth,
	...props
}) => {
	const { setFieldValue } = useFormikContext();

	//receive these from server
	const handleExpertiseChange = (option, field, disciplineField) => {
		setFieldValue(disciplineField, "");
		setFieldValue(field.name, option.value);
	};

	useEffect(() => {
		if (profileType) {
			getDisciplinesByProfileType(profileType);
		}
	}, [getExpertiseDisciplines, getDisciplinesByProfileType, profileType]);

	if (!options) {
		return "";
	}

	return (
		<ExpertiseRepeaterStyled className={className}>
			<div className="flex">
				<div className="mr-2 flex-1">
					<Select
						label={profileType === 3 ? "Service" : "Expertise"}
						name="expertise"
						className="col sm-6"
						options={Object.keys(options)}
						onChange={(option, field) =>
							handleExpertiseChange(option, field, "discipline")
						}
						required={required}
						freeText
					/>
				</div>
				<div className="ml-2 flex-1">
					<MultiSelect
						label={profileType === 3 ? "Category" : "Discipline"}
						name="discipline"
						className="col sm-6 pb-0.5"
						options={options[values.expertise]}
						required={required}
						value={values.discipline}
						maxWidth={maxWidth}
					/>
				</div>
			</div>
		</ExpertiseRepeaterStyled>
	);
};

const mapStateToProps = (state) => {
	return {
		options: state.companyProfile.expertiseDisciplinesByProfile,
	};
};

export const ExpertiseDisciplines = connect(mapStateToProps, {
	getExpertiseDisciplines,
	getDisciplinesByProfileType,
})(ExpertiseDisciplinesConnected);
