import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import { LoginWrapper, LoginSection, BottomTag, BottomLinks } from "./styled";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { guestSignup, guestLogin } from "../../actions/authActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Checkbox2 } from "../FormInputs/Checkbox2";
import { Link } from "@reach/router";
import { FlashError } from "../FormInputs/FlashError";
import { navigate } from "@reach/router";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";
import { showToast } from "utils/helpers";

const CustomLabel = () => {
	return (
		<p style={{ margin: "0px" }}>
			I agree to the{" "}
			<a
				href="/terms-and-conditions"
				target="_blank"
			>
				Terms & Conditions
			</a>{" "}
			and{" "}
			<a
				href="/privacy-policy"
				target="_blank"
			>
				Privacy Policy
			</a>
		</p>
	);
};

export const GuestAuthFormComponent = ({
	token,
	withRfqId,
	isSignUp,
	...props
}) => {
	const successMessage = (
		<>
			<h2>Login Successful</h2>
			<p>Welcome to Procuracon</p>
		</>
	);

	const urlParams = new URLSearchParams(window.location.search);
	const sc = urlParams.get("sc");
	const rit = urlParams.get("rit");

	// console.log(rit);

	useEffect(() => {
		if (sc || rit) {
			localStorage.setItem(`${rit ? "RIT" : "ST"}`, rit ?? sc);
		}
	}, [sc, rit]);

	const handleSubmit = async (values, setErrors, setSubmitting, setStatus) => {
		setSubmitting(true);
		setStatus(null);
		if (isSignUp) {
			try {
				await props.guestSignup(values, setSubmitting, setStatus);
			} catch (e) {
				const error = e.response.data;
				setStatus(true);
				setErrors(error);
				error.errors !== undefined && setErrors(error);
			}
		} else {
			try {
				await props.guestLogin(values);
				setSubmitting(false);
				showToast(successMessage, "Success", true);
				const sharedToken = localStorage.getItem("ST");
				if (sharedToken) {
					navigate(`/share-profile?sc=${sharedToken}`);
				} else {
					navigate(`/account/request-for-quote`);
				}
			} catch (e) {
				setSubmitting(false);
				const error = await e.response.json();
				setStatus(true);
				setErrors(error);
				error.errors !== undefined && setErrors(error.message);
			}
		}
	};

	return (
		<LoginWrapper>
			<LogoWrapper />

			<LoginSection>
				<Heading marginBottom="40px">
					<span>{isSignUp ? "Guest Sign up" : "Guest Sign in"}</span>
				</Heading>

				<Formik
					enableReinitialize
					initialValues={{
						email: "",
						password: "",
						token_type: `${rit ? "rfx" : "company"}`,
						token: `${rit ?? sc}`,
					}}
					validationSchema={Yup.object({
						email: Yup.string().email().required(),
						password: Yup.string().required(),
						agreed:
							isSignUp &&
							Yup.boolean()
								.oneOf([true], "Please accept before continuing")
								.required("Please accept before continuing"),
					})}
					onSubmit={(values, { setErrors, setSubmitting, setStatus }) => {
						setStatus(false);
						handleSubmit(values, setErrors, setSubmitting, setStatus);
					}}
				>
					{(props) => {
						const { setFieldValue, isSubmitting, status, setStatus, errors } =
							props;
						const onSubmitValidationError = () => {
							setStatus("required");
						};

						return (
							<Form onChange={() => setStatus(false)}>
								<div className="row">
									<TextInput
										label="Enter your email"
										placeholder="Email"
										name="email"
										type="email"
										className="col xl-12"
										setFieldValue={setFieldValue}
									/>

									<TextInput
										label="Enter your password"
										placeholder="Password"
										name="password"
										type="password"
										className="col xl-12"
										setFieldValue={setFieldValue}
										icon
									/>
									{!isSignUp ? (
										<BottomLinks className="col xl-12">
											<Checkbox2
												label="Remember me"
												name="remember me"
											/>
											<Link to="/guest/reset-password">Forgot my password</Link>
										</BottomLinks>
									) : (
										<>
											<BottomLinks className="col xl-12">
												<Checkbox2
													name="agreed"
													labelComponent={<CustomLabel />}
													noWarning
												/>
											</BottomLinks>
										</>
									)}
									<div className="col xl-12">
										{status && (
											<FlashError
												heading={errors?.title || errors?.error}
												text={
													errors.agreed ||
													errors?.error ||
													errors?.errors?.error ||
													errors?.errors?.token ||
													errors?.errors?.email ||
													errors?.errors?.password
												}
												margin="20px 0px 0px 0px"
											/>
										)}
									</div>
									<div className="col xl-12">
										<Submit
											type="submit"
											widthExpand
											marginTop={isSignUp && !status ? "0px" : "24px"}
											isSubmitting={isSubmitting}
											text={isSignUp ? "sign up" : "sign in"}
											submittingText={
												isSignUp ? "Signing up..." : "Logging in..."
											}
										/>
									</div>
								</div>
								<OnSubmitValidationError callback={onSubmitValidationError} />
							</Form>
						);
					}}
				</Formik>
				<BottomTag>
					{isSignUp ? (
						<>
							Already have an account?
							<br />
							<div className="flex justify-between gap-8">
								<Link to={"/guest/login"}>Sign In as a Guest</Link>
								<Link to={`/login`}>Sign In as a Subscriber</Link>
							</div>
						</>
					) : (
						<>
							Not Signed Up yet?
							<div className="flex justify-center gap-8 pt-2">
								<Link to="/signup">Sign Up as a Subscriber</Link>
							</div>
						</>
					)}
				</BottomTag>
			</LoginSection>
		</LoginWrapper>
	);
};

export const GuestAuthForm = connect(null, {
	guestLogin,
	guestSignup,
})(GuestAuthFormComponent);
