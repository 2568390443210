import { Flex } from "@chakra-ui/react";
import { updateAddendumAnswer } from "actions/RFQ/addendumAction";
import { Icon } from "assets/icons/Icon";
import { AddendumMessage } from "components/Pages/RFQDetail/AddendumMessage";
import { ChatBubbleBox } from "features/addendum/components/Addendum.elements";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { STATUS_PRIVATE, STATUS_PUBLIC } from "utils/constants";
import { Avatar } from "../Avatar";
import { ToggleButton } from "../ToggleButton";
import { AddendumAttachment } from "features/addendum/components/AddendumAttachment";
// import DropdownAddendumMenu from "./DropdownAddendumMenu";

export const SplitComp = ({ label }) => {
	return (
		<div
			className="text-primary-tertiary-600 relative mx-10 my-4 flex items-center justify-center overflow-hidden px-4 font-roboto text-[14px]"
			style={{ height: "20px" }}
		>
			<div
				className="border border-solid border-[#EAECF0]"
				style={{ width: "100%" }}
			></div>
			<div className="absolute bg-white p-4">{label}</div>
		</div>
	);
};

export const StatusComp = ({
	time,
	status = "public",
	isUser,
	handleClickReply,
	isAnswer,
	nested,
	id,
	addendum,
	...props
}) => {
	const [isPublished, setIsPublised] = useState(status === STATUS_PUBLIC);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsPublised(status === STATUS_PUBLIC);
	}, [status]);

	const handleChangePublished = async () => {
		setIsLoading(true);
		updateAddendumAnswer(id, {
			published: isPublished ? 0 : 1,
			addendum: addendum,
		}).then((res) => {
			if (res) {
				props?.publishQuestionHandler(props?.questionId, !isPublished);
				setIsPublised(!isPublished);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		});
	};

	return (
		<div className="mt-1.5 flex items-center justify-between">
			<Flex gap={3}>
				<div className="font-roboto text-xs text-tertiary-600">
					{moment(time).format("dddd h:mmA")}
				</div>
				{!isAnswer && props?.emptyAnswer && (
					<button
						type="button"
						onClick={handleClickReply}
					>
						<Flex
							gap={2}
							align={"center"}
						>
							<Icon
								icon="corner-up-left"
								color="#475467"
							/>
							<p className="text-xs text-royal-blue">Reply</p>
						</Flex>
					</button>
				)}
			</Flex>
			<div>
				<div className="flex items-center justify-end">
					{isAnswer && isUser && nested ? (
						<>
							{isPublished ? (
								<p className="font-roboto text-xs text-tertiary-600">
									Published
								</p>
							) : (
								<ToggleButton
									checked={isPublished}
									label={"Publish"}
									onChange={handleChangePublished}
									disabled={isLoading}
								/>
							)}
						</>
					) : (
						<>
							<p className="font-roboto text-xs text-tertiary-600">
								{isPublished ? "Public" : "Private"}
							</p>
							{status === STATUS_PUBLIC && (
								<div className="ml-2">
									{" "}
									<Icon
										icon="eye-on"
										color="#475467"
									/>
								</div>
							)}
							{status === STATUS_PRIVATE && (
								<div className="ml-2">
									{" "}
									<Icon
										icon="eye-off"
										color="#475467"
									/>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const AnswerComp = ({
	message,
	attachments,
	displayName,
	status,
	companyLogo,
	answerId,
	isUser,
	nested,
	isAnswer,
	isCommunication,
	...props
}) => {
	return (
		<div
			className={`group flex items-start justify-start gap-3 ${nested ? "ml-16" : ""}`}
		>
			<Avatar
				defaultImage={companyLogo ? "company" : "user"}
				avatar={companyLogo}
				className="bg-gray-50"
			/>
			<div
				className="flex-auto"
				style={{ maxWidth: "calc(100% - 52px)" }}
			>
				<div className="mb-2 flex min-h-10 w-full items-center justify-between">
					<div className="flex items-center gap-2">
						<div
							className="mb-1 mt-1 font-roboto font-medium text-secondary-700"
							style={{ fontSize: "14px" }}
						>
							{displayName}
						</div>
						{isUser && (
							<div className="max-h-[22px] rounded-full border border-gray-200 bg-gray-50 px-2 py-0.5 font-roboto text-xs font-medium capitalize text-utility-gray-700">
								YOU
							</div>
						)}
					</div>
					{/* <DropdownAddendumMenu
						nested={nested}
						answerId={answerId}
						id={`item-${answerId}`}
						key={`item-${answerId}`}
						{...props}
					/> */}
				</div>
				<ChatBubbleBox isPublished={status === "public"}>
					<div
						dangerouslySetInnerHTML={{
							__html: message?.replace("<span", "<p"),
						}}
					/>
					{attachments?.map((attachment) => (
						<AddendumAttachment
							attachment={attachment}
							isCommunication={isCommunication}
						/>
					))}
				</ChatBubbleBox>
				<StatusComp
					addendum={message}
					isAnswer={true}
					status={status}
					isUser={isUser}
					id={answerId}
					nested={nested}
					{...props}
				/>
			</div>
		</div>
	);
};

export const AskComp = ({
	message,
	attachments,
	displayName,
	status,
	isUser,
	companyLogo,
	time,
	rfqId,
	questionId,
	indexList,
	setAddendumData,
	emptyAnswer,
	publishQuestionHandler,
	isCommunication,
}) => {
	const [isReply, setIsReply] = React.useState(false);

	return (
		<>
			<div className="mb-4 mt-8 flex items-start justify-start gap-3">
				<Avatar
					defaultImage={companyLogo ? "company" : "user"}
					avatar={companyLogo}
					className="bg-gray-50"
				/>
				<div className="w-full">
					<div
						className="mb-1 mt-1 flex items-center gap-2 font-roboto font-medium text-secondary-700"
						style={{ fontSize: "14px" }}
					>
						<span>{displayName}</span>
						{isUser && (
							<div className="max-h-[22px] rounded-full bg-utility-gray-200 px-2 py-0.5 font-roboto text-xs font-medium capitalize text-utility-gray-700">
								YOU
							</div>
						)}
					</div>
					<ChatBubbleBox isPublished={status === "public"}>
						<div
							dangerouslySetInnerHTML={{
								__html: message.replace("<span", "<p"),
							}}
						/>
						{attachments?.map((attachment) => (
							<AddendumAttachment
								attachment={attachment}
								isCommunication={isCommunication}
							/>
						))}
					</ChatBubbleBox>
					<StatusComp
						addendum={message}
						status={status}
						handleClickReply={() => setIsReply(!isReply)}
						time={time}
						rfqId={rfqId}
						id={questionId}
						emptyAnswer={emptyAnswer}
						publishQuestionHandler={publishQuestionHandler}
					/>
				</div>
			</div>
			{isReply && (
				<AddendumMessage
					nested={true}
					logo={companyLogo}
					questionId={questionId}
					indexList={indexList}
					rfqId={rfqId}
					setAddendumData={setAddendumData}
					handleClose={() => setIsReply(false)}
				/>
			)}
		</>
	);
};
