import React from "react";

import { Button } from "components/RFQ/Button";
import { Spinner } from "@chakra-ui/react";

export const ButtonToggle = ({
	documentId,
	isPublic,
	handleClick,
	documentDisplayUpdateLoading,
}) => {
	return (
		<div className="flex gap-0 rounded-md">
			<Button
				btntype={`${isPublic ? "plain-border" : "primary"}`}
				disablePointer={!isPublic}
				disabledStyle={isPublic}
				size="sm"
				className="!rounded-none !rounded-bl-lg !rounded-tl-lg !py-3"
				onClick={() => handleClick(documentId, "private")}
			>
				{documentDisplayUpdateLoading.loading &&
				documentDisplayUpdateLoading?.documentId === documentId &&
				isPublic ? (
					<Spinner />
				) : (
					"Private"
				)}
			</Button>

			<Button
				btntype={`${isPublic ? "primary" : "plain-border"}`}
				disablePointer={isPublic}
				disabledStyle={!isPublic}
				size="sm"
				className="!rounded-none !rounded-br-lg !rounded-tr-lg !py-3"
				onClick={() => handleClick(documentId, "public")}
			>
				{documentDisplayUpdateLoading.loading &&
				documentDisplayUpdateLoading?.documentId === documentId &&
				!isPublic ? (
					<Spinner />
				) : (
					"public"
				)}
			</Button>
		</div>
	);
};
