import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { showToast } from "../../utils/helpers"; // Updated import

import { RequestAccessFormStyled } from "./styled";
import { FormHeading } from "../FormHeading";
import { TextInput } from "../FormInputs/TextInput";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { TextArea } from "../FormInputs/TextArea";
import { Submit } from "../FormInputs/Submit";

export const RequestAccessForm = ({
	documentId,
	userId,
	current_company,
	handleSubmit,
	profileTypeId,
	title,
	...props
}) => {
	const initialValues = {
		business_name: current_company?.name || "",
		client_id: userId,
		client_company_id: current_company?.id,
		document_id: documentId || "",
		owner_company_id: "",
		requestor_name: "",
		purpose: "",
	};
	return (
		<RequestAccessFormStyled>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					requestor_name: Yup.string().required("This is a required field"),
					purpose: Yup.string().required("This is a required field"),
				})}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const { isSubmitting } = props;
					const onSubmitValidationError = () => {
						showToast("Please review field errors in form.", "Error"); // Updated function call
					};
					return (
						<Form>
							<div className="container">
								<FormHeading
									color={"#263859"}
									margin={"18px 0px 40px 0px"}
									text={title}
								/>
								<div className="row">
									<TextInput
										label="Company Name"
										required={true}
										name="business_name"
										placeholder={"Your Company Name"}
										className="col xl-12"
										placeholderSize={"1rem"}
										disabled
									/>
									<TextInput
										label="Name of Requestor"
										required={true}
										name="requestor_name"
										placeholder={"Full Name"}
										className="col xl-12"
										placeholderSize={"1rem"}
									/>
									<TextInput
										label="Purpose"
										name="purpose"
										required={true}
										placeholder={"e.g. Seeking expert for project"}
										className="col xl-12"
										placeholderSize={"1rem"}
									/>
									<TextArea
										label="Project opportunity"
										name="opportunity"
										placeholder={
											"Provide a brief description of the project or opportunity"
										}
										className="col xl-12"
										placeholderSize={"1rem"}
									/>
								</div>
								<Submit
									type="submit"
									marginBottom={"18px"}
									isSubmitting={isSubmitting}
									text="REQUEST ACCESS"
									place
									submittingText="requesting..."
								/>
							</div>

							<OnSubmitValidationError callback={onSubmitValidationError} />
						</Form>
					);
				}}
			</Formik>
		</RequestAccessFormStyled>
	);
};
