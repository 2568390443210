import React from "react";
import { Badge } from "../Badge";

const StatusBadge = ({ status }) => {
	let colorStatus = "";
	let statusText = "";
	const statusType = status?.toUpperCase();
	switch (statusType) {
		case "ACTIVE":
			colorStatus = "active";
			statusText = "Open";
			break;
		case "COMPLETE":
			colorStatus = "success";
			statusText = "Complete";
			break;
		case "ON_HOLD":
			colorStatus = "default";
			statusText = "On Hold";
			break;
		case "ARCHIVED":
			colorStatus = "default";
			statusText = "Archived";
			break;
		case "CANCELLED":
			colorStatus = "error";
			statusText = "Cancelled";
			break;
		case "SUSPENDED":
			colorStatus = "error";
			statusText = "Suspended";
			break;
		case "INVITED":
			colorStatus = "success";
			statusText = "Invited";
			break;
		case "SUBMITTED":
			colorStatus = "active";
			statusText = "Submitted ";
			break;
		case "ACCEPTED":
			colorStatus = "success";
			statusText = "Accepted";
			break;
		case "EVALUATION":
			colorStatus = "success";
			statusText = "Evaluation";
			break;
		case "AWARDED":
			colorStatus = "active";
			statusText = "Awarded";
			break;
		case "DECLINE":
			colorStatus = "error";
			statusText = "Declined";
			break;
		case "INPROGRESS":
			colorStatus = "success";
			statusText = "In Progress";
			break;
		default:
			colorStatus = "default";
			statusText = status;
			break;
	}

	return (
		<div>
			<Badge color={colorStatus}>
				<p className="font-roboto text-sm font-medium capitalize leading-5">
					{statusText}
				</p>
			</Badge>
		</div>
	);
};

export default StatusBadge;
