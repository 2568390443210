import api from "api";
import * as constants from "./actionTypes";
import { getCookie } from "utils/cookiesHelper";

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const getGlobalPresence = () => {
	return async (dispatch) => {
		(async () => {
			try {
				const globalPresence = await getApiWithToken()
					.get("global-presence")
					.json();
				await dispatch({
					type: constants.GET_GLOBAL_PRESENCE,
					payload: globalPresence,
				});
			} catch (e) {
				console.log(e);
			}
		})();
	};
};
