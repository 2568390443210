import styled from "styled-components";

export const ExpertiseRepeaterStyled = styled.div``;

export const ExpertiseRepeaterRow = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	flex-wrap: nowrap;
`;

export const RemoveButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	margin: 0 0 ${(props) => props.theme.space.xxs};
	color: ${(props) => props.theme.colors.red};
	cursor: pointer;
	position: absolute;
	right: 15px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.8rem;
	z-index: 5;
	transition: ${(props) => props.theme.transitions.default};

	&::hover {
		color: ${(props) => props.theme.colors.redDark};
	}
`;
