import React from "react";
import styled from "styled-components";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as PinIcon } from "../../icons/pin.svg";
import { ReactComponent as WebIcon } from "../../icons/web.svg";

export const ContactDetail = styled.a`
	font-size: 0.875rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	margin: 0 0 0.75rem;
	word-break: break-word;
	color: ${(props) => props.theme.colors.gray};

	&::hover {
		color: ${(props) => props.theme.colors.royalBlue};

		svg {
			path {
				stroke: ${(props) => props.theme.colors.royalBlue};
			}
		}
	}

	svg {
		margin-right: 0.875rem;
		min-width: 15px;
		width: 17px;
		height: 17px;

		path {
			transition: ${(props) => props.theme.transitions.default};
			stroke: ${(props) => props.theme.colors.grayLight};
		}
	}

	${(props) =>
		props.highlight &&
		`
        color: ${props.theme.colors.royalBlue};
    `};
`;

export const ContactDetails = ({ details, ...props }) => {
	return (
		<>
			{props.phone && (
				<ContactDetail href={`tel:${props.phone}`}>
					<PhoneIcon />
					{props.phone}
				</ContactDetail>
			)}

			{props.email && (
				<ContactDetail href={`mailto:${props.email}`}>
					<MailIcon />
					{props.email}
				</ContactDetail>
			)}

			{props.address && (
				<ContactDetail
					href={`https://www.google.com/maps/search/?api=1&query=${props.address}`}
					target="_blank"
				>
					<PinIcon />
					{props.address}
				</ContactDetail>
			)}

			{props.website && (
				<ContactDetail
					highlight
					href={
						props.website.indexOf("http") === 0
							? props.website
							: `https://${props.website}`
					}
					target="_blank"
				>
					<WebIcon className="[&>path]:!stroke-transparent" />
					View website
				</ContactDetail>
			)}
		</>
	);
};
