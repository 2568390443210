import React from "react";
import { SupplierSearchForm } from "components/SupplierSearchForm";

export const SearchForm = ({ searchCompanies, projectId, ...props }) => {
	return (
		<div
			id="search-form"
			className="flex min-h-screen w-full flex-col items-center justify-center gap-5 overflow-y-auto"
		>
			<h1 className="font-heading text-[3.4rem] text-royal-blue">
				Search for a supplier
			</h1>
			<SupplierSearchForm
				searchCompanies={searchCompanies}
				projectId={projectId}
			/>
		</div>
	);
};
