import styled from "styled-components";

export const RefineResultsStyled = styled.div`
	background: ${(props) => props.theme.colors.blueGreyLight};
	padding: ${(props) => props.theme.space.lg} ${(props) => props.theme.space.lg}
		${(props) => props.theme.space.md};
	position: fixed;
	transform: ${(props) => (props.open ? "translateY(0)" : "translateY(-100%)")};
	will-change: transform;
	transition: transform 0.5s ease;
	z-index: 3;
	top: 0;
	left: 68px !important;
	width: calc(100% - 68px);
	box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.25);

	${(props) => props.theme.mediaQueries.xxl} {
		left: 92px;
	}

	.refineResults {
		&__row {
			display: flex;
			justify-content: flex-start;

			&.input-row {
				margin-left: -15px;
			}
		}

		&__tag-row {
			margin-bottom: 35px;
		}

		&__submit {
			align-self: flex-start;
			height: 46px;
		}
	}
`;

export const RefineButton = styled.div`
	position: ${(props) => (props.opened ? "absolute" : "inherit")};
	right: ${(props) => (props.opened ? "52px" : "inherit")};
	will-change: transform;
	transition: transform 0.5s ease;
`;
