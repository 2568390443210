export const getSavedCompanyField = (c) => {
	const company = c;
	return {
		logo: company.logo_path || "",
		banner_image: company.banner_path || "",
		name: company.name || "",
		company_number: company.company_number || "",
		sector_ids: Array.isArray(company.sector)
			? company.sector.map((sector) => sector.id)
			: [],
		speciality_ids: Array.isArray(company.speciality)
			? company.speciality.map((speciality) => speciality.id)
			: [],
		size_type_id: company.size_type_id || "",
		description: company.description || "",
		keywords: company.keywords || [],
		services: company.services || [],
		contact_name: company.contact_name || "",
		phone: company.phone || "",
		email: company.email || "",
		website: company.website || "",
		company_id: company.id || "",
		status: company.status || "",
		disciplines: company.disciplinesFormatted?.length
			? company.disciplinesFormatted
			: [{ expertise: "", disciplines: [] }],
		profile_type: company.profile_type.id,
		trade_ids: Array.isArray(company.trade)
			? company.trade.map((trade) => trade.id)
			: [],
		years_trading: parseInt(company.years_trading) || "",
		global_presence: company.global_presence.map((g) => {
			return {
				value: g.id,
				label: g.name,
			};
		}),
		abn: company.abn,
		indigenous_buisness: String(company.indigenous_buisness),
	};
};
