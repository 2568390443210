import React from "react";

import { Heading } from "../components/Heading";
import { ContentPage } from "../components/ContentPage/ContentPage";

export const TermsConditions = (props) => {
	return (
		<ContentPage>
			<Heading>Welcome to Procuracon! Build possible.</Heading>
			<div>
				<h2>WHAT PARTS OF THESE TERMS APPLY TO ME?</h2>
				<p>
					This agreement governs your use of the Procuracon platform, accessible
					at https://beta.procuracon.com.au/ (Platform) and any goods or
					services made available through the Platform. By clicking ‘accept’ to
					this agreement, you agree to be bound by this agreement which forms a
					binding contractual agreement between you, the User, and us,
					PROCURACON PTY LTD ABN 29 629 025 225 operating under the registered
					business name Procuracon (<strong>Procuracon</strong>,{" "}
					<strong>we</strong> or <strong>us</strong>).
				</p>
				<p>The remainder of this agreement is divided into three parts:</p>
				<ul>
					<li>
						<strong>Part A (All Users)</strong>, which sets out terms that apply
						to all Users;
					</li>
					<li>
						<strong>Part B (Suppliers)</strong>, which sets out additional terms
						that apply to Suppliers, being Users who register for a ‘Service
						Provider’ Account and/or offer to sell services through the
						Platform; and
					</li>
					<li>
						<strong>Part C (Clients)</strong>, which sets out additional terms
						that apply to Clients, being Users who register for a ‘Client
						Organisation and Project Owner / Representative’ Account and/or
						offer to buy services through the Platform.
					</li>
				</ul>
				<p>
					If you intend to use the Platform as a Supplier, only Parts Part A and
					Part B of these terms will apply to you. <br />
					If you intend to use the Platform as a Client, only Parts Part A and
					Part C of these terms will apply to you. <br />
					If you intend to use the Platform both as a Supplier and a Client,
					then Parts A, B and C of these terms will apply to you.
					<p>
						{" "}
						When we talk about the <strong>“Services”</strong> in this
						agreement, we are referring to the services available through the
						Platform via Suppliers, and any other services we may offer on the
						Platform.
					</p>
				</p>
				<h2>DISCLAIMER</h2>
				<ol type="a">
					<li>
						We rely on information given to us by Suppliers and we require that
						they comply with all applicable laws or industry requirements when
						listing Services on our Platform. However, we generally do not carry
						out any research or independent or external verification to prove
						that Suppliers comply with all of these requirements. It is all
						Users' responsibility to ensure that Services listed comply with all
						applicable laws and standards, are licensed to provide any Services
						and are fit for purpose and Users must undertake their own
						investigations to ensure this.{" "}
					</li>
					<li>
						Users must verify that any information uploaded on the Platform is
						true and accurate. We do not verify any of this information and it
						is up to you to ensure that any information you rely upon when
						engaging with a Supplier or other User on the Platform is verified
						and accurate. We will not be liable for any issues with any
						Suppliers’ or other Users’ documentation, including company or
						financial information.
					</li>
					<li>
						You are responsible for ensuring any Services you engage with and
						any Supplier you engage complies with any additional agreements you
						are a party to, such as any head contract.{" "}
					</li>
					<li>By using our Platform, you acknowledge and agree that:</li>
					<ol type="i">
						<li>
							we are reliant on the information provided by Suppliers and to the
							extent permitted by law, we disclaim all warranties that any
							Services listed will be fit for purpose or comply with all
							applicable laws and industry standards;
						</li>
						<li>
							you should make your own inquiries as to suitability of any
							Services for your particular use before purchasing;
						</li>
						<li>
							you are responsible for your supply and use of all Services,
							including complying with all laws, safety requirements and any
							industry standards;{" "}
						</li>
						<li>we do not endorse or recommend any User or Profile; and</li>
						<li>
							we will not be responsible for any issues with head contractor or
							subcontractors, licensing, consultancy agreements or compliance
							with any other agreements.{" "}
						</li>
					</ol>
				</ol>
				<p>
					A. PROCURACON has developed a pre-release Beta version of the platform
					functionality, not immediately available for public release, including
					modifications, enhancements, improvements, updates, additions,
					derivative works, documentation and related material (“Beta
					Platform”).
				</p>
				<p>
					B. PROCURACON desires that the Beta Platform be tested prior to a
					generally available commercial release.
				</p>
				<p>
					C. Licensee wishes to serve as a Beta tester for the Beta Platform and
					acknowledges the Beta Disclaimer;
				</p>
				<h2>1: ELIGIBILITY</h2>
				<ol type="a">
					<li>
						This Platform is not intended for unsupervised use by any person
						under the age of 18 years old or any person who has previously been
						suspended or prohibited from using the Platform or any competing
						Platforms of Procuracon (without the prior consent of Procuracon).
						By using the Platform, you represent and warrant that you:
						<ol type="i">
							<li>
								have not been suspended or prohibited from using the Platform;{" "}
							</li>
							<li>
								are not a direct competitor of Procuracon and are using the
								Platform without our consent; and
							</li>
							<li>
								are either:
								<ol type="A">
									<li>
										over the age of 18 years and accessing the Platform for
										business use; or
									</li>
									<li>
										accessing the Platform on behalf of someone under the age of
										18 years old and consent to that person’s use of the
										Platform.
									</li>
								</ol>
							</li>
						</ol>
					</li>
					<li>
						If you use the Platform on behalf of a company or organisation you
						warrant that you have the necessary authority from that company or
						organisation to do so. If you are signing up not as an individual
						but on behalf of your company, your employer, an organisation,
						government or other legal entity (
						<strong>Represented Entity</strong>), then “you” and “User” means
						the Represented Entity and you are binding the Represented Entity to
						this agreement. If you are accepting this agreement and using our
						Platform on behalf of a Represented Entity, you represent and
						warrant that you are authorised to do so.
					</li>
					<li>
						You must have a registered business and an ABN to use the Platform
						and we reserve the right to verify these details before providing
						you with an Account (defined below).
					</li>
				</ol>
				<h2>2: ACCOUNTS AND REGISTRATION</h2>
				<ol type="a">
					<li>
						{" "}
						All Users are required to sign-up, register and receive an account
						through the Platform (an <strong>Account</strong>).
					</li>
					<li>
						{" "}
						You will be required to choose your Account type – either Service
						Provider or Client Organisation and Project Owner/Representative or
						both. Depending on which type of Account you sign up for, this will
						allow you access to certain functionalities and permissions on the
						Platform.{" "}
					</li>
					<li>
						{" "}
						As part of the Account registration process and as part of your
						continued use of the Platform, you are required to provide personal
						information and details, such as your email address, first and last
						name, preferred username, a secure password, billing, postal and
						physical addresses, mobile phone number, bank account information,
						ABN and business information, and other information as determined by
						Procuracon from time to time.
					</li>
					<li>
						{" "}
						You warrant that any information you give to Procuracon in the
						course of completing the Account registration process will always be
						accurate, honest, correct and up-to-date.
					</li>
					<li>
						{" "}
						Once you complete the Account registration process, Procuracon may,
						in its absolute discretion, choose to accept you as a registered
						user within the Platform and provide you with an Account.
					</li>
					<li>
						{" "}
						Procuracon reserves the right to contact you about any concerning
						behaviour by you, or to seek a resolution with you.
					</li>
					<li>
						{" "}
						Procuracon may, in its absolute discretion, suspend or cancel your
						Account for any reason, including for any failure to comply with
						this agreement, or if we suspect there are issues with your business
						including where we consider or have information that you may not be
						financially solvent or are having difficulties to provide your
						Services on the Platform.
					</li>
				</ol>
				<h2>3: PROFILES AND PROCESS</h2>
				<ol type="a">
					<li>
						{" "}
						After you create an Account, you may then create a profile on the
						Platform including any information (<strong>Profile</strong>){" "}
					</li>
					<li>
						{" "}
						As part of your Profile, you are required to provide certain
						information about your business as set out on the Platform.{" "}
					</li>
					<li> Clients generally create basic Profiles. </li>
					<li>
						{" "}
						Suppliers can provide more information in their Profiles and can
						choose specific information they make available to our Client
						members.{" "}
					</li>
					<li>
						{" "}
						Clients can use the information in Profiles to search, compare and
						shortlist Suppliers.{" "}
					</li>
					<li>
						{" "}
						Once Suppliers have been shortlisted, Clients can contact Suppliers
						to arrange the procurement of Services.
					</li>
				</ol>
				<h2>4: FEES AND MEMBERSHIP OPTIONS</h2>
				<ol type="a">
					{" "}
					<li>
						(<strong>Free Trial</strong>) We may provide you with a free trial
						which will allow you to access some features of our Services and to
						try out the Platform. Any details, including timing and length of
						any Free Trial will be set out on the Platform or by agreement with
						you. In order for you to access additional features, we may require
						the payment of fees (<strong>Fees</strong>).
					</li>{" "}
					<li>
						{" "}
						(<strong>Registration Fee</strong>) We may charge a registration Fee
						in order for you to sign up, register and create an Account on the
						Platform.
					</li>{" "}
					<li>
						{" "}
						You may then choose to purchase an annual membership subscription
						for ongoing access to our Services as set out on the Platform (
						<strong>Membership</strong>). The inclusions and Fees of your
						Membership will be set out on the Platform. We may offer different
						levels of membership on the Platform and these details will be set
						out on the Platform. It is up to you to choose the Membership option
						that is the best for you.
					</li>{" "}
					<li>
						{" "}
						(<strong>Membership Fees non-refundable</strong>) Our Membership
						Fees are non-refundable.
					</li>{" "}
					<li>
						{" "}
						(<strong>Promotions</strong>) We may offer free Memberships or
						reduced Fees at any time for any time period as set out on the
						Platform or discussed with you.
					</li>{" "}
					<li>
						{" "}
						(<strong>Changing your Membership – Upgrading</strong>) If at any
						point you wish to change your Membership status, including upgrading
						your Membership please contact us. You must contact us before the
						end of your next billing period and with at least 7 business days’
						notice. The new Membership status will take effect upon the full
						payment of the new Fees. If you wish to downgrade your Membership
						you will not receive a refund of your current annual Membership. The
						new Membership Fees will be charged at your next billing cycle.
					</li>{" "}
					<li>
						(<strong>Automatic Renewal</strong>) If stated on our Platform that
						your Membership is automatically renewing, your Membership will
						continue to renew on an annual basis indefinitely, and you must pay
						Fees in respect of each yearly period, unless you notify us within
						30 days of the expiry of the next year that you want to cancel your
						Membership. Otherwise, we will continue to charge the Membership
						Fees. Please ensure you contact us if you want to cancel your
						Membership.
					</li>{" "}
					<li>
						{" "}
						Procuracon reserves the right to change or waive the Fees at any
						time by updating this agreement, on written notice to you. We will
						provide you with at least 30 days’ written notice if this occurs,
						and upon receipt of such notice you will have the right to terminate
						this agreement immediately, on written notice to us. Your continued
						use of the Services after you receive such written notice will
						constitute your consent to the change and/or waiver set out in that
						notice.
					</li>{" "}
					<li>
						{" "}
						We reserve the right to not accept your Account, Profile list any of
						your Services until you have paid any Fees applicable.
					</li>{" "}
					<li>
						We may offer additional services or functionalities on the Platform
						at any time. We may charge additional fees for these additional
						services or functionalities. Details of any of these additional
						services will be set out on the Platform.
					</li>{" "}
					<li>
						Procuracon reserves the right to change or waive the Service Fee at
						any time by updating this agreement on the Platform.
					</li>
				</ol>
				<h2>5: PAYMENT</h2>
				<ol type="a">
					<li>
						{" "}
						(<strong>Payment obligations</strong>) Unless otherwise agreed in
						writing you must pay for all Membership Fees before we provide you
						with access to an Account.
					</li>{" "}
					<li>
						(<strong>Card surcharges</strong>) Procuracon reserves the right to
						charge credit card surcharges in the event that payments are made
						using a credit, debit or charge card (including Visa, MasterCard or
						American Express).
					</li>{" "}
					<li>
						{" "}
						(<strong>Third Party Payment Platform</strong>) Procuracon processes
						payments through a Third Party Payment Platform, being Stripe.com.
						In addition to this agreement, your purchase of any Services via the
						Platform will be subject to the terms and the privacy policy of the
						Third Party Payment Platform, available on the Third Party Payment
						Platform’s website.
					</li>
					<li>
						{" "}
						(<strong>Release</strong>) You agree to release Procuracon and its
						employees and agents in respect of all liability for loss, damage or
						injury which may be suffered by any person arising from any act or
						omission of the Third Party Payment Platform, including any issue
						with security or performance of the Third Party Payment Platform or
						any error or mistake in processing your payment.
					</li>
				</ol>
				<h2>6: USER OBLIGATIONS</h2>
				<p>As a User, you agree:</p>
				<ol>
					<li>
						{" "}
						not to intimidate, harass, impersonate, stalk, threaten, bully or
						endanger any other User or distribute unsolicited commercial
						content, junk mail, spam, bulk content or harassment;
					</li>
					<li>
						{" "}
						to not share your Account with any other person and that any use of
						your Account by any other person is strictly prohibited. You must
						immediately notify Procuracon of any unauthorised use of your
						Account, password or email, or any other breach or potential breach
						of the Platform’s security;
					</li>
					<li>
						{" "}
						to not use the Platform for any purpose other than for the purpose
						of making arrangements to provide or receive Services, including:
						<ol type="i">
							<li>
								{" "}
								you must not use the Platform in a manner that is illegal or
								fraudulent or facilitates illegal or fraudulent activity
								(including requesting or accepting a job or a sale which
								includes illegal goods, activities or purposes); and{" "}
							</li>
							<li>
								you must not use the Platform in connection with any commercial
								or money making or other promotional or marketing endeavours
								except those that are endorsed herein, or as approved in writing
								by Procuracon;
							</li>
						</ol>
					</li>
					<li>
						{" "}
						not to act in any way that may harm the reputation of Procuracon or
						associated or interested parties or do anything at all contrary to
						the interests of Procuracon or the Platform;{" "}
					</li>
					<li>
						{" "}
						you must not make any automated use of the Platform and you must not
						copy, reproduce, translate, adapt, vary or modify the Platform
						without the express written consent of Procuracon;{" "}
					</li>
					<li>
						{" "}
						that Procuracon may change any features of the Platform or Services
						offered through the Platform at any time without notice to you;
					</li>
					<li>
						{" "}
						that information given to you through the Platform, by Procuracon or
						another User including a Supplier, is general in nature and we take
						no responsibility for anything caused by any actions you take in
						reliance on that information;{" "}
					</li>
					<li>
						{" "}
						that Procuracon may cancel your account at any time, including if it
						considers, in its absolute discretion, that you are in breach or are
						likely to breach this clause 3; and
					</li>
					<li>
						{" "}
						that you will be required to use a Third Party Payment Platform in
						making or receiving any payments via the Platform (
						<strong>Third Party Payment Platform</strong>), you warrant that you
						have read, understood and agree to be bound by Stripe.com’s terms at{" "}
						<a href="https://stripe.com/au/legal">
							<u>https://stripe.com/au/legal </u>
						</a>
						, or the terms of use of other third party payment portals or other
						payment methods from time to time, that will be available on the
						Stripe website and other payment portal websites.
					</li>
				</ol>
				<h2>7: POSTED MATERIALS</h2>
				<h3>7.1 - WARRANTIES</h3>
				<p>
					By providing or posting any information, materials or other content on
					the Platform including your logo and any information in any Profile (
					<strong>Posted Material</strong>), you represent and warrant that:
				</p>
				<ol type="a">
					<li>
						{" "}
						you are authorised to provide the Posted Material (including by
						being authorised to provide any services that you represent you
						provide);
					</li>
					<li>
						{" "}
						the Posted Material is accurate and true at the time it is provided;
					</li>
					<li>
						{" "}
						any Posted Material which is in the form of a review or feedback or
						Client testimonial is honest, accurate and presents a fair view of
						the relevant person and/or your experience;
					</li>
					<li>
						{" "}
						the Posted Material is free from any harmful, discriminatory,
						defamatory or maliciously false implications and does not contain
						any offensive or explicit material;
					</li>
					<li>
						{" "}
						the Posted Material is not “passing off” of any product or service
						and does not constitute unfair competition;{" "}
					</li>
					<li>
						{" "}
						the Posted Material does not infringe any Intellectual Property
						Rights, including copyright, trademarks, business names, patents,
						confidential information or any other similar proprietary rights,
						whether registered or unregistered, anywhere in the world;
					</li>
					<li>
						{" "}
						the Posted Material does not contain any viruses or other harmful
						code, or otherwise compromise the security or integrity of the
						Platform or any network or system; and
					</li>
					<li>
						{" "}
						the Posted Material does not breach or infringe any applicable laws.
					</li>
				</ol>
				<h3>7.2 - USE OF LOGO AND OTHER POSTED MATERIAL</h3>
				<p>
					Procuracon will seek authorisation from you if we wish to use your
					logo (and other Posted Material if applicable) to display on the
					Platform or any of Procuracon's other websites, promotional materials,
					social media or portfolios for the purposes of promotion and
					marketing. We will contact you to discuss further.{" "}
				</p>
				<h3>7.3 - LICENCE</h3>
				<ol type="a">
					<li>
						{" "}
						Subject to clause 7.2, you grant to Procuracon a perpetual,
						irrevocable, transferable, worldwide and royalty-free licence
						(including the right to sublicense) to use, copy, modify, reproduce
						and adapt any Intellectual Property Rights in any Posted Material in
						order for Procuracon to use, exploit or otherwise enjoy the benefit
						of such Posted Material to suit the requirements of the Platform and
						any software used, including for the purposes of providing you with
						the Services, performing backups, maintenance, testing and
						development, indexing, summarising, compiling analysing, and
						searching.{" "}
					</li>
					<li>
						{" "}
						If it is determined that you retain moral rights (including rights
						of attribution or integrity) in any Posted Material, you forever
						release Procuracon from any and all claims that you could assert
						against Procuracon by virtue of any such moral rights.
					</li>
					<li>
						{" "}
						You indemnify Procuracon against all damages, losses, costs and
						expenses incurred by Procuracon arising out of any third party claim
						that your Posted Material infringes any third party’s Intellectual
						Property Rights.
					</li>
				</ol>
				<h3>7.4 - REMOVAL</h3>
				<ol type="a">
					<li>
						{" "}
						Procuracon acts as a passive conduit for the online distribution of
						Posted Material and has no obligation to screen Posted Material in
						advance of it being posted. However, Procuracon may, in its absolute
						discretion, review and remove any Posted Material (including links,
						your Profile or other information you have posted on the Platform)
						at any time without giving any explanation or justification for
						removing the Posted Material.
					</li>
					<li>
						{" "}
						You agree that you are responsible for keeping and maintaining
						records of Posted Material.
					</li>
				</ol>
				<h2>8: INTELLECTUAL PROPERTY</h2>
				<ol type="a">
					<li>
						{" "}
						You retain all ownership in any Posted Material or material you
						supply the Platform, including in your Profile. You are responsible
						for updating and maintaining any of this intellectual property and
						this does not alter your Intellectual Property Rights in this
						material.
					</li>
					<li>
						{" "}
						Other than as set out in clause 8.1(a), Procuracon retains ownership
						of all materials developed or provided (or both, as the case may be)
						in connection with the Platform (including text, graphics, logos,
						design, icons, images, sound and video recordings, pricing,
						downloads and software) (Platform Content) and reserves all rights
						in any Intellectual Property Rights owned or licensed by it not
						expressly granted to you.{" "}
					</li>
					<li>
						{" "}
						You may make a temporary electronic copy of all or part of the
						Platform Content for the sole purpose of viewing it. You must not
						otherwise reproduce, transmit, adapt, distribute, sell, modify or
						publish the Platform Content without prior written consent from
						Procuracon or as permitted by law, or otherwise in accordance with
						clause 8.1(a).
					</li>
					<li>
						{" "}
						In this clause 8, <strong>
							“Intellectual Property Rights”
						</strong>{" "}
						means all copyright, trade mark, design, patent, trade, business,
						company and domain names, confidential and other proprietary rights,
						and any other rights to registration of such rights whether created
						before or after the date of this agreement both in Australia and
						throughout the world.
					</li>
				</ol>
				<h2>9: REFUNDS, SERVICE INTERRUPTIONS AND CANCELLATIONS</h2>
				<p>Procuracon will have no liability or obligation to you if:</p>
				<ol type="a">
					<li>
						{" "}
						a Client or Supplier cancels at any time or does not provide
						Services as listed in any Profile or as agreed; or{" "}
					</li>
					<li>
						{" "}
						for whatever reason, including technical faults, the Services cannot
						be provided, ​ and you will not be entitled to any compensation from
						Procuracon.{" "}
					</li>
				</ol>
				<h2>10: SERVICE LIMITATIONS</h2>
				<p>
					The Platform is made available to you strictly on an ‘as is’ basis.
					Without limitation, you acknowledge and agree that Procuracon cannot
					and does not represent, warrant or guarantee that:
				</p>
				<ol type="a">
					<li> the Platform will be free from errors or defects;</li>
					<li> the Platform will be accessible at all times;</li>
					<li>
						{" "}
						messages sent through the Platform will be delivered promptly, or
						delivered at all;
					</li>
					<li>
						{" "}
						information you receive or supply through the Platform will be
						secure or confidential; or
					</li>
					<li>
						{" "}
						any information provided through the Platform is accurate or true.
					</li>
				</ol>
				<h2>11: THIRD PARTY CONTENT</h2>
				<p>
					The Platform may contain text, images, data and other content provided
					by a third party and displayed on the Platform (
					<strong>Third Party Content</strong>). Procuracon accepts no
					responsibility for Third Party Content and makes no representation,
					warranty or guarantee about the quality, suitability, accuracy,
					reliability, currency or completeness of Third Party Content.
				</p>
				<h2>12: THIRD PARTY TERMS</h2>
				<ol type="a">
					<li>
						Any service that requires Procuracon to acquire goods and services
						supplied by a third party on behalf of the Client (including a third
						party payment service, like{" "}
						<a href="https://stripe.com/au">Stripe</a>) may be subject to the
						terms and conditions of that third party (
						<strong>Third Party Terms</strong>), including ‘no refund’ policies.
					</li>
					<li>
						Users agree to familiarise themselves with any Third Party Terms
						applicable to any such goods and services and, by instructing
						Procuracon to acquire the goods or services on the User’s behalf,
						the User will be taken to have agreed to such Third Party Terms.
					</li>
				</ol>
				<h2>13: DISPUTES </h2>
				<ol type="a">
					<li>
						{" "}
						All disputes between Users on the Platform should be dealt with
						between Users. Procuracon will not have any liability for disputes
						between Users and you must take all reasonable steps to resolve any
						dispute with another User.
					</li>
					<li>
						{" "}
						If any issue or problem relating to the Platform remains unresolved
						after directing a complaint to a relevant User, or if the complaint
						does not relate to another User, you must report it to Procuracon
						via admin@procuracon.com.au. We will assess the complaint and
						attempt to quickly and satisfactorily resolve it.
					</li>
					<li>
						{" "}
						Procuracon reserves the right to hold Fees in relation to a dispute
						until the dispute is resolved, either by us, the relevant parties or
						by a mediator or arbitrator. We reserve the right to disperse Fees
						held by us as we see fit, including by providing a User with a
						refund.
					</li>
					<li>
						{" "}
						Any costs you incur in relation to a complaint or dispute will be
						your responsibility.
					</li>
					<li>
						{" "}
						Procuracon has the option to appoint an independent mediator or
						arbitrator if needed. The cost of any mediator or arbitrator must be
						shared equally between each of the parties to the dispute.
					</li>
					<li>
						{" "}
						If you have a dispute with Procuracon, you agree to notify us first
						and enter into discussion, mediation or arbitration with us for a
						minimum of a 120-day period before pursuing any other proceedings.{" "}
					</li>
					<li>
						{" "}
						Notwithstanding any other provision of this clause 13, you or
						Procuracon may at any time cancel your Account or discontinue your
						use of the Platform.
					</li>
				</ol>
				<h2>14: SECURITY</h2>
				<p>
					Procuracon does not accept responsibility for loss or damage to
					computer systems, mobile phones or other electronic devices arising in
					connection with your use of the Platform. You should take your own
					precautions to ensure that the process you employ to access the
					Platform does not expose you to the risk of viruses, malicious
					computer code or other forms of interference.
				</p>
				<h2>15: DISCLAIMER</h2>
				<ol type="a">
					<li>
						{" "}
						(<strong>Introduction service </strong>) Procuracon is a medium that
						facilitates the introduction of Clients and Suppliers for the
						purposes of buying and selling Services. Procuracon simply collects
						a service fee in consideration for providing this introduction
						service and does not have any obligations or liabilities to, and is
						not a party to any contract between, Clients and Suppliers in
						relation to such Services or otherwise resulting from the
						introduction.
					</li>
					<li>
						{" "}
						(<strong>Limitation of liability </strong>) To the maximum extent
						permitted by applicable law, Procuracon excludes completely all
						liability to any person for loss or damage of any kind, however
						arising whether in contract, tort (including negligence), statute,
						equity, indemnity or otherwise, arising from or relating in any way
						to the Platform or its use or any services provided by any Supplier.
						This includes the transmission of any computer virus.
					</li>
					<li>
						{" "}
						(<strong>Disclaimer</strong>) All express or implied representations
						and warranties are, to the maximum extent permitted by applicable
						law, excluded. Where any law (including the Competition and Consumer
						Act 2010 (Cth)) implies a condition, warranty or guarantee into this
						agreement which may not lawfully be excluded, then to the maximum
						extent permitted by applicable law, Procuracon’s liability for
						breach of that non-excludable condition, warranty or guarantee will,
						at our option, be limited to in the case of services, the supply of
						the services again, or the payment of the cost of having them
						supplied again.{" "}
					</li>
					<li>
						{" "}
						(<strong>Indemnity</strong>) You agree to indemnify Procuracon and
						its employees and agents in respect of all liability for loss,
						damage or injury which may be suffered by any person arising from
						you or your representatives’:
						<ol type="i">
							<li> breach of any term of this agreement; </li>
							<li> use of the Platform; or</li>
							<li> your provision or receipt of Services from another User.</li>
						</ol>
					</li>
					<li>
						{" "}
						(<strong>Consequential loss</strong>) To the maximum extent
						permitted by law, under no circumstances will Procuracon be liable
						for any incidental, special or consequential loss or damages, or
						damages for loss of data, business or business opportunity,
						goodwill, anticipated savings, profits or revenue arising under or
						in connection with the Platform, this agreement or their subject
						matter, or any services provided by any Supplier (except to the
						extent this liability cannot be excluded under the Competition and
						Consumer Act 2010 (Cth)).
					</li>
				</ol>
				<h2>16: CONFIDENTIALITY</h2>
				<p>You agree that:</p>
				<ol type="a">
					<li>
						no information owned by Procuracon, including system operations,
						documents, marketing strategies, staff information and client
						information, may be disclosed or made available to any third
						parties; and
					</li>
					<li>
						all communications involving the details of other users on this
						Platform and of the Supplier are confidential, and must be kept as
						such by you and must not be distributed nor disclosed to any third
						party.
					</li>
				</ol>
				<h2>17: COLLECTION NOTICE AND PRIVACY</h2>
				<ol type="a">
					<li>
						<span
							dir="ltr"
							role="presentation"
						>
							We may collect personal information about you in the course of
							providing you with our Services, the Platform, to contact and
							communicate with you, to respond to your enquiries and for other
							purposes set out in our Privacy Policy.
						</span>
					</li>
					<li>
						Our Privacy Policy contains more information about how we use,
						disclose and store your information and details how you can access
						and correct your personal information.
					</li>
					<li>
						You agree to be bound by the clauses outlined in Procuracon’s
						Privacy Policy and consent to our use of your personal information
						in accordance with our Privacy Policy.
					</li>
					<li>
						Providing personal information for others If you provide any
						information to us which includes personal information of another
						person, you must first obtain consent from that person before giving
						us their personal information. By providing that information to us,
						you represent and warrant that you have obtained such consent. We
						will not be liable for any claims that arise out of your
						non-compliance with this clause, and you indemnify us against any
						such claims.{" "}
					</li>
					<li>
						Consent to upload personal information Where you provide any
						information to us on the Platform, including in your Account or
						Profile, you consent to the use of that information on our Platform.
					</li>
					<li>
						(Sensitive information ) We will not ask you for sensitive
						information. If you upload any information that contains sensitive
						information to the Platform it is your choice as to
						<br role="presentation" />
						<span
							dir="ltr"
							role="presentation"
						>
							whether or not to upload this information, and if you do so, we
							will imply that you have{" "}
						</span>
						<br role="presentation" />
						<span
							dir="ltr"
							role="presentation"
						>
							consented to the disclosure of this information.{" "}
						</span>
					</li>
					<li>
						Overseas Users Be aware that other Users may be overseas and before
						you upload order supply any personal information it is your
						responsibility to check where any other User is located and how this
						concerns your personal information. It is your choice if you engage
						with overseas Users.{" "}
					</li>
					<li>
						You must ensure you comply with any applicable privacy laws relevant
						to you, or your Represented Entity . You must handle any personal
						information you receive as part of using the Platform in accordance
						with these laws, if applicable.
					</li>
				</ol>
				<h2>18: TERMINATION</h2>
				<ol type="a">
					<li>
						Procuracon reserves the right to terminate a User’s access to any or
						all of the Platform (including any Account, Profiles and other
						memberships) at any time without notice, for any reason.
					</li>
					<li>
						In the event that a User’s Account, access or membership is
						terminated:
						<ol type="i">
							<li>
								the User’s access to all posting tools on the Platform will be
								revoked;
							</li>
							<li>
								the User will be unable to view the details of all other Users
								(including contact details, geographic details, any other
								personal details and Profiles); and
							</li>
							<li>
								the User may be unable to view the details of all Suppliers
								(including contact details, geographic details and any other
								details), and all Profiles previously posted by the respective
								User will also be removed from the Platform.
							</li>
						</ol>
					</li>
					<li>
						Users may terminate their Account or membership on the Platform at
						any time by using the Platform’s functionality where such
						functionality is available. Where such functionality is not
						available, Procuracon will effect such termination within a
						reasonable time after receiving written notice from the User.
					</li>
					<li>
						Notwithstanding termination or expiry of your Account or membership
						or this agreement, the provisions of Part A and any other provision
						which by its nature would reasonably be expected to be complied with
						after termination or expiry, will continue to apply.
					</li>
				</ol>
				<h2>19: TAX</h2>
				<p>
					You are responsible for the collection and remission of all taxes
					associated with the services you provide or receive or any
					transactions through your use of the Platform, and Procuracon will not
					be held accountable in relation to any transactions between Clients
					and Suppliers where tax related misconduct has occurred.
				</p>
				<h2>20: RECORD / AUDIT</h2>
				<p>
					To the extent permitted by law, Procuracon reserves the right to keep
					all records of any and all transactions and communications made
					through this Platform between you and other Users (including
					conversations, user posts, user activity including searching,
					comparing and shortlisting Suppliers, comments, feedback, cookies, and
					I.P. address information) for administration purposes and also holds
					the right to produce these records in the event of any legal dispute
					involving Procuracon.
				</p>
				<h2>21: NOTICES</h2>
				<p>
					A notice or other communication to a party under this agreement must
					be:
				</p>
				<ol type="a">
					<li>in writing and in English; and</li>
					<li>
						delivered via email to the other party, to the email address
						specified in this agreement, or if no email address is specified in
						this agreement, then the email address most regularly used by the
						parties to correspond for the purposes of the subject matter of this
						agreement as at the date of this agreement (
						<u>admin@procuracon.com.au</u>). The parties may update their Email
						Address by notice to the other party.
					</li>
					<li>
						Unless the party sending the notice knows or reasonably ought to
						suspect that an email was not delivered to the other party’s Email
						Address, notice will be taken to be given:
						<ol type="i">
							<li>
								24 hours after the email was sent, unless that falls on a
								Saturday, Sunday or a public holiday in the state or territory
								whose laws govern this agreement, in which case the notice will
								be taken to be given on the next occurring business day in that
								state or territory; or
							</li>
							<li>when replied to by the other party,</li>
						</ol>
					</li>
				</ol>
				<h2>22: GENERAL</h2>
				<h2>22.1 GOVERNING LAW AND JURISDICTION</h2>​ This agreement is governed
				by the law applying in Western Australia. Each party irrevocably submits
				to the exclusive jurisdiction of the courts of Western Australia and
				courts of appeal from them in respect of any proceedings arising out of
				or in connection with this agreement. Each party irrevocably waives any
				objection to the venue of any legal process on the basis that the
				process has been brought in an inconvenient forum.
				<h2>22.2 WAIVER</h2>​ No party to this agreement may rely on the words
				or conduct of any other party as a waiver of any right unless the waiver
				is in writing and signed by the party granting the waiver.
				<h2>22.3 SEVERANCE</h2>​ Any term of this agreement which is wholly or
				partially void or unenforceable is severed to the extent that it is void
				or unenforceable. The validity and enforceability of the remainder of
				this agreement is not limited or otherwise affected.
				<h2>22.4 JOINT AND SEVERAL LIABILITY</h2>​ An obligation or a liability
				assumed by, or a right conferred on, two or more persons binds or
				benefits them jointly and severally.
				<h2>22.5 ASSIGNMENT</h2>​ A party cannot assign, novate or otherwise
				transfer any of its rights or obligations under this agreement without
				the prior written consent of the other party.
				<h2>22.6 COSTS</h2>​ Except as otherwise provided in this agreement,
				each party must pay its own costs and expenses in connection with
				negotiating, preparing, executing and performing this agreement.
				<h2>22.7 ENTIRE AGREEMENT</h2>​ This agreement embodies the entire
				agreement between the parties and supersedes any prior negotiation,
				conduct, arrangement, understanding or agreement, express or implied, in
				relation to the subject matter of this agreement.
				<h2>28: INTERPRETATION</h2>
				<ol type="a">
					<li>
						(<b>singular and plural</b>) words in the singular includes the
						plural (and vice versa);
					</li>
					<li>
						(<b>gender</b>) words indicating a gender includes the corresponding
						words of any other gender;
					</li>
					<li>
						(<b>defined terms</b>) if a word or phrase is given a defined
						meaning, any other part of speech or grammatical form of that word
						or phrase has a corresponding meaning;
					</li>
					<li>
						(<b>person</b>) a reference to “person” or “you” includes an
						individual, the estate of an individual, a corporation, an
						authority, an association, consortium or joint venture (whether
						incorporated or unincorporated), a partnership, a trust and any
						other entity;
					</li>
					<li>
						(<b>party</b>) a reference to a party includes that party’s
						executors, administrators, successors and permitted assigns,
						including persons taking by way of novation and, in the case of a
						trustee, includes any substituted or additional trustee;
					</li>
					<li>
						(<b>this agreement</b>) a reference to a party, clause, paragraph,
						schedule, exhibit, attachment or annexure is a reference to a party,
						clause, paragraph, schedule, exhibit, attachment or annexure to or
						of this agreement, and a reference to this agreement includes all
						schedules, exhibits, attachments and annexures to it;
					</li>
					<li>
						(<b>document</b>) a reference to a document (including this
						agreement) is to that document as varied, novated, ratified or
						replaced from time to time;
					</li>
					<li>
						(<b>headings</b>) headings and words in bold type are for
						convenience only and do not affect interpretation;
					</li>
					<li>
						(<b>includes</b>) the word “includes” and similar words in any form
						is not a word of limitation;
					</li>
					<li>
						(<b>adverse interpretation</b>) no provision of this agreement will
						be interpreted adversely to a party because that party was
						responsible for the preparation of this agreement or that provision;
						and
					</li>
					<li>
						(<b>currency</b>) a reference to $, or “dollar”, is to Australia
						currency, unless otherwise agreed in writing.
					</li>
				</ol>
				<h2>Part B Suppliers</h2>
				<ol>
					<li>
						<h2>ELIGIBILITY, QUALIFICATION and PROFILE</h2>
						<ol type="a">
							<li>
								In order to register your business, create Profiles, become a
								Member and be listed as a Supplier on the Platform your business
								must meet any pre-qualification requirements or eligibility
								criteria that we set out on the Platform or as otherwise advised
								to you (<b>Eligibility Criteria</b>). We may require you to
								verify or validate any information you do upload to the
								Platform.
							</li>
							<li>
								We will require further information from you to determine that
								you meet the Eligibility Criteria including business
								information, company information and any other evidence that you
								meet the Eligibility Criteria as determined by Procuracon from
								time to time.
							</li>
							<li>
								There will be mandatory information that we require, and other
								information that you choose to upload on the Platform. Any
								information that is NOT mandatory, it is your choice as to
								whether or not you upload this information on the Platform.
								However, if you choose to upload any additional information, you
								consent to the uploading of this information.
							</li>
							<li>
								You warrant that any information you give to Procuracon in the
								course of completing the registration process, Account and in
								any Profile, will always be accurate, honest, correct and up to
								date and that you meet the Eligibility Criteria.
							</li>
							<li>
								Once you complete the registration process, Procuracon may, in
								its absolute discretion, choose to accept you as a registered
								Supplier on the Platform and provide you access to further
								Services. We reserve the right to not give you access to the
								Platform, an Account or Profile for any reason, including where
								you do not meet the Eligibility Criteria.
							</li>
							<li>
								Procuracon reserves the right to contact you to verify if you
								meet the Eligibility Criteria, any concerning behaviour by you,
								or to seek a resolution with you.
							</li>
							<li>
								We may require you to confirm at each billing period that you
								meet the Eligibility Criteria.
							</li>
							<li>
								Procuracon may, in its absolute discretion, suspend or cancel
								your access to the Member Dashboard and Membership (defined
								below) for any reason, including for any failure to comply with
								this agreement and if at any stage you do not meet the
								Eligibility Criteria.
							</li>
							<li>
								You agree not to share your Account and Profile with any other
								person or business and that any use of your Account by any other
								person or business is strictly prohibited. You must immediately
								notify Procuracon of any unauthorised use of your Account, your
								Membership or email, or any other breach or potential breach of
								the Platform’s security.
							</li>
							<li>
								(<b>Warranty and Indemnity</b>) You acknowledge and agree that
								we are reliant on the information provided by you to determine
								if you are eligible to receive an Account, Profile and
								Membership. If at any point you do not meet our Eligibility
								Criteria you must inform us immediately. You warrant that you
								meet the Eligibility Criteria and you agree to indemnify
								Procuracon and its employees and agents in respect of all
								liability for loss, damage or injury which may be suffered by
								any person or Client arising from you not meeting the
								Eligibility Criteria or if any information in your Profile does
								not meet the Eligibility Criteria.
							</li>
						</ol>
					</li>
					<li>
						<h2 class="[l1]-level-1-heading---sprintlaw">
							SHARING INFORMATION
						</h2>
						<ol type="a">
							<li>
								You can control the visibility of your Profile and who has
								access to certain parts of your Profile information, if the
								functionality is available. If this functionality is available,
								you can choose the information you want to keep hidden or set
								the settings to public. This is your choice.
							</li>
							<li>
								You may choose who you give access to this information and what
								kind of permissions you give on the Platform. You may also
								choose to only give certain Users access to your information
							</li>
							<li>
								Please be careful in the sharing of information on the Platform,
								particularly any sensitive information. We will not be liable
								for any issues or damages that occur due to any information that
								you upload to the Platform.
							</li>
							<li>
								If you are concerned with the security or sensitivity of any of
								your information, we recommend obtaining non-disclosure or
								confidentiality agreements from any Users you wish to engage
								with.
							</li>
						</ol>
					</li>
					<li>
						<h2 class="[l1]-level-1-heading---sprintlaw">PROFILES</h2>
						<p>You acknowledge and agree that:</p>
						<ol type="a">
							<li>
								Procuracon may choose not to accept any Profile you submit to
								the Platform, and Procuracon may limit the number of Profiles
								you can submit on the Platform;
							</li>
							<li>
								delivery of Services to Clients is the responsibility of the
								Supplier;
							</li>
							<li>
								you must take all reasonable steps to provide the Services as
								described in your Profile;
							</li>
							<li>
								you must deal with any dispute with a Client in accordance with
								clause 13 of Part A;
							</li>
							<li>
								any additional terms and conditions relating to a Profile
								provided via the Platform are solely between you and the Client
								and do not involve Procuracon in any way, except that they must
								not be inconsistent with either party’s obligations under this
								agreement;
							</li>
							<li>
								Procuracon will have no responsibility for the accuracy,
								reliability or timeliness of the content provided by a Client
								responding to a Profile, and the Supplier must take steps to
								independently verify the accuracy and reliability of such
								content; and
							</li>
						</ol>
					</li>
					<li>
						<h2 class="[l1]-level-1-heading---sprintlaw">WARRANTIES</h2>
						<p>
							By listing yourself as a Supplier on the Platform and posting a
							Profile, you represent and warrant that:
						</p>
						<ol type="a">
							<li>
								You are able to provide the Services as specified in the
								Profile; and
							</li>
							<li>
								you will provide the relevant Services to Clients in compliance
								with all applicable laws.
							</li>
						</ol>
					</li>
				</ol>
				<h2>Part C Clients</h2>
				<ol>
					<li>
						<h2 class="[l1]-level-1-heading---sprintlaw">PROFILES AND FEES</h2>
						<p>You acknowledge and agree that:</p>

						<ol type="a">
							<li>
								any terms and conditions relating to Services provided via the
								Platform are solely between you and the Supplier and do not
								involve Procuracon in any way, except that they must not be
								inconsistent with either party’s obligations under this
								agreement; and
							</li>
							<li>
								it is your responsibility to verify any Supplier or other User
								you engage with and that you comply with any professional
								obligations or other agreements you are a party to.
							</li>
						</ol>
					</li>
					<li>
						<h2>CANCELLATIONS</h2>
						<p>
							Procuracon will have no liability or obligation to you if a
							Supplier cancels a Profile, is unable to provide services or there
							are any other issues with the Services at any time and you will
							not be entitled to any compensation from Procuracon, including any
							portion of the Membership Fee.
						</p>
					</li>
					<li>
						<h2>LINKED BUSINESSES</h2>
						<p>You acknowledge and agree that:</p>
						<ol type="a">
							<li>
								the Platform provides links and introductions to Suppliers owned
								and operated by third parties that are not under the control of
								Procuracon;
							</li>
							<li>
								the provision by Procuracon of introductions to Suppliers does
								not imply any endorsement or recommendation by Procuracon of any
								Supplier;
							</li>
							<li>
								Procuracon does not examine, determine or warrant the
								certification and/or licensing, competence, solvency or
								information of any Supplier who uses or is listed on the
								Platform; and
							</li>
							<li>
								any terms and conditions relating to a good or service, Profile
								or quote provided via the Platform constitute a contract between
								you and the Supplier and do not involve Procuracon in any way.
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</ContentPage>
	);
};
