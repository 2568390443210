import React from "react";
import styled from "styled-components";

export const FormHeadingStyled = styled.h2`
	margin: ${(props) => (props.margin ? props.margin : "0 0 1.75rem 0")};
	font-size: ${(props) => props.theme.fontSizes.xxl};
	color: ${(props) => props.color};
	font-family: "roboto";
`;

export const FormHeading = ({ color, text, margin }) => {
	return (
		<FormHeadingStyled
			color={color}
			margin={margin}
		>
			<div className="text-[18px] font-semibold text-primary-900">{text}</div>
		</FormHeadingStyled>
	);
};
