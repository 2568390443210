import { SIDEBARCOLLAPSE } from "constants/sidebar";

export const sidebarCollapse = (payload) => {
	return (dispatch) => {
		const storagePayload = {
			sidebarcollapse: payload,
		};
		localStorage.setItem("sidebar", JSON.stringify(storagePayload));
		dispatch({
			type: SIDEBARCOLLAPSE,
			payload,
		});
	};
};
