import React from "react";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Button } from "components/RFQ/Button";

function ChangeSubscriptionModal({ isOpen, setIsOpenSubs }) {
	const handleClose = () => {
		setIsOpenSubs(false);
	};

	function redirectToEmail() {
		window.location.href = "mailto:support@procuracon.com.au";
	}

	const dialogProperty = {
		icon: `blue-circle-check`,
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: `Want to change your subscription plan?`,
		description: (
			<>
				If you would like to change your subscription plan or explore other
				options, please get in touch with us at{" "}
				<a
					href="mailto:support@procuracon.com.au"
					className="text-blue-600 underline hover:text-blue-800"
				>
					support@procuracon.com.au
				</a>
				.
			</>
		),
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					className="!w-full !normal-case"
					btntype="primary"
					onClick={redirectToEmail}
				>
					Contact Support
				</Button>
				<Button
					className="!w-full"
					btntype={"secondary"}
					onClick={handleClose}
				>
					Close
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			header={dialogProperty}
			onClose={() => setIsOpenSubs(false)}
			footerComponent={<FooterComponent />}
		/>
	);
}

export default ChangeSubscriptionModal;
