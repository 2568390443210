import axios from "axios";
import { getCookie } from "utils/cookiesHelper";
import { showToast } from "utils/helpers";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL_V2;
const serverUrl = process.env.REACT_APP_API_URL_V2;
const apiV2 = axios.create({
	baseURL: serverUrl,
	withCredentials: true,
	timeout: 120000, // Milliseconds
});

apiV2.interceptors.request.use(
	(config) => {
		const authToken = getCookie("access_token");
		if (authToken) {
			config.headers.Authorization = `Bearer ${authToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

apiV2.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error?.response) {
			switch (error?.response?.status) {
				case 404:
					showToast(error?.message, "Error");
					break;
				case 403:
					let errorMessage = error?.message;
					if (errorMessage !== "This action is unauthorized.") {
						errorMessage = "This action is unauthorized.";
					}
					showToast(errorMessage, "Error");
					// setTimeout(() => {
					//     window.location.pathname = "/";
					// }, 1000);
					break;
				default:
					return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const unauthorized = [401, 419];
		if (unauthorized.includes(error.response?.status)) {
			if (
				!localStorage.getItem("twoFactorRequired") ||
				!getCookie("access_token")
			) {
				showToast(
					"Please try and sign in again.",
					"Sorry, your session has expired."
				);

				localStorage.removeItem("access_token");

				if (window.location.pathname !== "/") {
					if (window.location.pathname !== "/logout") {
						localStorage.setItem("returnTo", window.location.pathname);
					}
					window.location.href = "/";
				}
			}
		} else if (error.response?.status === 500) {
			showToast("Sorry, there was an error processing your request", "Error");
			return;
		} else {
			return Promise.reject(error);
		}
	}
);

// export const getCsrfCookie = async () => {
//     await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`);
// };

export default apiV2;
