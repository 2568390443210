import styled from "styled-components";
import { HeadingStyled } from "../Heading";

export const InvoiceViewSection = styled.div`
	.flex {
		display: flex;
		justify-content: space-between;
	}
	padding: 0px 64px;
	hr {
		margin: 24px 0px 24px 0px;
		color: #263859;
	}
	strong {
		color: #000000;
		margin-right: 1px;
		line-height: 5;
	}
	.billing-details {
		margin-top: 32px;
		.label {
			line-height: 19.2px;
			color: #000000;
			font-size: 12px;
			font-weight: 400;
			p {
				margin: 0;
			}
			h3 {
				font-size: 12px;
				font-weight: 700;
				margin: 0;
			}
		}
	}
	.flex-table {
		line-height: 19.2px;
		display: flex;
		.label {
			flex: 3;
			color: #000000;
			font-size: 12px;
			font-weight: 400;
			flex: inherit;
			p {
				margin: 0;
			}
			h3 {
				font-size: 12px;
				font-weight: 700;
				margin: 0;
			}
			.text-right {
				text-align: right;
			}
		}
		.details {
			color: #000000;
			flex: 3;
			font-size: 12px;
			font-weight: 400;
			margin-top: 19px;
			p {
				margin: 0;
			}
		}
		.invoice-details {
			color: #000000;
			flex: 3;
			margin-left: 25px;
			font-size: 12px;
			font-weight: 400;
			p {
				margin: 0;
			}
			h3 {
				font-size: 12px;
				font-weight: 700;
				margin: 0;
			}
			p {
				margin: 0;
			}
			.line-height {
				line-height: 5;
			}
		}
	}
	margin: 14px auto 260px auto;
	max-width: 1000px;
	button {
		margin-top: 32px;
		width: 250px;
	}
	.instructions {
		font-size: 15px;
	}
	.mb-0 {
		margin-bottom: 0px;
	}
	${HeadingStyled} {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: #273444;
		max-width: 470px;
		.welcome {
			margin-bottom: 40px;
		}

		span {
			display: block;
		}
	}
`;
