import styled from "styled-components";
import { HeadingStyled } from "../Heading";

export const LoginWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 40px;
	min-height: 100vh;
	position: relative;
	background: white;
	h6 {
		min-width: 30%;
		margin-top: 0px;
		color: ${(props) => props.theme.colors.navy};
		font-size: 18px;
		font-weight: 500;
		text-align: center;
	}
	button {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const Logo = styled.img`
	max-width: 160px;
	cursor: pointer;
`;

export const Tagline = styled.img`
	margin-left: 10px;
	height: 36px;
	margin-top: 1px;
`;

export const LoginSection = styled.div`
	max-width: 519px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	${HeadingStyled} {
		font-size: 3.55rem;
		line-height: 0.9;
		color: #273444;
		text-align: center;

		.welcome {
			margin-bottom: 40px;
		}

		span {
			display: block;
		}
	}
`;

export const InputLabel = styled.label`
	font-size: 0.9rem;
	text-transform: uppercase;
	color: #8492a6;
	display: block;
`;

export const BottomTag = styled.div`
	margin-top: 46px;
	text-align: center;
	font-size: ${(props) => props.theme.fontSizes.md};
	color: ${(props) => props.theme.colors.grayLight};
	font-family: "Roboto";
	a {
		font-family: "Roboto";
		color: ${(props) => props.theme.colors.royalBlueLight};
		text-decoration: underline;
	}
`;

export const BottomLinks = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	font-family: "Roboto";
	a {
		color: ${(props) => props.theme.colors.royalBlueLight};
		font-size: ${(props) => props.theme.fontSizes.md};
		text-decoration: underline;
	}
`;
