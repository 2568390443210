import React from "react";

const RFQStatusComp = ({ status, rounded }) => {
	status = status?.replace(/ /g, "")?.toUpperCase();

	const getRfxStatus = (status) => {
		switch (status) {
			case "AWARDED":
			case "ARCHIVED":
				return "CLOSED";
			case "ACTIVE":
				return "OPEN";
			default:
				return status;
		}
	};

	const rfqStatus = [
		{
			status: "Closed",
			color: "#B42318",
			background: "#FEF3F2",
			colorBorder: "#FECDCA",
		},
		{
			status: "Open",
			color: "#00976B",
			background: "#D5F9F3",
			colorBorder: "#0FE3C7",
		},
		{
			status: "Evaluation",
			color: "#5925DC",
			background: "#F4F3FF",
			colorBorder: "#D9D6FE",
		},
	];

	switch (getRfxStatus(status)) {
		case "CLOSED":
			return (
				<div
					className={`flex items-center justify-center text-center font-roboto text-[14px] font-medium ${rounded !== undefined ? rounded : "rounded-md"} p-3 shadow-sm`}
					style={{
						width: "fit-content",
						color: rfqStatus[0].color,
						background: rfqStatus[0].background,
						border: `solid 1px ${rfqStatus[0].colorBorder}`,
						height: "24px",
					}}
				>
					{rfqStatus[0].status}
				</div>
			);
		case "OPEN":
			return (
				<div
					className={`flex items-center justify-center text-center font-roboto text-[14px] font-medium ${rounded !== undefined ? rounded : "rounded-md"} p-3 shadow-sm`}
					style={{
						width: "fit-content",
						color: rfqStatus[1].color,
						background: rfqStatus[1].background,
						border: `solid 1px ${rfqStatus[1].colorBorder}`,
						height: "24px",
					}}
				>
					{rfqStatus[1].status}
				</div>
			);
		case "EVALUATION":
			return (
				<div
					className={`flex items-center justify-center text-center font-roboto text-[14px] font-medium ${rounded !== undefined ? rounded : "rounded-md"} p-3 shadow-sm`}
					style={{
						width: "fit-content",
						color: rfqStatus[2].color,
						background: rfqStatus[2].background,
						border: `solid 1px ${rfqStatus[2].colorBorder}`,
						height: "24px",
					}}
				>
					{rfqStatus[2].status}
				</div>
			);
		default:
			return null;
	}
};

export default RFQStatusComp;
