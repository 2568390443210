import { Heading } from "components/Heading";
import React from "react";
import styled from "styled-components";
import { ReactComponent as BGError } from "../../src/assets/img/not-found-bg.svg";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";

const NotFoundStyled = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const NotFound = () => {
	const company = useSelector((state) => state.auth.current_company);
	const isClient = company.profile_type?.id === 1;
	const companyId = company?.id;
	const redirectPath = companyId
		? `/account/${companyId}/${isClient ? "projects" : "request-for-quote"}`
		: `/account/request-for-quote`;
	if (!companyId) return null;
	return (
		<NotFoundStyled>
			<div className="relative mx-auto mt-[10vh] h-[90vh] max-h-[680px] w-full max-w-[1280px]">
				<BGError className="absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2" />
				<div className="relative top-[45%] z-20 -translate-y-1/2 space-y-6 text-center">
					<Heading className="text-[160px] !leading-[1] !text-white">
						404
					</Heading>
					<Heading className="text-[48px] !text-utility-brand-300">
						Page not found
					</Heading>
					<p className="font-roboto text-xl text-white">
						The page you are looking for might have been removed <br /> or is
						temporarily unavailable.
					</p>
					<Link
						className="mx-auto !mt-12 block h-[60px] w-[180px] rounded-sm !bg-white px-6 py-4 font-roboto font-semibold leading-[30px] !text-royal-blue"
						to={redirectPath}
					>
						Go to homepage
					</Link>
				</div>
			</div>
		</NotFoundStyled>
	);
};
