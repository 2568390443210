import React from "react";

//todo: move this component
import { Documents } from "../account/companyProfile/Documents";
import { NoResults } from "../../components/NoResults";

export const CorporateDocuments = ({ documents, isRequesting, ...props }) => {
	return (
		<>
			{!isRequesting && documents.length ? (
				<Documents
					documents={documents}
					hideExpireDate={true}
				/>
			) : (
				<NoResults />
			)}
		</>
	);
};
