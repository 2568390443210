import React from "react";
import RFQStatusComp from "components/RFQ/RFQStatusComp/RFQStatusComp";
import RfQResponseComp from "components/RFQ/RfQResponseComp/RfQResponseComp";
import { Button } from "components/RFQ/Button";
import moment from "moment";
import { Flex } from "@chakra-ui/core";
import { STATUS_DECLINE } from "utils/constants";
import { timezoneChecker } from "utils/helpers";
import { Avatar } from "components/RFQ/Avatar";
import { AsyncImage } from "loadable-image";
import { DEFAULT_IMAGE_BANNER } from "utils/constants";
const RfqCard = ({ data, navigatePreview }) => {
	const renderButtonRFQView = (response, status) => {
		const statusName =
			typeof response === "string" ? response.toUpperCase() : response;

		if (statusName === "INPROGRESS" && status?.toUpperCase() !== "EVALUATION") {
			return "Edit RFx";
		} else if (
			statusName === "INVITED" &&
			status?.toUpperCase() !== "EVALUATION"
		) {
			return "Accept/Decline RFx";
		} else {
			return "View RFx";
		}
	};

	return (
		<div
			className="card-shadow mb-3 mr-5 mt-3 h-[405px] w-full overflow-hidden rounded-md bg-white"
			style={{ minWidth: "320px" }}
		>
			<div className="h-[6px] w-full bg-royal-blue2"></div>
			<div className="pb-3 pl-5 pr-5 pt-3">
				<div
					className="relative"
					style={{ height: "60px" }}
				>
					<div className="absolute w-full">
						<AsyncImage
							src={`${data?.rfq?.project?.backgroundImagePath}`}
							alt="banner-card"
							className="h-[60px]"
							loader={
								<img
									src={DEFAULT_IMAGE_BANNER}
									alt="banner-card"
									style={{ filter: "blur(10px)" }}
								/>
							}
						/>
					</div>
					<div className="px-2 pt-2">
						<Avatar
							avatar={data?.rfq?.project?.logoPath}
							size="lg"
						/>
					</div>
				</div>
				<div>
					<h3 className="mt-3 line-clamp-1 overflow-hidden font-roboto text-[20px] font-semibold capitalize text-primary-900">
						{data?.rfq?.name || "Project"}
					</h3>
					<div className="mb-4 font-roboto text-[14px] font-normal text-primary-500">
						{data?.rfq?.rfxPublished
							? `Issued on ${moment(data?.rfq?.rfxPublished)
									.tz(timezoneChecker(data?.rfq?.rfxPublished || "AWST"))
									.format("DD MMM YYYY")} `
							: data?.rfq?.supplierList?.name || "-"}
					</div>
				</div>
				<div
					className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
					style={{ height: "40px" }}
				>
					<div className="font-roboto text-[14px] font-normal text-primary-500">
						Client
					</div>
					<div className="font-roboto text-[14px] font-semibold text-primary-900">
						{data?.rfq?.client}
					</div>
				</div>
				<div
					className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
					style={{ height: "40px" }}
				>
					<div className="font-roboto text-[14px] font-normal text-primary-500">
						RFx status
					</div>
					<RFQStatusComp status={data?.rfq?.status}></RFQStatusComp>
				</div>

				<div
					className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
					style={{ height: "40px" }}
				>
					<div className="font-roboto text-[14px] font-normal text-primary-500">
						Submission Deadline
					</div>
					<div className="text-end font-roboto text-[14px] font-semibold text-primary-900">
						{moment(data?.rfq?.submissionDeadline)
							.tz(timezoneChecker(data?.rfq?.submissionTimezone || "AWST"))
							.format("dddd, D MMMM YYYY")}
					</div>
				</div>
				<div
					className="flex items-center justify-between space-x-0"
					style={{ height: "40px" }}
				>
					<div className="font-roboto text-[14px] font-normal text-primary-500">
						Response
					</div>
					<RfQResponseComp response={data?.status}></RfQResponseComp>
				</div>
				{data?.status !== STATUS_DECLINE && (
					<Button
						btntype="secondary"
						width="100%"
						className="mt-5"
						onClick={() =>
							navigatePreview(`${data?.rfqId ?? data?.rfq?.id}/documentation`)
						}
					>
						<Flex align={"center"}>
							<p className="font-roboto font-semibold text-utility-blue-dark-100">
								{renderButtonRFQView(data?.status, data?.rfq?.status)}
							</p>
						</Flex>
					</Button>
				)}
			</div>
		</div>
	);
};
export default RfqCard;
