import styled from "styled-components";

export const DocumentControlsStyled = styled.div`
	display: flex;
	opacity: 0;
	&:hover {
		opacity: 1;
	}
`;

export const DocumentActionButton = styled.button`
	font-size: ${(props) => props.theme.fontSizes.xs};
	color: ${(props) =>
		props.danger ? props.theme.colors.red : props.theme.colors.grayLight};
	font-style: italic;
	border: none;
	background: none;
	font-weight: 600;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	cursor: pointer;
	margin-left: 1rem;
	transition: ${(props) => props.theme.transitions.default};

	&::hover {
		color: ${(props) =>
			props.danger ? props.theme.colors.redDark : props.theme.colors.gray};
	}

	svg {
		width: 20px;
		height: 20px;
		margin-right: 0.3rem;
	}
`;
