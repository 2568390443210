import React from "react";

const RfQResponseComp = ({ response, size }) => {
	response = response?.replace(/ /g, "")?.toUpperCase();

	const responseStatus = [
		{
			response: "Submitted",
			color: "#00976B",
			colorBorder: "#00A87C",
		},
		{
			response: "In Progress",
			color: "#0031DD",
			colorBorder: "#003CE9",
		},
		{
			response: "Declined",
			color: "#B42318",
			colorBorder: "#D92D20",
		},
		{
			response: "Awarded",
			color: "#00976B",
			colorBorder: "#00A87C",
		},
		{
			response: "Invited",
			color: "#0031DD",
			colorBorder: "#003CE9",
		},
		{
			response: "Accepted",
			color: "#00976B",
			colorBorder: "#00A87C",
		},
		{
			response: "Unsuccessful",
			color: "#B42318",
			colorBorder: "#D92D20",
		},
	];

	switch (response) {
		case "SUBMITTED":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[0].color,
						border: `solid 1.5px ${responseStatus[0].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[0].response}</div>
				</div>
			);
		case "INPROGRESS":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[1].color,
						border: `solid 1.5px ${responseStatus[1].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[1].response}</div>
				</div>
			);
		case "DECLINE":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[2].color,
						border: `solid 1.5px ${responseStatus[2].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[2].response}</div>
				</div>
			);
		case "AWARDED":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[3].color,
						border: `solid 1.5px ${responseStatus[3].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[3].response}</div>
				</div>
			);
		case "INVITED":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[4].color,
						border: `solid 1.5px ${responseStatus[4].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[4].response}</div>
				</div>
			);
		case "ACCEPTED":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[5].color,
						border: `solid 1.5px ${responseStatus[5].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[5].response}</div>
				</div>
			);
		case "UNSUCCESSFUL":
			return (
				<div
					className={`flex items-center justify-center rounded-full text-center font-roboto text-[14px] font-medium ${size !== undefined ? size : "p-3"} bg-white shadow-sm`}
					style={{
						width: "fit-content",
						color: responseStatus[6].color,
						border: `solid 1.5px ${responseStatus[6].colorBorder}`,
						height: "24px",
					}}
				>
					<div>{responseStatus[6].response}</div>
				</div>
			);
		default:
			return null;
	}
};

export default RfQResponseComp;
