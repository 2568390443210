import React, { useState } from "react";
import PropTypes from "prop-types";

import { SideDrawStyled } from "./styled";
import { ActionButton } from "../ActionButton";
import { TileGroup } from "../TileGroup";

export const SideDraw = ({ shortlist, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<SideDrawStyled open={isOpen}>
			<ActionButton
				onClick={handleClick}
				size="sm"
				icon="arrow"
				open={isOpen}
				className="side-draw__toggle"
			/>

			<div className="side-draw__inner">
				<h3 className="side-draw__heading">
					{shortlist ? "Project Shortlist" : "Project Groups"}
				</h3>
				{shortlist && shortlist.length ? (
					<>
						{shortlist.map((company, index) => {
							if (shortlist && shortlist === null) {
								return "";
							}

							return (
								<TileGroup
									key={index}
									heading={company.name}
									projects={company.compare_projects}
									highlight
								/>
							);
						})}
					</>
				) : (
					<p style={{ textAlign: "center", margin: "30px 0" }}>
						Shortlist is empty.
					</p>
				)}
			</div>
		</SideDrawStyled>
	);
};

SideDraw.defaultProps = {
	open: true,
};

SideDraw.propTypes = {
	open: PropTypes.bool,
};
