import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { navigate } from "@reach/router";
import { Flex, Spinner } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import { JumbotronComp } from "./JumbotronComp";
import { Button } from "components/RFQ/Button";
import DocumentationComp from "./DocumentationComp";
import { PreviewOnlyComp } from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";

import {
	downloadAllDocumentation,
	getSubmission,
	saveSubmissionRFQ,
} from "actions/RFQ/Supplier/rfqActions";
import ReturnableSchedulesComp from "./ReturnableSchedulesComp";
import { AcceptDeclineModal } from "./AcceptDeclineModal";
import { Input } from "components/RFQ/Input";
import { getDocumentType, getUnitMeasures } from "actions/RFQ/rfqsAction";
import { SkeletonBase } from "components/Skeleton";
import YourQuoteComp from "pages/RFQSubmission/RFQPreview/YourQuoteComp";
import {
	initialQuoteValue,
	initialReturnableValue,
	valuesPayload,
} from "./RFQUtils/RFQSubmissionUtils";
import { Form, Formik } from "formik";
import { SubmitModal } from "./SubmitModal";
import {
	downloadAllDocumentationGuest,
	getSubmissionGuest,
	saveSubmissionGuestRFQ,
} from "actions/guestflow/guestRfqActions";
import { useSelector } from "react-redux";
import { NotFound } from "routes/NotFound";
import ButtonActions from "./ActionButtons";
import { allValidationSchema } from "./RFQUtils/ValidationSchemas";
import { isEmptyObj, isRFxClosed } from "utils/helpers";
import { getInitialErrors } from "./RFQUtils/RFQHelper";
import FormErrorComp from "./FormErrorComp";
import QuoteFile from "./QuoteFile";
import {
	STATUS_ACCEPTED,
	STATUS_IN_PROGRESS,
	STATUS_SUBMITTED,
} from "utils/constants";
import { RFQContext } from "context/RFQContext";
import useRefreshWarning from "hooks/useRefreshWarning";
import { AddendumContainer } from "../addendum/AddendumContainer";

/* 
  name : content request name,
  type: type of content either custom form or file,
  templateFile: file uploaded by procurer,
  uploadedFile: file uploaded by supplier in response to template file,
  questions: custom form question by procurer,
  answers: answer given supplier
*/

const initialRfxRequestData = [
	{
		name: "returnableSchedules",
		type: "form",
		templateFile: null,
		uploadedFile: null,
		questions: null,
		answers: null,
	},
	{
		name: "quote",
		type: "form",
		templateFile: null,
		uploadedFile: null,
		questions: null,
		answers: null,
		unitMeasures: null,
	},
];

const RFQPreview = ({ id, isGuest, currentTab }) => {
	const tabHeaderRef = useRef(null);
	const formRef = useRef(null);

	// form initial values and form current status
	const [initialFormValue, setInitialFormValue] = useState(null);
	const [validForm, setValidForm] = useState(true);
	const [errors, setErrors] = useState(null);
	const [submissionStatus, setSubmissionStatus] = useState(null);

	// loading status
	const [loadingSave, setLoadingSave] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);

	// rfx form data
	const [rfxRequestData, setRfxRequestData] = useState(initialRfxRequestData);
	const requestRfxReturnables = rfxRequestData.find(
		(rfxData) => rfxData.name === "returnableSchedules"
	);
	const requestRfxQuote = rfxRequestData.find(
		(rfxData) => rfxData.name === "quote"
	);

	// rfx
	const [rfqDetail, setRfqDetail] = useState();
	// rfx status
	const rfxClosedStatus = isRFxClosed(rfqDetail?.status);
	const isAccepted =
		submissionStatus === STATUS_ACCEPTED ||
		submissionStatus === STATUS_IN_PROGRESS ||
		submissionStatus === STATUS_SUBMITTED;
	const isStatusProgressOrAccepted =
		submissionStatus?.toUpperCase() === "INPROGRESS" ||
		submissionStatus?.toUpperCase() === "ACCEPTED";

	//
	const [unitMeasures, setUnitMeasures] = useState(null);
	const [documentCategories, setDocumentCategories] = useState([]);
	const [documentFilter, setDocumentFilter] = useState(null);

	const rfqContext = useContext(RFQContext);

	useRefreshWarning(
		rfqContext?.uploadInProgresses?.rfxBidder?.length > 0,
		"Are you sure you want to refresh the page?"
	);

	const {
		documents,
		resources,
		pastProjects,
		isRequesting,
		requestingResources,
		loadingPastProjects,
	} = useSelector((state) => state?.companyProfile);

	// current user profile type either guest or bidder with account
	const isLoadedCompanyProfile = isGuest
		? true
		: !isRequesting && !requestingResources && !loadingPastProjects;
	const guestEmail = useSelector((state) => state.auth.guestUser.email) || null;
	const company = useSelector((state) => state.auth?.current_company);

	// filter company resources to select appropriate form field type either smart field or textarea
	let hasDocuments = documents?.length > 0;
	let hasResources = resources?.length > 0;
	let hasPasProjects = pastProjects?.length > 0;
	const companyId = localStorage.getItem("company_id");
	const guestUserId =
		isGuest && JSON.parse(localStorage.getItem("guest_user"))?.id;
	const rfqId = id;

	// handle status for dialog
	const [showSubmitDialog, setShowSubmitDialog] = useState(false);
	const [showDialog, setShowDialog] = useState(null);

	const [isNotFound, setIsNotFound] = useState(false);

	// tab contents are filtered based on tab names
	const [tabMenu, setTabMenu] = useState([
		{
			id: "documentation",
			name: "Documentation",
			showBadge: true,
			total: 0,
			isValid: true,
		},
		{
			id: "returnable-schedules",
			name: "Returnable Schedules",
			showBadge: true,
			total: 0,
			isValid: false,
		},
		{
			id: "quote",
			name: "Quote",
			showBadge: true,
			total: 0,
			isValid: false,
		},
		{
			id: "addendums",
			name: "Addendums",
			showBadge: true,
			total: 0,
			isValid: true,
		},
		{
			id: "comms",
			name: "Comms",
			showBadge: true,
			total: 0,
			isValid: true,
		},
	]);

	useEffect(() => {
		tabHeaderRef?.current?.scrollIntoView({ behavior: "instant" });
	}, [currentTab]);

	const getSubmissionByUserType = () => {
		return isGuest
			? getSubmissionGuest(rfqId, guestEmail)
			: getSubmission(rfqId, companyId);
	};

	useEffect(() => {
		// set rfqId for context
		rfqContext?.setRFQId(id);
		rfqContext?.setGuestUserId(guestUserId);
		rfqContext?.setCurrentCompanyId(companyId);
		setIsLoading(true);
		getSubmissionByUserType()
			.then((res) => {
				const submissionRes = res;
				if (!submissionRes) {
					setIsNotFound(true);
				}

				setRfqStateDetail(submissionRes);

				getDocumentType().then((res) => {
					if (res) {
						setDocumentCategories([
							{ value: null, label: "All Categories" },
							...res.map((category) => ({
								value: category?.id,
								label: category?.name,
							})),
						]);
					}
				});
				getUnitMeasures().then((res) => {
					if (res) {
						setUnitMeasures(
							res.map((unit) => ({ value: unit?.id, label: unit?.name }))
						);
					}
				});
			})
			.catch((e) => {
				console.log(e);
				setIsNotFound(true);
				setIsLoading(false);
			});
		//eslint-disable-next-line
	}, [rfqId, guestEmail, isLoadedCompanyProfile]);

	const setRfqStateDetail = (rfqStateResponse) => {
		const {
			submissionStatus,
			quoteData,
			returnableData,
			rfqDetail,
			quoteSavedFile,
			returnableScheduledSavedFile,
		} = rfqStateResponse;
		if (submissionStatus?.toLowerCase() === "decline") {
			isGuest
				? navigate(".")
				: navigate(`/account/${companyId}/request-for-quote`);
		} else {
			if (isLoadedCompanyProfile) {
				const initialReturnable =
					rfqDetail?.returnableScheduleType !== "file"
						? initialReturnableValue(
								returnableData,
								isGuest,
								hasDocuments,
								hasPasProjects,
								hasResources
							)
						: {
								returnable_schedule_file:
									returnableScheduledSavedFile?.file_name || null,
							};

				const initialQuote =
					rfqDetail?.quoteType === "file"
						? { quote_file: quoteSavedFile?.file_name }
						: initialQuoteValue(quoteData);

				const initialFormValue = {
					...initialReturnable,
					...initialQuote,
					returnables_type: rfqDetail?.returnableScheduleType,
					quote_type: rfqDetail?.quoteType,
				};

				setInitialFormValue(initialFormValue);

				setSubmissionStatus(submissionStatus);
				setRfqDetail(rfqDetail);

				const updatedRfxResponseData = rfxRequestData.map((rfxRequestFile) =>
					rfxRequestFile.name === "returnableSchedules"
						? {
								...rfxRequestFile,
								type: rfqDetail?.returnableScheduleType,
								templateFile: rfqDetail?.returnableScheduleFilePath,
								savedFile: returnableScheduledSavedFile,
								questions: returnableData,
							}
						: {
								...rfxRequestFile,
								type: rfqDetail?.quoteType,
								templateFile: rfqDetail?.quoteFilePath,
								savedFile: quoteSavedFile,
								questions: quoteData,
							}
				);

				setRfxRequestData(updatedRfxResponseData);

				let tempTab = [...tabMenu];
				tempTab[0].total = rfqDetail?.documentationDocument?.length || 0;
				tempTab[1].total =
					rfqDetail?.returnableScheduleType !== "file"
						? returnableData?.length || 0
						: 1;
				tempTab[2].total =
					rfqDetail?.quoteType === "file" ? 1 : quoteData?.length;
				tempTab[3].total = rfqDetail?.addendum_count;
				tempTab[4].total = rfqDetail?.comms_count;
				setTabMenu(tempTab);
				setIsLoading(false);
			}
		}
	};

	const setCountForTabs = (tab, count) => {
		setTabMenu((prevTabMenu) => {
			let tempTab = [...prevTabMenu];
			const tabIndex = tempTab.findIndex((_tab) => _tab.id === tab);
			tempTab[tabIndex].total = count;
			return tempTab;
		});
	};

	useEffect(() => {
		// check for initial errors
		if (isStatusProgressOrAccepted) {
			const initialErrors = getInitialErrors(
				requestRfxReturnables?.questions,
				requestRfxQuote?.questions,
				requestRfxQuote,
				requestRfxReturnables
			);
			setValidForm(isEmptyObj(initialErrors));
		}
	}, [
		rfxRequestData,
		isStatusProgressOrAccepted,
		requestRfxReturnables,
		requestRfxQuote,
	]);

	const downloadAllHandler = () => {
		setDownloadLoading(true);
		(isGuest
			? downloadAllDocumentationGuest(rfqId, guestEmail)
			: downloadAllDocumentation(rfqId)
		).then((_) => {
			setDownloadLoading(false);
		});
	};

	const saveHandler = useCallback(async () => {
		setLoadingSave(true);
		focusErrorField();
		return new Promise((resolve, reject) => {
			(isGuest
				? saveSubmissionGuestRFQ(
						guestEmail,
						rfqId,
						valuesPayload(
							formRef?.current?.values,
							requestRfxReturnables?.questions,
							isGuest
						)
					)
				: saveSubmissionRFQ(
						rfqId,
						valuesPayload(
							formRef?.current?.values,
							requestRfxReturnables?.questions
						)
					)
			)
				.then((res) => {
					if (res) {
						setLoadingSave(false);
						resolve(true);
					} else {
						setLoadingSave(false);
						reject(false);
					}
				})
				.catch((e) => {
					console.log(e);

					setLoadingSave(false);
					reject(false);
				});
		});
	}, [formRef, rfqId, requestRfxReturnables?.questions, isGuest, guestEmail]);

	const submitHandler = useCallback(async () => {
		try {
			if (formRef?.current?.isValid) {
				await formRef?.current?.submitForm();
				setShowSubmitDialog(true);
			}
		} catch (e) {}
		//eslint-disable-next-line
	}, [formRef]);

	const continueTab = async () => {
		let current = tabMenu.findIndex((tab) => tab.id === currentTab);
		if (current === 1 || current === 2) {
			saveHandler();
		}
		navigate(`./${tabMenu[current + 1].id}`);
	};

	// _ is used due to the value of first parameter is unused
	const changeTab = (_, id) => {
		navigate(`./${id}`);
	};

	const focusErrorField = () => {
		formRef?.current?.validateForm().then((res) => {
			setErrors(res);
			formRef?.current?.setTouched(res);
		});
	};

	if (isNotFound) {
		return <NotFound />;
	}

	return (
		<div>
			<ButtonActions
				isLoading={isLoading}
				submissionStatus={submissionStatus}
				isRFxClosed={rfxClosedStatus}
				setShowDialog={setShowDialog}
				saveHandler={saveHandler}
				currentTab={currentTab}
				validForm={validForm}
				loadingSave={loadingSave}
				continueTab={continueTab}
				submitHandler={submitHandler}
				isFileUploadInProgress={
					rfqContext?.uploadInProgresses?.rfxBidder?.length > 0
				}
			/>
			<div
				id="layout-dashboard"
				className="mt-8"
			>
				<JumbotronComp
					data={rfqDetail}
					isLoading={isLoading}
					submissionStatus={submissionStatus}
				/>
				<div
					ref={tabHeaderRef}
					id="rfx-container"
					className="mb-10 mt-10 flex h-14 max-h-14 items-center justify-between"
				>
					<TabsComp
						menus={tabMenu}
						activeTab={currentTab}
						changeActiveTab={changeTab}
						isLoading={isLoading}
					/>
					<div ref={tabHeaderRef}></div>

					{!isLoading ? (
						currentTab === "documentation" && (
							<Flex
								align={"center"}
								style={{ gap: "8px" }}
							>
								{
									<Input
										type="select"
										name="category"
										placeholder="Refine by Category"
										onChange={(e) => setDocumentFilter(e.value)}
										value={documentFilter}
										options={documentCategories}
										className="min-w-[280px]"
										disabled={true}
									/>
								}
								<Button
									btntype="secondary"
									className="!h-[56px] !min-w-[180px]"
									disabled={downloadLoading}
									onClick={downloadAllHandler}
								>
									{!downloadLoading ? (
										<Flex
											align={"center"}
											gap={2}
										>
											<Icon
												icon="download"
												color="#0031DD"
											/>
											Download All
										</Flex>
									) : (
										<Spinner size="md" />
									)}
								</Button>
							</Flex>
						)
					) : (
						<Flex gap={2}>
							<SkeletonBase
								width="240px"
								height="56px"
							/>
							<SkeletonBase
								width="160px"
								height="56px"
							/>
						</Flex>
					)}
				</div>
				{errors && !validForm && currentTab !== "documentation" && (
					<FormErrorComp
						isReturnableError={
							errors?.question_answer || errors?.returnable_schedule_file
						}
						isQuoteError={errors?.quote_answer || errors?.quote_file}
						error={errors}
					/>
				)}
				<div className="relative">
					{currentTab === "documentation" && (
						<>
							{!isLoading ? (
								rfqDetail && (
									<DocumentationComp
										data={rfqDetail?.documentationDocument}
										rfqId={rfqId}
										documentFilter={documentFilter}
									/>
								)
							) : (
								<>
									<SkeletonBase
										startColor="lightgray"
										width="100%"
										height="72px"
										className="my-4 !rounded-lg"
									/>
									<SkeletonBase
										startColor="lightgray"
										width="100%"
										height="72px"
										className="!rounded-lg"
									/>
								</>
							)}
						</>
					)}

					{initialFormValue && (
						<Formik
							initialValues={initialFormValue}
							innerRef={formRef}
							initialErrors={errors}
							validationSchema={allValidationSchema(
								requestRfxReturnables?.questions,
								requestRfxQuote?.questions,
								isGuest,
								hasDocuments,
								hasPasProjects,
								hasResources
							)}
							validateOnBlur
							validateOnMount={false}
							onSubmit={() => true}
						>
							{({ isValid, touched, errors, values }) => {
								if (!isEmptyObj(touched)) {
									setValidForm(isValid);
									setErrors(errors);
								}

								return (
									<Form>
										{currentTab === "returnable-schedules" && (
											<ReturnableSchedulesComp
												questionnaire={requestRfxReturnables?.questions}
												rfqId={rfqId}
												isGuest={isGuest}
												isReturnableSchedulesTypeFile={
													requestRfxReturnables?.type
												}
												companyName={rfqDetail?.companyOwner?.name}
												templateReturnableScheduleFile={
													requestRfxReturnables?.templateFile
												}
												currentCompanyId={company?.id}
												disabled={
													submissionStatus?.toLowerCase() === "submitted"
												}
											/>
										)}

										{currentTab === "quote" &&
											(requestRfxQuote?.type === "file" ? (
												<QuoteFile
													companyName={rfqDetail?.companyOwner?.name}
													quoteFilepath={rfqDetail?.quoteFilePath}
													currentCompanyId={company?.id}
													disabled={
														submissionStatus?.toLowerCase() === "submitted"
													}
													rfqId={id}
												/>
											) : (
												<YourQuoteComp
													listQuotes={requestRfxQuote?.questions}
													unitMeasures={unitMeasures}
													disabled={
														submissionStatus?.toLowerCase() === "submitted"
													}
												/>
											))}
									</Form>
								);
							}}
						</Formik>
					)}
					{(currentTab === "addendums" || currentTab === "comms") && (
						<AddendumContainer
							rfqId={rfqId}
							rfqCompanyId={rfqDetail?.companyId}
							isGuest={isGuest}
							guestEmail={guestEmail}
							disabled={submissionStatus?.toLowerCase() === "submitted"}
							isCommunication={currentTab === "comms"}
							setCounterForTabs={setCountForTabs}
						/>
					)}

					{!isAccepted && !isLoading && currentTab !== "documentation" && (
						<>
							<div
								style={{
									background: `rgba(255,255,255,0.6)`,
									zIndex: "333",
									width: "100%",
									height: "100%",
									position: "absolute",
									top: 0,
									left: 0,
								}}
							>
								<div className="sticky left-1/2 top-[80%] w-max -translate-x-1/2 py-[48px]">
									<PreviewOnlyComp
										submissionStatus={submissionStatus}
										isRFxClosed={rfxClosedStatus}
										label={
											rfxClosedStatus
												? "Bid submitted. No amendments permitted."
												: "Preview only."
										}
									/>
								</div>
							</div>
						</>
					)}
				</div>

				<ButtonActions
					isLoading={isLoading}
					setShowDialog={setShowDialog}
					saveHandler={saveHandler}
					currentTab={currentTab}
					loadingSave={loadingSave}
					isBottom
					isRFxClosed={rfxClosedStatus}
					submissionStatus={submissionStatus}
					continueTab={continueTab}
					submitHandler={submitHandler}
					validForm={validForm}
					isFileUploadInProgress={
						rfqContext?.uploadInProgresses?.rfxBidder?.length > 0
					}
				/>

				<AcceptDeclineModal
					showDialog={showDialog}
					onClose={() => setShowDialog(null)}
					rfqId={rfqId}
					setSubmissionStatus={setSubmissionStatus}
					guestEmail={guestEmail}
					isGuest={isGuest}
					companyId={companyId}
				/>

				<SubmitModal
					showDialog={showSubmitDialog}
					onClose={() => setShowSubmitDialog(false)}
					rfqId={rfqId}
					companyId={companyId}
					saveSubmissionHandler={saveHandler}
					isGuest={isGuest}
					guestEmail={guestEmail}
				/>
			</div>
		</div>
	);
};

export default RFQPreview;
