import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";
import { Badge } from "components/RFQ/Badge";
import { Checkbox } from "components/RFQ/Checkbox";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import moment from "moment";
import React from "react";
import tw, { styled, css } from "twin.macro";
import { cn } from "utils/helpers";
import { useSelector } from "react-redux";
import { STATUS_EVALUATION, STATUS_ACTIVE } from "utils/constants";
import { ButtonLink } from "components/ButtonLink";
import { Icon } from "assets/icons/Icon";

const ListViewStyled = styled.div(() => [
	tw`my-8 rounded-2xl`,
	css`
		box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.25);
		.title {
			font-weight: 500;
			font-family: Roboto;
			font-size: 14px;
		}

		.text {
			font-weight: 400;
			font-family: Roboto;
			font-size: 14px;
		}
	`,
]);

const SkeletonCustom = styled.div(() => [
	tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
	<TableRow>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
	</TableRow>
);

const SupplierListDrawer = ({
	data,
	isLoading,
	checkedRow,
	setCheckedRow,
	currPage,
	projectData,
}) => {
	const companyId = useSelector((state) => state.auth.user?.company_id) || null;
	const avatars = (data) =>
		data.map((supplier) => {
			return {
				avatar: supplier?.logo_path,
			};
		});

	const rowSelected = (rowId) => checkedRow.some((row) => row.uuid === rowId);
	const checkHandler = (e, row) => {
		if (e.target.checked) {
			setCheckedRow((prevRow) => [...prevRow, row]);
		} else {
			const newArray = checkedRow.filter(
				(checked) => checked.uuid !== row.uuid
			);
			setCheckedRow(newArray);
		}
	};

	const isRestrictForRemove = (searchSupplierListId) => {
		const displayItem = projectData?.supplierList?.find((it) => {
			return it.id === searchSupplierListId;
		});

		if (displayItem) {
			switch (displayItem?.relateRfq?.rfqStatus?.toUpperCase()) {
				case STATUS_EVALUATION:
				case STATUS_ACTIVE:
					return true;
				default:
					return false;
			}
		}
		return false;
	};

	return (
		<ListViewStyled>
			<div className="w-full rounded-t-2xl border-gray-200 p-6">
				<h2 className="text-lg font-semibold text-gray-800">Supplier Lists</h2>
			</div>
			<Table
				containerClass={tw`w-full rounded-2xl rounded-t-none border-t border-gray-200`}
			>
				<TableHeader>
					<TableRow>
						<TableHead
							firstCol={true}
							className="!pl-6"
						>
							<div
								className="flex"
								style={{ gap: "8px", alignItems: "center" }}
							>
								Supplier List
							</div>
						</TableHead>
						<TableHead className={tw`max-w-[108px]`}>Start Date</TableHead>
						<TableHead className={tw`max-w-[400px]`}>
							No. of Suppliers
						</TableHead>
						<TableHead className={tw`max-w-[108px]`}>No. of RFx</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{!projectData ? (
						[...Array(6)].map(() => <SkeleteonRow />)
					) : data?.length > 0 ? (
						<>
							{data?.map((supplier, index) => (
								<TableRow
									key={index}
									className={cn(
										rowSelected(supplier.uuid) &&
											"[&>td]:bg-utility-blue-light-50/30"
									)}
								>
									<TableCell
										firstCol={true}
										className="w-[35%] overflow-hidden !pl-6"
									>
										<div className="flex gap-4">
											<Checkbox
												disabled={isRestrictForRemove(supplier.id)}
												onChange={(e) => checkHandler(e, supplier)}
												checked={rowSelected(supplier.uuid)}
												className="cursor-pointer"
											/>
											<div className="relative ml-3">
												<p className="title max-w-[350px] overflow-hidden text-ellipsis">
													{supplier.name ? supplier.name : " - "}
												</p>
												<p className="max-w-[350px] overflow-hidden text-ellipsis">
													{supplier.description}
												</p>
											</div>
										</div>
									</TableCell>
									<TableCell className={tw`max-w-[218px]`}>
										<p className="text">
											{moment(supplier?.startDate).format("DD MMMM YYYY")}
										</p>
									</TableCell>
									<TableCell className={tw`max-w-[400px]`}>
										<p className="text">
											<GlobalAvatarGroup
												avatarSize="sm"
												maxAvatars={5}
												avatars={avatars(supplier?.suppliers)}
											/>
										</p>
									</TableCell>
									<TableCell className={tw`max-w-[108px]`}>
										<Badge
											notFull={true}
											rounded={"sm"}
											color={"light"}
											size="md"
										>
											<p className="text-xs font-medium">
												{supplier.numberRfq ? supplier.numberRfq : "0"}
											</p>
										</Badge>
									</TableCell>
								</TableRow>
							))}
							{data && isLoading && [...Array(2)].map(() => <SkeleteonRow />)}
						</>
					) : (
						<>
							{isLoading && [...Array(2)].map(() => <SkeleteonRow />)}
							{!isLoading && (
								<TableRow className="col-span-5 h-[500px]">
									<div className="absolute left-2/4 -translate-x-2/4">
										<NotFoundDatas
											text={`No Supplier Lists found`}
											description={
												"Please adjust your filters to see available Supplier Lists, or create supplier list."
											}
										>
											<ButtonLink
												to={`/account/${companyId}/supplier-list/new-supplier-list`}
												btnType="secondary"
											>
												<Icon
													icon="plus"
													className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
												/>
												Create Supplier List
											</ButtonLink>
										</NotFoundDatas>
									</div>
								</TableRow>
							)}
						</>
					)}
				</TableBody>
			</Table>
		</ListViewStyled>
	);
};

export default SupplierListDrawer;
