import React from "react";
const BadgeComp = ({ total, title, active }) => {
	return (
		<div
			className={`ml-2 flex rounded-full px-2 py-[2px] text-center font-roboto group-hover:!border group-hover:!border-utility-blue-dark-50 group-hover:bg-utility-blue-light-50 ${title === active ? "bg-utility-blue-light-50" : "bg-utility-gray-50"}`}
			style={{
				border: title === active ? "solid 1px #B0CEFF" : "solid 1px #EAECF0",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				className={`font-roboto ${title === active ? "text-utility-blue-dark-100" : "text-primary-700"} font-medium`}
				style={{ fontSize: "14px", lineHeight: "20px" }}
			>
				{total}
			</div>
		</div>
	);
};
export default BadgeComp;
