import * as constants from "../actions/actionTypes";

const INITIAL_SECTORS_STATE = {
	sectorData: [],
};

export const sectorsReducer = (state = INITIAL_SECTORS_STATE, action) => {
	switch (action.type) {
		case constants.GET_SECTORS:
			return {
				...state,
				sectorData: action.payload,
			};
		default:
			return state;
	}
};
