export const ADMINISTRATOR_TEXT = "administrator";

// Preview RFQ constant

export const PROJECT_DETAILS = "project-details";
export const DOCUMENTATION = "documentation";
export const QUESTIONNAIRE = "questionnaire";
export const TENDER_COST = "tender-cost";

// RFQ Detail constant

export const ALL_INVITED_SUPPLIER = "all";
export const ACCEPTED = "accepted";
export const SUBMITTED = "submitted";
export const DECLINED = "declined";

// RFQ Status constant

export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_COMPLETE = "COMPLETE";
export const STATUS_ON_HOLD = "ON_HOLD";
export const STATUS_ARCHIVED = "ARCHIVED";
export const STATUS_CANCELLED = "CANCELLED";
export const STATUS_SUSPENDED = "SUSPENDED";
export const STATUS_INVITED = "INVITED";
export const STATUS_SUBMITTED = "SUBMITTED";
export const STATUS_ACCEPTED = "ACCEPTED";
export const STATUS_EVALUATION = "EVALUATION";
export const STATUS_AWARDED = "AWARDED";
export const STATUS_DECLINE = "DECLINE";
export const STATUS_IN_PROGRESS = "INPROGRESS";
export const STATUS_DRAFT = "DRAFT";
export const STATUS_UNSUCCESSFUL = "unsuccessful";

// Addendum constant

export const STATUS_PRIVATE = "private";
export const STATUS_PUBLIC = "public";

// RFQ Profile

//image path
export const DEFAULT_IMAGE_BANNER = "/img/defaultimage.jpg";
export const DEFAULT_IMAGE_AVATAR = "/img/avatar-default.jpg";
