import React from "react";
import { ContextMenuLink } from "./ContextMenuLink";
import { IconBox } from "../IconBox";
import { ReactComponent as RequestIcon } from "../../icons/request-icon.svg";
import { ReactComponent as SearchIcon } from "./../../icons/search.svg";
import { ReactComponent as ShortListIcon } from "./../../icons/shortlist.svg";
import { ReactComponent as SignOutIcon } from "./../../icons/logout.svg";
import { logoutGuestUser, upgradeAccount } from "actions/authActions";
import { connect } from "react-redux";
import logo from "./logo.png";
import logoText from "./logo-procuracon.svg";
import { limitNotif } from "helpers/notificationHelper";
import { Button } from "components/Button";
import { SearchUpgradeModal } from "components/GuestModal/SearchUpgradeModal";
import { InfoWrapper } from "components/GuestSidebar/styled";
import { ReactComponent as InfoIcon } from "../../icons/info2.svg";
function ContextMenuGuestComponent({
	isCollapseNav,
	logoutGuestUser,
	guest_rfq,
	user,
	upgradeAccount,
	props,
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const displaySideMenu = true;
	const topMenu = [
		{
			name: "RFx",
			link: "/account/request-for-quote",
			icon: <RequestIcon />,
			total: guest_rfq.total,
			right: "-250px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
			icon_width: "24px",
			icon_height: "24px",
		},
	];

	const menus = [
		{
			name: "Search & Compare",
			isLink: true,
			icon: <SearchIcon />,
			link: "company-profile/company-information",
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
		},
		{
			name: "Shortlist Report",
			isLink: true,
			icon: <ShortListIcon />,
			link: "company-profile/edit-user-profile",
			showBorder: false,
			icon_width: "24px",
			icon_height: "24px",
			color_primary: "#0045F5",
			color_secondary: "#BDD6FF",
		},
	];

	return (
		<>
			{!isCollapseNav && (
				<img
					src={logoText}
					alt="Procuracon Logo text"
					className={`fadeintext ml-5 ${!isCollapseNav && "active"}`}
					style={{ marginTop: "50px", width: "207px", height: "32px" }}
				/>
			)}
			{isCollapseNav && (
				<img
					src={logo}
					className={`fadeintext ${isCollapseNav && "activelogo"}`}
					style={{
						minWidth: "40px !important",
						height: "40px !important",
						width: "40px",
						marginTop: "40px",
					}}
					alt="Procuracon Logo"
				/>
			)}
			<div style={{ display: "grid", overflow: "hidden", marginTop: "50px" }}>
				<div
					className="scroll-container"
					style={{
						height: isCollapseNav ? "calc(100vh - 60%)" : "calc(100vh - 50%)",
					}}
				>
					{topMenu.map((menu, index) => {
						if (menu.name === "RFx" && guest_rfq.total === 0) {
							return null;
						}
						return (
							<div
								key={index}
								className={`${isCollapseNav ? "menuleft mb-2 mr-3 rounded-md hover:bg-white-20" : "menuleft group overflow-hidden hover:bg-utility-blue-light-50 focus:bg-white"}`}
							>
								<ContextMenuLink
									to={menu.link}
									height={isCollapseNav ? "auto" : "56px"}
								>
									<div
										style={{
											paddingLeft: isCollapseNav ? "13px" : "32px",
											width: "24px",
											marginRight: "36px",
										}}
									>
										<IconBox
											icon={menu.icon}
											color={
												isCollapseNav
													? menu.color_secondary
													: menu.color_primary
											}
											width={menu.icon_width}
											height={menu.icon_width}
										/>
									</div>
									<div
										style={{
											display: isCollapseNav ? "none" : "flex",
											position: "relative",
											fontSize: "16px",
											lineHeight: "24px",
										}}
										className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
									>
										{menu.name}
										{menu.total !== null && (
											<div
												style={{
													fontSize: "12px",
													textAlign: "center",
													width: "30px",
													height: "22px",
													position: "absolute",
													right: menu.right,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
												className="rounded-full border border-quarternary-400 bg-gray-utility-100 font-medium"
											>
												<div className="font-roboto font-semibold text-secondary-700">
													{limitNotif(menu?.total)}
												</div>
											</div>
										)}
									</div>
								</ContextMenuLink>
							</div>
						);
					})}
				</div>
				<div>
					{user?.shared_profiles?.map((profile) => {
						return (
							<ContextMenuLink
								key={profile?.id}
								to={`/company/${profile?.company?.id}`}
								height={isCollapseNav ? "auto" : "64px"}
							>
								<div
									className={`flex w-full cursor-pointer items-center gap-4 py-3 ${isCollapseNav ? "menuleft mb-2 mr-3 rounded-full px-2 hover:bg-white-20" : "menuleft group overflow-hidden px-4 hover:bg-utility-blue-light-50 focus:bg-white"}`}
								>
									<img
										className={`${isCollapseNav ? "h-[40px] w-[40px]" : "h-[48px] w-[48px]"} rounded-full border-2 border-solid object-cover`}
										src={profile?.company?.logo_path}
										alt="img"
										onError={(e) => {
											e.target.onerror = null;
											e.target.src = `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`;
										}}
									/>
									<p className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue">
										{profile?.company?.name}
									</p>
								</div>
							</ContextMenuLink>
						);
					})}
					{user?.shared_profiles?.length > 0 && (
						<InfoWrapper>
							<InfoIcon />
							<span>
								Your Guest Account is restricted to 3 profiles. To view more
								companies please upgrade your Account.
							</span>
						</InfoWrapper>
					)}
				</div>
				<div style={{ position: "absolute", bottom: "32px", width: "100%" }}>
					{displaySideMenu &&
						menus.map((menu, index) => {
							return (
								<button
									key={menu?.id}
									className={`${isCollapseNav ? "menuleft mb-2 mr-3 h-auto rounded-md hover:bg-white-20" : "menuleft group h-56px w-full overflow-hidden hover:bg-utility-blue-light-50 focus:bg-white"}`}
									onClick={() => setIsOpen(true)}
								>
									{menu.isLink && (
										<div
											className="flex cursor-pointer items-center"
											style={{ paddingTop: isCollapseNav ? "" : "10px" }}
										>
											<div
												className={isCollapseNav ? "" : "-mt-1"}
												style={{
													paddingLeft: isCollapseNav ? "12px" : "32px",
													width: "24px",
													marginRight: "36px",
												}}
											>
												<IconBox
													icon={menu.icon}
													color={
														isCollapseNav
															? menu.color_secondary
															: menu.color_primary
													}
													width={menu.icon_width}
													height={menu.icon_width}
												/>
											</div>
											<div
												style={{
													display: isCollapseNav ? "none" : "flex",
													position: "relative",
													fontSize: "16px",
													lineHeight: "24px",
												}}
												className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
											>
												{menu.name}
											</div>
										</div>
									)}
								</button>
							);
						})}
					{displaySideMenu && (
						<div
							className={`${isCollapseNav ? "mb-2 mr-3 h-auto rounded-md hover:bg-white-20" : "group hover:bg-utility-blue-light-50 focus:bg-white"}`}
						>
							<div
								style={{
									borderBottom: isCollapseNav
										? "solid 0px #EAECF0"
										: "solid 1px #EAECF0",
									marginBottom: "15px",
									marginLeft: "7px",
								}}
								className="sidemuchild"
							></div>
							<button
								onClick={() => logoutGuestUser()}
								className="ml-4 flex cursor-pointer items-center gap-2"
							>
								<div
									style={{
										marginLeft: isCollapseNav ? "-7px" : "16px",
										width: "24px",
										marginRight: "8px",
									}}
								>
									<IconBox
										icon={
											<SignOutIcon
												width="24px"
												height="24px"
											/>
										}
										color={isCollapseNav ? "#BDD6FF" : "#0045F5"}
										width="24px"
										height="24px"
									/>
								</div>
								<div
									style={{
										display: isCollapseNav ? "none" : "block",
										lineHeight: "24px",
									}}
									className="sidemuchild font-roboto font-medium group-hover:text-royal-blue group-focus:text-royal-blue"
								>
									<p className="text-lg">Logout</p>
								</div>
							</button>
							<div
								style={{
									borderBottom: isCollapseNav
										? "solid 0px #EAECF0"
										: "solid 1px #EAECF0",
									marginTop: "15px",
									marginLeft: "7px",
								}}
								className="sidemuchild"
							></div>
						</div>
					)}
					{!isCollapseNav && (
						<>
							<div className="mb-5 ml-5 mr-5 mt-5">
								<div
									className={`fadeintext font-roboto font-medium text-primary-500 ${!isCollapseNav && "active"}`}
									style={{ fontSize: "16px" }}
								>
									Guest Account
								</div>
								<div
									className={`fadeintext line-clamp-1 overflow-hidden overflow-ellipsis font-roboto font-medium text-primary-900 ${!isCollapseNav && "active"}`}
									style={{ fontSize: "18px" }}
								>
									{user.email}
								</div>
							</div>
							<div
								className={`fadeintext mb-5 ml-5 mr-5 mt-5 ${!isCollapseNav && "active"}`}
							>
								<Button
									width="100%"
									onClick={() => {
										upgradeAccount(user?.email);
									}}
								>
									Upgrade Account
								</Button>
							</div>
						</>
					)}
				</div>
			</div>
			<SearchUpgradeModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				guestEmail={user.email}
				upgradeAction
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.guestUser,
		guest_rfq: state.guest_rfq,
	};
};

const ContextMenuGuest = connect(mapStateToProps, {
	logoutGuestUser,
	upgradeAccount,
})(ContextMenuGuestComponent);

export default ContextMenuGuest;
