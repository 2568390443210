import { Flex, Grid, Tooltip } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";

import React from "react";
import { cn } from "utils/helpers";
import { CheckboxField } from "../Checkbox";
import tw, { styled } from "twin.macro";

const ContainerChecboxes = styled(Grid)(() => [tw`ml-1 my-3 gap-x-2 gap-y-3`]);

export const MultipleCheckbox = ({
	label,
	className,
	hint,
	description,
	checkboxes,
	name,
	required,
	noAttribute,
	...props
}) => {
	return (
		<div className={cn(className)}>
			<Flex
				gap={1}
				className="items-center"
			>
				{label && (
					<label className="my-2 block font-semibold">
						{label + `${required ? "*" : ""}`}
					</label>
				)}
				{hint && (
					<Tooltip
						dataTip={`input-${props.name}`}
						content={hint}
					>
						<Icon
							icon="info-circle"
							className="text-gray-500"
							size="sm"
						/>
					</Tooltip>
				)}
			</Flex>
			{description && <p className="text-gray-text mb-2">{description}</p>}
			<ContainerChecboxes>
				<Flex
					gap={1}
					align={"center"}
				>
					<CheckboxField
						type="checkbox"
						name={name}
						option={checkboxes}
						attribute={noAttribute ? null : "count"}
						border={props?.border}
					/>
				</Flex>
			</ContainerChecboxes>
		</div>
	);
};
