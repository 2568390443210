import styled from "styled-components";

export const IconBoxStyled = styled.span`
	border: none;
	background: transparent;
	cursor: pointer;
	padding: 8px;
	border-radius: ${(props) => props.theme.radii.default};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ${(props) => props.theme.transitions.default};
	color: ${(props) =>
		props.color === "dark"
			? props.theme.colors.gray
			: props.color === "white"
				? "white"
				: "#003CE9"};

	&::hover {
		color: ${(props) =>
			props.color === "dark" ? props.theme.colors.gray : "white"};
		${(props) =>
			props.admin &&
			`
            background-color: ${props.theme.colors.royalBlue};
        `}
	}

	${(props) =>
		props.admin &&
		`
        background-color: ${props.theme.colors.royalBlueLight};
    `};

	svg {
		width: ${(props) => (props.width !== undefined ? props.width : "32px")};
		height: ${(props) => (props.height !== undefined ? props.height : "32px")};
		/* color:#263859; */

		path {
			stroke: currentColor;
		}
	}

	${(props) =>
		props.logo &&
		`
        border-radius: 8px;
        padding:0px;
        margin-top: 14px;
    `}
`;

export const IconStyled = styled.span`
	display: flex;
	align-items: center;
	color: ${(props) => (props.color !== undefined ? props.color : "unset")};
	${(props) =>
		props.color === "dark" &&
		`
    padding: 7px;
    border-radius: ${props.theme.radii.default};
    `};
`;
