import styled from "styled-components";

import { TextInputStyles } from "../TextInput";

export const AutosuggestStyled = styled.div`
	position: relative;
	width: 100%;

	input {
		${TextInputStyles}
		margin-bottom: 0;
	}
`;

export const Suggestion = styled.div`
	padding: ${(props) => props.theme.space.xs};
	line-height: 1.25;
`;
