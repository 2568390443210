import { useState, useCallback } from "react";
import apiV2 from "api-v2";

const useChunkFileUpload = ({
	url = "/file-upload/store",
	chunkSize = 1024 * 1024,
	maxRetries = 3,
	handleFileUploadComplete,
}) => {
	const [progress, setProgress] = useState(0);
	const [isUploadCompleted, setIsUploadCompleted] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [error, setError] = useState(null);

	const uploadChunk = async (
		formData,
		chunkIndex,
		totalChunks,
		retries = 0
	) => {
		try {
			const res = await apiV2.post(url, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setProgress(() =>
				parseFloat((((chunkIndex + 1) / totalChunks) * 100).toFixed(1))
			);
			return res;
		} catch (err) {
			if (retries < maxRetries) {
				return uploadChunk(formData, chunkIndex, totalChunks, retries + 1);
			} else {
				throw err;
			}
		}
	};

	const uploadFile = useCallback(
		async (file, extraData) => {
			if (file && file instanceof File) {
				const totalChunks = Math.ceil(file.size / chunkSize);
				setProgress(0);
				setError(null);
				setIsUploading(true);

				try {
					let uploadId = Math.random().toString(36).substring(7);
					for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
						const start = chunkIndex * chunkSize;
						const end = Math.min(start + chunkSize, file.size);
						const chunk = file.slice(start, end);

						const formData = new FormData();
						formData.append("file", chunk);
						formData.append("resumableChunkNumber", chunkIndex);
						formData.append("resumableTotalChunks", totalChunks);
						formData.append("originalName", file.name);
						formData.append("uploadId", uploadId);
						for (let key in extraData) {
							formData.append(key, extraData[key]);
						}
						const res = await uploadChunk(formData, chunkIndex, totalChunks);

						if (chunkIndex + 1 === totalChunks) {
							handleFileUploadComplete(res?.data?.data ?? res?.data);
						}
					}
					setIsUploadCompleted(true);
					setIsUploading(false);
				} catch (err) {
					setError(err);
				}
			}
		},
		// eslint-disable-next-line
		[url, chunkSize, isUploadCompleted]
	);

	return {
		uploadFile,
		progress,
		error,
		setError,
		isUploading,
		isUploadCompleted,
	};
};

export default useChunkFileUpload;
