import { SUPPLIERRFQ, SUPPLIERRFQTOTAL } from "constants/supplier";

const INITIAL_SECTORS_STATE = {
	total: "",
	data: "",
};

export const supplierRFQReducer = (state = INITIAL_SECTORS_STATE, action) => {
	switch (action.type) {
		case SUPPLIERRFQ:
			return {
				...state,
				data: action.payload,
			};
		case SUPPLIERRFQTOTAL:
			return {
				...state,
				total: action.payload,
			};
		default:
			return state;
	}
};
