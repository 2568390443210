import { Spinner } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { submitSubmissionGuestRFQ } from "actions/guestflow/guestRfqActions";
import { submitSubmissionRFQ } from "actions/RFQ/Supplier/rfqActions";
import { Checkbox } from "components/RFQ/Checkbox";
import { useState } from "react";

const { Button } = require("components/RFQ/Button");
const { CustomModal } = require("components/RFQ/Modal/CustomModal");

export const SubmitModal = ({
	showDialog,
	onClose,
	rfqId,
	companyId,
	saveSubmissionHandler,
	isGuest,
	guestEmail,
}) => {
	const [checked, setChecked] = useState(false);
	const [saving, setSaving] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const getAddendumURL = () => {
		const currentURL = window.location.href;
		const pathParts = currentURL.split("/");
		pathParts[pathParts.length - 1] = "addendum";
		return pathParts.join("/");
	};

	const dialogProperty = {
		icon: "blue-circle-check",
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: "Submit your application",
		description:
			"You're about to submit your response to this request. Please review all details carefully to ensure all your information is accurate and complete. ",
	};

	const submitHandler = () => {
		setSaving(true);
		saveSubmissionHandler(true).then((res) => {
			if (res) {
				setSaving(false);
				setSubmitting(true);
				(isGuest
					? submitSubmissionGuestRFQ(rfqId, guestEmail, checked ? 1 : 0)
					: submitSubmissionRFQ(rfqId, companyId, checked ? 1 : 0)
				).then((res) => {
					if (res) {
						setSubmitting(false);
						onClose();
						navigate("..");
					} else {
						setSubmitting(false);
						onClose();
					}
				});
			} else {
				setSaving(false);
				setSubmitting(false);
				onClose();
				return false;
			}
		});
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2 text-utility-error-50">
				<Button
					onClick={onClose}
					btntype="base"
					className="!w-full"
					disabled={submitting || saving}
				>
					Cancel
				</Button>
				<Button
					className="flex !w-full gap-3"
					onClick={submitHandler}
					disabled={!checked || submitting || saving}
				>
					{(submitting || saving) && <Spinner />}{" "}
					{saving ? "Saving ..." : submitting ? "Submitting ..." : "Submit Now"}
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			onClose={onClose}
			isOpen={showDialog}
			header={dialogProperty}
			footerComponent={<FooterComponent />}
			size="xl"
		>
			<div className="px-1 py-2">
				<Checkbox
					label={
						<span>
							I have read and acknowledged all{" "}
							<a
								target="_blank"
								href={`${getAddendumURL()}#rfx-container`}
								className="pe-1"
								rel="noreferrer"
							>
								addendums
							</a>
							published in this request.
						</span>
					}
					className="text-sm"
					onChange={(e) => setChecked(e.target.checked)}
					checked={checked}
				/>
			</div>
		</CustomModal>
	);
};
