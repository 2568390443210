import React from "react";
import { Heading } from "../Heading";
import { InvoiceViewSection } from "./styled";
import moment from "moment";
import { totalDaysFromDate, dateParser } from "utils/dateFormat";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const InvoiceView = ({
	company,
	componentRef,
	target_invoice,
	prevItem,
	prevLabel,
	invoiceLabel,
}) => {
	const amount = target_invoice.charged_amount / 100 || 0;
	const totalAmount = amount - target_invoice.discount;
	const options = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	};
	const formatSeperator = (number) => {
		return Number(number).toLocaleString("en", options);
	};

	return (
		<InvoiceViewSection ref={componentRef}>
			<LogoWrapper />

			<Heading
				fontFamily={"Roboto"}
				marginTop={"98px"}
				marginBottom={"32px"}
			>
				<span>Tax Invoice</span>
			</Heading>
			<div>
				<p className="instructions mb-0"></p>
			</div>
			<div className="flex">
				<div className="flex-table">
					<div className="label">
						<h3>Procuracon Pty Ltd</h3>
						<p>ABN</p>
						<p>Email</p>
					</div>
					<div className="details">
						<p>29 629 025 225</p>
						<p>support@procuracon.com.au</p>
					</div>
				</div>
				<div className="flex-table">
					<div className="label">
						<p>Invoice Number:</p>
						<p>Invoice Date:</p>
						<p>Invoice Status:</p>
					</div>
					<div className="invoice-details">
						<p>{"000" + target_invoice.id || <>&mdash;</>}</p>
						<p>
							{moment(target_invoice.created_at).format("DD/MM/YYYY") || (
								<>&mdash;</>
							)}
						</p>
						<p> {amount ? "Paid" : "Unpaid"}</p>
					</div>
				</div>
			</div>
			<div className="billing-details">
				<div className="label">
					<h3>Bill To:</h3>
					<p>{company?.name || "—"}</p>
					<p>{company?.primary_address?.address || "—"}</p>
					<p>
						{company?.primary_address?.state || "—"}{" "}
						{company?.primary_address?.postcode || "—"}
					</p>
					<p>Australia</p>
					<p>ABN: {company?.abn || "—"}</p>
					<p>Email: {company?.email || "—"}</p>
				</div>
			</div>
			<hr />
			<div className="flex">
				<div className="flex-table">
					<div className="label">
						<h3>Item</h3>
						{prevItem !== null && <p>{`Previous Plan : ${prevLabel}`}</p>}
						<p>{`Current Plan : ${invoiceLabel}`}</p>
						<p>
							From{" "}
							{moment(dateParser(target_invoice.transaction_date)).format(
								"DD/MM/YYYY"
							)}{" "}
							to{" "}
							{moment(dateParser(target_invoice.next_billing_date)).format(
								"DD/MM/YYYY"
							)}
							{" (total of "}
							{totalDaysFromDate(
								target_invoice.transaction_date,
								target_invoice.next_billing_date
							)}
							{" Days)"}
						</p>
					</div>
				</div>
				<div className="flex-table">
					<div className="label">
						<h3>Qty</h3>
						<h3>1</h3>
						<h3>Discount</h3>
						<h3>Subtotal</h3>
						<h3>GST</h3>
						<h3>Total</h3>
					</div>
					<div className="invoice-details">
						<h3>Price</h3>
						<p>
							{" "}
							{"$" + formatSeperator(amount - target_invoice.gst) || (
								<>&mdash;</>
							)}{" "}
						</p>
						<p>
							{" "}
							{"$" + formatSeperator(target_invoice.discount) || (
								<>&mdash;</>
							)}{" "}
						</p>
						<p>
							$
							{formatSeperator(
								amount - target_invoice.discount - target_invoice.gst
							) || <>&mdash;</>}
						</p>
						<p> {"$" + formatSeperator(target_invoice.gst) || <>&mdash;</>} </p>
						<p>{"$" + formatSeperator(totalAmount) || <>&mdash;</>}</p>
					</div>
				</div>
			</div>
			<hr />
		</InvoiceViewSection>
	);
};
