import styled from "styled-components";
import { HeadingStyled } from "../Heading";

export const SignupWrapper = styled.div`
	display: flex;
	width: 100vw;
	min-height: 100vh;
	position: relative;
	background: white;
`;

export const LeftSection = styled.div`
    width: 60%;
    padding:40px;
    padding-bottom: 160px;
    box-shadow: 10px 0px 20px 10px rgba(0, 0, 0, 0.15);
    ${(props) => props.theme.mediaQueries.xxl}{
        width:50%;
    }
    z-index:1;
    .float-bottom {
        position:absolute;
        display:flex;
        bottom:40px;
        left:40px;
        p{
            margin-top:16px;
            margin-right:24px;
            font-size:${(props) => props.theme.fontSizes.xs};
            a{
                color: ${(props) => props.theme.colors.succession};
            }
        }
    }
    
} 
`;
export const StepIndicators = styled.div`
	float: right;
`;

export const Step = styled.span`
	height: 8px;
	width: 8px;
	background-color: #8492a6;
	border-radius: 50%;
	display: inline-block;
	margin-left: 8px;
	${(props) =>
		props.completed &&
		`
        background-color: #0045F5;
    `}
`;

export const RightSection = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	margin-left: auto;
	width: 40%;
	${(props) => props.theme.mediaQueries.xxl} {
		width: 50%;
	}
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right bottom;
	height: 100vh;
`;
export const SignupSection = styled.div`
    margin: 80px 0px 0px 80px;
    max-width: 550px;
    .reset-arrow {
        display: flex;
        align-items: center;
        margin-bottom: 38px;
       
    }
    .instructions {
        font-size: 15px;
        margin-bottom:40px;
        font-family: ${(props) => props.theme.fonts.open_sauce_two};
        color: #6F7782;
    }
    .uptid{
        margin-top: 16px;
    }

    .required {
        color ${(props) => props.theme.colors.required};
    }

    .sub {
        font-size: 15px;
    }

    .support-link {
        color: ${(props) => props.theme.colors.black};
    }
    
    ${HeadingStyled} {
        font-size: 3.55rem;
        line-height: 0.9;
        color: #273444;
        max-width:435px;
        .welcome {
            margin-bottom: 40px;
        }

        span {
            display: block;
        } 
    }
`;

export const BottomLinks = styled.div`
	margin-bottom: 0px;
	display: flex;
	justify-content: left;
	font-family: "Roboto";
	a {
		color: ${(props) => props.theme.colors.royalBlueLight};
		font-size: ${(props) => props.theme.fontSizes.md};
		text-decoration: underline;
	}
	p {
		margin-left: 5px;
		margin-top: 0px;
	}
`;

export const SignUpWelcomeSection = styled.div`
	margin: 260px auto 260px auto;
	max-width: 519px;
	button {
		margin-top: 32px;
		width: 250px;
	}
	.instructions {
		font-size: 15px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		color: #6f7782;
	}
	.mb-0 {
		margin-bottom: 0px;
	}
	${HeadingStyled} {
		font-size: 56px;
		line-height: 0.9;
		color: #273444;
		max-width: 470px;
		.welcome {
			margin-bottom: 40px;
		}

		span {
			display: block;
		}
	}
`;

export const PaymentFormWrapper = styled.div`
	width: 100%;
	.row-fields {
		justify-content: space-between;
		display: flex;
		width: -webkit-fill-available;
		.block-field {
			width: 100%;
			padding: 0px 15px;
		}
	}
	.card-number {
		background: white;
		border: 1px solid #e7eaee;
		border-radius: 6px;
		padding: 18px;
		width: 100%;
		margin-bottom: 1.75rem;
		color: ${(props) => props.theme.colors.gray};
		transition: ${(props) => props.theme.transitions.default};
		height: 56px;

		::-webkit-input-placeholder {
			color: #6f7c8f;
			font-size: ${(props) => props.theme.fontSizes.lg};
		}
		:disabled {
			background: #f9f9f9;
			border-color: #f9f9f9;
			cursor: not-allowed;
			border: none;
			&:hover,
			&:focus {
				background-color: #d7d7d7;
			}
		}

		&:focus,
		&::hover {
			outline: none;
			border-color: ${(props) => props.theme.colors.royalBlueLight};
		}
	}
`;
export const FormLabel = styled.label`
	font-size: ${(props) => props.theme.fontSizes.xs};
	color: ${(props) => props.theme.colors.grayLight};
	letter-spacing: 1.25px;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	margin-bottom: ${(props) => props.theme.space.xxs};

	> span {
		color: #dc2538;
		margin-left: 3px;
	}
	.guideline {
		svg {
			position: inherit;
		}
	}
	.card-left-content {
		display: flex;
	}
	img {
		max-width: 70px;
	}
	.guide {
		border: 0.5px solid #8492a6;
		border-radius: 4px;
		letter-spacing: 0.1px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		font-size: 13px;
		text-transform: none;
		padding: 4px 8px;
		font-weight: normal;
		max-width: 400px;
	}
`;

export const AmountLabel = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
	margin-bottom: 40px;
	border-bottom: 1px solid ${(props) => props.theme.colors.navy};
	p {
		margin: 0px;
		color: #6f7c8f;
		font-size: ${(props) => props.theme.fontSizes.lg};
	}
	span {
		color: #336af7;
		font-size: ${(props) => props.theme.fontSizes.xxl};
	}
`;

export const SubsLabel = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	font-family: "Roboto";
	color: ${(props) => props.theme.colors.grayLight};
	font-size: ${(props) => props.theme.fontSizes.md};
`;
