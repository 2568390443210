import { navigate, useLocation } from "@reach/router";
import {
	getProjects,
	setProjectArchived,
	setProjectUnArchived,
} from "actions/RFQ/projectsAction";
import { Icon } from "assets/icons/Icon";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
	MenuDivider,
} from "components/RFQ/MenuDropdown";
import React from "react";
import { useDispatch } from "react-redux";

const EditDropdown = ({ id, currentTab, noBg }) => {
	const isArchived = currentTab === "archived";
	const listMenu = [
		{
			name: "Update Project",
			icon: "edit-icon",
			divider: true,
		},
		{
			name: isArchived ? "Unarchive" : "Archive",
			icon: "archive",
			divider: false,
		},
	];

	const dispatch = useDispatch();
	const location = useLocation();
	const redirectChecker = location.pathname.includes("projects/")
		? `edit-project/${id}`
		: `projects/edit-project/${id}`;

	const archivedHandler = async () => {
		if (isArchived) {
			await dispatch(setProjectUnArchived(id)).then((data) => {
				if (data?.status) {
					dispatch(getProjects({ reset: true }));
				}
			});
		} else {
			await dispatch(setProjectArchived(id)).then((data) => {
				if (data?.status) {
					dispatch(getProjects({ reset: true }));
				}
			});
		}
	};

	const actionFunc = (menu) => {
		switch (menu) {
			case "Update Project":
				navigate(redirectChecker);
				break;
			case "Unarchive":
				archivedHandler();
				break;
			case "Archive":
				archivedHandler();
				break;
			default:
				break;
		}
	};
	return (
		<div>
			<DropdownMenu>
				<DropdownMenuTrigger
					icon
					noBg={noBg}
				></DropdownMenuTrigger>
				<DropdownMenuDrawer>
					{!isArchived ? (
						listMenu.map((menu, index) => {
							return (
								<div key={index}>
									<DropdownItem
										className="w-full"
										onClick={() => actionFunc(menu.name)}
									>
										<Icon
											icon={menu.icon}
											color="#667085"
										/>
										<p
											className="font-roboto text-[14px] font-medium text-secondary-700"
											style={{ lineHeight: "20px" }}
										>
											{menu.name}
										</p>
									</DropdownItem>
									{menu.divider && <MenuDivider />}
								</div>
							);
						})
					) : (
						<DropdownItem
							className="w-full"
							onClick={() => actionFunc(listMenu[1].name)}
						>
							<Icon
								icon={listMenu[1].icon}
								color="#667085"
							/>
							<p
								className="font-roboto text-[14px] font-medium text-secondary-700"
								style={{ lineHeight: "20px" }}
							>
								{listMenu[1].name}
							</p>
						</DropdownItem>
					)}
				</DropdownMenuDrawer>
			</DropdownMenu>
		</div>
	);
};

export default EditDropdown;
