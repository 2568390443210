import { Flex } from "@chakra-ui/core";
import { Button } from "components/Button";
import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState } from "react";
import { SupplierListStyled } from "./styled";
import FilterButton from "components/FilterButton/FilterButton";
import ButtonViewGroup from "components/ButtonViewGroup/ButtonViewGroup";
import { useDisclosure } from "@chakra-ui/core";
import { getSupplierLists } from "actions/RFQ/supplierListsActions";
import { connect } from "react-redux";
import GridViewSupplierLists from "./GridViewSupplierLists";
import ListViewSupplierLists from "./ListViewSupplierLists";
import FilterSupplier from "components/RFQ/FilterSupplier/FilterSupplier";
import SearchBar from "components/RFQ/SearchBar/SearchBar";
import BadgeComp from "components/RFQ/BadgeComp/BadgeComp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SET_SUPPLIER_BADGES_COUNT } from "actions/RFQ/actionTypes";
import SortComp from "components/RFQ/SortComp/SortComp";
import { Icon } from "assets/icons/Icon";
import { ButtonLink } from "components/ButtonLink";

const sortedData = [
	{
		name: "Sort Alphabetically (A to Z)",
		value: "asc",
		sort: "Sort by: A to Z",
	},
	{
		name: "Sort Alphabetically (Z to A)",
		value: "desc",
		sort: "Sort by: Z to A",
	},
	{
		name: "Sort by Last Activity",
		value: "last_activity",
		sort: "Sort by: Activity",
	},
	{
		name: "Sort by Date Created",
		value: "created",
		sort: "Sort by: Date",
	},
];

const tabMenu = [
	{
		id: "myDepartment",
		name: "My Supplier Lists",
		showBadge: true,
	},
	{
		id: "companyApprovedList",
		name: "Panel Lists",
		showBadge: true,
	},
	{
		id: "archived",
		name: "Archived",
		showBadge: true,
	},
];

function SupplierLists({ getSupplierLists, supplierLists, isLoading }) {
	const [currentTab, setCurrentTab] = useState("myDepartment");
	const currentPage = supplierLists?.[currentTab]?.meta?.current_page;
	const [view, setView] = useState("grid");
	const [sorted, setSorted] = useState(sortedData[2]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [openPopUp, setOpenPopUp] = useState(false);
	const [filters, setFilters] = useState(null);
	const [loadingPagination, setLoadingPagination] = useState(false);
	const [search, setSearch] = useState("");

	// get data from redux store for counting badges
	const supplierListBadgeCounts = useSelector(
		(state) => state.rfq?.supplierLists?.supplierCount
	);

	const dispatch = useDispatch();

	const pageChecker = (pages = "current_page") => {
		let page = 1;

		if (JSON.stringify(supplierLists) !== "{}") {
			page = supplierLists?.[currentTab]?.meta?.[pages];
		}
		return page;
	};

	const [currPage, setCurrPage] = useState(pageChecker("current_page"));
	const [lastPage, setLastPage] = useState(pageChecker("last_page"));

	const onScroll = async () => {
		const { clientHeight, scrollHeight, scrollTop } =
			document.getElementById("element-scroll");
		if (
			!isLoading &&
			!loadingPagination &&
			scrollTop + clientHeight >= scrollHeight - 180
		) {
			if (currPage < lastPage) {
				setLoadingPagination(true);
				getSupplierLists({
					page: currPage + 1,
					search,
					sort: sorted.value,
					filters,
				});
				setLoadingPagination(false);
			}
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			onScroll();
		};

		const scrollableElement = document.getElementById("element-scroll");

		if (scrollableElement) {
			scrollableElement.addEventListener("scroll", handleScroll);

			return () => {
				scrollableElement.removeEventListener("scroll", handleScroll);
			};
		}
		// eslint-disable-next-line
	}, [currPage, lastPage, supplierLists, isLoading, loadingPagination]);

	useEffect(() => {
		setLoadingPagination(false);
		// eslint-disable-next-line
	}, [currPage]);

	useEffect(() => {
		setCurrPage(1);
		getSupplierLists({
			page: 1,
			search,
			sort: sorted.value,
			filters,
			reset: true,
		});
		//eslint-disable-next-line
	}, [search, sorted, filters, currentTab]);

	useEffect(() => {
		setCurrPage(pageChecker("current_page"));
		setLastPage(pageChecker("last_page"));
		//eslint-disable-next-line
	}, [supplierLists, currentPage, currentTab]);

	const getSortingProject = () => {
		setOpenPopUp(false);
	};

	useEffect(() => {
		const currentTotal = supplierLists?.[currentTab]?.data?.length;
		if (currentTotal !== undefined && currentTotal !== null) {
			const updatedBadgeCounts = {
				...supplierListBadgeCounts,
				[currentTab]: currentTotal,
			};
			dispatch({
				type: SET_SUPPLIER_BADGES_COUNT,
				payload: updatedBadgeCounts,
			});
		}
		//eslint-disable-next-line
	}, [search, sorted, filters, currentTab]);

	return (
		<SupplierListStyled>
			<TabHeader
				heading="Supplier Lists"
				description={`Create and manage your organisations supplier list.${currentTab === "companyApprovedList" ? " (Admin permissions only)" : ""}`}
				tooltip="A Supplier List includes your preferred Suppliers, such as Lead Architects, Tier 2 Contractors or Electrical Contractors."
			>
				<ButtonLink
					to="new-supplier-list"
					btnType="secondary"
					className="max-h-12"
				>
					<Icon
						icon="plus"
						className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
					/>
					Create Supplier List
				</ButtonLink>
			</TabHeader>
			<div id="layout-dashboard">
				<div>
					<Flex style={{ justifyContent: "space-between" }}>
						<Flex style={{ gap: "8px" }}>
							{tabMenu.map((tbMenu, index) => {
								return (
									<div
										key={index}
										className="group"
									>
										<Button
											btntype="tab"
											active={currentTab === tbMenu.id ? true : false}
											onClick={() => setCurrentTab(tbMenu.id)}
										>
											<div
												style={{ fontFamily: "roboto", fontWeight: "500" }}
												className={`text-${currentTab === tbMenu.id ? "to-utility-brand-700" : "text-primary-500"}`}
											>
												{tbMenu.name}
											</div>
											{tbMenu.showBadge && (
												<BadgeComp
													title={tbMenu.id}
													active={currentTab}
													total={
														supplierLists?.[tbMenu.id]?.data?.length ||
														supplierListBadgeCounts?.[tbMenu.id] ||
														0
													}
												/>
											)}
										</Button>
									</div>
								);
							})}
						</Flex>
						<Flex style={{ gap: "16px" }}>
							<SearchBar setState={setSearch} />
							<FilterButton
								onClick={onOpen}
								filters={filters}
							/>
							<SortComp
								data={sortedData}
								sorted={sorted}
								onClickCB={(item) => {
									getSortingProject();
									setSorted(item);
								}}
								openPopUpCB={openPopUp}
								setOpenPopUpCB={setOpenPopUp}
							></SortComp>
							<ButtonViewGroup
								setView={setView}
								view={view}
							/>
						</Flex>
					</Flex>
					<FilterSupplier
						isOpen={isOpen}
						onClose={onClose}
						setFilters={setFilters}
						filters={filters}
					/>
				</div>
				{view === "grid" ? (
					<GridViewSupplierLists
						isLoading={isLoading}
						data={supplierLists?.[currentTab]?.data}
						archived={currentTab === "archived"}
						currPage={currPage}
						currentTab={currentTab}
					/>
				) : (
					<ListViewSupplierLists
						isLoading={isLoading}
						data={supplierLists?.[currentTab]?.data}
						archived={currentTab === "archived"}
						currPage={currPage}
						currentTab={currentTab}
					/>
				)}
			</div>
		</SupplierListStyled>
	);
}

const mapStateToProps = (state) => {
	return {
		supplierLists: state.rfq.supplierLists.supplier,
		isLoading: state.rfq.supplierLists.loading,
	};
};

export default connect(mapStateToProps, { getSupplierLists })(SupplierLists);
