import React from "react";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Input } from "components/RFQ/Input";
import { Button } from "components/RFQ/Button";
import { Checkbox } from "components/RFQ/Checkbox";
import { saveQuestionnareTemplate } from "actions/RFQ/rfqsAction";
import { useDispatch } from "react-redux";
import { addTemplate } from "actions/RFQ/templateActions";
import { Spinner } from "@chakra-ui/react";
import { isNull } from "utils/validation";

const SubmitRFQModal = ({
	isOpen,
	setIsOpen,
	rfqId,
	isSaving,
	isSubmitting,
	handleSubmit,
}) => {
	const [checked, setChecked] = React.useState(false);
	const [templateName, setTemplateName] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [additionalMessage, setAdditionalMessage] = React.useState("");
	const isEmpty = templateName === "";
	const dispatch = useDispatch();

	const headerProperty = {
		icon: "request-for-quote",
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: "Send RFx",
		description:
			"By selecting SEND, this Rfx will be sent to your nominated participants. Would you like to proceed?",
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const onClickSendHandler = async () => {
		if (isNull(additionalMessage)) {
			return;
		}

		if (checked && !isEmpty) {
			setIsLoading(true);
			if (!isEmpty) {
				const saved = await saveQuestionnareTemplate(rfqId, templateName);
				if (saved) {
					dispatch(addTemplate(saved));
					handleSubmit("submit", additionalMessage);
				}
				setTemplateName("");
			}
			setIsLoading(false);
		} else {
			handleSubmit("submit", additionalMessage);
		}
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					onClick={onClose}
					btntype="base"
					className="!w-full"
				>
					Cancel
				</Button>
				<Button
					variant="primary"
					className="flex !w-full gap-3"
					disabled={
						(checked && isEmpty) || isLoading || isSubmitting || isSaving
					}
					onClick={onClickSendHandler}
				>
					{isLoading ? "Saving template.." : isSaving ? "Sending RFx" : "Send"}
					{(isLoading || isSubmitting || isSaving) && (
						<Spinner
							width={4}
							height={4}
						/>
					)}
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={!isSubmitting ? onClose : null}
			header={headerProperty}
			footerComponent={<FooterComponent />}
		>
			<div>
				<div className="py-4">
					<Input
						label="Additional Message"
						name="additional_message"
						type="textArea"
						placeholder="Enter additional message you want to send on mail"
						required
						error={
							isNull(additionalMessage) && "Additional message is required"
						}
						onChange={(e) => setAdditionalMessage(e.target.value)}
					/>
				</div>
				<div className="space-y-6 rounded-xl bg-[#EEF5FFB2] p-6">
					<Checkbox
						label="I’d like to save this RFx as a template for future use."
						className="text-sm"
						onChange={(e) => setChecked(e.target.checked)}
						checked={checked}
					/>
					{checked && (
						<Input
							label="Template name"
							name="template_name"
							type="text"
							placeholder="Enter Template name"
							onChange={(e) => {
								setTemplateName(e.target.value);
							}}
						/>
					)}
				</div>
			</div>
		</CustomModal>
	);
};

export default SubmitRFQModal;
