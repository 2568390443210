import React from "react";
import styled from "styled-components";
import { color, space, fontSize } from "styled-system";

export const HeadingStyled = styled.h1`
	font-family: ${(props) =>
		props.fontFamily ? props.fontFamily : props.theme.fonts.sans_serif_heading};
	${color}
	font-weight: 400;
	margin: 0;
	${space}
	line-height: 1.25;
	${fontSize}
	margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
	margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
`;

export const Heading = ({ children, ...rest }) => {
	return <HeadingStyled {...rest}>{children}</HeadingStyled>;
};

Heading.defaultProps = {
	color: "black",
};
