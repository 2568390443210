import React from "react";

import { DocumentControlsStyled, DocumentActionButton } from "./styled";
import { ReactComponent as Edit } from "../../icons/edit.svg";
import { ReactComponent as Cross } from "../../icons/cross-circle.svg";

export const DocumentControls = ({ item, onDelete, onEdit, ...props }) => {
	return (
		<DocumentControlsStyled>
			<DocumentActionButton
				type="button"
				onClick={() => onEdit(item)}
			>
				<Edit />
				Edit
			</DocumentActionButton>

			<DocumentActionButton
				type="button"
				onClick={() => onDelete(item)}
				danger
			>
				<Cross />
				Delete
			</DocumentActionButton>
		</DocumentControlsStyled>
	);
};
