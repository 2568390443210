import React from "react";
import { FileCard } from "components/RFQ/FileCard";
import { FileUpload } from "components/RFQ/FileUpload";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export default function QuoteFile({
	quoteFilepath,
	companyName,
	rfqId,
	disabled,
}) {
	const fileData = {
		name: "Quote template.xlsx",
		path: quoteFilepath,
		description: `${companyName} has submitted a quote template. Please download it and re-upload below once it has been completed`,
	};

	return (
		<>
			<LabelForm label="Download quotation template" />
			<FileCard
				file={fileData}
				isPreview
			/>

			<FileUpload
				type="file"
				label="Attach quote response"
				name="quote_file"
				className="my-4 w-full"
				types={["xlsx", "xls", "csv"]}
				required
				multiple={false}
				maxFileSize={100}
				disabled={disabled}
				fileExtraData={{
					model: "rfq_quote_file_response",
					rfq_id: rfqId,
				}}
			/>
		</>
	);
}
