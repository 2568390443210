import React from "react";
import { Modal } from "../Modal";
import { ShareProfile } from "./ShareProfile";

export const ShareProfileModal = ({ isOpen, setIsOpen }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			width="650"
			height="570px"
			closeIconHeight="24px"
			closeIconWidth="24px"
			closeIconTop="20px"
		>
			<ShareProfile />
		</Modal>
	);
};
