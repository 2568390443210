import { Flex } from "@chakra-ui/react";
import { CardQuestioner } from "components/RFQ/CardQuestioner";
import { FieldForm } from "components/RFQ/Input/Input";
import React from "react";
import { QuestionComponent } from "./QuestionTypeComponent";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorderList } from "utils/reorderList";
import { useField } from "formik";
import { getSortedReturnables } from "pages/RFQ/RFQ/CreateRFQ/CreateRFQPage/ReturnableSchedules/ReturnableSchedulesHelpers";

const SectionContent = ({ OptionSections, index }) => {
	const fieldname = (name) => `sections[${index}]${name}`;
	const [, meta] = useField(fieldname("questionnaire"));

	return (
		<div className="mb-5 space-y-3">
			<Flex gap={5}>
				<FieldForm
					placeholder="Add Section Name: E.g. Returnable Schedule 1.0"
					name={fieldname("name")}
				/>
			</Flex>
			<Flex>
				<FieldForm
					type="textArea"
					name={fieldname("description")}
					placeholder="Add Section Description: E.g. Program and Methodology"
				/>
			</Flex>
			{meta?.error && typeof meta.error === "string" && meta.touched ? (
				<p className="text-md mt-2 text-red-500">{meta.error}</p>
			) : null}
		</div>
	);
};

const ReturnableSchedulesCard = ({
	OptionSections,
	isSmartField,
	dragHandle,
	index,
	deleteSectionsHandler,
	copySectionsHandler,
	sectionList,
	setFieldError,
}) => {
	const fieldname = (name) => `sections[${index}]${name}`;
	const [, meta, helper] = useField(fieldname("questionnaire"));
	const questionsValue = meta.value;
	const sectionIndex = index;
	const dragEndHandler = (result) => {
		let value = questionsValue;
		let valueSetter = (newValue) => (value = newValue);
		reorderList(value, result, valueSetter);
		const reordered = value.map((item, index) => ({
			...item,
			sort_order: index + 1,
		}));
		helper.setValue(reordered);
	};

	const copyQuestionHandler = (index) => {
		const formQuestion = {
			...meta.value[index],
			sort_order: meta.value.length + 1,
			id: null,
			uuid: Math.floor(1000 + Math.random() * 9000),
		};
		helper.setValue(getSortedReturnables([...meta.value, formQuestion]));

		const sectionLastIndex = sectionIndex;
		const questionLastIndex = questionsValue?.length - 1 || 0;
		setTimeout(() => {
			const cardElement = document.getElementById(
				`question-${sectionLastIndex}-${questionLastIndex}`
			);
			cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}, 200);
	};

	const deleteQuestionHandler = (index) => {
		const formValue = meta.value[index];
		if (formValue?.id) {
			let newFormQuestions = [...meta.value];
			newFormQuestions[index] = { ...formValue, isRemove: 1, fields: [] };
			helper.setValue(getSortedReturnables(newFormQuestions));
		} else {
			const newFormQuestions = [...meta.value];
			newFormQuestions.splice(index, 1);
			helper.setValue(getSortedReturnables(newFormQuestions));
		}
	};

	const isSectionDeletable = () => {
		if (sectionList?.length > 2) {
			return true;
		}

		if (sectionList?.length > 1) {
			const deletedSection = sectionList?.find(
				(section) => section?.name === "deleted"
			);
			if (!deletedSection) {
				return true;
			}
		}
		return false;
	};

	const isQuestionnaireDeletable = (index) => {
		const removedQuestions = sectionList?.[index].questionnaire?.filter(
			(question) => question.isRemove === 1
		);
		const sectionQuestions = sectionList?.[index].questionnaire;

		return (
			sectionQuestions.length > 1 &&
			removedQuestions?.length < sectionQuestions.length - 1
		);
	};

	return (
		<div
			className="h-max w-full scroll-pt-8"
			id={`section-container-${index}`}
		>
			{
				<CardQuestioner
					dragHandle={dragHandle}
					isSection={true}
					isSmartField={isSmartField}
					deleteSectionHandler={() => deleteSectionsHandler(index)}
					copySectionHandler={() => copySectionsHandler(index)}
					id={`section-${index}`}
					label={`Section ${index + 1}`}
					isDeletable={isSectionDeletable()}
				>
					<SectionContent
						OptionSections={OptionSections}
						index={index}
					/>
				</CardQuestioner>
			}
			<DragDropContext
				onDragEnd={dragEndHandler}
				className="my-10"
			>
				{/* index = section index and i = questionnaire index */}
				<Droppable
					droppableId={`questioner-container-${index}`}
					direction="vertical"
				>
					{(provided) => (
						<div
							id="questioner-container"
							className="mb-8"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{questionsValue &&
								questionsValue.length > 0 &&
								questionsValue?.map((question, i) => {
									return question?.isRemove !== 1 ? (
										<Draggable
											key={`question-${i}`}
											draggableId={`question-${i}`}
											index={i}
										>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<CardQuestioner
														dragHandle={provided.dragHandleProps}
														sectionIndex={index}
														index={i}
														copyQuestionHandler={() => copyQuestionHandler(i)}
														deleteQuestionHandler={() =>
															deleteQuestionHandler(i)
														}
														id={`question-${index}-${i}`}
														label={`Section ${index + 1} - Question ${i + 1}`}
														isDeletable={isQuestionnaireDeletable(index)}
													>
														<QuestionComponent
															index={i}
															sectionIndex={index}
															question={question}
															isDeletable
															setFieldError={setFieldError}
														/>
													</CardQuestioner>
												</div>
											)}
										</Draggable>
									) : null;
								})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default ReturnableSchedulesCard;
