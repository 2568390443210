import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import api from "api";
import { connect } from "react-redux";
import { showToast } from "utils/helpers";
import { DataTable } from "../../../components/DataTable";
import { ProjectLink } from "../../../components/ProjectLink";
import { Toolbar } from "../../../components/Toolbar";
import { Button } from "../../../components/Button";
import { SearchBox } from "../../../components/SearchBox";
import { NoResults } from "../../../components/NoResults";
import { editPastProject } from "../../../actions/companyActions";
import { getPastProjects } from "../../../actions/companyActions";
import { SkeletonBase } from "components/Skeleton";
import { getCookie } from "utils/cookiesHelper";

const ProjectListingComponent = ({
	projects,
	companyId,
	editPastProject,
	handleAddProjectClick,
	handleRemoveProjectClick,
	edit,
	hideSearch,
	disableAddButton,
	disabledTest,
	loadingPastProjects,
	profileTypeId,
	getPastProjects,
	...props
}) => {
	const [searchResults, setSearchResults] = useState([]);
	const [hasSearched, setHasSearched] = useState(null);
	const [isSearching, setIsSearching] = useState(true);

	const tableHeaders = [
		"",
		{ label: "Name", key: "name" },
		{ label: "Primary Sector", key: "sector.name" },
		{ label: "Construction Value (AUD)", key: "value" },
		{ label: "Year Complete", key: "year_completed" },
		{ label: "Procurement Model", key: "procurement_methods[0].name" },
		{
			label: "Featured Projects",
			hover: "Select 2 projects to be featured first in your public profile.",
		},
	];

	const tableHeadersSubcontractor = [
		"",
		{ label: "Name", key: "name" },
		{ label: "Primary Sector", key: "sector.name" },
		{ label: "Trade Packages Value (AUD)", key: "trade_value" },
		{ label: "Year Complete", key: "year_completed" },
		{ label: "Trade Package/s", key: "trades[0].name" },
		{
			label: "Featured Projects",
			hover: "Select 2 projects to be featured first in your public profile.",
		},
	];

	function getApiWithToken() {
		const token = getCookie("access_token");
		return api.extend({
			hooks: {
				beforeRequest: [
					(request) => {
						request.headers.set("Authorization", `Bearer ${token}`);
					},
				],
			},
		});
	}

	const onSearchSubmit = async (values) => {
		const { search, sector, keyword } = values;
		if (!search && !sector && !keyword) {
			setHasSearched(false);
		} else {
			setHasSearched(true);
		}
		setIsSearching(true);

		try {
			const filteredProjects = await getApiWithToken()
				.get(
					`past-projects?filter[company_id]=${companyId}&filter[company_id]=${companyId}&filter[name]=${search},${keyword}&filter[sector_id]=${
						sector || ""
					}`
				)
				.json();
			if (keyword) {
				const filteredProjects2 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[year_completed]=${keyword}`
					)
					.json();
				const filteredProjects3 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[summary]=${keyword}`
					)
					.json();
				const filteredProjects4 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[company_role]=${keyword}`
					)
					.json();
				const filteredProjects5 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[procurement_or_contract_type]=${keyword}`
					)
					.json();
				const filteredProjects6 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[innovation_value_summary]=${keyword}`
					)
					.json();
				const filteredProjects7 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[awards]=${keyword}`
					)
					.json();
				const filteredProjects8 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[status]=${keyword}`
					)
					.json();
				const filteredProjects9 = await getApiWithToken()
					.get(
						`past-projects?filter[company_id]=${companyId}&filter[client_name]=${keyword}`
					)
					.json();
				filteredProjects.data = [
					...new Map(
						[
							...filteredProjects.data,
							...filteredProjects2.data,
							...filteredProjects3.data,
							...filteredProjects4.data,
							...filteredProjects5.data,
							...filteredProjects6.data,
							...filteredProjects7.data,
							...filteredProjects8.data,
							...filteredProjects9.data,
						].map((o) => [o.id, o])
					).values(),
				];
			}
			setSearchResults(filteredProjects.data);
		} catch (e) {
			console.log(e);
		}

		setIsSearching(false);
	};

	const onVisibiltyToggle = async (id, currentStatus) => {
		try {
			await editPastProject(
				id,
				{
					status: currentStatus === "published" ? "draft" : "published",
				},
				false,
				null,
				null,
				null,
				true
			);
			showToast("Visibility updated successfully.", "Success", true);
		} catch (e) {
			console.error(e);

			showToast("Visibility failed to update.", "Error");
		}
	};

	useEffect(() => {
		if (!props?.disableUseEffect) {
			getPastProjects(companyId);
		}
		// eslint-disable-next-line
		setIsSearching(false);
	}, [companyId, getPastProjects, props?.disableUseEffect]);

	return (
		<>
			<Toolbar>
				{edit && (
					<Button
						style={{ maxHeight: "48px" }}
						as={Link}
						to="./create-project"
					>
						Add Project
					</Button>
				)}

				<SearchBox
					placeholder="Search resources"
					handleFormSubmit={onSearchSubmit}
					showPorojectsCount
					companyId={companyId}
					sectorFilter
					keywordFilter
					whiteInput={true}
				/>
			</Toolbar>
			{isSearching ||
			(loadingPastProjects !== undefined && loadingPastProjects) ? (
				[...Array(5)].map((e, i) => (
					<div className="my-4 flex h-[72px] w-full items-center justify-between gap-3 bg-gray-100 px-4 py-1.5">
						<SkeletonBase
							key={i}
							width="80px"
							height="55px"
						/>
						<SkeletonBase
							key={i}
							width="80%"
							height="36px"
						/>
						<SkeletonBase
							key={i}
							width="135px"
							height="48px"
						/>
					</div>
				))
			) : (hasSearched && !searchResults.length) || !projects?.length ? (
				<NoResults />
			) : (
				<DataTable
					headers={
						profileTypeId === 3 ? tableHeadersSubcontractor : tableHeaders
					}
					data={hasSearched !== null ? searchResults : projects}
					component={ProjectLink}
					loading={loadingPastProjects}
					componentProps={{
						className: "col sm-12",
						edit: edit,
						onVisibiltyToggle: onVisibiltyToggle,
						handleAddProjectClick: handleAddProjectClick,
						handleRemoveProjectClick: handleRemoveProjectClick,
						disabledTest: disabledTest,
					}}
					isEdit={edit}
					companyId={companyId}
					hasSearched={hasSearched}
				/>
			)}
		</>
	);
};

ProjectListingComponent.defaultProps = {
	edit: true,
};

const mapStateToProps = (state) => {
	return {
		loadingPastProjects: state.companyProfile.loadingPastProjects,
		profileTypeId: state.search.activeCompany.profile_type.id,
		companyUserId: state.search.activeCompany.id,
	};
};

export const ProjectListing = connect(mapStateToProps, {
	editPastProject,
	getPastProjects,
})(ProjectListingComponent);
