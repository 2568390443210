import { Flex } from "@chakra-ui/react";
import { BoxQuestioner } from "components/RFQ/BoxQuestioner";
import React from "react";

const QuoteTotal = () => {
	return (
		<div className="w-full font-roboto">
			<BoxQuestioner className="card-shadow overflow-hidden pl-6">
				<Flex
					align={"center"}
					justifyContent={"space-between"}
				>
					<div>
						<h1 className="text-lg font-semibold">Total</h1>
					</div>
					<Flex
						className="w-1/4"
						alignContent={"space-between"}
					>
						<div className="w-full border-l p-3">
							<h2 className="text-xs font-semibold">Subtotal</h2>
							<td className="text-gray-300"> ${"0.00"}</td>
						</div>
						<div className="w-full border-l p-3">
							<h2 className="text-xs font-semibold">GST (10%)</h2>
							<td className="text-gray-300"> ${"0.00"}</td>
						</div>
						<div className="w-full border-l bg-royal-blue p-3 text-white">
							<h2 className="text-xs font-semibold">Total Cost</h2>
							<td> ${"0.00"}</td>
						</div>
					</Flex>
				</Flex>
			</BoxQuestioner>
		</div>
	);
};

export default QuoteTotal;
