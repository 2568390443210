import styled from "styled-components";
import { ReactComponent as Cross } from "../../../icons/cross-circle.svg";

const getColor = (props) => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isDragActive) {
		return "#2196f3";
	}
	return "#eeeeee";
};

export const DeleteStyled = styled(Cross)`
	opacity: 1;
	position: absolute;
	top: 2px;
	right: 2px;
	border-radius: 50%;
	display: block;
	background: white;
	padding: 2px;
	width: 20px;
	height: 20px;
	transition: ${(props) => props.theme.transitions.default};
	color: ${(props) => props.theme.colors.redDark};
`;

//this is used for the gallery field items as we need to wrap the delete button
export const ImageContainer = styled.div`
	transition: ${(props) => props.theme.transitions.default};
	position: relative;
	margin: 3px;

	img {
		transition: ${(props) => props.theme.transitions.default};
	}

	&:hover {
		img {
			opacity: 0.35;
		}

		${DeleteStyled} {
			opacity: 1;
		}
	}
`;

export const ImageUploadStyled = styled.div`
	display: flex;
	flex-grow: 0;
	flex-direction: column;
	padding: ${(props) => (props.uploadImage ? "2rem" : props.theme.space.xs)};
	border-width: 2px;
	border-radius: 3px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: rgba(255, 255, 255, 0.5);
	color: ${(props) => props.theme.colors.textDark};
	outline: none;
	transition: border 0.24s ease-in-out;
	margin: 0 0 ${(props) => props.theme.space.xs};
	cursor: ${(props) => (props.isSubmitting ? "not-allowed" : "pointer")};
	font-size: ${(props) => props.theme.fontSizes.sm};
	transition: ${(props) => props.theme.transitions.default};
	max-width: ${(props) => (props.gallery ? "none" : "100%")};
	text-align: center;
	${"" /* min-height: ${(props) => (props.gallery ? '100px' : 'auto')}; */}
	flex-direction: ${(props) => (props.files ? "column" : "row")};
	flex-wrap: wrap;
	justify-content: ${(props) => (props.gallery ? "flex-start" : "center")};
	align-items: ${(props) => (props.files ? "flex-start" : "center")};
	flex-direction: ${(props) => (props.column ? "column" : "row")};
	width: 100%;

	${(props) =>
		props.width &&
		`
        max-width: ${props.width};
    `}

	&::hover {
		border-color: ${(props) => props.theme.colors.mint};
	}

	img {
		width: ${(props) => (props.gallery ? "100px" : "auto")};
		height: ${(props) => (props.gallery ? "100px" : "auto")};
		object-fit: ${(props) => (props.gallery ? "cover" : "scale-down")};
		${(props) => (!props.gallery ? "" : "max-height: 200px")};
	}

	.guideline {
		svg {
			position: inherit;
		}
	}
	.guide {
		border: 0.5px solid #8492a6;
		border-radius: 4px;
		letter-spacing: 0.1px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		font-size: 13px;
		text-transform: none;
		padding: 4px 8px;
		font-weight: normal;
		max-width: 400px;
	}
`;

export const ImageUploadLabel = styled.p`
	font-size: ${(props) => props.theme.fontSizes.md};
	font-weight: 500;
	color: ${(props) => props.theme.colors.navy};
	margin: 0 0 ${(props) => props.theme.space.xs};
`;

export const ImagePreview = styled.div`
	position: relative;
	background-color: transparent;
	transition: ${(props) => props.theme.transitions.default};

	&:hover {
		img {
			opacity: 0.7;
		}

		${DeleteStyled} {
			opacity: 1;
		}
	}
`;
