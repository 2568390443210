import { useEffect } from "react";

const useRefreshWarning = (warnUserFromReloading, message) => {
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (warnUserFromReloading) {
				event.preventDefault();
				event.returnValue = "";
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [message, warnUserFromReloading]);
};

export default useRefreshWarning;
