import api from "api";
import * as constants from "./actionTypes";
import { processFormData, showToast } from "../utils/helpers";
import { getCookie, setCookie } from "utils/cookiesHelper";

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const getCompanyUsers = (company_id) => async (dispatch) => {
	(async () => {
		const company_users = await api.get(`all-users/${company_id}`).json();
		await dispatch({
			type: constants.GET_COMPANY_USERS,
			payload: company_users,
		});
	})();
};

export const update_user = (values, profile = false) => {
	values.position === "administrator"
		? (values["company_admin"] = 1)
		: (values["company_admin"] = 0);
	if (!values.password) {
		delete values["password"];
	}
	return async (dispatch) => {
		const formData = processFormData(values);
		try {
			const result = await api
				.patch(`users/${values.id}`, {
					body: formData,
				})
				.json();
			const user = result.data;
			if (profile && user) {
				localStorage.setItem("user", JSON.stringify(user));
				await dispatch({
					type: constants.GET_USER,
					payload: user,
				});
				return user;
			} else {
				return user;
			}
		} catch (e) {
			const error = await e.response.json();
			const errorMessages = error?.errors?.password;
			const errorMessage =
				errorMessages.length < 2
					? errorMessages[0]
					: errorMessages[errorMessages.length - 1];
			showToast(errorMessage || error.error, "Error");
		}
	};
};

export const updateUserTourFlags = async (userId, uiTour, rfqTour) => {
	try {
		const api = getApiWithToken();

		const payload = {
			json: {
				ui_tour_seen: uiTour ? 1 : 0,
				rfq_tour_seen: rfqTour ? 1 : 0,
			},
		};

		console.log("Sending request with payload:", payload);

		const result = await api.post(`users/user-guide`, payload).json();

		if (result.success) {
			console.log("Tour flags updated successfully:", result.user);

			// Update the cookie with the new values
			const rawUsersCookie = getCookie("users");
			const currentUsers = JSON.parse(rawUsersCookie || "{}");

			if (currentUsers?.user) {
				const updatedUsers = {
					...currentUsers,
					user: {
						...currentUsers.user,
						...result.user, // Update with all new user data from API
					},
				};

				setCookie("users", JSON.stringify(updatedUsers));
			}

			return result.user;
		}

		throw new Error(result.message || "Update failed");
	} catch (error) {
		console.error("Error updating tour flags", {
			error,
			requestData: {
				userId,
				uiTour,
				rfqTour,
			},
		});

		// Even on error, ensure cookies reflect the attempted state
		const rawUsersCookie = getCookie("users");
		const currentUsers = JSON.parse(rawUsersCookie || "{}");

		if (currentUsers?.user) {
			const updatedUsers = {
				...currentUsers,
				user: {
					...currentUsers.user,
					ui_tour_seen: uiTour ? 1 : 0,
					rfq_tour_seen: rfqTour ? 1 : 0,
				},
			};

			setCookie("users", JSON.stringify(updatedUsers));
		}

		throw error;
	}
};

export const changePasswordActions = (values, userId) => {
	return async (dispatch) => {
		const formData = processFormData(values);
		try {
			const result = await api
				.patch(`users/${userId}`, {
					body: formData,
				})
				.json();
			const user = result.data;
			if (user) {
				localStorage.setItem("user", JSON.stringify(user));
				await dispatch({
					type: constants.GET_USER,
					payload: user,
				});
				showToast("Password changed", "Success", true);
				return user;
			} else {
				showToast("Password changed", "Success", true);
				return user;
			}
		} catch (e) {
			const error = await e.response.json();
			showToast(error.message || "Failed to change password", "Error");
		}
	};
};

export const allTransaction = (company_id) => {
	return async (dispatch) => {
		(async () => {
			try {
				const all_transactions = await getApiWithToken()
					.get(`all-transaction/${company_id}`)
					.json();

				await dispatch({
					type: constants.TRANSACTION_DETAILS,
					payload: all_transactions.data,
				});
			} catch (e) {
				console.log(e.response);
			}
		})();
	};
};

export const delete_user = (id) => {
	return async () => {
		try {
			const user = await api.delete(`users/${id}`).json();
			if (user.status === "success") {
				return user;
			} else {
				return user;
			}
		} catch (e) {
			// const error = await e.response;
			// cheat fix
			showToast("User deleted", "Success", true);
			// showToast(error.error, "Error");
		}
	};
};

export const delete_plan = (id) => {
	return async (dispatch) => {
		const formData = processFormData({
			account_type_id: null,
		});

		const company = await getApiWithToken()
			.patch(`companies/${id}`, { body: formData })
			.json();
		await dispatch({
			type: constants.SUBMIT_COMPANY_PROFILE_FORM,
			payload: company.data,
		});
		showToast("Plan Updated", "Success", true);
		return company;
	};
};

export const create_draft = (values) => {
	return async (dispatch) => {
		const formData = processFormData(values);
		try {
			const user = await getApiWithToken()
				.post("user-draft/create", {
					body: formData,
				})
				.json();
			if (user.success) {
				showToast("Details Saved", "Success", true);
			}
		} catch (e) {
			const error = await e.response.json();
			showToast(error.error, "Error");
		}
	};
};

export const cancel_subs = async (mes) => {
	const formData = new FormData();
	formData.append("cancellation_reason", mes);
	try {
		const canceled = await getApiWithToken()
			.post("subscription/cancel", {
				body: formData,
				timeout: 60000,
			})
			.json();
		showToast(
			"Subscription cancelled. All Admin Users will be notified via email of this cancellation",
			"Success",
			true
		);
		return canceled;
	} catch (e) {
		showToast("Cancelling Subscription Failed", "Error");
		throw e;
	}
};

export const restart_subs = () => {
	return async (dispatch) => {
		try {
			const restarted = await getApiWithToken()
				.patch("subscription/restart", {})
				.json();
			await dispatch({
				type: constants.SET_ACCOUNT_STATUS,
				payload: null,
			});
			showToast("Subscription Restarted", "Success", true);
			return restarted;
		} catch (e) {
			showToast("Restarting Subscription Failed", "Error");
			throw e;
		}
	};
};

export const setAccountStatus = (status) => {
	return async (dispatch) => {
		await dispatch({
			type: constants.SET_ACCOUNT_STATUS,
			payload: status,
		});
	};
};
